import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DropdownContent from "./DropdownContent";

interface DropdownComponentProps {
  dropdownOption: { value: string; name: string }[];
  placeHolderText?: string;
  handleChange: Function;
  selectedOption: string;
  label?: string;
  disabled?: boolean;
}

const PaginationDropdownComponent: React.FC<DropdownComponentProps> = ({
  dropdownOption = [],
  handleChange,
  selectedOption = "",
  label = "",
  disabled = false
}) => {
  const [open, setOpen] = React.useState(false);
  const generateNameFromValue = (
    array: { value: string; name: string }[],
    value: string
  ) => {
    const result = array.filter((e) => value === e.value);
    return result;
  };

  React.useEffect(() => {
    if (selectedOption) {
      setOpen(false);
    }
  }, [selectedOption]);
  return (
    <div className="custom-pagination-dropdown">
      <div
        onClick={() => {
          !disabled && setOpen(true);
        }}
        className="custom-select-wrapper"
      >
        {label && (
          <p
            style={{
              color: "#112940",
              fontSize: "13px",
              fontWeight: 500,
              margin: "0",
              marginBottom: "3.5px"
            }}
          >
            {label}
          </p>
        )}

        <div className="custom-select__div">
          <div className="custom-select__trigger">
            <span>
              {generateNameFromValue(dropdownOption, selectedOption)[0]?.name ??
                "20"}
            </span>
            {!open ? (
              <FaChevronDown color="#ABB7BF" size={12} />
            ) : (
              <FaChevronUp color="#ABB7BF" size={12} />
            )}
          </div>
          {open && (
            <DropdownContent
              onClose={() => {
                setOpen(false);
              }}
            >
              <div className="custom-dropdown__option">
                {dropdownOption.map((option, index) => (
                  <span
                    key={index}
                    onClick={() => handleChange(option.value)}
                    className={`custom-option ${
                      selectedOption.toString().toLowerCase() ===
                      option.name.toLowerCase()
                        ? "selected"
                        : ""
                    }`}
                  >
                    {option.name}
                  </span>
                ))}
              </div>
            </DropdownContent>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaginationDropdownComponent;
