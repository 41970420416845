import styled from "styled-components";

const QuickActionsButton = styled.button`
  border-radius: 100px;
  background: rgba(45, 180, 158, 0.16);
  border: none;
  padding: 8px 12px;
  color: #01493d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export { QuickActionsButton };
