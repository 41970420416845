import styled from "styled-components";

const TableEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 80px 0;

  p {
    color: #5c696e;
    margin: 10px 0 0 0;
    padding: 0 0 0 0;
  }
`;

export {
  TableEmptyContainer
};