import { BusinessBillingInterface } from "./../interfaces/wallet";

function getPayoutStatusColor(status: string) {
  const transformedStatus = status.toLowerCase();
  switch (transformedStatus) {
    case "successful":
    case "success":
    case "active":
    case "inactive":
    case "completed":
    case "approved":
    case "pending":
    case "failed":
    case "draft":
    case "denied":
    case "not-available":
    case "open":
    case "paid":
    case "partiallypaid":
    case "permission":
    case "incomplete":
    case "pending":
    case "submitted":
    case "rejected_with_reason":
    case "activated":
    case "declined":
    case "incomplete":
    case "tokenization":
    case "declined":
    case "active":
    case "repayment_initiated":
    case "settled":
    case "overdue":
    case "not_linked":
    case "accepted":
    case "rejected":
      return transformedStatus;
    default:
      return "not-available";
  }
}

function transformPayoutStatus(status: string) {
  switch (status.toLowerCase()) {
    case "successful":
      return "Completed";
    case "not_linked":
      return "Not Linked";
    default:
      return status;
  }
}

const checkIfAnyoneCanComplete = (
  can_complete: boolean | undefined,
  approved: boolean,
  denied: boolean
) => {
  return can_complete && (approved || denied);
};

function getPayoutBillingFee(
  billingInfo: BusinessBillingInterface | undefined,
  amount: number
) {
  if (billingInfo?.fees.send_money_fee.feeStructure === "fixed") {
    return billingInfo.fees.send_money_fee.fixed;
  }

  if (billingInfo?.fees.send_money_fee.feeStructure === "feeStructure") {
    if (
      amount * billingInfo.fees.send_money_fee.percentage.value <=
      billingInfo.fees.send_money_fee.percentage.min
    ) {
      return billingInfo.fees.send_money_fee.percentage.min;
    }

    if (
      amount * billingInfo.fees.send_money_fee.percentage.value >=
      billingInfo.fees.send_money_fee.percentage.max
    ) {
      return billingInfo.fees.send_money_fee.percentage.max;
    }
  }

  if (billingInfo?.fees.send_money_fee.feeStructure === "band") {
    const filteredBand = billingInfo.fees.send_money_fee.band.filter(
      (band) => amount >= band.start && amount <= band.end
    );
    return filteredBand[0]?.fee || 0;
  }

  return 0;
}

export {
  getPayoutBillingFee,
  getPayoutStatusColor,
  transformPayoutStatus,
  checkIfAnyoneCanComplete
};
