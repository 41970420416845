import styled from "styled-components";
import { device } from "styles";
import colors from "styles/colors";

interface BulkPayoutDetailsSummaryProps {
  requiresApproval?: boolean;
}

interface ExpenseAccountLinkProps {
  hasVendorActive?: boolean;
}

const PayoutButtonContainer = styled.div`
  text-align: right;
  margin-top: 20px;

  .alternate-btn {
    border: 1px solid ${colors.pri};
    color: ${colors.pri};
  }

  @media ${device.mobile_} {
    margin-bottom: 0px;
    white-space: nowrap;
    text-align: left;
    button[mode] {
      margin-left: 0;
    }
  }
`;

const PayoutsFormCustomFieldsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .payoutsFormFieldLabel {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #192850;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
  .payoutsFormFieldInput {
    margin-bottom: 36px;
    input {
      padding: 15px;
      border: 1px solid #dcdde6;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .payoutsFromFieldButtons {
    button:first-child {
      margin-left: 0;
    }
    button {
      width: 100%;
    }
    button:last-child {
      margin-left: 0;
      margin-top: 10px;
    }
    @media ${device.laptop} {
      button {
        width: 116px;
      }

      button:last-child {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }
`;

const PayoutSettingsContainer = styled.div`
  width: auto;
  height: 100%;
  padding: 34px 40px 1px 40px;
  box-sizing: border-box;
  margin: 0;
  .payoutsSettingsWrapper {
    @media ${device.laptop} {
      display: flex;
    }
    flex-direction: row;
    justify-content: space-between;
    border: none;
    width: 100%;
    max-width: 1107px;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    background-color: #ffffff;
    overflow: hidden;
    margin: 0 auto;
    .payoutsSettingsFormBuilder {
      @media ${device.laptop} {
        width: 56.35%;
      }

      max-width: 624px;
      height: 100%;
      margin: 0;
      background-color: #fdfdfd;
      border: 1px solid #ecf0f3;
      box-sizing: border-box;
      overflow: hidden;
      .payoutsSettingsFormBuilderControls {
        padding: 30px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media ${device.laptop} {
          height: 40px;
          display: flex;
          margin-bottom: 2px;
        }
      }
      .payoutsSettingsFormBuilderBoard {
        box-sizing: border-box;
        padding: 0 0 124px 0;
        margin-left: 30px;
        margin-right: 30px;
        overflow: hidden;
        height: 100%;
        .payoutsSettingsFormBuilderBoardBox {
          background: #ffffff;
          border: 0.5px solid #eeeeee;
          border-radius: 5px;
          padding: 0 40px 0 40px;
          margin: 0;
          height: 100%;
          overflow: hidden;
          min-height: 250px;
          box-shadow: 2px 0 8px -2px rgba(100, 100, 100, 0.2);
          .payoutsSettingsFormBuilderBoardBoxBody {
            display: block;
            height: 100%;
            box-sizing: border-box;
            padding-bottom: 65px;
            .payoutsSettingsFormBuilderBoardBoxBodyFieldsSet {
              height: 100%;
              overflow: auto;
              .payoutsSettingsFormBuilderBoardBoxBodyField {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background: #ffffff;
                border: 1px solid #dcdde6;
                border-radius: 5px;
                padding: 11px 14px;
                span {
                  text-transform: capitalize;
                }
                + {
                  .payoutsSettingsFormBuilderBoardBoxBodyField {
                    margin-top: 10px;
                  }
                }
              }
              .payoutsSettingsFormBuilderBoardBoxBodyFieldButton {
                background: #ffffff;
                border: 1px dashed #dcdde6;
                border-radius: 5px;
                padding: 11px 22px;
                cursor: pointer;
                margin-top: 25px;
                span {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  text-transform: capitalize;
                  color: #2db0b4;
                }
              }
            }
          }
          .payoutsSettingsFormBuilderBoardBoxHeader {
            @media ${device.laptop} {
              display: flex;
            }
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 37px 0 0 0;
            margin-bottom: 10px;
            span {
              &:first-child {
                text-transform: uppercase;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: rgba(22, 41, 48, 1);
                letter-spacing: 0.5px;
                white-space: nowrap;
              }
            }
          }
        }
        .payoutsSettingsFormBuilderBoardBoxHeader {
          span {
            &:last-child {
              white-space: nowrap;
              button {
                text-transform: capitalize;
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .payoutsSettingsFormBuilderPreview {
      @media ${device.laptop} {
        width: 43.65%;
      }
      max-width: 483px;
      display: block;
      height: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 0;
      .payoutsFormBuilderPreviewHeader {
        margin: 0;
        background-color: rgba(22, 41, 48, 0.05);
        color: rgba(123, 123, 118, 0.8);
        height: 66px;
        line-height: 66px;
        text-indent: 24px;
      }
      .payoutsFormBuilderPreviewBox {
        display: block;
        height: 100%;
        padding: 0;
        margin: 0;
        padding: 0 0 69px;
        overflow: hidden;
        box-sizing: border-box;
        .payoutsFormBuilderPreviewBoxFrame {
          display: block;
          height: 100%;
          padding: 0;
          margin: 0;
          overflow: auto;
          background-color: #fdfdfd;
        }
      }
    }
  }
`;

const PayoutStatusContainer = styled.span`
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
  &.successful,
  &.success,
  &.approved,
  &.completed,
  &.active,
  &.accepted,
  &.paid {
    color: #414f3e;
    background: #bbe5b3 !important;
  }
  &.unsuccessful {
    color: red;
    background: #000000;
  }
  &.pending,
  &.not_linked,
  &.open {
    color: #d97724 !important;
    background: #fdefcb;
  }
  &.draft,
  &.inactive {
    background: #eff3f4;
    color: #5d696e !important;
  }

  &.failed,
  &.rejected,
  &.denied,
  &.declined {
    background: #fbe5e5;
    color: #ed5b5c !important;
  }
  &.not-available,
  &.submitted,
  &.partiallypaid {
    background: #cfe0f5;
    color: #087fca !important;
  }
  &.permission {
    background: #d4fcea;
    color: #006355;
    padding: 4px 12px;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 4px;
  }
`;

const PayoutBalanceContainer = styled.div`
  padding: 0 30px;
  p {
    color: #94a2ab;
  }
  .currency {
    span {
      color: #162930;
      font-size: 30px;
      font-weight: 600;
    }
  }
  @media ${device.mobile_} {
    padding: 0;
    .currency {
      span {
        color: #162930;
        letter-spacing: 0;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
`;

const PayoutMobileCardContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  justify-content: space-around;
  background-color: transparent;
  .payoutCardsShelf {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: transparent;
    -webkit-flex-flow: row;
    flex-flow: row;
    + {
      .payoutCardsShelf {
        margin-top: 10px;
      }
    }
  }
  .payoutCardItem {
    border: 1px white solid;
    box-shadow: 0 0 26px 8px rgba(100, 100, 100, 0.1);
    border-radius: 5px;
    padding: 16px 20px 10px;
    box-sizing: border-box;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    flex: 1 50%;
    + {
      .payoutCardItem {
        margin-left: 10px;
      }
    }
  }
  .payoutsCardItemCorner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    + {
      .payoutsCardItemCorner {
        margin-top: 17px;
      }
    }
    span {
      &:first-child {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 10px;
        color: #5c696e;
      }
      vertical-align: middle;
    }
    b {
      letter-spacing: 0.1em;
      font-weight: 700;
      color: #2db49e;
    }
  }
`;

const PayoutCardContainer = styled.div`
  margin: 40px 0 25px 0;
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 20px;
  }
`;

const ScheduledPayoutCardContainer = styled.div`
  margin: 40px 20px 25px 20px;
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 20px;
    margin: 40px 0 25px 0;
  }
`;

const ScheduledPayoutSearchContainer = styled.div`
  display: block;
  button {
    margin: 0 0 40px 0;
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
    }
  }
  input {
    margin-right: 10px;
  }
  .search {
    display: flex;
  }
`;

const PayoutTableContainer = styled.div`
  margin: 20px 20px 0 20px;

  @media ${device.mobile_} {
    margin: 0;
  }
  @media ${device.laptop} {
    margin: 20px 0 0 0;
  }
`;

const PayoutCard = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
  border-radius: 5px;
  /* height: 107px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 20px 0 20px 30px;
  margin-bottom: 20px;
  .title {
    color: #5c696e;
    line-height: 17.75px;
    /* font-size: 15px; */
    text-transform: uppercase;
    font-weight: 400;
  }
  .payout {
    color: #2db49e;
    line-height: 14px;
    font-weight: 400;
    padding-top: 5px;
  }
  .count {
    font-size: 30px;
    /* color: ${colors.pri}; */
    padding: 3px;
    color: #103129;
    font-weight: bold;
    span {
      color: #103129;
      /* color: ${colors.pri}; */
    }
  }
`;

const NewPayoutContainer = styled.div`
  max-width: 830px;
  background: #fdfdfd;
  border: 1px solid #ecf0f3;
  margin: 40px auto 0 auto;
  padding: 30px;
  .form-container {
    margin-top: 20px;
    padding: 45px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;
  }
  .bulk-upload-header {
    text-align: center;

    p {
      color: #7a7978;
    }
  }
  .header-section {
    display: block;
    @media ${device.laptop} {
      display: flex;
      justify-content: space-between;
    }
    .button-container {
      display: flex;
    }
    h2 {
      font-weight: 400;
      font-size: 20px;
    }
  }
  label {
    font-weight: 300;
  }
`;

const BulkPayoutDetailsContainer = styled.div`
  height: 100vh;
  padding: 30px;

  .switchButtonsItems {
    width: 100% !important;
  }
`;

const BulkPayoutDetailsSummary = styled.div<BulkPayoutDetailsSummaryProps>`
  border: 1px solid #f0f0f0;
  padding: 20px;
  margin-top: ${(props) => (props.requiresApproval ? "24px" : "0")};

  h2 {
    color: #162930;
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0;
    padding: 0;
    text-transform: capitalize;
  }
  .summary {
    display: flex;
    gap: 2.5rem;
    margin: 20px 0;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #94a2ab;
        font-size: 14px;
        margin: 1.4rem 0;
        span {
          color: #000;
        }
      }
    }
  }
`;
const BulkPreviewDetailsSummary = styled.div`
  border: 1px solid #f0f0f0;
  padding: 20px;
  position: relative;
  background: #fdfdfd;

  .custom-select__trigger.error {
    border: 1px solid #f84f31 !important;
  }

  .error-message {
    display: block;
    margin-bottom: 1.1rem;
    color: #f84f31 !important;
    font-size: 0.875rem;
    margin-top: -12px;
  }
  h2 {
    color: #162930;
    font-family: "EuclidCircularB", sans-serif !important;
    font-size: 20px;
    margin: 15px 0;
    padding: 0;
    text-transform: capitalize;
  }
  .summary {
    display: flex;
    gap: 2.5rem;
    margin: 20px 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #94a2ab;
        font-size: 14px;
        margin: 1.4rem 0;
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        gap: 10px;

        span {
          color: #000;
        }
        .custom-dropdown .custom-select__div {
          height: 40px;
        }
        .custom-dropdown__option {
          margin-top: -15px;
        }
        .label-holder {
          margin-top: -20px;
        }
      }
    }
    .batch {
      border: 1px solid red;
      padding: 12px;
      min-width: 270px;
      height: 12px;
      border-radius: 6px;
      @media ${device.mobile_} {
        min-width: 160px;
      }
    }
    small {
      display: block;
      padding: 4px 0;
    }
  }
  .error-notification {
    position: absolute;
    top: 0;
    left: 0;
    background: #fcf7f7;
    width: 96%;
    padding: 10px 2%;
    color: #ed5b5c;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .txn-table {
    box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 20%),
      0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  .table__td__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

const PayoutHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PayoutSettingsDropDownContainer = styled.div`
  .custom-dropdown {
    margin-bottom: 5px;
  }
  .error {
    margin-bottom: 10px;
  }
`;

const FilterDropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 125px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  padding: 12px 16px;
  z-index: 2;
  border-radius: 5px;
  left: 0;
  top: 45px;
  .header {
    color: #94a2ab;
    font-size: 14px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .dropdown-options {
    margin: 0.7rem 0;
  }

  @media ${device.mobile_} {
    left: auto;
    right: 0;
  }
`;

const WorkflowEmptyStateContainer = styled.div`
  padding: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;

  h3 {
    color: #07000f;
    font-size: 18px;
    font-weight: 550;
    margin: 30px 0 0;
  }
  p {
    color: rgba(92, 105, 110, 0.6);
    font-weight: 400;
  }
  a {
    color: ${colors.pri};
    text-decoration: none;
    cursor: pointer;
  }
`;

const PaymentDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  white-space: nowrap;

  .dropdown-content {
    display: block;
    position: absolute;
    min-width: 160px;
    left: 0;
    overflow: auto;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;
    z-index: 1;
  }

  .dropdown-options {
    letter-spacing: 0.004em;
    font-size: 14.5px;
    cursor: pointer;
    line-height: 20px;
    font-weight: 500;
    color: #5c696e;
    padding: 7px;
  }

  .show {
    display: block;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  p:hover {
    background-color: #ddd;
    border-radius: 8px;
  }

  .button {
    background: #2db49e;
    color: #ffffff;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #eeeeee;
    margin-top: 20px;

    svg {
      margin-left: 10px;
    }
  }

  @media ${device.mobile_} {
    .button {
      vertical-align: middle;
      padding: 0 15px;
      border-radius: 5px;
      font-size: 13px;
    }

    .dropdown-content {
      left: auto;
      right: 0;
    }
  }
`;

const AddRecipientContainer = styled.div`
  background: #e9f7f5;
  height: 54px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%232DB49EFF' stroke-width='4' stroke-dasharray='18' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const ProductSpotlightContainer = styled.div`
  margin-top: 60px;
  margin-left: -30px;
  margin-right: -30px;

  h4 {
    font-size: 14px;
    color: #01493d;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .link-container {
    background: #e5f4f2;
    padding: 24px 30px 24px;
    background: #e5f4f2;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
      color: #2db49e;
    }
  }
  .link-card {
    box-shadow: 0px 6px 28px rgba(172, 172, 190, 0.08);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    cursor: pointer;
    background: #fff;
    align-items: center;
    gap: 16px;
    font-size: 14px;
  }
`;
const ExpenseAccountLink = styled.div<ExpenseAccountLinkProps>`
  margin-top: ${(props) => (props.hasVendorActive ? "30px" : "0")};
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  padding-top: 16px;
  padding-bottom: 0px;
  span {
    text-align: left;
  }
  a {
    color: #01493d;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  svg {
    flex-shrink: 0;
  }
`;
export {
  PayoutButtonContainer,
  PayoutSettingsContainer,
  PayoutBalanceContainer,
  PayoutMobileCardContainer,
  PayoutCardContainer,
  PayoutCard,
  PayoutsFormCustomFieldsBox,
  PayoutSettingsDropDownContainer,
  PayoutTableContainer,
  ScheduledPayoutSearchContainer,
  ScheduledPayoutCardContainer,
  NewPayoutContainer,
  BulkPayoutDetailsContainer,
  BulkPayoutDetailsSummary,
  PayoutStatusContainer,
  BulkPreviewDetailsSummary,
  FilterDropdownContent,
  PayoutHeaderSection,
  WorkflowEmptyStateContainer,
  PaymentDropdownContainer,
  AddRecipientContainer,
  ProductSpotlightContainer,
  ExpenseAccountLink
};
