import React, { createContext, useContext, useMemo } from "react";
import { useSetState } from "react-use";
import { TourInterface } from "interfaces/productTour";

const tourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  jumpToSpecificStep: false
};

export const TourContext = createContext({
  state: tourState,
  setState: () => undefined,
  showTour: false,
  setShowTour: (condition: boolean) => condition
});
TourContext.displayName = "TourContext";

export function TourProvider(props: any) {
  const [state, setState] = useSetState(tourState);
  const [showTour, setShowTour] = React.useState<boolean>(false);

  const value = useMemo(
    () => ({
      state,
      setState,
      showTour,
      setShowTour
    }),
    [setState, showTour, state]
  );

  return <TourContext.Provider value={value} {...props} />;
}

export function useTourContext(): {
  setState: (
    patch:
      | Partial<TourInterface>
      | ((previousState: TourInterface) => Partial<TourInterface>)
  ) => void;
  state: TourInterface;
} {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return context;
}
