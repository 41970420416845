import CustomButton from "components/CustomButton";
import { BusinessInterface } from "interfaces/users";
import { VirtualAccountResponse } from "interfaces/wallet";
import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Routes } from "routes/routes-constants";
import Modal from ".";
import {
  ModalFooter,
  VirtualAccountModalContainer,
  VirtualAccountModalItem
} from "./styles";
import { FaRegCopy } from "react-icons/fa";
import toast from "react-hot-toast";

interface FundAccountModalProps
  extends Pick<
    RouteComponentProps<{}, StaticContext, { [key: string]: unknown }>,
    "history"
  > {
  virtualAccountInfo: VirtualAccountResponse;
  merchant: BusinessInterface;
  close: () => void;
}

const FundAccountModal: React.FC<FundAccountModalProps> = ({
  virtualAccountInfo,
  history,
  merchant,
  close
}) => {
  const copyToClipboard = (title: string, ref?: string) => {
    if (typeof ref === "string") {
      /* @NOTE: `navigator.clipboard.writeText(...)` throws vague error in Safari 13.1.x (and above) even when called in a real user context */
      /* @CHECK: https://developer.apple.com/forums/thread/691873 */
      navigator.clipboard.writeText(ref);
    }
    toast.success(`${title} copied to clipboard!`);
  };
  return (
    <Modal
      title="Fund Business Account"
      close={close}
      isFooter={false}
      isCloseIcon={false}
      isOnlyClose={true}
    >
      <div className="modal-body">
        <p
          style={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            color: "#192850",
            marginTop: "-10px",
            marginBottom: "52px"
          }}
        >
          Fund your business using the virtual account displayed below. To
          collect payments from your customer{" "}
          <a
            onClick={(e) => {
              e.preventDefault();
              history.push(Routes.Customers);
              close();
            }}
            style={{ color: "#2DB49E", cursor: "pointer" }}
          >
            click here
          </a>{" "}
          .
        </p>

        <div style={{ backgroundColor: "#F6F6F6", padding: "23px 52px" }}>
          <VirtualAccountModalContainer>
            <VirtualAccountModalItem>
              <p className="header">Account Number</p>
              <p className="value">
                {virtualAccountInfo.account_number}{" "}
                <span className="text-v-aligned">
                  <button
                    className="copy-button"
                    onClick={() =>
                      copyToClipboard(
                        "Account number",
                        virtualAccountInfo.account_number
                      )
                    }
                  >
                    <FaRegCopy
                      style={{ color: "#162930", fontWeight: "bold" }}
                    />
                  </button>
                </span>
              </p>
            </VirtualAccountModalItem>

            <VirtualAccountModalItem>
              <p className="header">Bank Name</p>
              <p className="value">
                {" "}
                {merchant.display_state === "test"
                  ? "Test Bank"
                  : virtualAccountInfo.provider.bank_name}
              </p>
            </VirtualAccountModalItem>
          </VirtualAccountModalContainer>

          <VirtualAccountModalContainer>
            <VirtualAccountModalItem>
              <p className="header">Account Name</p>
              <p className="value">{merchant.trading_name}</p>
            </VirtualAccountModalItem>
          </VirtualAccountModalContainer>
        </div>
      </div>

      <ModalFooter
        style={{ justifyContent: "center", backgroundColor: "#ffffff" }}
      >
        <CustomButton onClick={close} label="Close" height={40} />
      </ModalFooter>
    </Modal>
  );
};

export default FundAccountModal;
