import CustomButton from "components/CustomButton";
import DropdownComponent from "components/DropdownComponent";
import InputComponent from "components/FormElements/inputs";
import { ErrorSpan } from "components/FormElements/styles";
import { Formik, FormikConfig, FormikProps } from "formik";
import { WalletTransferForm, WalletTransferPayload } from "interfaces/wallet";
import { useGetBusinessWallets, useWalletTransfer } from "lib/query/wallet";
import React from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import * as rudderanalytics from "rudder-sdk-js";
import { walletTransferSchema } from "utils/validationSchema";
import Modal from ".";
import { CancelButton, FilterModalFooter } from "./styles";
import config from "config";

const initialValues: WalletTransferForm = {
  amount: 0,
  description: ""
};

interface WalletTransferModalProps {
  close: () => void;
  businessID: string;
}

const WalletTransferModal: React.FC<WalletTransferModalProps> = ({
  close,
  businessID
}) => {
  const queryClient = useQueryClient();
  const { data, status } = useGetBusinessWallets(businessID);
  const [selectedTransfer, setSelectedTransfer] = React.useState("");
  const [selectedTransferError, setSelectedTransferError] =
    React.useState<boolean>(false);
  const [selectedTo, setSelectedTo] = React.useState("");
  const [selectedToError, setSelectedToError] = React.useState<boolean>(false);
  const [listOfBanks, setListOfBanks] =
    React.useState<{ value: string; name: string }[]>();

  const { mutate, isLoading } = useWalletTransfer();

  const listOfBanksFn = () => {
    const formattedData: { name: string; value: string }[] = [];
    data?.data
      .filter((wallet) => !wallet.is_master && wallet.bank_accounts.length)
      .forEach((wallet) => {
        formattedData.push({
          name:
            wallet.bank_accounts[0].first_name +
            " " +
            wallet.bank_accounts[0].last_name +
            " - " +
            wallet.available_balance,
          value: wallet.wallet_ref
        });
      });
    setListOfBanks(formattedData);

    return formattedData;
  };

  const handleFormSubmit = async (values: WalletTransferForm) => {
    if (!selectedTransfer) {
      setSelectedTransferError(true);
    }
    if (!selectedTo) {
      setSelectedToError(true);
    }
    if (selectedTransfer && selectedTo) {
      const payload: WalletTransferPayload = {
        amount: values.amount,
        description: values.description,
        receiver_ref: selectedTo,
        sender_ref: selectedTransfer,
        business_id: businessID
      };
  
      mutate(payload, {
        onSuccess: () => {
          toast.success("Transfer successful");
          queryClient.invalidateQueries("master-wallet-txn");
          queryClient.invalidateQueries("business-wallet");

          if (config.PROD) {
            rudderanalytics.track(
              "form_submit:wallet_transfer_completed",
              {
                page: document.title,
                page_url: window.location.href,
                page_search: window.location.search,
                page_history_state: {},
                business_id: businessID,
                form_id: "customer_transfer",
                label: "wallet_transfer_completed"
              },
              () => close()
            );
          } else {
            close();
          }
        },
        onError: (err) => {
          if (!("message" in err)) {
            if (err.length > 0) {
              err.forEach((err) => toast.error(err as string));
              return;
            }
          } else {
            if (typeof err.message !== "string") {
              return;
            }
  
            toast.error(`${err.message}`);
          }
        }
      });
    }
  };

  React.useEffect(() => {
    if (selectedTo) {
      setSelectedToError(false);
    }

    if (selectedTransfer) {
      setSelectedTransferError(false);
    }
  }, [selectedTo, selectedTransfer]);

  React.useEffect(() => {
    if (status === "success") {
      listOfBanksFn();
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data]);

  return (
    <Modal<FormikConfig<WalletTransferForm>>
      title="Customer Transfer"
      submitButtonText={"Transfer"}
      submitButtonHeight={50}
      isWorking={isLoading}
      close={close}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={walletTransferSchema}
        onSubmit={async (values) => {
          await handleFormSubmit(values);
        }}
      >
        {({ handleSubmit }: FormikProps<WalletTransferForm>) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="modal-body">
              <DropdownComponent
                label="Transfer From"
                placeHolderText="Transfer From"
                dropdownOption={listOfBanks || []}
                selectedOption={selectedTransfer}
                onChange={(e: string) => setSelectedTransfer(e)}
                disabled={false}
              />
              {selectedTransferError && (
                <ErrorSpan>Transfer From is required</ErrorSpan>
              )}

              <div style={{ margin: "20px 0" }}></div>

              <DropdownComponent
                label="Transfer To"
                placeHolderText="Transfer To"
                dropdownOption={listOfBanks || []}
                selectedOption={selectedTo}
                onChange={(e: string) => setSelectedTo(e)}
                disabled={false}
              />
              {selectedToError && <ErrorSpan>Transfer To required</ErrorSpan>}

              <div style={{ margin: "20px 0" }}></div>

              <InputComponent name="amount" title="Amount" type="text" />

              <InputComponent
                name="description"
                title="Description"
                type="text"
              />
            </div>
            <FilterModalFooter>
              <CancelButton onClick={close}>Cancel</CancelButton>
              <CustomButton
                type="submit"
                label="Transfer"
                height={42}
                isLoading={isLoading}
              />
            </FilterModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default WalletTransferModal;
