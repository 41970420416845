export const kFormatter = (amount: number) => {
  if (isNaN(amount)) return amount;

  if (amount < 9999) {
    return amount;
  }

  if (amount < 1000000) {
    return (amount / 1000).toFixed(2) + "K";
  }
  if (amount < 10000000) {
    return (amount / 1000000).toFixed(2) + "M";
  }

  if (amount < 1000000000) {
    return Math.round(amount / 1000000).toFixed(2) + "M";
  }

  if (amount < 1000000000000) {
    return Math.round(amount / 1000000000) + "B";
  }

  return "1T+";
};
