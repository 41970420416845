import styled from "styled-components";
import { device } from "styles";
import colors from "styles/colors";

interface EmailPreferenceProps {
  isEnabled?: boolean;
}

interface RadioProps {
  isChecked?: boolean;
}

interface RadioElementProps {
  isActive: boolean;
}

interface BankRadioProps {
  isChecked?: boolean;
}

interface RadioElementProps {
  isActive: boolean;
}

interface TableBadgeProps {
  caps?: boolean;
  variant: "basic" | "slick";
  display: "shade" | "shine" | "warning" | "blue";
}

const getStatusDisplay = (
  display: "shade" | "warning" | "shine" | "blue",
  prop: "color" | "b_color",
  variant: "basic" | "slick"
) => {
  const colorMap = {
    shade: variant === "slick" ? "#5C696E" : "#162930",
    warning: variant === "slick" ? "#D97724" : "#ED5B5C",
    shine: variant === "slick" ? "#414F3E" : "#65C466",
    blue: variant === "slick" ? "#087fca" : "#65C466"
  };

  const bColorMap = {
    shade: variant === "slick" ? "#EFF3F4" : "rgba(0, 0, 0, 0.1)",
    warning: variant === "slick" ? "#FDEFCB" : "rgba(237, 91, 92, 0.1)",
    shine: variant === "slick" ? "#BBE5B3" : "rgba(101, 196, 102, 0.2)",
    blue: variant === "slick" ? "#cfe0f5" : "rgba(101, 196, 102, 0.2)"
  };

  if (prop === "color") {
    return colorMap[display];
  } else {
    return bColorMap[display];
  }
};

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #162930 !important;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
  }

  span {
    font-weight: 300;
  }
`;
const RadioElement = styled.div<RadioElementProps>`
  width: 18px;
  height: 18px;
  border: ${(props) =>
    props.isActive ? `2.5px solid ${colors.pri}` : "1px solid #babfc1"};
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 9px;
    height: 9px;
    background: #96dace;
    border-radius: 25px;
  }
`;

const SettingsEmailContainer = styled.div`
  position: relative;
  max-width: 400px;
  @media ${device.laptop} {
    max-width: 700px;
  }

  svg {
    @media ${device.laptop} {
      position: relative;
      max-width: 40px;
    }
    position: absolute;
    top: -5px;
    right: 0;
    z-index: 9999999;
    max-width: 20px;
  }
`;

const NewPolicyContainer = styled.div`
  max-width: 830px;
  background: #fdfdfd;
  border: 1px solid #ecf0f3;
  margin: 40px auto 0 auto;
  padding: 30px;
  .form-container {
    margin-top: 20px;
    padding: 45px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;
    @media ${device.mobile_} {
      padding: 15px;
    }
  }

  .header-section {
    display: block;
    @media ${device.laptop} {
      display: flex;
      justify-content: space-between;
    }
    .button-container {
      display: flex;
    }
  }

  @media ${device.mobile_} {
    padding: 15px 20px;
  }
`;

const NewTeamMemberContainer = styled.div`
  max-width: 830px;
  background: #fdfdfd;
  border: 1px solid #ecf0f3;
  margin: 40px auto 0 auto;
  padding: 30px;
  .form-container {
    margin-top: 20px;
    padding: 45px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;

    h3 {
      color: #07000f;
      font-weight: 600;
      font-size: 17px;
      margin: 0 0 25px;
    }
    label {
      font-weight: 300;
    }

    .form {
      width: 100%;

      .form__control {
        @media ${device.laptop} {
          display: grid;
        }
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 5px 0;

        input {
          border: 1px solid #dcdde5;
          border-radius: 5px;
        }

        .inputs__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
        }

        .action__div {
          margin-left: "20px";
          cursor: "pointer";
        }
      }

      .form-footer {
        padding: 10px 30px;
        margin-top: 20px;
      }

      @media ${device.mobile_} {
        width: 100%;
      }
    }
  }

  .header-section {
    display: block;
    @media ${device.laptop} {
      display: flex;
      justify-content: space-between;
    }
    .button-container {
      display: flex;
    }
  }
`;

const EditTeamMemberContainer = styled.div`
  max-width: 830px;
  background: #fdfdfd;
  border: 1px solid #ecf0f3;
  margin: 40px auto 0 auto;
  padding: 30px;
  .form-container {
    margin-top: 20px;
    padding: 45px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;

    section {
      @media ${device.laptop} {
        display: grid;
      }
      align-items: center;
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      margin: 5px 0;

      label {
        font-weight: 300;
      }
    }
  }

  .header-section {
    display: block;
    @media ${device.laptop} {
      display: flex;
      justify-content: space-between;
    }
    .button-container {
      display: flex;
    }
  }
`;
const NewRoleContainer = styled.div`
  max-width: 830px;
  background: #fdfdfd;
  border: 1px solid #ecf0f3;
  margin: 40px auto 0 auto;
  padding: 30px;
  .form-container {
    margin-top: 20px;
    padding: 25px 45px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
    border-radius: 5px;

    .permissions-list {
      margin: 50px 0 0 0;
      padding: 0;
      list-style-type: none;

      li {
        border-radius: 8px;
        border: 1px solid #ecebf5;
        padding: 13px 20px;
        margin-bottom: 25px;
        cursor: pointer;

        h4 {
          margin: 0;
        }
        p {
          margin-bottom: 0;
          color: ${colors.pri};
          font-weight: 400;
          span {
            color: #afafaf;
          }
        }
      }
    }

    h3 {
      font-weight: 400;
      font-size: 18px;
      color: #07000f;
      margin-bottom: 0;
    }
    p {
      color: #5c696e;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      margin: 10px 0px 25px;
    }
    label {
      font-weight: 300;
    }

    .permissions {
      margin: 40px 0 25px;
      padding: 0;
      list-style-type: none;
      display: flex;
      gap: 40px;
      li {
        flex-grow: 1;

        .radio-label {
          background: white;
          border: 1px solid rgba(220, 221, 229, 0.5);
          border-radius: 7px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          cursor: pointer;
          font-weight: 600;
          margin: 0 auto 10px;
          padding: 12px 20px 12px 50px;
          position: relative;
          transition: 0.3s ease all;
          color: #5c696e;
          font-weight: 400;
          display: block;
        }
        .radio-label:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        }

        .radio-label:before {
          background: #fff;
          border-radius: 50%;
          content: "";
          height: 15px;
          left: 20px;
          position: absolute;
          top: calc(50% - 10px);
          transition: 0.3s ease background-color;
          width: 15px;
          border: 2px solid rgba(92, 105, 110, 0.3);
        }
        .radio-label span {
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        .radio-btn {
          position: absolute;
          visibility: hidden;
        }
        .radio-btn:checked + .radio-label {
          background: #f7f7ff;
          border-color: #0b2e28;
        }
      }
      .radio-btn:checked + .radio-label:before {
        background-color: rgba (11, 46, 40, 0.5);
        border: 3px solid #0b2e28;
        background: ".";
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
      }
      .radio-btn:checked + .radio-label:after {
        background: #0b2e28;
        opacity: 0.5;
        border-radius: 50%;
        content: "";
        height: 5px;
        left: 26px;
        position: absolute;
        top: calc(50% - 4px);
        transition: 0.3s ease background-color;
        width: 5px;
        border: 2px solid rgba(92, 105, 110, 0.3);
      }
    }
  }

  .header-section {
    h2 {
      font-size: 20px;
      color: #162930;
      font-weight: 400;
    }
    display: block;
    @media ${device.laptop} {
      display: flex;
      justify-content: space-between;
    }
    .button-container {
      display: flex;
    }
  }
`;

const PermissionListContainer = styled.div`
  display: flex;
  align-items: center;

  .switch-container {
    flex: 1;
  }
  p {
    margin: 0;
  }
  .phone-wrapper {
    margin-top: 30px;
  }
  .switch {
    width: 35px;
    height: 20px;
  }
  .slider:before {
    height: 14px;
    width: 14px;
    bottom: 3px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
`;

const ApprovalPolicyDetailsBox = styled.div`
  position: relative;
  min-height: 0;
  margin: 0;
  padding: 10px;
  background-color: transparent;
  .approvalPolicyDetailsPaneBody {
    background-color: #ffffff;
    padding-top: 14px;
    .approvalPolicyDetailsPaneHeader {
      background-color: #f8f9f9;
      font-size: 12px;
      padding: 16px 20px;
      text-transform: uppercase;
      color: #babfc1;
    }
    .approvalPolicyDetailsPaneSectionList {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 8px;
      margin-bottom: 15px;
      li {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        .tag {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
          color: rgba(92, 105, 110, 0.6);
        }
        .content {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          text-align: right;
          text-transform: capitalize;
          color: #162930;
        }
      }
      li.peronaListItem {
        align-items: center;
        justify-content: flex-start;
        .content {
          margin-left: 15px;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
`;

const ApprovalWorkFlowSheet = styled.section`
  @media ${device.laptop} {
    display: flex;
  }
  flex-direction: row;
  justify-content: space-between;
  border: none;
  width: 100%;
  max-width: 1107px;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0 auto;

  .approvalListingsBox {
    @media ${device.laptop} {
      width: 64.498645%;
    }
    max-width: 714px;
    height: 100%;
    margin: 0;
    background-color: #fdfdfd;
    border: 1px solid #ecf0f3;
    box-sizing: border-box;
    /* overflow: hidden; */

    .approvalsControlsPane {
      padding: 30px 30px 15px 30px;
      height: 40px;
      margin-bottom: 2px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-weight: 400;
        font-size: 18px;
      }
    }

    .approvalPolicyBoard {
      box-sizing: border-box;
      padding: 0 0 75px 0;
      margin-left: 30px;
      margin-right: 30px;
      overflow: hidden;
      height: 555px;

      .approvalPolicyBoardBox {
        background: #ffffff;
        border: 0.5px solid #eeeeee;
        border-radius: 5px;
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: auto;
        min-height: 250px;
        box-shadow: 0px 12px 26px -7px #5c696e1a;

        .approvalPolicyEmptyStateBox {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 400px;
          height: 100%;
          padding: 25px;
        }
      }
    }
  }

  .approvalDetailsBox {
    @media ${device.laptop} {
      width: 35.501355%;
    }
    max-width: 393px;
    background-color: #e9f7f5;
    display: block;
    box-sizing: border-box;
    overflow: auto;
    margin: 0;

    .approvalDetailsEmptyStateBox {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 200px;
      height: 100%;
    }
  }
`;

const ApproverAvatarsBox = styled.section`
  display: inline-block;
  .avatar-container {
    min-width: 100px;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .avatar-container-item {
        margin-left: 5px;
        transition: 0.4s all ease-in-out;
      }
    }
    .avatar-container-item {
      margin-left: -15px;
      transition: 0.4s all ease-in-out;
      transform: scale(1);
      flex: 0 0 auto;
      .avatar {
        display: block;
        background: #efefef;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 3px solid #dcdcdc;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
        z-index: 2;
      }
    }
  }
`;

const TableBadge = styled.span<TableBadgeProps>`
  box-sizing: border-box;
  display: inline-block;
  text-transform: ${(props) => (props.caps ? "capitalize" : "none")};
  background-color: ${(props) =>
    getStatusDisplay(props.display, "b_color", props.variant)};
  color: ${(props) =>
    getStatusDisplay(props.display, "color", props.variant)} !important;
  border-radius: 28px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding: 4px 8px 2px;
  line-height: inherit;
`;

const BankChecked = styled.div<BankRadioProps>`
  background: ${(props) => (props.isChecked ? `${colors.pri}` : "#DCDDE5")};
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: ${(props) => (props.isChecked ? "block" : "none")};
  }
`;

const SettingsInnerContainer = styled.div`
  padding: 40px;

  p {
    color: rgba(57, 63, 73, 0.8);
    font-size: 17px;
  }
`;

const SettingsCardContainer = styled.div`
  margin-top: 40px;
  @media ${device.laptop} {
    display: flex;
  }
`;

const MemberContainer = styled.div`
  padding: 40px 25px;

  p {
    color: rgba(57, 63, 73, 0.8);
    font-size: 17px;
  }
`;

const EmailTempGrid = styled.div`
  display: grid;
  h3 {
    color: #393f49;
  }

  @media ${device.laptop} {
    grid-template-columns: 47% 47%;
    grid-column-gap: 25px;
  }

  .form-header {
    color: #192850;
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const NotificationSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SettingsCard = styled.div<EmailPreferenceProps>`
  background: #ffffff;
  border: 1px solid #e6ecf0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 367px;
  height: auto;
  padding: 20px 30px;

  .note-text {
    font-size: 13px;
  }

  .settlement-header {
    color: rgba(57, 63, 73, 0.9);
  }

  .settlement-sub-text {
    font-size: 14px;
    line-height: 18px;
  }

  .box {
    margin-bottom: 20px;
    background: #f8f9fd;
    width: 280px;
    padding: 5px 30px;
    font-weight: 500;
  }

  hr {
    border-top: 1px solid #ecf0f3;
    border-bottom: none;
    margin: 30px 0;
  }

  .h3 {
    margin: 0;
    padding: 0;
    color: #393f49;
  }

  p {
    color: rgba(57, 63, 73, 0.6);
    font-size: 15px;
  }

  .add-bank {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      color: #94a2ab;
      margin: 0 0 0 8px;
      cursor: pointer;
      font-weight: 11.5px;
    }

    .add-btn {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: #2db49e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  button {
    margin: ${(props) => (props.isEnabled ? "30px auto 0 auto" : "30px 0 0 0")};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    font-size: 14px;
  }

  @media ${device.laptop} {
    margin-right: 40px;
  }
`;

const AddEmailModalFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AddEmailModalContainer = styled.div`
  h3 {
    color: #162930;
    text-align: center;
    margin-bottom: 30px;
  }

  .form-header {
    color: #192850;
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const CustomRadioContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  p {
    color: #94a2ab;
    font-size: 14px;
    margin: 0 0 0 8px;
    padding: 0;
  }
`;

const EmailInputContainer = styled.div`
  margin: 10px 0;
  p {
    margin: 20px 0 30px 0;
    padding: 0;
    color: #94a2ab;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
  }
`;

const CustomRadio = styled.div<RadioProps>`
  border: 3px solid ${colors.pri};
  background: ${(props) => (props.isChecked ? `${colors.pri}` : "")};
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 25px;
`;

const CustomCheck = styled.div<RadioProps>`
  border: 3px solid ${colors.pri};
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner-child {
    background: ${(props) => (props.isChecked ? `${colors.pri}` : "")};
    width: 12px;
    height: 12px;
  }
`;

const PortalActivationContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 50%;
  grid-template-rows: 100px 300px;
  grid-gap: 10px;
  padding: 10px;
  margin-top: 40px;

  h3 {
    color: #393f49;
    font-size: 15px;
  }

  p {
    color: rgba(57, 63, 73, 0.6);
    font-size: 12px;
  }
`;

const ActivateContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

const CustomDivText = styled.div`
  display: inline-block;
  margin-left: 5px;
  position: absolute;
`;

const TeamMemberTableContainer = styled.div`
  margin-top: 20px;

  .avatar__td {
    & span:nth-child(1) {
      height: 35px;
      width: 35px;
    }
  }

  .letter {
    font-size: 12px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #162930;
    background: #b8eedf;
  }
`;

const FilterDropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 125px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  padding: 12px 16px;
  z-index: 2;
  border-radius: 5px;
  white-space: nowrap;
  left: 12px;
  top: 2px;

  @media ${device.laptop} {
    left: 5rem;
    top: 2px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    text-align: center;

    small {
      margin: 20px 0;
      font-size: 12px;
    }
    .upload-icon {
      max-height: 55px;
      max-width: 55px;
    }
    .action-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin: 0 !important;
      }
    }
  }
`;

const OtpPayoutContainer = styled.div`
  .switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    margin: 0;
    font-weight: 400;
    color: #000;
  }
  .phone-wrapper {
    margin-top: 30px;
  }
  .switch {
    width: 35px;
    height: 20px;
  }
  .slider:before {
    height: 14px;
    width: 14px;
    bottom: 3px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  label {
    font-weight: 300;
  }
`;

const PolicyFormAccordionBox = styled.section`
  background-color: #ffffff;
  border: 0.5px solid #65c466;
  box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.04);
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
  + {
    & {
      position: relative;
      margin-top: 20px;
      &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 20px;
        bottom: 0;
        top: -20px;
        background-color: gray;
        left: auto;
        right: 0;
        margin-right: 20px;
      }
      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 20px;
        bottom: 0;
        top: -20px;
        background-color: gray;
        left: 0;
        right: auto;
        margin-left: 20px;
      }
    }
  }
  &:focus-within,
  &:active {
    border-color: #65c466;
  }
  .policyDetailsBox {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    padding: 20px 30px;
    [id^="accordionLock"] {
      opacity: 0;
      position: absolute;
      z-index: -1;
      top: 0;
      left: -100%;
      &:checked {
        + {
          .policyDetailsBoxLabel {
            .policyDetailsBoxLabelChevron {
              transform-origin: center 45%;
              transform: rotate(180deg);
            }
          }
        }
        ~ {
          .policyDetailsBoxContent {
            .policySectionBody {
              height: auto;
              opacity: 1;
            }
          }
        }
      }
    }
    .policyDetailsBoxLabel {
      padding: 5px;
      cursor: pointer;
      order: 2;
      position: relative;
      z-index: 2;
      .policyDetailsBoxLabelChevron {
        font-weight: 100;
        display: inline-block;
        padding: 2px;
        transition: transform 0.15s ease-in-out;
      }
    }
    .policyDetailsBoxContent {
      height: auto;
      order: 1;
      margin-right: -20px;
      flex: 1 1 auto;
      .policySectionBody {
        opacity: 0;
        padding: 0;
        height: 0;
        transition: opacity 0.2s ease-in-out;
      }
      .policySectionHeading {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: #162930;
        margin-top: 0;
      }

      @media ${device.mobile_} {
        width: 100%;
      }
    }

    @media ${device.mobile_} {
      padding: 10px 15px;
    }
  }
`;

const PermissionsContainer = styled.div`
  background: "#FFF";
  height: 100%;
  padding: 20px;

  .sub-heading {
    font-weight: 200;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 40px 0;

    @media ${device.mobile_} {
      grid-template-columns: auto;
    }
  }
`;

const PermissionsCard = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(122, 121, 120, 0.16);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 10px;

  .icon {
    height: 40px;
    width: 40px;
    background: #f6f6f6;
    border-radius: 50%;
  }
  .description {
    flex: 1;
    margin-left: 10px;
    font-size: 14px;

    p {
      margin: 5px 0;
    }
    & p:nth-child(2) {
      font-weight: 200;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${device.mobile_} {
    justify-content: start;
    gap: 30px;
    flex-direction: column;
  }
`;

const FilterButton = styled.button`
  background: #eee;
  padding: 8px 16px;
  border: 0;
  border-radius: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  height: 40px;
`;
const DeveloperInfoCard = styled.div`
  margin: 40px auto 0 auto;
  border-radius: 8px;
  background: rgba(45, 180, 158, 0.05);
  padding: 8px;
  max-width: 540px;

  .info-content {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px 10px;
    border-radius: 8px;
    column-gap: 16px;

    svg {
      flex-shrink: 0;
    }

    p {
      margin: 0px;
    }
    a {
      color: ${colors.pri};
      font-size: small;
      display: block;
    }
  }
`;
const PreferredNameInfoCard = styled.div`
  margin: 40px 0;
  border-radius: 8px;
  background: rgba(45, 180, 158, 0.05);
  padding: 8px;
  max-width: 540px;

  .info-content {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px 10px;
    border-radius: 8px;
    column-gap: 8px;

    svg {
      flex-shrink: 0;
    }

    p {
      margin: 0px;
    }
  }
`;
const InitiatorInviteInfoCard = styled.div`
  margin: 20px auto 80px auto;
  border-radius: 8px;
  background: rgba(240, 244, 249, 0.6);
  padding: 20px 15px 20px 0;
  display: flex;
  align-items: start;

  svg {
    flex-shrink: 0;
    padding 15px 10px 0;
  }

  p {
    margin: 0px;
  }
`;
const OTPInfoCard = styled.div`
  margin: 20px auto 0 auto;
  border-radius: 8px;
  background: rgba(240, 244, 249, 0.6);
  padding: 8px;
  max-width: 540px;
  display: flex;
  align-items: center;
  padding: 15px 10px;

  p {
    margin: 0px;
  }
`;

const PayoutApiOptions = styled.div`
  margin: 25px 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  li {
    flex-grow: 1;

    .radio-label {
      background: white;
      border: 1px solid rgba(220, 221, 229, 0.5);
      border-radius: 7px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      font-weight: 600;
      margin: 0 auto 10px;
      padding: 12px 20px 12px 50px;
      position: relative;
      transition: 0.3s ease all;
      color: #5c696e;
      font-weight: 400;
      display: block;
      font-size: 14px;
    }
    .radio-label:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    }

    .radio-label:before {
      background: #fff;
      border-radius: 50%;
      content: "";
      height: 15px;
      left: 20px;
      position: absolute;
      top: calc(50% - 10px);
      transition: 0.3s ease background-color;
      width: 15px;
      border: 2px solid rgba(92, 105, 110, 0.3);
    }
    .radio-label span {
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }
    .radio-btn {
      position: absolute;
      visibility: hidden;
    }
    .radio-btn:checked + .radio-label {
      background: #f7f7ff;
      border: 1px solid #0b2e28;
    }
  }
  .radio-btn:checked + .radio-label:before {
    background-color: rgba (11, 46, 40, 0.5);
    border: 3px solid #0b2e28;
    background: ".";
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
  }
  .radio-btn:checked + .radio-label:after {
    background: #0b2e28;
    opacity: 0.5;
    border-radius: 50%;
    content: "";
    height: 5px;
    left: 26px;
    position: absolute;
    top: calc(50% - 4px);
    transition: 0.3s ease background-color;
    width: 5px;
    border: 2px solid rgba(92, 105, 110, 0.3);
  }
`;

const DepartmentPreviewHeader = styled.div`
  h3 {
    color: #07000f;
    font-size: 17px;
    margin-bottom: 8px;

    span {
      background-color: #cde2ea;
      padding: 0px 5px;
      color: #103129;
      font-size: 14px;
      border-radius: 2px;
      margin-left: 5px;
    }
  }

  p {
    color: #5c696e;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0;
  }
`;
const PolicyInfoSpan = styled.span`
  color: #5c696e;
  font-weight: 300;
  font-size: 14px;
  svg {
    margin-right: 5px;
  }
`;

export {
  SettingsInnerContainer,
  CustomCheck,
  SettingsCardContainer,
  MemberContainer,
  SettingsCard,
  AddEmailModalFooter,
  AddEmailModalContainer,
  CustomRadio,
  EmailInputContainer,
  CustomRadioContainer,
  EmailTempGrid,
  NotificationSelectContainer,
  PortalActivationContainer,
  ActivateContainer,
  CustomDivText,
  BankChecked,
  TableBadge,
  TeamMemberTableContainer,
  OtpPayoutContainer,
  PermissionsContainer,
  PermissionsCard,
  FlexContainer,
  FilterButton,
  FilterDropdownContent,
  ApprovalWorkFlowSheet,
  NewPolicyContainer,
  NewTeamMemberContainer,
  EditTeamMemberContainer,
  PolicyFormAccordionBox,
  ApprovalPolicyDetailsBox,
  ApproverAvatarsBox,
  SettingsEmailContainer,
  NewRoleContainer,
  PermissionListContainer,
  DeveloperInfoCard,
  PayoutApiOptions,
  OTPInfoCard,
  InitiatorInviteInfoCard,
  PreferredNameInfoCard,
  RadioContainer,
  RadioElement,
  DepartmentPreviewHeader,
  PolicyInfoSpan
};
