import React from "react";
import Modal from ".";
import { ReactComponent as FundAccountIcon } from "../../assets/svg/quickactions[fund-account].svg";
import { ReactComponent as CustomerTransferIcon } from "../../assets/svg/quickactions[customer-transfer].svg";
import { ReactComponent as SweepActionIcon } from "../../assets/svg/quickactions[sweep-actions].svg";
import { ReactComponent as SendMoneyIcon } from "../../assets/svg/quickactions[send-money].svg";
import { ClosePreviewIcon, QuickActionsContent } from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router";
import { Routes } from "routes/routes-constants";
import { VirtualAccountPayload } from "interfaces/wallet";
import toast from "react-hot-toast";
import FundAccountModal from "./FundAccountModal";
import { useModals } from "context/modalContext";
import { BusinessInterface, MerchantInterface } from "interfaces/users";
import { decrypt_user0bj } from "utils/auth-util";
import { defaultBusinessKey, userKey } from "utils/constants";
import storage from "utils/storage";
import { useGetVirtualAccounts } from "lib/query/wallet";
import SweepModal from "./SweepModal";
import WalletTransferModal from "./WalletTransferModal";

interface QuickActionsModalProps {
  closeQuickActionsModal: () => void;
  title: string;
  history: any;
}

const QuickActionsModal: React.FC<QuickActionsModalProps> = ({
  closeQuickActionsModal,
  title,
  history
}) => {
  const fundHistory = useHistory<{ [key: string]: unknown }>();
  const { push, close } = useModals();
  const { push: pushSweepModal, close: closeSweepModal } = useModals();
  const { push: walletTransferModal, close: closeWallettransferModal } =
    useModals();
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

  const merchantInfo: MerchantInterface = decrypt_user0bj(storage.get(userKey));

  const { mutate: getAllVirtualAccounts, isLoading } = useGetVirtualAccounts();

  const triggerVirtualAccountModal = () => {
    const values: VirtualAccountPayload = {
      email: merchantInfo.email,
      first_name: merchantInfo.first_name || "",
      last_name: merchantInfo.last_name || "",
      business_id: defaultBusinessInfo.id,
      is_permanent: true,
      single_use: false,
      is_for_funding: true,
      purpose: "funding Business wallet",
      category: "fund_bank",
      preferred_banks: [
        {
          bank_code: "111",
          bank_name: "Wema Bank",
          id: "wema"
        }
      ]
    };

    getAllVirtualAccounts(values, {
      onSuccess: async (response) => {
        push(
          <div>
            <FundAccountModal
              close={close}
              virtualAccountInfo={response}
              merchant={defaultBusinessInfo}
              history={fundHistory}
            />
          </div>
        );
      },
      onError: (err) => {
        if (!("message" in err)) {
          if (err.length > 0) {
            err.forEach((err) => toast.error(err as string));
            return;
          }
        } else {
          if (typeof err.message !== "string") {
            return;
          }
          close();
          toast.error(`${err.message}`);
        }
      }
    });
  };

  const triggerCustomerTransferModal = () => {
    close();
    walletTransferModal(
      <div>
        <WalletTransferModal
          close={closeWallettransferModal}
          businessID={defaultBusinessInfo.id}
        />
      </div>
    );
  };

  const triggerSweepModal = () => {
    close();
    pushSweepModal(
      <div>
        <SweepModal
          businessId={defaultBusinessInfo.id}
          close={closeSweepModal}
        />
      </div>
    );
  };
  return (
    <Modal
      isFooter={false}
      isHeader={false}
      submitButtonHeight={50}
      submitButtonText={"Yes, Delete"}
    >
      <QuickActionsContent>
        <div className="modal-body">
          <ClosePreviewIcon
            className="close-icon"
            isQuickActions
            onClick={() => closeQuickActionsModal()}
          >
            <AiOutlineClose color="#fff" />
          </ClosePreviewIcon>
          <div className="title">{title}</div>

          <ul>
            <li onClick={() => triggerVirtualAccountModal()}>
              <FundAccountIcon />
              <div>
                <h3>Fund Account</h3>
                <p>Generate a virtual account to fund your business wallet.</p>
              </div>
              <div> {isLoading && <div id="loading"></div>}</div>
            </li>
            <li
              onClick={() => {
                close();
                history.push(Routes.NewPayout);
              }}
            >
              <SendMoneyIcon />
              <div>
                <h3>Send Money</h3>
                <p>
                  Transfer money from your business wallet to your active bank
                  account.
                </p>
              </div>
            </li>
            <li onClick={() => triggerSweepModal()}>
              <SweepActionIcon />
              <div>
                <h3>Sweep Action</h3>
                <p>Transfer money from all wallets to your business account.</p>
              </div>
            </li>
            <li onClick={() => triggerCustomerTransferModal()}>
              <CustomerTransferIcon />
              <div>
                <h3>Customer Transfer</h3>
                <p>Transfer funds from one customer account to another.</p>
              </div>
            </li>
          </ul>
        </div>
      </QuickActionsContent>
    </Modal>
  );
};

export default QuickActionsModal;
