import React from "react";
import { FormEventHandler } from "react";
import colors from "styles/colors";
import { CustomButtomContainer } from "./styles";

interface ButtonProps {
  mode?: "basic" | "standard" | "skeletal" | "setter";
  type?: "submit" | "button" | "reset";
  label: React.ReactElement | string;
  className?: string;
  bgColor?: string;
  color?: string;
  height?: number;
  disabled?: boolean;
  width?: number;
  isLoading?: boolean;
  form?: string;
  onClick?: FormEventHandler | (() => void);
}

const CustomButton: React.FC<ButtonProps> = ({
  mode = "basic",
  type = "submit",
  form,
  label,
  className,
  bgColor = `${colors.pri}`,
  color = "#ffffff",
  height = 56,
  width = 145,
  disabled,
  isLoading,
  onClick
}) => {
  const formatLabel = (label: string | React.ReactElement, mode: string) => {
    return mode === "standard" || mode === "setter" ? <b>{label}</b> : label;
  };

  return (
    <CustomButtomContainer
      className={className}
      form={form}
      mode={mode}
      height={height}
      width={width}
      bgColor={mode === "setter" ? "#5C696E" : bgColor}
      color={mode === "setter" ? "#ffffff" : color}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="lds-dual-ring"></div>
      ) : (
        formatLabel(label, mode)
      )}
    </CustomButtomContainer>
  );
};

export default CustomButton;
