import React from "react";
import { NotFoundContainer, ModifiedCancelButton } from "./styles";
import CustomButton from "components/CustomButton";
import { Routes } from "routes/routes-constants";

const NotFound = () => {
  const navigateToRelevantLink = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <NotFoundContainer>
      <main>
        <h1>404</h1>

        <h3>Oops! Something went wrong</h3>
        <p>The page you are looking for is not available at the moment.</p>
        <div className="action">
          {" "}
          <ModifiedCancelButton
            onClick={() =>
              navigateToRelevantLink(
                "https://duplo-help.freshdesk.com/support/home"
              )
            }
          >
            Contact Us
          </ModifiedCancelButton>
          <CustomButton
            label="Go back"
            onClick={() => (window.location.href = Routes.Invoice)}
            height={50}
            width={150}
            bgColor="#006355"
          />
        </div>
      </main>
      <footer>
        <div
          className="link"
          onClick={() =>
            navigateToRelevantLink("https://duplo-product-demo.webflow.io/")
          }
        >
          Need Help?
        </div>

        <div
          className="link"
          onClick={() =>
            navigateToRelevantLink(
              "https://www.tryduplo.com/terms-and-conditions"
            )
          }
        >
          Terms & Conditions
        </div>

        <div
          className="link"
          onClick={() =>
            navigateToRelevantLink("https://www.tryduplo.com/privacy-policy")
          }
        >
          Privacy Policy
        </div>
      </footer>
    </NotFoundContainer>
  );
};

export default NotFound;
