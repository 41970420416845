import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Svg,
  Path,
  Font
} from "@react-pdf/renderer";
import { formatAmount } from "utils/number-formatter";
import moment from "moment";
import { PDFReceiptType } from "./@types";

const PDFRenderer: React.FC<{
  transactionDetails: PDFReceiptType | null;
}> = ({ transactionDetails }) => {
  /* @CHECK: (For how to setup the fonts) https://stackoverflow.com/a/70577891 */
  /* @CHECK: (For how to find font links) https://developers.google.com/fonts/docs/developer_api */

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf"
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
        fontStyle: "italic"
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
        fontStyle: "italic",
        fontWeight: 700
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
        fontWeight: 700
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
        fontWeight: 500
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
        fontWeight: 300
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
        fontWeight: 900
      }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      padding: "0 35px",
      flexDirection: "column",
      backgroundColor: "#003F34",
      position: "relative",
      fontFamily: "Roboto"
    },
    pageBackground: {
      position: "absolute",
      minWidth: "100%",
      minHeight: "100%",
      height: "100%",
      width: "100%"
    },
    headerSection: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "transparent",
      marginTop: "35px"
    },
    mainSection: {
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      borderRadius: "3px",
      paddingLeft: "32px",
      paddingRight: "32px",
      marginTop: "45px",
      marginBottom: "40px",
      fontFamily: "Roboto"
    },
    mainSectionTitleBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: "32px",
      paddingBottom: "18px",
      borderBottom: "1px solid #DDDDDD"
    },
    mainSectionMainTitle: {
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "18px",
      textAlign: "center",
      color: "#162930",
      fontFamily: "Roboto"
    },
    mainSectionSubTitle: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "8px",
      color: "#6E757C",
      textAlign: "center",
      fontFamily: "Roboto"
    },
    mainSectionAmountBox: {
      paddingTop: "22px",
      paddingBottom: "32px",
      borderBottom: "1px solid #DDDDDD"
    },
    mainSectionAmountHeading: {
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "30px",
      color: "#003F34",
      fontFamily: "Roboto"
    },
    mainSectionAmountDateNote: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      marginTop: "10px",
      color: "#5C696E",
      fontFamily: "Roboto"
    },
    mainSectionParticularsBox: {
      paddingBottom: "82px",
      display: "flex",
      flexDirection: "column"
    },
    mainSectionParticularsRow: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "32px",
      justifyContent: "space-between"
    },
    mainSectionParticlarsColumn: {
      display: "flex",
      flexDirection: "column"
    },

    mainSectionLeftParticlarsColumn: {
      flex: 2,
      marginRight: "20px"
    },
    mainSectionRightParticlarsColumn: {
      flex: 1
    },
    mainSectionParticularsName: {
      marginBottom: "5px",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#5C696E",
      fontFamily: "Roboto",
      textAlign: "left"
    },
    mainSectionParticularsValue: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      color: "#003F34",
      fontFamily: "Roboto",
      textAlign: "justify",
      wordWrap: "break-word"
    },
    mainSectionParticularsValueExtra: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      color: "#003F34",
      fontFamily: "Roboto",
      textAlign: "left",
      textTransform: "capitalize"
    },
    textBaser: {
      padding: "5px 6px",
      backgroundColor: "#BBE5B3",
      borderRadius: "4px",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      color: "#003F34",
      fontFamily: "Roboto"
    },
    footerSection: {
      flexDirection: "column",
      backgroundColor: "transparent",
      color: "#6E757C",
      fontStyle: "normal",
      fontWeight: "medium",
      fontSize: "12px",
      justifyContent: "center"
    },
    footerSectionText: {
      textAlign: "center"
    }
  });

  const Logo = () => (
    <Svg style={{ width: 100, height: 30 }}>
      <Path
        d="M70.12 6.77393C65.6509 6.77393 62.0313 10.4216 62.0313 14.9253C62.0313 19.429 65.6509 23.0767 70.12 23.0767C74.589 23.0767 78.2086 19.429 78.2086 14.9253C78.2086 10.4216 74.5706 6.77393 70.12 6.77393ZM70.12 19.8198C67.4422 19.8198 65.2631 17.6238 65.2631 14.9253C65.2631 12.2268 67.4422 10.0307 70.12 10.0307C72.7977 10.0307 74.9768 12.2268 74.9768 14.9253C74.9768 17.6238 72.7977 19.8198 70.12 19.8198Z"
        fill="white"
      />
      <Path
        d="M47.5345 6.77393C45.7063 6.77393 44.0258 7.38807 42.6777 8.43026V7.18335H39.4644V29.9997H42.6777V21.4203C44.0258 22.4439 45.7063 23.0767 47.5345 23.0767C52.0036 23.0767 55.6232 19.429 55.6232 14.9253C55.6232 10.4216 52.0036 6.77393 47.5345 6.77393ZM47.5345 19.8198C44.8568 19.8198 42.6777 17.6238 42.6777 14.9253C42.6777 12.2268 44.8568 10.0307 47.5345 10.0307C50.2123 10.0307 52.3914 12.2268 52.3914 14.9253C52.3914 17.6238 50.2123 19.8198 47.5345 19.8198Z"
        fill="white"
      />
      <Path
        d="M7.92244 0H0V22.8164H7.92244C11.0619 22.8164 13.7765 21.6998 16.0295 19.4479C18.2825 17.196 19.409 14.5161 19.409 11.4082C19.409 8.30025 18.2825 5.62035 16.0295 3.36849C13.7765 1.11663 11.0619 0 7.92244 0ZM13.5365 17.196C12.0037 18.7779 10.1385 19.5596 7.92244 19.5596H3.54571V3.25682H7.92244C10.12 3.25682 11.9852 4.03846 13.5365 5.62035C15.0693 7.20224 15.8449 9.11911 15.8449 11.4082C15.8264 13.6973 15.0693 15.6141 13.5365 17.196Z"
        fill="white"
      />
      <Path d="M60.4248 0H57.2115V22.8164H60.4248V0Z" fill="white" />
      <Path
        d="M34.3305 14.9255C34.3305 17.624 32.1514 19.8201 29.4736 19.8201C26.7959 19.8201 24.6167 17.624 24.6167 14.9255V7.18359H21.3665V14.9255C21.3665 19.4293 24.9861 23.0769 29.4552 23.0769C31.2834 23.0769 32.9639 22.4628 34.312 21.4206V22.8163H37.5253V7.18359H34.312V14.9255H34.3305Z"
        fill="white"
      />
      <Path
        d="M86.8511 22.7577C84.7458 22.7577 83.0284 21.027 83.0284 18.9054V10.661C83.0284 8.53937 84.7458 6.80859 86.8511 6.80859C91.2094 6.80859 94.7735 10.3818 94.7735 14.7925C94.7735 19.1659 91.2094 22.7577 86.8511 22.7577ZM86.8511 8.11132C85.4661 8.11132 84.3211 9.24656 84.3211 10.661V18.9054C84.3211 20.3011 85.4476 21.455 86.8511 21.455C90.5076 21.455 93.4624 18.4587 93.4624 14.7925C93.4624 11.089 90.5076 8.11132 86.8511 8.11132Z"
        fill="#2DB49E"
      />
      <Path
        d="M92.0774 18.6659C89.9721 18.6659 88.2546 16.9352 88.2546 14.8136V6.56915C88.2546 4.44757 89.9721 2.7168 92.0774 2.7168C96.4356 2.7168 99.9998 6.29 99.9998 10.7007C99.9813 15.0741 96.4356 18.6659 92.0774 18.6659ZM92.0774 4.01953C90.6923 4.01953 89.5473 5.15476 89.5473 6.56915V14.8136C89.5473 16.2094 90.6738 17.3632 92.0774 17.3632C95.7339 17.3632 98.6886 14.3669 98.6886 10.7007C98.6886 7.03441 95.7154 4.01953 92.0774 4.01953Z"
        fill="white"
      />
    </Svg>
  );
  return (
    <Document author="Duplo" subject="Duplo Payout Reciept">
      <Page size={"A4"} style={styles.page}>
        {/* @CHECK: https://github.com/diegomura/react-pdf/issues/2089 */}
        {/* @NOTE: The above link is to an issue with an error i am experiencing when try to give this PDF... */}
        {/* @NOTE: ... a background image `ctx.EmbedImage is not a function`. This means that for now the PDF... */}
        {/* @NOTE: ... generated here will not have a background image until this bug is fixed in @react-pdf/pdfkit. */}
        {/* @NOTE: `@react-pdf/pdfkit` is a dependency of the package used here: `@react-pdf/renderer` imported here above */}
        {/* @TODO: `import { Image } from @react-pdf/renderer` for the future fix to work */}
        {/* @FIXME: When a fix is available in future version of `@react-pdf/renderer`, fix the below snippet and uncomment it into the codebase;
        <View fixed={true}>
          <Image src={"https://assets.tryduplo.com/emails/generic-background.jpg"} style={styles.pageBackground} />
        </View>
      */}
        <View style={styles.headerSection}>
          <Logo />
        </View>
        <View style={styles.mainSection}>
          <View style={styles.mainSectionTitleBox}>
            <Text style={styles.mainSectionMainTitle}>
              <b>Transaction Receipt</b>
            </Text>
            <Text style={styles.mainSectionSubTitle}>
              Generated on {moment(new Date()).format("MMM DD, YYYY | hh:mm A")}
            </Text>
          </View>
          <View style={styles.mainSectionAmountBox}>
            <Text style={styles.mainSectionAmountHeading}>
              <b>₦ {formatAmount(transactionDetails?.amount || 0)}</b>
            </Text>
            <Text style={styles.mainSectionAmountDateNote}>
              {moment(transactionDetails?.created_at || "").format(
                "MMM DD, YYYY | hh:mm A"
              )}
            </Text>
          </View>
          <View style={styles.mainSectionParticularsBox}>
            <View style={styles.mainSectionParticularsRow}>
              <View style={styles.mainSectionLeftParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>Recipient</Text>
                <Text style={styles.mainSectionParticularsValue}>
                  {transactionDetails?.account_name}
                </Text>
              </View>
              <View style={styles.mainSectionRightParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>Bank</Text>
                <Text style={styles.mainSectionParticularsValue}>
                  {transactionDetails?.bank_name}
                </Text>
              </View>
            </View>

            <View style={styles.mainSectionParticularsRow}>
              <View style={styles.mainSectionLeftParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>
                  Account Number
                </Text>
                <Text style={styles.mainSectionParticularsValue}>
                  {transactionDetails?.account_number}
                </Text>
              </View>
              <View style={styles.mainSectionRightParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>Status</Text>
                <Text>
                  <Text style={styles.textBaser}>
                    &nbsp;{transactionDetails?.txn_status}&nbsp;
                  </Text>
                </Text>
              </View>
            </View>

            <View style={styles.mainSectionParticularsRow}>
              <View style={styles.mainSectionParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>
                  Transaction Ref
                </Text>
                <Text style={styles.mainSectionParticularsValue}>
                  {transactionDetails?.transaction_ref}
                </Text>
              </View>
            </View>
            <View style={styles.mainSectionParticularsRow}>
              <View style={styles.mainSectionParticlarsColumn}>
                <Text style={styles.mainSectionParticularsName}>
                  Description
                </Text>
                <Text style={styles.mainSectionParticularsValue}>
                  {transactionDetails?.description}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.footerSection}>
          <Text style={styles.footerSectionText}>
            © Duplo {new Date().getFullYear()}
          </Text>
          <Text style={styles.footerSectionText}>
            Business Payments, Simplified.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFRenderer;
