import React from "react";
import { ReactComponent as QuickActionsIcon } from "assets/svg/flash.svg";
import { QuickActionsButton } from "./styles";
import { useModals } from "context/modalContext";
import QuickActionsModal from "components/Modal/QuickActionsModal";
import { device } from "styles";
import { useMediaQuery } from "lib/hooks";

interface QuickActionsProps {
  history: any;
}

const QuickActions: React.FC<QuickActionsProps> = ({ history }) => {
  const { push, close: close } = useModals();

  const isMobile = useMediaQuery(device.mobile_);

  const triggerQuickActionsModal = () => {
    push(
      <div>
        <QuickActionsModal
          title="Quick Actions"
          closeQuickActionsModal={close}
          history={history}
        />
      </div>
    );
  };
  return (
    <>
      {isMobile ? (
        <QuickActionsIcon
          width={25}
          height={25}
          onClick={triggerQuickActionsModal}
        />
      ) : (
        <QuickActionsButton onClick={triggerQuickActionsModal}>
          <QuickActionsIcon width={20} height={20} />
          <span>Quick Actions</span>
        </QuickActionsButton>
      )}
    </>
  );
};

export default QuickActions;
