import styled from "styled-components";
import { device } from "styles";

type PaginationContainerProps = {
  gripped: boolean;
}

const PaginationContainer = styled.div<PaginationContainerProps>`
  margin: 30px 30px 50px 30px;
  display: flex;
  justify-content: flex-start;

  @media ${device.mobile_} {
    margin: ${(props) => props.gripped ? "15px 0px 25px 0px": "15px 20px 25px 20px"}; 
    justify-content: center;
  }
`;

const PaginationElement = styled.div`
  display: flex;

  @media ${device.mobile_} {
    width: 100%;
    justify-content: space-between;
  }
`;

const PaginationDropdownContainer = styled.div`
  display: flex;
  margin-right: 10px;

  p {
    margin-left: 10px;
    color: #9da5a8;
    font-size: 15px;
  }
`;

const PaginationDiv = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  .selected {
    color: #5c696e;
    font-weight: 600;
  }

  li {
    color: #c4c9cb;
  }

  .previous {
    color: #5c696e;
    font-weight: 600;
    padding-top: 2.5px;
  }

  .next {
    color: #5c696e;
    font-weight: 600;
    padding-top: 2.5px;
  }

  li {
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
  }
`;

export {
  PaginationContainer,
  PaginationElement,
  PaginationDropdownContainer,
  PaginationDiv
};
