import config from "config";
import React from "react";
import { IoIosNotifications } from "react-icons/io";

const HeadwayWidget = () => {

	  React.useEffect(() => {
          const script = document.createElement("script");
          script.async = true;
          script.src = "//cdn.headwayapp.co/widget.js";
          document.head.appendChild(script);

          const headwayConfig = {
            selector: "#headway-badge",
            account:  config.HEADWAY_ACCOUNT_ID || "",
            trigger: "#headway-badge",
            translations: {
              title: "Latest Updates",
              readMore: "Read more",
              labels: {
                new: "News",
                improvement: "Updates",
                fix: "Fixes"
              },
              footer: "Read more 👉"
            }
          };
          script.onload = function () {
            window.Headway.init(headwayConfig);
          };
    }, []);

  return <IoIosNotifications color="#2DB49E" size={25} />;
};

export default HeadwayWidget;
