import CustomButton from "components/CustomButton";
import InputComponent from "components/FormElements/inputs";
import { Formik, FormikProps } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { addTeamMemberSchema } from "utils/validationSchema";
import Modal from ".";
import { CancelButton, ModalFooter } from "./styles";
import { Routes } from "routes/routes-constants";
import SuccessfulActivationModal from "./SuccessfulActivationModal";
import { useModals } from "context/modalContext";
import SwitchButtons from "components/SwitchButtons";
import { FlexContainer } from "pages/Approvals/styles";
import DropdownComponent from "components/DropdownComponent";
import { ErrorSpan } from "components/FormElements/styles";
import colors from "styles/colors";
import { DepartmentMemberFormInterface } from "interfaces/departments";
import {
  BusinessInterface,
  IRolesResponseObject,
  ITeamMemberResponseObject,
  MerchantInterface,
  TeamMemberPayload,
  UpdateTeamMemberInterface
} from "interfaces/users";
import { decrypt_user0bj } from "utils/auth-util";
import { defaultBusinessKey, userKey } from "utils/constants";
import storage from "utils/storage";
import { useCreateTeamMember, useUpdateTeamMember } from "lib/query/user";

interface AddDepartmentMemberModalProps {
  departmentId: string;
  closeAddMemberModal: () => void;
  listOfAllMembers: ITeamMemberResponseObject[];
  listOfRoles: IRolesResponseObject[];
}

enum ActiveSwichEnum {
  EXISTING = "Existing team member",
  NEW = "New team member"
}

const initialValues: DepartmentMemberFormInterface = {
  job_title: "",
  email: ""
};
const errorStyles = { marginTop: "-12px", marginBottom: "1.1rem" };
const AddDepartmentMemberModal: React.FC<AddDepartmentMemberModalProps> = ({
  departmentId,
  closeAddMemberModal,
  listOfAllMembers,
  listOfRoles
}) => {
  const { mutate: createTeamMember, isLoading } = useCreateTeamMember();
  const { push, close } = useModals();
  const [activeSwitch, setActiveSwitch] = React.useState<string>(
    ActiveSwichEnum.EXISTING
  );

  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));
  const [selectedMember, setSelectedMember] = React.useState("");
  const [selectedMemberError, setSelectedMemberError] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [selectedRoleError, setSelectedRoleError] = React.useState(false);

  /* @HINT: Get the merchant details for the merchant business attached to the logged-in user account */
  const merchantInfo: MerchantInterface = decrypt_user0bj<MerchantInterface>(
    storage.get(userKey)
  );
  const { mutate: updateTeamMember, isLoading: isUpdateLoading } =
    useUpdateTeamMember();

  const onToggleSwitched = (label: React.ReactElement | string) => {
    setActiveSwitch(label as string);
  };

  const handleMembersFormatting = React.useCallback(() => {
    const array: ITeamMemberResponseObject[] = listOfAllMembers.filter(
      ({ user }) => user?.is_active && (user?.first_name || user?.last_name)
    );

    const filteredData: { name: string; value: string }[] = [];
    array.forEach(({ user }) =>
      filteredData.push({
        name: user?.first_name + " " + user?.last_name,
        value: user.id || ""
      })
    );
    return filteredData;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [listOfAllMembers]);

  const handleRoleFormatting = React.useCallback(() => {
    const array: IRolesResponseObject[] = listOfRoles;

    const filteredData: { name: string; value: string }[] = [];
    array.forEach((role) =>
      filteredData.push({
        name: role.name,
        value: role.id
      })
    );
    return filteredData;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [listOfRoles]);

  const triggerSuccessModal = () => {
    push(
      <div>
        <SuccessfulActivationModal
          closeSuccessModal={() => {
            close();
            window.location.href = `${Routes.DepartmentPreview}`;
          }}
          text="Team member added successfully! "
          buttonText="Close"
        >
          <br />
          <p>We’ve sent them an email for their onboarding instructions.</p>
        </SuccessfulActivationModal>
      </div>
    );
  };

  const handleFormSubmit = async (values: DepartmentMemberFormInterface) => {
    const [existingMember] =
      ActiveSwichEnum.EXISTING &&
      listOfAllMembers.filter(({ user }) => user?.id === selectedMember);

    if (activeSwitch === ActiveSwichEnum.EXISTING) {
      if (!selectedMember) {
        return setSelectedMemberError(true);
      }
      const payload: UpdateTeamMemberInterface = {
        user_id: existingMember?.user?.id,
        merchant_id: merchantInfo.id,
        business_id: defaultBusinessInfo.id,
        role_id: existingMember?.role?.id,
        department_id: departmentId
      };

      updateTeamMember(payload, {
        onSuccess: () => {
          closeAddMemberModal();
          triggerSuccessModal();
        },
        onError: (err) => {
          if (!("message" in err)) {
            if (err.length > 0) {
              err.forEach((err) => toast.error(err as string));
              return;
            }
          } else {
            if (typeof err.message !== "string") {
              return;
            }

            toast.error(`${err.message}`);
          }
        }
      });
    } else {
      const payload: TeamMemberPayload = {
        merchant_id: merchantInfo.id,
        business_id: defaultBusinessInfo.id,
        email: values.email,
        role_id: [selectedRole],
        job_title: values.job_title,
        department_id: departmentId
      };
      if (!selectedRole) {
        return setSelectedRoleError(true);
      }

      createTeamMember(payload, {
        onSuccess: () => {
          closeAddMemberModal();
          triggerSuccessModal();
        },
        onError: (err) => {
          if (!("message" in err)) {
            if (err.length > 0) {
              err.forEach((err) => toast.error(err as string));
              return;
            }
          } else {
            if (typeof err.message !== "string") {
              return;
            }

            toast.error(`${err.message}`);
          }
        }
      });
    }
  };

  React.useEffect(() => {
    if (selectedRole) {
      setSelectedRoleError(false);
    }
    if (selectedMember) {
      setSelectedMemberError(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedMember, selectedRole]);

  return (
    <Modal
      title="Add Team Member"
      width={530}
      isWorking={isLoading || isUpdateLoading}
      isCloseIcon={false}
      close={closeAddMemberModal}
    >
      <Formik
        initialValues={initialValues}
        {...(activeSwitch === ActiveSwichEnum.NEW && {
          validationSchema: addTeamMemberSchema
        })}
        onSubmit={async (values) => {
          await handleFormSubmit(values);
        }}
      >
        {({ handleSubmit }: FormikProps<DepartmentMemberFormInterface>) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="modal-body">
              <FlexContainer
                style={{ margin: "5px 0 30px", justifyContent: "center" }}
              >
                <SwitchButtons
                  labels={[ActiveSwichEnum.EXISTING, ActiveSwichEnum.NEW]}
                  onSwitch={onToggleSwitched}
                />
              </FlexContainer>
              {activeSwitch === ActiveSwichEnum.EXISTING && (
                <>
                  <DropdownComponent
                    multiple={false}
                    label={
                      <span>
                        Team Member
                        <span style={{ color: `${colors.error}` }}>*</span>
                      </span>
                    }
                    placeHolderText="Choose existing team member"
                    dropdownOption={handleMembersFormatting()}
                    selectedOption={selectedMember}
                    onChange={setSelectedMember}
                    hasError={selectedMemberError}
                  />

                  {selectedMemberError && (
                    <div className="error" style={errorStyles}>
                      <ErrorSpan>Please select a team member</ErrorSpan>
                    </div>
                  )}
                </>
              )}
              {activeSwitch === ActiveSwichEnum.NEW && (
                <>
                  <InputComponent
                    title="Email Address"
                    disabled={false}
                    isRequired={true}
                    name="email"
                    placeholder=""
                    type="email"
                    labelStyles={{ fontWeight: "300" }}
                  />
                  <DropdownComponent
                    multiple={false}
                    label={
                      <span>
                        Role<span style={{ color: `${colors.error}` }}>*</span>
                      </span>
                    }
                    placeHolderText=""
                    dropdownOption={handleRoleFormatting()}
                    selectedOption={selectedRole}
                    onChange={setSelectedRole}
                    disabled={false}
                    hasError={selectedRoleError}
                  />

                  {selectedRoleError && (
                    <div className="error" style={errorStyles}>
                      <ErrorSpan>Please select a role</ErrorSpan>
                    </div>
                  )}
                  <InputComponent
                    title="Job Title"
                    isRequired={false}
                    name="job_title"
                    placeholder=""
                    type="text"
                    maxLength={15}
                    labelStyles={{ fontWeight: "300" }}
                  />
                </>
              )}
            </div>
            <ModalFooter>
              <CancelButton onClick={closeAddMemberModal}>Cancel</CancelButton>
              <CustomButton
                type="submit"
                label="Add team member"
                isLoading={isLoading || isUpdateLoading}
                disabled={isLoading || isUpdateLoading}
                height={40}
                width={165}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddDepartmentMemberModal;
