import config from "config";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiHeader, OptionsArgs, ServerResponse } from "interfaces/client";
import { decrypt_key, handleLogout, isCORSViolation } from "utils/auth-util";
import { isEmpty } from "utils/object-util";
import storage from "utils/storage";
import { loginKey } from "utils/constants";

const expandOnError = (
  errorMessage: string,
  errorCode?: string,
  errorResponse?: AxiosResponse
) => {
  if (errorMessage === "Network Error" && Boolean(!errorResponse)) {
    /* @HINT: The message returned below simply means that a CORS error occured OR the name resolution failed */
    return errorCode === undefined
      ? "We are currently updating our systems... Please try again later"
      : "The browser restricted access to the server response! Please contact admin";
  }
  return "Your ISP seems to have some other network-related issues";
};

const getMessageFromError = (error: AxiosError<ServerResponse<{}>>) => {
  let message = "";

  const isNotCORSViolation = !isCORSViolation(
    "request" in error
      ? (error?.request as XMLHttpRequest)
      : new XMLHttpRequest(),
    error?.config
  );

  /* @HINT: These are the varying ranges of error(s) that can occur when making async HTTP requests */
  /* @CHECK: https://axios-http.com/docs/handling_errors */
  /* @CHECK: https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/ */
  const isServerResponseEmpty =
    Boolean(!error?.response) &&
    isNotCORSViolation &&
    error?.code !== "ERR_NETWORK";
  const isServerTimedOut =
    error?.code === "ECONNABORTED" || error?.code === "ETIMEDOUT";
  const isServerUnreachable = error?.code === "ECONNREFUSED";
  const isClientOffline = !window.navigator.onLine;

  const errorMessagesMap = {
    empty: "Our systems response returned empty! Please try again",
    unreachable: "Our systems are unreachable! Please try again later",
    timeout: "Our systems request(s) are timing out! Please try again",
    indeterminate: "Something went wrong! Please try again",
    offline: "Your internet is unstable! Please check and try again"
  };

  switch (true) {
    case isClientOffline:
      message = errorMessagesMap["offline"];
      break;
    case isServerUnreachable:
      message = errorMessagesMap["unreachable"];
      break;
    case isServerTimedOut:
      message = errorMessagesMap["timeout"];
      break;
    case isServerResponseEmpty:
      message = errorMessagesMap["empty"];
      break;
    default:
      message =
        expandOnError(error.message, error?.code, error?.response) ||
        errorMessagesMap["indeterminate"];
  }

  return message;
};

/** Axios interceptors to transform error message for clientFn */
axios.interceptors.response.use(
  function (response: AxiosResponse<ServerResponse<{}>>) {
    const pageReferer = document.referrer || "";
    const isRejectedRequest =
      response?.status === 401 || response?.status === 403;
    const isFailedRequest = response?.status === 400 || response?.status >= 500;

    if (isRejectedRequest) {
      return Promise.reject(handleLogout(pageReferer.includes("/app") || true));
    }

    if (isFailedRequest) {
      return Promise.reject({
        data: {},
        errors: {},
        message: "Our systems request failed abruptly, Please try again"
      });
    }

    if (!response) {
      return Promise.reject({
        data: {},
        errors: {},
        message: "Our systems response returned empty! Please try again"
      });
    }

    return response;
  },
  function (error: AxiosError<ServerResponse<{}>>) {
    const pageReferer = document.referrer || "";

    let errorArray: string[] = [];

    /* @HINT: Normalize using the default response data object to take the shape of "ServerResponse" type */
    const defaultResponseData: ServerResponse<{}> = {
      data: {},
      errors: {},
      message: getMessageFromError(error)
    };
    const errorResponse = error?.response;
    const isRejectedRequest =
      errorResponse?.status === 401 || errorResponse?.status === 403;

    /* @HINT: If the response data from the API call is undefined, then use the default response data */
    const responseData = errorResponse?.data ?? defaultResponseData;

    if (
      isRejectedRequest ||
      responseData?.message?.includes("Invalid or expired token")
    ) {
      handleLogout(pageReferer.includes("/app"));
    }

    if (!isEmpty(responseData.errors)) {
      errorArray = Object.values<string>(responseData.errors);
    }

    return Promise.reject(errorArray.length > 0 ? errorArray : responseData);
  }
);

export async function client<ResponseType, T extends {}>(
  endpoint: string,
  method?: "GET" | "PATCH" | "POST" | "PUT" | "DELETE" | "HEAD",
  { body, headers: customHeaders, params, ...customConfig }: OptionsArgs<T> = {}
) {
  const encrypted_token = storage.get(loginKey);
  let token = "";

  if (encrypted_token) {
    token = decrypt_key(encrypted_token);
  }

  const headers: ApiHeader = {
    "Content-type": customConfig.isFormData
      ? "multipart/form-data"
      : "application/json"
  };

  if (customConfig.isHeader) {
    headers.dpmlekdno = config.DUPLO_KEY;
  }

  if (customConfig.isWalletURL && !customConfig.isHeader) {
    headers.dpmlekdno = config.WALLET_KEY;
  }

  if (token) headers.Authorization = `Bearer ${token}`;

  const options: AxiosRequestConfig<T> = {
    method: method || "GET",
    withCredentials: true,
    ...customConfig,
    headers: {
      ...headers,
      ...customHeaders
    }
  };

  if (body) options.data = body;
  if (params) options.params = params;

  let response: ResponseType;
  const { data } = await axios(`${endpoint}`, options);

  if (data?.data) {
    const { data: resolvedResponse } = data;
    if (
      data.message === "Your payout has been sent for approval" ||
      data.message ===
        "Payout has been successfully sent. You will be notified on the status of the payout"
    ) {
      response = { ...resolvedResponse, __text: data.message as string };
    } else {
      response = resolvedResponse;
    }
  } else {
    response = data;
  }

  return response;
}
