import React from "react";
import {
  StyledPreviewModal,
  ClosePreviewIcon,
  PDFDocumentWrapper
} from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import { fileExtension } from "utils/fileUpload";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface DocumentPreviewModalProps {
  closeSuccessModal: () => void;
  url: string;
  is_from_aws?: boolean;
}

const DocumentPreviewModal: React.FC<DocumentPreviewModalProps> = ({
  closeSuccessModal,
  url,
  is_from_aws
}) => {
  const [numPages, setNumPages] = React.useState<number>();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [renderNavButtons, setRenderNavButtons] =
    React.useState<Boolean>(false);

  const onSuccess = () => {
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  return (
    <>
      {url && (
        <StyledPreviewModal>
          <div className="modal-content">
            <ClosePreviewIcon
              className="close-icon"
              onClick={() => closeSuccessModal()}
            >
              <AiOutlineClose />
            </ClosePreviewIcon>
            <div className="modal-body">
              {fileExtension(url) === "pdf" && is_from_aws ? (
                <>
                  <PDFDocumentWrapper>
                    <Document
                      file={url}
                      onLoadSuccess={({ numPages }: { numPages: number }) => {
                        setNumPages(numPages);
                        !(pageNumber === numPages && pageNumber <= 1)
                          ? setRenderNavButtons(true)
                          : setRenderNavButtons(false);
                        onSuccess;
                      }}
                    >
                      <Page
                        pageNumber={pageNumber}
                        style={{ backgroundColor: "tomato" }}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Document>
                  </PDFDocumentWrapper>
                  {renderNavButtons && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "5px"
                      }}
                    >
                      <button disabled={pageNumber <= 1} onClick={previousPage}>
                        Previous Page
                      </button>
                      {"  "}
                      <button
                        disabled={pageNumber === numPages}
                        onClick={nextPage}
                      >
                        Next Page
                      </button>
                    </div>
                  )}
                </>
              ) : fileExtension(url) === "pdf" ? (
                <iframe
                  src={`https://docs.google.com/viewerng/viewer?url=${url}&embedded=true`}
                  frameBorder="0"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : null}

              {(fileExtension(url) === "jpg" ||
                fileExtension(url) === "jpeg" ||
                fileExtension(url) === "png") && (
                <iframe
                  srcDoc={`<img src="${url}" style="width:100%" />`}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              )}
            </div>
          </div>
        </StyledPreviewModal>
      )}
    </>
  );
};

export default DocumentPreviewModal;
