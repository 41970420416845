import React from "react";
import styled from "styled-components";
import { ReactComponent as Memoji } from "assets/svg/memoji.svg";
import { IoClose } from "react-icons/io5";
import { BiCheck } from "react-icons/bi";

interface ApprovalStep {
  name: string;
  action: string;
  timeAgo: string;
  status?: string;
  comment?: string;
}

const getApprovalStatus = (status: string) => {
  switch (status) {
    case "approved":
      return "#4CAF50";
    case "rejected":
      return "#ed5a5c";
    default:
      return "#E0E0E0";
  }
};

interface ApprovalTrailProps {
  steps: ApprovalStep[];
  isLastVertical: boolean;
}

const TrailContainer = styled.div`
  max-width: 400px;
`;

const TrailStep = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div<{ approved?: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => getApprovalStatus(props.approved || "")};
  margin-right: 16px;
`;

const StepInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-weight: 500;
  color: #001812;
  font-size: 14px;
`;

const Action = styled.div`
  color: #6d6f75;
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: #e0e0e0;
  height: 30px;
  margin-left: 15px;
`;

const CommentHeader = styled.div`
  background: #2db49e1f;
  display: flex;
  align-items: center;
  padding: 0 20px;

  h3 {
    color: #01493d;
    font-weight: 600;
    font-size: 14px;
  }

  p {
    margin-left: 8px;
    color: #6d6f75;
    font-size: 10px;
    font-weight: 400;
  }
`;

const CommentBody = styled.div`
  font-size: 12px;
  color: #5c696e;
  padding: 20px 20px;
  font-weight: 400;

  .content-area {
    white-space: normal;
    overflow-wrap: break-word;
  }
`;

const AuditTrailComponent: React.FC<ApprovalTrailProps> = ({
  steps,
  isLastVertical
}) => {
  return (
    <TrailContainer>
      {steps?.map((step, index) => (
        <React.Fragment key={index}>
          <TrailStep>
            <IconContainer approved={step.status}>
              {step.status === "approved" && (
                <BiCheck size={24} color="white" />
              )}
              {step.status === "rejected" && (
                <IoClose size={24} color="white" />
              )}
              {(step.status === "pending" || !step.status) && <Memoji />}
            </IconContainer>
            <StepInfo>
              <Name>{step.name}</Name>
              <Action>
                {step.action} {step.timeAgo}
              </Action>
            </StepInfo>
          </TrailStep>
          {step.comment && (
            <>
              <VerticalLine />
              <div>
                <CommentHeader>
                  <h3>{step.name}</h3>
                  <p>left a comment {step.timeAgo}</p>
                </CommentHeader>
                <CommentBody>
                  <div className="content-area">{step.comment}</div>
                </CommentBody>
              </div>
            </>
          )}
          {isLastVertical ? (
            <VerticalLine />
          ) : (
            index < steps.length - 1 && <VerticalLine />
          )}
        </React.Fragment>
      ))}
    </TrailContainer>
  );
};

export default AuditTrailComponent;
