import styled from "styled-components";
import { device } from "styles";
import colors from "styles/colors";

type TransactionFilterContainerProps = {
  standard?: boolean;
};

type FlexDirection = {
  isReversed?: boolean;
};
const TransactionTableContainer = styled.div`
  margin: 30px;

  @media ${device.mobile_} {
    margin: 20px;
  }
`;

const FilterButton = styled.button`
  border: 1px solid ${colors.pri};
  color: ${colors.pri};
  background: #fff;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;

  svg {
    margin-right: 5px;
  }
`;

const TransactionFilterContainer = styled.div<TransactionFilterContainerProps>`
  margin: 20px 30px 0 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.mobileL_} {
    flex-direction: column;
  }

  @media ${device.mobile_} {
    margin: 0;
    padding: 10px 20px;
    flex-direction: ${(props) => (props.standard ? "column" : "row")};
    width: ${(props) => (props.standard ? "100%" : "auto")};
    display: ${(props) => (props.standard ? "block" : "flex")};
    box-sizing: border-box;
  }
`;

const ClearFilterContainer = styled.div`
  p {
    color: ${colors.pri} !important;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
  }
`;

const SearchInputContainer = styled.div`
  input {
    border: 1px solid #dcdde6;
    border-radius: 5px;
    height: 40px;
    width: 380px;
    padding: 0px 4px 0px 40px;
    font-family: "EuclidCircularB", sans-serif !important;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;

    &::-webkit-input-placeholder,
    &::placeholder {
      color: rgba(92, 105, 110, 1);
    }
  }

  @media ${device.laptopL_} {
    input {
      width: 250px;
    }
  }

  @media ${device.minitablet_} {
    input {
      width: 280px;
    }
  }

  @media ${device.mobile_} {
    display: block;
    width: 100%;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

const FlexContainer = styled.div<FlexDirection>`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: ${(props) => (props.isReversed ? "row-reverse" : "row")};
`;
const RecipientSyncLink = styled.a`
  color: ${colors.pri};
  text-decoration: underline;
  cursor: pointer;
  @media ${device.laptop} {
    margin-right: 10px;
  }
  @media ${device.mobile_} {
    margin-right: 0;
  }
`;
const WorkflowFlexContainer = styled(FlexContainer)`
  margin: 40px 0px 30px 0;
`;

const FilterListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 30px;
  margin-top: 30px;

  p {
    color: #8a93a7;
    margin-right: 20px;
    margin-top: 1px;

    @media ${device.laptop} {
      margin-top: 12px;
    }
  }

  .pill {
    background: #f3f5f7;
    font-size: 12px;
    width: fit-content !important;
    padding: 7px 15px;
    border-radius: 8px;
    margin-right: 10px;
    color: #b1b7c4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    @media ${device.laptop} {
      margin-bottom: 0;
    }

    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;

const DownloadButtonContainer = styled.div`
  margin-top: 10px;
  @media ${device.laptop} {
    width: 90px;
    margin-top: 0;
  }
  display: flex;
  align-items: center;
  justify-content: end;

  .div-loader {
    margin-right: 30px;
  }
`;

export {
  TransactionTableContainer,
  TransactionFilterContainer,
  SearchInputContainer,
  FlexContainer,
  FilterButton,
  ClearFilterContainer,
  FilterListContainer,
  DownloadButtonContainer,
  WorkflowFlexContainer,
  RecipientSyncLink
};
