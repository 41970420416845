import styled from "styled-components";

const DuploFullPageLoaderContainer = styled.div`
  .loading {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    opacity: 0.1;
    animation: fadeIn 5s;
  }
  .logo-container {
    background: #fff;
    z-index: 999;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 50%;
  }
  .logo {
    max-width: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 26px;
    left: 19px;
  }
`;

export { DuploFullPageLoaderContainer };
