import { useMutation, useQuery } from "react-query";
import * as Expense from "lib/api/expense";
import {
  ExpenseApprovalPayload,
  ExpenseStatsResponse,
  SingleExpenseResponse
} from "pages/Expense/@types";
import { Errors } from "interfaces/errors";
import { ExpenseListFilterInterface } from "interfaces/filter";

function useGetExpenseStats(
  departmentId: string,
  businessId: string,
  initiatorId: string
) {
  const { data, status, refetch } = useQuery(
    ["expense-stats", departmentId, businessId, initiatorId],
    () => Expense.getExpenseStats({}, departmentId, businessId, initiatorId)
  );
  return {
    data: data || ({} as ExpenseStatsResponse),
    status,
    refetch
  };
}

function useGetSingleExpense(id: string, business_id: string) {
  const { data, status, refetch } = useQuery(
    ["single-expense", id],
    () => Expense.getSingleExpense({}, id, business_id),
    {
      enabled: !!id
    }
  );
  return {
    data: data || ({} as SingleExpenseResponse),
    status,
    refetch
  };
}

function useGetAllExpenses(
  department_id: string,
  business_id: string,
  creator_id: string,
  params?: ExpenseListFilterInterface
) {
  const { data, status, refetch } = useQuery(
    ["all-expense" + department_id, business_id, params],
    () =>
      Expense.getAllExpenses({}, department_id, business_id, creator_id, params)
  );
  return {
    data,
    status,
    refetch
  };
}

const useCreateNewExpense = (options = {}) => {
  return useMutation<{}, Errors, FormData>(Expense.createExpense, options);
};

const useApproveExpense = (options = {}) => {
  return useMutation<{}, Errors, ExpenseApprovalPayload>(
    Expense.approveExpense,
    options
  );
};

export {
  useGetExpenseStats,
  useCreateNewExpense,
  useGetAllExpenses,
  useGetSingleExpense,
  useApproveExpense
};
