import React from "react";

type TableProps = {
  children: React.ReactNode;
};

const MobileTable: React.FC<TableProps> = ({ children }) => {
  return (
    <div className="table-wrapper">
      <ul className="mobile_table">{children}</ul>
    </div>
  );
};

export default MobileTable;