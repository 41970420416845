import Modal from "components/Modal";
import React, { ReactNode, useContext } from "react";

export const ModalContext = React.createContext({
  // eslint-disable-next-line
  push(node: ReactNode) {},
  // eslint-disable-next-line
  close() {}
});

const uid = (() => {
  let i = 0;
  return () => `${i++}`;
})();

export const ModalProvider = ModalContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const useModals = () => {
  const actions = useContext(ModalContext);
  return actions;
};

export const ModalProviderContainer: React.FC<Props> = ({ children }) => {
  const [modals, setModals] = React.useState<React.ReactElement[]>([]);
  const actions = React.useMemo(
    () => ({
      push(node: ReactNode) {
        const key = uid();

        const modal = <Modal key={key}>{node}</Modal>;
        setModals((modals) => [...modals, modal]);
      },
      close() {
        setModals([]);
      }
    }),
    []
  );

  return (
    <ModalProvider value={actions}>
      {modals} {children}
    </ModalProvider>
  );
};

export default ModalContext;
