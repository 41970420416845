import styled from "styled-components";
import colors from "styles/colors";

interface AvatarContainerProps {
    sizeTag: "tn" | "sm" | "md" | "lg";
    useBgColor: boolean;
}

type sizeTag = Pick<AvatarContainerProps, "sizeTag">

const selectSizing = ({ sizeTag }: sizeTag) => {
    switch (sizeTag) {
        case "tn":
            return "15px";
        case "sm":
            return "30px";
        case "md":
            return "60px";
        case "lg":
            return "100px";
        default:
            return "auto";
    }
};

const selectFontSizing = ({ sizeTag }: sizeTag) => {
    switch (sizeTag) {
        case "tn":
            return "8px";
        case "sm":
            return "16px";
        case "md":
            return "32px";
        case "lg":
            return "44px";
        default:
            return "auto";
    }
};

const AvatarContainer = styled.span<AvatarContainerProps>`
  display: inline-flex;
  overflow: hidden;

  background-color: ${(props) =>
    props.useBgColor ? colors.pri : "transparent"};
  border-radius: 50%;
  & {
    .image {
      max-width: ${(props) => selectSizing(props)};
    }
    .letter {
      width: ${(props) => selectSizing(props)};
      text-align: center;
      vertical-align: middle;
      position: relative;
      height: ${(props) => selectSizing(props)};
    }
  }

  .letter {
    font-size: ${(props) => selectFontSizing(props)};
    font-weight: 800;
    height: ${(props) => selectSizing(props)};
    line-height: ${(props) => selectSizing(props)};
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
  }

  .image {
    object-fit: cover;
    width: 100%;
    display: inline-block;
    height: auto;
  }
`;

export { AvatarContainer };