import CustomButton from "components/CustomButton";
import { useModals } from "context/modalContext";
import { useGetV2Wallets, useSweepCustomersWallet } from "lib/query/wallet";
import React from "react";
import toast from "react-hot-toast";
import { formatAmount } from "utils/number-formatter";
import Modal from ".";
import { ReactComponent as ConfirmSweepIcon } from "../../assets/svg/confirm-sweep-icon.svg";
import { CancelButton, ModalFooter } from "./styles";
import SuccessfulSweepModal from "./SuccessfulSweepModal copy";

interface SweepModalProps {
  businessId: string;
  close: () => void;
}

const SweepModal: React.FC<SweepModalProps> = ({ close, businessId }) => {
  const { push, close: sweepModalClose } = useModals();

  const { data: v2Wallets } = useGetV2Wallets(businessId);

  const { mutate, isLoading } = useSweepCustomersWallet();

  const handleSweepModal = () => {
    window.location.reload();
    sweepModalClose();
  };

  const handleSweep = async () => {
    const payload = {
      business_id: businessId
    };

    mutate(payload, {
      onSuccess: () => {
        close();
        push(
          <div>
            <SuccessfulSweepModal closeSuccessModal={handleSweepModal} />
          </div>
        );
      },
      onError: (err) => {
        if (!("message" in err)) {
          if (err.length > 0) {
            err.forEach((err) => toast.error(err as string));
            return;
          }
        } else {
          if (typeof err.message !== "string") {
            return;
          }
          close();
          toast.error(`${err.message}`);
        }
      }
    });
  };

  return (
    <Modal
      title="Confirm Sweep Action"
      footerInlineStyle={{ textAlign: "center", marginTop: "80px" }}
      submitButtonText={"Transfer"}
      submitButtonHeight={50}
      isWorking={isLoading}
      proceed={() => handleSweep()}
      close={close}
    >
      <div className="modal-body" style={{ textAlign: "center" }}>
        <ConfirmSweepIcon />
      </div>

      <div
        className="modal-body"
        style={{ textAlign: "center", marginBottom: "50px" }}
      >
        <p>
          You are about to transfer{" "}
          <strong>
            ₦
            {formatAmount(v2Wallets?.customer_wallets?.total_available_balance)}
          </strong>{" "}
          from <strong>All wallets </strong> to your business account.
        </p>
      </div>

      <ModalFooter>
        <CancelButton onClick={close}>Cancel</CancelButton>
        <CustomButton
          type="submit"
          label="Transfer"
          onClick={handleSweep}
          height={40}
          width={150}
        />
      </ModalFooter>
    </Modal>
  );
};

export default SweepModal;
