import styled from "styled-components";

interface ButtonColorProps {
  mode: "basic" | "standard" | "skeletal" | "setter";
  bgColor: string;
  color: string;
  width: number;
  height: number;
}

const getCSSBorder = (props: ButtonColorProps) => {
  return props.mode === "setter" ? "1px solid #c9c9c9" : `1px solid ${props.bgColor}`;
};

const CustomButtomContainer = styled.button<ButtonColorProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: 6px;
  background: ${(props) => props.mode === "basic" ? props.bgColor : props.color};
  color: ${(props) => props.mode === "basic" ? props.color : props.bgColor};
  padding: ${(props) => props.mode === "skeletal" ? "0" : "0 20px"};
  display: ${(props) => props.mode === "skeletal" ? "block" : ""};
  margin: ${(props) => props.mode === "skeletal" ? "0" : "0 0 0 10px"};
  outline: none;
  white-space: nowrap;
  border: ${(props) => props.mode === "basic" || props.mode === "skeletal" ? "none" : getCSSBorder(props)};
  cursor: pointer;
  font-family: 'EuclidCircularB', sans-serif !important;

  &:disabled,
  &[disabled] {
    opacity: 0.6;
  }
`;

export { CustomButtomContainer };
