import React from "react";
import Avatar from "components/Avatar";
import { ReactComponent as ArrowDownIcon } from "assets/svg/arrow-down.svg";
import { DropdownContainer } from "./styles";

import { Link } from "react-router-dom";
import { decrypt_user0bj, handleLogout } from "utils/auth-util";
import storage from "utils/storage";
import {
  BusinessInterface,
  ITeamMemberResponseObject,
  MerchantInterface
} from "interfaces/users";
import { defaultBusinessKey, memberKey, userKey } from "utils/constants";

const PersonaDropdown: React.FC<{ link: string }> = ({ link }) => {
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));
  const memberInfo: ITeamMemberResponseObject =
    decrypt_user0bj<ITeamMemberResponseObject>(storage.get(memberKey));

  const isMemberUser = !(
    memberInfo &&
    Object.keys(memberInfo).length === 0 &&
    Object.getPrototypeOf(memberInfo) === Object.prototype
  );

  const merchantInfo: MerchantInterface = decrypt_user0bj<MerchantInterface>(
    storage.get(userKey)
  );

  const newlyUpdatedLogoUrl = storage.get(
    `user_logo_updated_url:${defaultBusinessInfo.id}`
  );

  const isProfilePictureAvailable = () =>
    isMemberUser ? memberInfo.profile_picture : merchantInfo.profile_picture;

  return (
    <DropdownContainer>
      <section className="dropdownChildBox">
        <p className="copped">
          {isProfilePictureAvailable() ? (
            <div className="profile">
              <img
                className="logo"
                src={newlyUpdatedLogoUrl || isProfilePictureAvailable()}
                alt="logo"
              />{" "}
              <span className="user-name">
                {isMemberUser ? memberInfo.first_name : merchantInfo.first_name}
              </span>
              <span className="padded-icon">
                <ArrowDownIcon />
              </span>
            </div>
          ) : (
            <>
              <span className="avatar">
                <Avatar />
              </span>
              <span className="padded">
                <ArrowDownIcon />
              </span>
            </>
          )}
        </p>
      </section>
      <section className="dropdownWrapper">
        <menu className="dropdownMenu">
          <li>
            <Link to={link}>My Profile</Link>
          </li>
          <li className="logout" onClick={() => handleLogout()}>
            Logout
          </li>
        </menu>
      </section>
    </DropdownContainer>
  );
};

export default PersonaDropdown;
