import React from "react";

type TableProps = {
  children: React.ReactNode;
};

const Table: React.FC<TableProps> = ({ children }) => {
  return (
    <div id="table-wrapper" style={{ maxWidth: "100%", overflowX: "auto", overflowY: "visible", position: "relative", zIndex: 0, minHeight: "1px" }}>
      <table className="table">{children}</table>
      <span style={{ display: "block", margin: "70px 0"}}></span>
    </div>
  );
};

export default Table;
