import styled from "styled-components";
import colors from "styles/colors";
import BgSideBar from "assets/png/auth-illustration.png";
import { device } from "styles";

interface MenuItemProps {
  highlight?: boolean;
}

interface SubMenuProps {
  isActive: boolean;
}

interface PageIdentifierContainerProps {
  topIndent?: boolean;
  topInsetType: "basic" | "extra";
  complianceSection?: boolean;
  isApprover?: boolean;
}

interface BannerBlankNavProps {
  visibility?: boolean;
}

const AppContainer = styled.div`
  background: #fff;
  height: 100vh;
  display: flex;
  @media ${device.mobileS} {
    display: flex;
  }
  @media ${device.desktopL} {
    width: 100%;
    display: block;
  }
`;

const MenuOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const AppMenuContainer = styled.div`
  width: 250px;
  background-color: #103129;
  background-image: url(${BgSideBar});
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #aaaaaa;
  }
  @media ${device.mobileS} {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    padding-left: 30px;
    height: 100%;
    top: 0;
    left: -100%;
    transition: all 0.3s ease-in-out;
    .mobile-menu-show & {
      padding-top: 20px;
      width: 70%;
      left: 0;
      padding-right: 10px;
    }
  }
  @media ${device.tablet} {
    width: 270px;
    padding-left: 15px;
    padding-right: 10px;
    position: relative;
    top: 0;
    left: 0;
  }
  @media ${device.laptop} {
    width: 290px;
    box-sizing: border-box;
    padding: 10px 15px;
  }
`;

const AppBodyContainer = styled.div`
  padding: 25px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  @media ${device.mobileS} {
    padding: 5px 5px;
  }
  @media ${device.tablet} {
    padding: 0 25px 0 25px;
  }
  @media ${device.mobile_} {
    padding: 0;
  }
`;

const BannerBlankNav = styled.div<BannerBlankNavProps>`
  background: #fff;
  margin-top: 0px;
  position: sticky;
  top: 0px;
  box-sizing: border-box;
  height: 43px;
  z-index: 99;
  border-radius: 0;
  margin-bottom: 15px;
  width: 100%;
  display: ${(props) => (props.visibility ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;

const AppBodyElements = styled.div`
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const topInsetMap = {
  basic: "43px",
  extra: "62px"
};

const PageIdentifierContainer = styled.div<PageIdentifierContainerProps>`
  position: sticky;
  border-radius: ${(props) => (props.isApprover ? "5px" : "10px 10px 0px 0px")};
  margin-top: ${(props) => (props.isApprover ? "20px" : "10px")};
  top: ${(props) =>
    props.topIndent || props.isApprover
      ? "0px"
      : topInsetMap[props.topInsetType]};
  background-color: ${(props) =>
    props.complianceSection ? "#fff" : "#f0f0f0"};
  z-index: 3;
  height: ${(props) =>
    props.complianceSection ? "70px" : props.isApprover ? "60px" : "50px"};
  display: ${(props) => (props.complianceSection ? "block" : "flex")};
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .title {
    color: #abb7bf;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 3px;
  }
  @media ${device.mobile_} {
    padding: 0 10px;
    .title {
      width: auto;
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 9px;
  p {
    color: #5c696e;
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
`;

const SubMenuContainer = styled.div`
  color: #5c696e;
  margin: 13px 0;
  padding: 0 0 0 10px;
  cursor: pointer;
  text-decoration: none;
`;

const SubMenuHeader = styled.div<SubMenuProps>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isActive ? colors.pri : "")};
  font-weight: ${(props) => (props.isActive ? "bold" : "400")};
`;

const SubMenuElement = styled.div`
  a {
    margin: 16px 0 0 30px !important;
    padding: 0;
  }
`;

const MenuItemContainer = styled.div<MenuItemProps>`
  margin-top: 10px;
  margin-left: -15px;
  border-left: 10px solid
    ${(props) => (props.highlight ? "white" : "transparent")};
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .menu-dropdown {
    color: rgba(255, 255, 255, 0.7) !important;
    font-weight: 400;
    font-size: 11px !important;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    p {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 11px !important;
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
  .text-hr {
    display: block;
    background-color: transparent;
    position: relative;
    text-align: none;
    font-family: "EuclidCircularB", sans-serif !important;
    width: 100%;
    overflow: hidden;
    margin: 4px 0;
    color: rgba(255, 255, 255, 0.7);
    padding: 0;
    &__text {
      background-color: #103129;
      padding-right: 5px;
      font-size: 10px;
      text-transform: uppercase;
      margin: 0;
      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
    &::after {
      display: block;
      background: rgba(255, 255, 255, 0.4);
      content: ".";
      position: absolute;
      left: 0;
      z-index: -1;
      top: 50%;
      width: 100%;
      color: transparent;
      height: 1px;
      margin-left: -15px;
    }
    &--right {
      text-align: right;
      .text-hr__text {
        background-color: #103129;
        padding: 0 0 0 5px;
        font-size: 10px;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
  a {
    color: ${(props) =>
      props.highlight
        ? "rgba(255, 255, 255, 0.9)"
        : "rgba(255, 255, 255, 0.4)"};
    margin: 10px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  span {
    margin-left: 10px;
  }
  .active {
    color: ${colors.pri};
    font-weight: bold;
  }
`;

const LogoutContainer = styled.div`
  color: #ed5b5c;
  display: flex;
  align-items: center;
  padding: 30px 0 13px 0px;
  cursor: pointer;
`;

const MenuItemElement = styled.div`
  color: #5c696e;
  padding: 13px 0 13px 10px;
  cursor: pointer;
`;

const DismissibleAlert = styled.div`
  padding: 20px;
  background-color: #2DB49E;
  color: white;
  margin-bottom: 15px;
  .closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
`;

export {
  AppContainer,
  AppMenuContainer,
  AppBodyContainer,
  AppBodyElements,
  PageIdentifierContainer,
  MenuItemContainer,
  MenuItemElement,
  SubMenuContainer,
  LogoutContainer,
  ToggleContainer,
  SubMenuHeader,
  SubMenuElement,
  BannerBlankNav,
  MenuOuterContainer,
  DismissibleAlert
};
