import RouteLayout from "layout";
import { useHistory } from "react-router";
import * as rudderanalytics from "rudder-sdk-js";
import config from "config";
import React from "react";

import { TextFilterAlgorithmsProvider } from "lib/hooks";
import { pageChangeStatusForHotJar } from "utils/hotjar";

const AppRouter = () => {
  const history = useHistory();

  React.useEffect(() => {
    /* @HINT: */
    const unblock = history.block(function onBeforeRouteChange() {
      /* @HINT: Fetch signal for unsave items on the Dashboard created by the user (e.g. Payout Setting, Approver Policies) */
      const unsavedItemsStatus =
        window.sessionStorage.getItem("merchantUnsavedItems") || "saved";
      /* @HINT: If the there are items to be "saved", then prompt the user with a dialog box message */
      if (unsavedItemsStatus !== "saved") {
        const canDiscardUnsavedItems = window.confirm(
          "You have unsaved items on this page. Would you like to discard them ?"
        );
        /* @HINT: Depending on the response of the user to the prompt via the dialog box update the signal to "saved" or "pending" */
        if (canDiscardUnsavedItems) {
          window.sessionStorage.setItem("merchantUnsavedItems", "saved");
          unblock();
          /* @HINT: There are parts of this React app that listen for this custom event ("discardunsaveditems") and acts accordingly */
          /* @NOTE: Event "discardunsaveditems" is fired here so that items yet to saved are discarded and not saved */
          window.dispatchEvent(new Event("discardunsaveditems"));
        } else {
          /* @HINT: Store signal for unsaved items on the Dashboard as pending*/
          window.sessionStorage.setItem("merchantUnsavedItems", "pending");
          return false;
        }
      }
    });

    /* @HINT: */
    const unlisten = history.listen(function onRouteChange(location, action) {
      /* @HINT: The last loaded page URL is stored in session storage and retrieved upon the next page route change */
      const formerURL = window.sessionStorage.getItem("former_url") || "/";
      /* @HINT: The document <title> of the page is programatically created from the page URL pathname */
      const title = location.pathname
        .replace(/^\//, "")
        .split("/")
        .slice(1)
        .reduce((buffer, suffix) => {
          const capitalizedSuffix =
            suffix.charAt(0).toUpperCase() + suffix.substring(1);
          return (
            buffer +
            (buffer !== "" ? " " + capitalizedSuffix : capitalizedSuffix)
          );
        }, "");
      /* @HINT: The document <title> assigned with an additional prefix */
      document.title =
        "Duplo - " +
        (title.includes("-")
          ? title
              .split("-")
              .map((word) => {
                return word?.[0]?.toUpperCase() + word?.substring(1);
              })
              .join(" ")
          : title);

      /* @HINT: Update the last loaded URL so it is consistent with the next page route change */
      window.sessionStorage.setItem("former_url", location.pathname || "/");

      /* @HINT: Programtically take the user to the previous page if the 
          user did navigate from the root path ( "/" ) and user is being 
          referred or navigating */
      if (location.pathname.includes("/app/") && formerURL === "/") {
        const dummyURL = "https://x.yx/";
        const referrerURL = new URL(document.referrer || dummyURL);
        if (
          referrerURL.host.includes(".tryduplo.com") &&
          referrerURL.pathname.includes("/app/")
        ) {
          history.goBack();
        }
      }

      /* @HINT: `config.PROD` is used to emphasize when this app is on staging or production */
      if (config.PROD) {
        /* @HINT: Don't allow a page which belongs to the root path ( "/" ) to be tracked by Rudderstack */
        if (title.trim() === "Duplo") {
          return;
        }

        /* @HINT: */
        const enableRudderstackAnalyticsTrackingOnProdOnly =
          config.API_ENDPOINT?.search(/(dev|staging)\./) === -1;

        /* @HINT: Rudderstack SPA page navigation tracking */
        /* @NOTE: Rudderstack is the platform Duplo uses to gather and aggregate analytics 
            about real-time user actions/behavior on this app */

        rudderanalytics.page(
          title,
          {
            path: location.pathname,
            url: window.location.href,
            title: document.title,
            search: window.location.search,
            referrer: document.referrer,
            navigation_mode: action
          },
          {
            integrations: {
              All: enableRudderstackAnalyticsTrackingOnProdOnly,
              Hotjar: false,
              Hubspot: enableRudderstackAnalyticsTrackingOnProdOnly
            }
          },
          () => {
            /* @HINT: Track page route change event for HotJar (Analytics) */
            pageChangeStatusForHotJar(location.pathname);
          }
        );
      }
    });

    return () => {
      /* @HINT: If there is a listener set for the "beforeunload" event */
      if (typeof unblock === "function") {
        /* @HINT: Then, at this point, assume all unsaved items are saved  
            and then remove the listener for "beforeunload" event */
        sessionStorage.setItem("merchantUnsavedItems", "saved");
        unblock();
      }
      unlisten();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [history]);

  return (
    /* @HINT: Setup the provider for the `useTextFilteredList()` hook for filtering all table lists */
    /* @NOTE: */

    /* @CHECK: Tests[file path]: `tests/unit-tests/hooks/test_useTextFilteredList.spec.tsx` */
    <TextFilterAlgorithmsProvider extendAlgos={{}}>
      <RouteLayout history={history} />
    </TextFilterAlgorithmsProvider>
  );
};

export default AppRouter;
