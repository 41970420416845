import {
  FilterInterface,
  ComplexTransactionsFilterInterface,
  CompoundTransactionsFilterInterface
} from "interfaces/filter";
import {
  AddPreferencePayload,
  AddPreferenceResponse,
  Bank,
  BankAccountListInterface,
  BankNameVerificationPayload,
  BankNameVerificationResponse,
  BussinessWalletResponse,
  BankAccountPayload,
  SendMoneyPayload,
  TriggerOTPPayload,
  VerifyOTPPayload,
  VirtualAccountPayload,
  VirtualAccountResponse,
  WalletTransactionResponse,
  WalletTransferPayload,
  WalletWithdrawalPayload,
  AddCustomerResponse,
  AddCustomerPayload,
  NotificationsPayload,
  SweepCustomersWalletPayload,
  NewWalletPayloadInterface,
  FetchCustomersResponse,
  VirtualAccountsProviderPayload,
  VirtualAccountsResponse,
  SettlementBankAccountPayload,
  BankBody,
  TransferFundsPayload,
  IP2pPayload
} from "interfaces/wallet";
import { useQueryConfigLite } from "utils/constants";
import * as wallet from "lib/api/wallet";
import { useMutation, useQuery } from "react-query";
import { Errors } from "interfaces/errors";
import { PaginationApiResponse } from "interfaces/pagination";
import {
  BulkPayoutJSONModel,
  ReinitiatePayoutPayload
} from "interfaces/payout";
import { DashboardSummaryInterface } from "interfaces/users";

function useGetMasterWallet(businessId?: string) {
  const { data, status, refetch } = useQuery(
    ["master-wallet", businessId],
    () => wallet.getMasterWallet(businessId || ""),
    {
      ...useQueryConfigLite,
      enabled: !!businessId // Query will only run if businessId is not falsy
    }
  );
  return { data, status, refetch };
}

function useGetWalletTransaction(
  walletRef: string,
  businessId: string,
  params?: ComplexTransactionsFilterInterface
) {
  const { data, status } = useQuery(
    ["master-wallet-txn", walletRef, businessId, params],
    () => wallet.getWalletTransaction(walletRef, businessId, params),
    useQueryConfigLite
  );
  return {
    data: data || {
      transactions: [] as WalletTransactionResponse[],
      meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
        perPage: 20,
        nextPage: false,
        previousPage: false,
        total: 0
      } as PaginationApiResponse
    },
    status
  };
}

function useGetBusinessTransaction(
  businessId: string,
  params: CompoundTransactionsFilterInterface & {
    start_date?: string;
    end_date?: string;
  }
) {
  if (
    !params?.amount &&
    !params?.category &&
    !params?.channel &&
    !params?.status &&
    !params?.type
  ) {
    params = { ...params, category: "fee,agent,bank_transfer,invoice" };
  }

  const types = params.type?.includes(",") ? undefined : params?.type;
  const statuses = params.status?.includes(",") ? undefined : params?.status;
  const amount = params.amount ? params.amount : undefined;

  const { data, status } = useQuery(
    ["business-txn", businessId, params],
    () =>
      wallet.getBusinessTransaction({}, businessId, {
        ...params,
        type: types,
        status: statuses,
        amount
      }),
    useQueryConfigLite
  );
  return {
    data: data || {
      transactions: [] as WalletTransactionResponse[],
      meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
        perPage: 20,
        nextPage: false,
        previousPage: false,
        total: 0
      } as PaginationApiResponse
    },
    status
  };
}

function useGetListOfBanks(businessId: string) {
  const { data, status } = useQuery(
    ["list-of-banks", businessId],
    () => wallet.getListOfBanks(businessId),
    useQueryConfigLite
  );
  const listOfBanks: Bank[] = [];
  if (data) {
    data.forEach((bank) => {
      listOfBanks.push({ name: bank.name, value: bank.code });
    });
  }
  return { listOfBanks, status };
}

function useGetBusinessWallets(businessId: string, params?: FilterInterface) {
  const { data, status } = useQuery(
    ["business-wallet", businessId],
    () => wallet.getBusinessWallets(businessId, params),
    useQueryConfigLite
  );
  return {
    data: data || {
      data: [] as BussinessWalletResponse[],
      meta: {
        nextPage: false,
        previousPage: false,
        total: 0,
        page: 1,
        pageCount: 1,
        limit: 20,
        perPage: 20
      } as PaginationApiResponse
    },
    status
  };
}

function useGetBusinessBilling(businessId: string) {
  const { data, status } = useQuery(
    ["business-billing", businessId],
    () => wallet.getBusinessBilling(businessId),
    useQueryConfigLite
  );
  return { data, status };
}

function useGetTransferFee(businessId: string) {
  const { data, status } = useQuery(
    ["transfer-fee", businessId],
    () => wallet.getTransferFee(businessId),
    useQueryConfigLite
  );
  return { data, status };
}

function useGetWalletDetails(walletRef: string, businessId: string) {
  const { data, status } = useQuery(
    ["business-wallet", walletRef, businessId],
    () => wallet.getWalletDetails(walletRef, businessId),
    useQueryConfigLite
  );
  return { data, status };
}

function useGetWalletSummary(businessId: string) {
  const { data, status } = useQuery(
    ["wallet-summary-data", businessId],
    () => wallet.getWalletSummary(businessId),
    useQueryConfigLite
  );
  return {
    data:
      data ||
      ({
        total_credits: {
          this_month: 0,
          this_week: 0,
          today: 0
        },
        total_debits: {
          this_month: 0,
          this_week: 0,
          today: 0
        },
        total_transactions: {
          last: 0,
          this_month: 0,
          this_week: 0
        },
        total_wallet_count: 0,
        masterWalletBalance: {
          available_balance: 0,
          ledger_balance: 0
        },
        message: "",
        totalNumberOfWallet: 0,
        totalNumberOfCustomer: 0,
        totalNumberOfTransactions: 0,
        paymentVolume: [],
        debits: [],
        credits: [],
        fees: [],
        mostActiveWallets: [],
        topWallets: [],
        transactionForLastThirtyDays: []
      } as DashboardSummaryInterface),
    status
  };
}

function useGetVirtualAccounts(options = {}) {
  return useMutation<VirtualAccountResponse, Errors, VirtualAccountPayload>(
    wallet.getVirtualAccounts,
    options
  );
}

function useMerchantWithdrawal(options = {}) {
  return useMutation<
    { data: { [key: string]: unknown } },
    Errors,
    WalletWithdrawalPayload
  >(wallet.merchantWithdrawal, options);
}
function useTransferFundsToAccount(options = {}) {
  return useMutation<
    { data: { [key: string]: unknown } },
    Errors,
    TransferFundsPayload
  >(wallet.transferFundsToAccount, options);
}

function useSendMoney(options = {}) {
  return useMutation<
    { data: { [key: string]: string } },
    Errors,
    SendMoneyPayload
  >(wallet.sendMoney, options);
}
function useReinitiatePayout(options = {}) {
  return useMutation<
    { data: { [key: string]: string } },
    Errors,
    ReinitiatePayoutPayload
  >(wallet.reinitiatePayout, options);
}

function useBulkPayoutJson(options = {}) {
  return useMutation<
    { data: { [key: string]: unknown } },
    Errors,
    BulkPayoutJSONModel
  >(wallet.bulkPayoutJson, options);
}

function useSendMoneySchedule(options = {}) {
  return useMutation<
    { data: { [key: string]: string } },
    Errors,
    SendMoneyPayload
  >(wallet.sendMoneySchedule, options);
}

function useAddBusinessPreference<
  T extends AddPreferencePayload | NotificationsPayload
>(options = {}) {
  return useMutation<{ data: AddPreferenceResponse }, Errors, T>(
    wallet.addBusinessPreference,
    options
  );
}

function useAddNotificationPreference<
  T extends AddPreferencePayload | NotificationsPayload
>(options = {}) {
  return useMutation<{ data: AddPreferenceResponse }, Errors, T>(
    wallet.addBusinessPreference,
    options
  );
}

function useUpdateBusinessPreference() {
  return useMutation<
    { data: AddPreferenceResponse },
    Errors,
    NotificationsPayload
  >(wallet.updateBusinessPreference);
}

function useUpdateNotification(options = {}) {
  return useMutation<
    { data: AddPreferenceResponse },
    Errors,
    NotificationsPayload
  >(wallet.updateBusinessPreference, options);
}

function useAddVendorPreference<
  T extends AddPreferencePayload | NotificationsPayload
>(options = {}) {
  return useMutation<{ data: AddPreferenceResponse }, Errors, T>(
    wallet.addBusinessPreference,
    options
  );
}
function useUpdateVendorPreference() {
  return useMutation<
    { data: AddPreferenceResponse },
    Errors,
    NotificationsPayload
  >(wallet.updateBusinessPreference);
}

function useToggleBusinessPortalActivation(options = {}) {
  return useMutation(wallet.toggleBusinessPortalActivation, options);
}

function useWalletTransfer(options = {}) {
  return useMutation<
    { data: { [key: string]: unknown } },
    Errors,
    WalletTransferPayload
  >(wallet.walletTransfer, options);
}

function useSweepCustomersWallet(options = {}) {
  return useMutation<
    { data: { [key: string]: unknown } },
    Errors,
    SweepCustomersWalletPayload
  >(wallet.sweepCustomersWallet, options);
}

function useResolveBankAccountName(options = {}) {
  return useMutation<
    BankNameVerificationResponse,
    Errors,
    BankNameVerificationPayload
  >(wallet.resolveBankName, options);
}

function useCreateNewWallet(options = {}) {
  return useMutation<
    { data: BussinessWalletResponse },
    Errors,
    NewWalletPayloadInterface
  >(wallet.createNewWallet, options);
}

function useGetVirtualAccountProviders(options = {}) {
  return useMutation<
    VirtualAccountsResponse,
    Errors,
    VirtualAccountsProviderPayload
  >(wallet.getVirtualAccountProviders, options);
}

function useGetDefaultBusiness(businessId: string) {
  const { data, status, refetch } = useQuery(
    ["default-business", businessId],
    () => wallet.getDefaultBusiness(businessId),
    useQueryConfigLite
  );
  return { data, status, refetch };
}

function useDownloadTransaction(options = {}) {
  return useMutation<string, Errors, { businessId: string; walletRef: string }>(
    wallet.downloadTransaction,
    options
  );
}

function useCreateBankAccount(options = {}) {
  return useMutation<
    { data: BankAccountListInterface },
    Errors,
    BankAccountPayload
  >(wallet.createBankAccount, options);
}

function useEditBankAccount(options = {}) {
  return useMutation<{ data: BankAccountListInterface }, Errors, BankBody>(
    wallet.editBankAccount,
    options
  );
}

function useAddSettlementAccount(options = {}) {
  return useMutation<{}, Errors, SettlementBankAccountPayload>(
    wallet.addSettlementAccount,
    options
  );
}

function useDownloadAllTransaction(options = {}) {
  return useMutation<
    string & { message: string },
    Errors,
    {
      businessId: string;
      email: string;
      filters: CompoundTransactionsFilterInterface;
      showBalance?: boolean;
      walletRef?: string;
    }
  >(wallet.downloadAllTransaction, options);
}

function useTriggerOtp(options = {}) {
  return useMutation<TriggerOTPPayload, Errors, TriggerOTPPayload, unknown>(
    wallet.triggerOTP,
    options
  );
}

function useVerifyOtp(options = {}) {
  return useMutation<VerifyOTPPayload, Errors, VerifyOTPPayload, unknown>(
    wallet.verifyOTP,
    options
  );
}

function useGetBusinessDetails(businessId: string) {
  const { data, status } = useQuery(["business-details", businessId], () =>
    wallet.getBusinessDetails(businessId)
  );
  return { data, status };
}

function useGetBankAccounts(businessId: string) {
  const { data, status } = useQuery(["bank-accounts", businessId], () =>
    wallet.getBankAccounts(businessId)
  );
  return {
    data: data || { accounts: [] as BankAccountListInterface[] },
    status
  };
}

function useGetV2Wallets(businessId: string) {
  const { data, status } = useQuery(["wallets-V2", businessId], () =>
    wallet.getV2Wallets(businessId)
  );
  return {
    data: data || {
      customer_wallets: {
        total_available_balance: 0
      },
      total_available_balance: 0,
      total_customer_wallets: 0
    },
    status
  };
}

function useGetCustomers(businessId: string) {
  const { data, status } = useQuery(
    ["fetch-customers", businessId],
    () => wallet.getCustomers(businessId),
    useQueryConfigLite
  );
  return {
    data: data || {
      data: [] as FetchCustomersResponse[],
      meta: {} as PaginationApiResponse
    },
    status
  };
}

function useAddCustomer(options = {}) {
  return useMutation<AddCustomerResponse, Errors, AddCustomerPayload>(
    wallet.addCustomer,
    options
  );
}

function useUpdateBankAccount(options = {}) {
  return useMutation<
    { data: BankAccountListInterface },
    Errors,
    BankAccountPayload
  >(wallet.updateBankAccount, options);
}

function useP2PTransfer(options = {}) {
  return useMutation<any, Errors, IP2pPayload>(wallet.p2pTransfer, options);
}

export {
  useGetMasterWallet,
  useGetWalletTransaction,
  useGetVirtualAccounts,
  useWalletTransfer,
  useGetBusinessTransaction,
  useGetBusinessWallets,
  useGetWalletDetails,
  useMerchantWithdrawal,
  useGetListOfBanks,
  useResolveBankAccountName,
  useGetTransferFee,
  useCreateNewWallet,
  useGetVirtualAccountProviders,
  useGetDefaultBusiness,
  useDownloadTransaction,
  useGetBusinessBilling,
  useTriggerOtp,
  useVerifyOtp,
  useDownloadAllTransaction,
  useAddBusinessPreference,
  useUpdateBusinessPreference,
  useToggleBusinessPortalActivation,
  useSendMoney,
  useGetWalletSummary,
  useGetBusinessDetails,
  useUpdateNotification,
  useAddNotificationPreference,
  useSweepCustomersWallet,
  useGetV2Wallets,
  useGetCustomers,
  useAddCustomer,
  useCreateBankAccount,
  useAddSettlementAccount,
  useGetBankAccounts,
  useUpdateBankAccount,
  useEditBankAccount,
  useSendMoneySchedule,
  useBulkPayoutJson,
  useTransferFundsToAccount,
  useAddVendorPreference,
  useUpdateVendorPreference,
  useReinitiatePayout,
  useP2PTransfer
};
