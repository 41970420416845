import React from "react";

export type Permission = string;

type PermissionContextType = {
  isAllowedTo: (page: string, permission: Permission) => Promise<boolean>;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => Promise.resolve(false)
};
const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);
export const PermissionProvider = PermissionContext.Provider;

type Props = {
  fetchPermission: (p: string, r: Permission) => Promise<boolean>;
};

type PermissionCache = {
  [key: string]: boolean;
};

export const PermissionProviderContainer: React.FC<Props> = ({
  fetchPermission,
  children
}) => {
  const cache: PermissionCache = {};

  const isAllowedTo = async (
    page: string,
    permission: Permission
  ): Promise<boolean> => {
    if (Object.keys(cache).includes(permission)) {
      return cache[permission];
    }
    const isAllowed = await fetchPermission(page, permission);
    cache[permission] = isAllowed;
    return isAllowed;
  };

  return (
    <PermissionProvider
      value={{
        isAllowedTo
      }}
    >
      {children}
    </PermissionProvider>
  );
};

export default PermissionContext;
