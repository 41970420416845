import React, { ChangeEvent, MouseEvent } from "react";
import { ReactComponent as ExitIcon } from "assets/svg/exit.svg";
import { ReactComponent as DropdownCheck } from "assets/svg/dropdown-check.svg";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { isEmpty } from "utils/object-util";
import { truncateString } from "utils/text-util";
import DropdownContent from "./DropdownContent";
import colors from "styles/colors";

export interface DropdownOptionModel {
  value: string;
  name: string;
  icon?: React.ReactNode;
}
export interface ICreateAction {
  action: () => void;
  text: string;
  icon?: React.ReactNode;
}

export interface DropdownComponentProps {
  dropdownOption?: DropdownOptionModel[];
  placeHolderText?: string;
  onChange: (s: string) => void;
  selectedOption?: string;
  label?: string | React.ReactElement;
  disabled?: boolean;
  multiple?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  truncateCharLength?: number;
  labelBold?: boolean;
  className?: string;
  showBorder?: boolean;
  hasError?: boolean;
  isRequired?: boolean;
  createAction?: ICreateAction;
}
const DropdownComponent: React.FC<DropdownComponentProps> = ({
  dropdownOption = [],
  placeHolderText = "Select an option",
  onChange,
  selectedOption = "",
  multiple = false,
  truncateCharLength = 35,
  label = "",
  labelBold = false,
  disabled = false,
  searchPlaceholder = "Search...",
  isSearchable = dropdownOption.length > 5,
  className,
  showBorder,
  isRequired,
  hasError,
  createAction
}) => {
  const [multipleOptions, setMultipleOptions] = React.useState<
    DropdownOptionModel[]
  >([]);
  const [optionsCleared, setOptionsCleared] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const generateNameFromValue = (
    array: { value: string; name: string; icon?: React.ReactNode }[],
    value: string
  ) => {
    const result = array.filter((e) => value === e.value);
    return result;
  };
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onRemoveMultipleOption = (index: number) => {
    const options = multipleOptions.slice(0);
    options.splice(index, 1);

    if (options.length === 0) {
      setOptionsCleared(true);
    }
    setMultipleOptions(options);
  };

  const filterOption = (options: DropdownOptionModel[]) => {
    const displayOptions = options
      .map((item) => {
        if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        return {} as DropdownOptionModel;
      })
      .filter((item) => !isEmpty(item));
    return displayOptions;
  };

  if (isSearchable && !multiple) {
    dropdownOption.sort((previousOption, nextOption) => {
      return previousOption.name.localeCompare(nextOption.name);
    });
  }

  React.useEffect(() => {
    if (selectedOption) {
      setOpen(false);
    }
    if (selectedOption === "--") {
      setOpen(false);
    }
  }, [selectedOption]);

  React.useEffect(() => {
    if (multiple) {
      if (multipleOptions.length > 0) {
        onChange(
          multipleOptions.reduce((joined, option, index) => {
            const serialized = `${option.value}:${option.name}`;
            joined += `${index === 0 ? "" : ","}${serialized}`;
            return joined;
          }, "")
        );
      } else {
        if (selectedOption !== "") {
          if (optionsCleared) {
            onChange("");
            return;
          }

          /* @HINT: For multiple default options */

          if (selectedOption.indexOf(",") !== -1) {
            const defaultOptions = selectedOption
              .split(",")
              .map((item: string) => item.trim());

            const selectedDropdownOptions = dropdownOption.filter((option) => {
              return defaultOptions.some(
                (defaultOption: string) =>
                  defaultOption.toLowerCase() === option.name.toLowerCase()
              );
            });

            if (selectedDropdownOptions.length > 0) {
              setMultipleOptions(selectedDropdownOptions);
            }
          } else {
            /* @HINT: For single default option */
            const selectedDropdownOptions = dropdownOption.filter((option) => {
              return (
                (selectedOption || "").toString().toLowerCase() ===
                option.name.toLowerCase()
              );
            });
            if (selectedDropdownOptions.length > 0) {
              setMultipleOptions(selectedDropdownOptions);
            }
          }
        } else {
          onChange(selectedOption || "");
        }
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [multipleOptions.length, selectedOption, multiple, dropdownOption.length]);

  return (
    <div
      className="custom-dropdown"
      style={multiple || showBorder ? { marginBottom: "0px" } : undefined}
    >
      <div
        onClick={() => {
          !disabled && setOpen(true);
        }}
        className="custom-select-wrapper"
      >
        {label && (
          <p
            style={{
              color: "#192850",
              fontSize: "14px",
              fontWeight: labelBold ? 400 : 300,
              margin: "0",
              marginBottom: "3.5px"
            }}
          >
            {label}{" "}
            {isRequired && <span style={{ color: colors.error }}>*</span>}{" "}
          </p>
        )}
        <div className="custom-select__div">
          <div
            className={"custom-select__trigger" + " " + className}
            style={{
              backgroundColor: disabled ? "#EFEFEF4D" : "",
              border: hasError
                ? `1px solid ${colors.error}`
                : disabled
                ? "1px solid #DCDDE6"
                : showBorder
                ? "none"
                : "",
              justifyContent: showBorder ? "flex-start" : "",
              paddingLeft: showBorder ? "15px" : ""
            }}
          >
            {(!multiple && (
              <p
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "clip",
                  marginRight: "10px",
                  marginLeft: "-10px",
                  fontWeight: showBorder ? 400 : ""
                }}
              >
                {(dropdownOption.length > 0 &&
                  selectedOption !== "" &&
                  truncateString(
                    generateNameFromValue(dropdownOption, selectedOption)[0]
                      ?.name,
                    truncateCharLength
                  )) ||
                  placeHolderText?.substring(0, 35)}
              </p>
            )) ||
              (!open && multipleOptions.length > 0 && (
                <div
                  style={{
                    overflowY: "hidden",
                    height: "40px",
                    overflowX: "scroll",
                    marginRight: "10px",
                    marginLeft: "-10px",
                    maxWidth: "95%",
                    flex: "1 0 auto",
                    display: "flex"
                  }}
                >
                  <ul
                    style={{
                      listStyle: "none",
                      margin: "0",
                      padding: "0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {multipleOptions.map((option, index) => {
                      return (
                        <li
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderRadius: "8px",
                            padding: "3px 8px",
                            marginRight: "8px"
                          }}
                          key={String(index)}
                        >
                          {option.icon && <span>{option.icon}</span>}
                          <span
                            style={
                              option.icon
                                ? { marginLeft: "5px" }
                                : { fontWeight: 700, fontSize: "14px" }
                            }
                          >
                            {option.name}
                          </span>
                          <span
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={(e: MouseEvent<HTMLSpanElement>) => {
                              e.stopPropagation();
                              onRemoveMultipleOption(index);
                            }}
                          >
                            <ExitIcon />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )) || (
                <span
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "clip",
                    marginRight: "10px",
                    marginLeft: "-10px"
                  }}
                >
                  {(dropdownOption.length > 0 &&
                    selectedOption !== "" &&
                    truncateString(
                      generateNameFromValue(dropdownOption, selectedOption)[0]
                        ?.name,
                      truncateCharLength
                    )) ||
                    placeHolderText?.substring(0, 35)}
                </span>
              )}
            {!open ? (
              <FaAngleDown color="#162930" size={15} />
            ) : (
              <FaAngleUp color="#162930" size={15} />
            )}
          </div>
          {open && (
            <DropdownContent
              onClose={() => {
                setOpen(false);
              }}
            >
              <div className="custom-dropdown__option">
                {isSearchable && dropdownOption.length > 5 && (
                  <span
                    style={{
                      display: "block",
                      padding: "0 22px"
                    }}
                  >
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder={searchPlaceholder}
                      style={
                        multiple
                          ? {
                              marginTop: "20px",
                              marginBottom: "20px",
                              height: "45px",
                              padding: "0 10px",
                              borderRadius: "4px",
                              width: "100%",
                              boxSizing: "border-box",
                              border: "0.3px solid #e2e2e2",
                              outline: "none",
                              textIndent: "16px",
                              backgroundPosition: "6px center",
                              backgroundRepeat: "no-repeat",
                              backgroundImage:
                                "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjkwNDMgMTIuODIxNUwxMC41ODQ4IDkuNTAxOTVDMTAuNTIxOSA5LjQzOTA2IDEwLjQzOTggOS40MDYyNSAxMC4zNTIzIDkuNDA2MjVIOS45OTE0MUMxMC44NTI3IDguNDA4MiAxMS4zNzUgNy4xMDkzOCAxMS4zNzUgNS42ODc1QzExLjM3NSAyLjU0NTcgOC44MjkzIDAgNS42ODc1IDBDMi41NDU3IDAgMCAyLjU0NTcgMCA1LjY4NzVDMCA4LjgyOTMgMi41NDU3IDExLjM3NSA1LjY4NzUgMTEuMzc1QzcuMTA5MzggMTEuMzc1IDguNDA4MiAxMC44NTI3IDkuNDA2MjUgOS45OTE0MVYxMC4zNTIzQzkuNDA2MjUgMTAuNDM5OCA5LjQ0MTggMTAuNTIxOSA5LjUwMTk1IDEwLjU4NDhMMTIuODIxNSAxMy45MDQzQzEyLjk1IDE0LjAzMjggMTMuMTU3OCAxNC4wMzI4IDEzLjI4NjMgMTMuOTA0M0wxMy45MDQzIDEzLjI4NjNDMTQuMDMyOCAxMy4xNTc4IDE0LjAzMjggMTIuOTUgMTMuOTA0MyAxMi44MjE1Wk01LjY4NzUgMTAuMDYyNUMzLjI3MDMxIDEwLjA2MjUgMS4zMTI1IDguMTA0NjkgMS4zMTI1IDUuNjg3NUMxLjMxMjUgMy4yNzAzMSAzLjI3MDMxIDEuMzEyNSA1LjY4NzUgMS4zMTI1QzguMTA0NjkgMS4zMTI1IDEwLjA2MjUgMy4yNzAzMSAxMC4wNjI1IDUuNjg3NUMxMC4wNjI1IDguMTA0NjkgOC4xMDQ2OSAxMC4wNjI1IDUuNjg3NSAxMC4wNjI1WiIgZmlsbD0iIzdCN0I3NiIvPgo8L3N2Zz4K')"
                            }
                          : {
                              marginTop: "20px",
                              marginBottom: "20px",
                              height: "45px",
                              padding: "0 10px",
                              borderRadius: "4px",
                              width: "100%",
                              boxSizing: "border-box",
                              border: "0.3px solid #e2e2e2",
                              outline: "none"
                            }
                      }
                      onChange={handleSearchChange}
                      value={searchText}
                    />
                  </span>
                )}
                {multiple && (
                  <div
                    style={{
                      overflowY: "hidden",
                      overflowX: "scroll",
                      marginRight: "10px",
                      marginLeft: "-10px",
                      maxWidth: "95%",
                      flex: "1 0 auto",
                      marginBottom: "10px"
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        margin: "0",
                        padding: "0 20px",
                        display: "flex",
                        flexDirection: "row"
                      }}
                    >
                      {multipleOptions.map((option, index) => {
                        return (
                          <li
                            style={{
                              backgroundColor: "#F7F9FC",
                              borderRadius: "8px",
                              padding: "8px 9px",
                              marginRight: "8px"
                            }}
                            key={String(index)}
                          >
                            {option.icon && <span>{option.icon}</span>}
                            <span
                              style={
                                option.icon
                                  ? { marginLeft: "5px" }
                                  : { fontWeight: 300, fontSize: "14px" }
                              }
                            >
                              {option.name}
                            </span>
                            <span
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                              onClick={(e: MouseEvent<HTMLSpanElement>) => {
                                e.stopPropagation();
                                onRemoveMultipleOption(index);
                              }}
                            >
                              <ExitIcon />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div
                  style={
                    multiple
                      ? {
                          position: "relative",
                          maxHeight: "150px",
                          overflowX: "hidden",
                          overflowY: "scroll"
                        }
                      : undefined
                  }
                >
                  {isSearchable ? (
                    filterOption(dropdownOption).map(
                      (option, index: number) => (
                        <span
                          key={index}
                          onClick={
                            !multiple
                              ? () => {
                                  setSearchText("");
                                  onChange(option.value);
                                }
                              : () => {
                                  setSearchText("");
                                  const previouslySelectedOption =
                                    multipleOptions.filter(
                                      ($option) => $option.name === option.name
                                    ).length > 0;

                                  if (!previouslySelectedOption) {
                                    setOptionsCleared(false);
                                    setMultipleOptions([
                                      ...multipleOptions,
                                      option
                                    ]);
                                  }
                                }
                          }
                          className={`custom-option${
                            selectedOption.toString().toLowerCase() ===
                            option.name.toLowerCase()
                              ? " selected"
                              : ""
                          }`}
                        >
                          {option?.icon !== undefined ? (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: "0 30px"
                              }}
                            >
                              <span>{option?.icon}</span>
                              <span style={{ marginLeft: "15px" }}>
                                {option?.name}
                              </span>
                            </span>
                          ) : (
                            option?.name
                          )}
                        </span>
                      )
                    )
                  ) : (
                    <>
                      {dropdownOption.map((option, index) => (
                        <span
                          key={index}
                          onClick={
                            !multiple
                              ? () => {
                                  onChange(option.value);
                                }
                              : () => {
                                  const previouslySelectedOption =
                                    multipleOptions.filter(
                                      ($option) => $option.name === option.name
                                    ).length > 0;

                                  if (!previouslySelectedOption) {
                                    setOptionsCleared(false);
                                    setMultipleOptions([
                                      ...multipleOptions,
                                      option
                                    ]);
                                  }
                                }
                          }
                          style={
                            !multiple &&
                            selectedOption.toString().toLowerCase() ===
                              option.name.toLowerCase()
                              ? {
                                  background: "#E9F7F5",
                                  color: `${colors.pri}`
                                }
                              : {}
                          }
                          className={`custom-option${
                            selectedOption.toString().toLowerCase() ===
                            option.name.toLowerCase()
                              ? " selected"
                              : ""
                          }`}
                        >
                          {!option?.icon &&
                            selectedOption.toString().toLowerCase() ===
                              option.name.toLowerCase() && (
                              <span className="check-icon">
                                <DropdownCheck />
                              </span>
                            )}
                          {option?.icon !== undefined ? (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: "0 30px"
                              }}
                            >
                              <span>{option?.icon}</span>
                              <span
                                style={{
                                  marginLeft: "15px",
                                  textTransform: "capitalize"
                                }}
                              >
                                {option?.name}
                              </span>
                            </span>
                          ) : (
                            option?.name
                          )}
                        </span>
                      ))}
                    </>
                  )}
                  {createAction?.text && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "8px 22px",
                        backgroundColor: "rgba(45, 180, 158, 0.1)",
                        color: "#006355",
                        marginTop: "5px",
                        cursor: "pointer"
                      }}
                      className={`custom-option"
                              }`}
                      onClick={() => {
                        createAction.action();
                      }}
                    >
                      {createAction.icon && (
                        <span style={{ marginTop: "8px" }}>
                          {createAction?.icon}
                        </span>
                      )}
                      <span
                        style={{
                          marginLeft: "10px",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          opacity: 1
                        }}
                      >
                        {createAction.text}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </DropdownContent>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropdownComponent;
