import React from "react";
import Table from "components/Table";
import moment from "moment";
import { TableBadge, TeamMemberTableContainer } from "./styles";
import { ITeamMemberResponseObject, UserActionStatus } from "interfaces/users";
import Skeleton from "react-loading-skeleton";
import { useModals } from "context/modalContext";
import toast from "react-hot-toast";
import MemberActionModal from "components/Modal/MemberActionModal";
import { Routes } from "routes/routes-constants";
import MobileTable from "components/MobileTable";
import { truncateString, getInitials } from "utils/text-util";
import { useMediaQuery } from "lib/hooks";
import { device } from "styles";
import ActionsDropdown from "components/ActionsDropdown";
import Avatar, { AvatarSizes } from "components/Avatar";
import { ReactComponent as WarningIcon } from "assets/svg/red-warning.svg";
import colors from "styles/colors";
import {
  useAssignAsHOD,
  useRemoveUserFromDepartment
} from "lib/query/departments";
import { AppointUserPayload, RemoveUserPayload } from "interfaces/departments";

interface DepartmentMemberTableProps {
  isLoading?: boolean;
  departmentId: string;
  listOfMembers: ITeamMemberResponseObject[];
}

const DepartmentMemberTable: React.FC<DepartmentMemberTableProps> = ({
  isLoading,
  departmentId,
  listOfMembers
}) => {
  const isMobile = useMediaQuery(device.mobile_);

  const [drop, setDrop] = React.useState<boolean>(false);
  const [clickedIndex, setClickedIndex] = React.useState<number>();
  const { push, close } = useModals();
  const { close: closeUserModal, push: openUserModal } = useModals();
  const { mutate: assignMember, isLoading: isUpdateLoading } = useAssignAsHOD();
  const { mutate: removeUser, isLoading: loading } =
    useRemoveUserFromDepartment();

  const handleDrop = (retrievedIndex: number) => {
    listOfMembers.map((member, index) => {
      if (index === retrievedIndex) {
        setDrop(!drop);
        setClickedIndex(retrievedIndex);
      }
    });
  };

  const triggerRemoveUserModal = (member: ITeamMemberResponseObject) => {
    openUserModal(
      <MemberActionModal
        closeModal={closeUserModal}
        icon={<WarningIcon />}
        title="Confirm action"
        subtitle={
          <span>
            Are you sure you want to remove{" "}
            <b>{member?.user?.first_name + " " + member?.user?.last_name}</b>{" "}
            from the department?
          </span>
        }
        handleSubmitButton={() => removeMember(member)}
        submitButtonText="Yes, Remove"
        submitButtonColor="#ED5B5C"
        isButtonLoading={loading}
      />
    );
  };

  const triggerHODModal = (member: ITeamMemberResponseObject) => {
    push(
      <MemberActionModal
        closeModal={closeUserModal}
        icon={<WarningIcon />}
        title="Confirm action"
        subtitle={
          <span>
            Are you sure you want to appoint{" "}
            <b>{member?.user?.first_name + " " + member?.user?.last_name}</b> as
            head of department?
          </span>
        }
        handleSubmitButton={() => appointMemberAsHOD(member)}
        submitButtonText="Yes, Appoint"
        submitButtonColor="#2DB49E"
        isButtonLoading={isUpdateLoading}
      />
    );
  };

  const removeMember = (member: ITeamMemberResponseObject) => {
    const payload: RemoveUserPayload = {
      business_id: member.business?.id,
      department_id: departmentId,
      user_id: member.user?.id
    };

    removeUser(payload, {
      onSuccess: () => {
        close();
        toast.success("Member has been removed successfully");
        window.location.href = `${Routes.DepartmentPreview}`;
      },
      onError: (err) => {
        if (!("message" in err)) {
          if (err.length > 0) {
            err.forEach((err) => toast.error(err as string));
            return;
          }
        } else {
          if (typeof err.message !== "string") {
            return;
          }

          toast.error(`${err.message}`);
        }
      }
    });
  };
  const appointMemberAsHOD = (member: ITeamMemberResponseObject) => {
    const payload: AppointUserPayload = {
      business_id: member.business?.id,
      department_id: departmentId,
      hod_id: member.user?.id
    };

    if (member.role.name !== UserActionStatus.approver) {
      close();
      return toast.error(
        "Head of department should be user with role of approver"
      );
    }
    assignMember(payload, {
      onSuccess: () => {
        close();
        toast.success("Member has been assigned successfully");
        window.location.href = `${Routes.DepartmentPreview}`;
      },
      onError: (err) => {
        if (!("message" in err)) {
          if (err.length > 0) {
            err.forEach((err) => toast.error(err as string));
            return;
          }
        } else {
          if (typeof err.message !== "string") {
            return;
          }

          toast.error(`${err.message}`);
        }
      }
    });
  };

  const dropStyle = {
    margin: "10",
    padding: "5px 0px",
    fontSize: "14px",
    color: "rgba(22,41,48,1)",
    cursor: "pointer",
    lineHeight: "22px"
  };

  const isMemberHOD = (
    members: ITeamMemberResponseObject[],
    userId: string
  ): boolean =>
    members.some(
      ({ department }) => department && department.hod_id === userId
    );

  return (
    <TeamMemberTableContainer>
      <div className="txn-table">
        {isLoading ? (
          <Skeleton count={5} height={60} style={{ marginBottom: "20px" }} />
        ) : isMobile ? (
          <MobileTable>
            {listOfMembers.map((member, index: number) => {
              return (
                <li
                  className="mobile_table__tr"
                  style={{ cursor: "pointer" }}
                  key={member.business.id + index}
                  onClick={() => handleDrop(index)}
                >
                  <section className="mobile_table_td">
                    <span className="super_text">
                      {truncateString(
                        `${member.user?.first_name || " "} ${
                          member.user?.last_name || " "
                        }`,
                        18
                      )}
                    </span>
                    <span className="sub_text">
                      {moment(member.created_at).format("DD/MM/YYYY")}
                    </span>
                  </section>
                  <section className="mobile_table_td">
                    <span className="sub_text">
                      {member.user?.is_invite_active
                        ? "Invite sent"
                        : member.user?.is_active
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </section>
                </li>
              );
            })}
          </MobileTable>
        ) : (
          <Table>
            <thead className="table__thead">
              <tr className="table__head">
                <th className="table__th">MEMBER</th>
                <th className="table__th">EMAIL</th>
                <th className="table__th">DATE ADDED</th>
                <th className="table__th">STATUS</th>
                <th className="table__th">ACTIONS</th>
              </tr>
            </thead>
            <tbody className="table__tbody">
              {listOfMembers.map((member, index: number) => {
                return (
                  <tr
                    className="table__tr"
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => handleDrop(index)}
                  >
                    <td className="table__td table__mobile-title avatar__td">
                      {member.user?.first_name ? (
                        <Avatar
                          letter={getInitials(
                            `${member.user?.first_name || ""} ${
                              member.user?.last_name || ""
                            }`
                          )}
                          size={AvatarSizes.Small}
                        />
                      ) : null}
                      <span style={{ marginLeft: "10px" }}>
                        {truncateString(
                          `${member.user?.first_name || " "} ${
                            member.user?.last_name || " "
                          }`,
                          18
                        )}
                      </span>
                      {isMemberHOD(listOfMembers, member.user.id) && (
                        <span
                          style={{
                            padding: "2px 10px",
                            backgroundColor: "#E5E5E5",
                            borderRadius: "4px",
                            fontSize: "11px",
                            fontWeight: 300,
                            marginLeft: "12px"
                          }}
                        >
                          Head
                        </span>
                      )}
                    </td>
                    <td className="table__td table__mobile-title">
                      <span>{member.user.email}</span>
                    </td>

                    <td className="table__td table__mobile-title">
                      <span>
                        {moment(member.user.created_at).format("DD/MM/yyyy")}
                      </span>
                    </td>
                    <td className="table__td table__mobile-title">
                      <TableBadge
                        variant={"basic"}
                        display={
                          member.user?.is_invite_active
                            ? "shade"
                            : (member.user?.is_active && "shine") || "warning"
                        }
                      >
                        {member.user?.is_invite_active
                          ? "Invite sent"
                          : member.user?.is_active
                          ? "Active"
                          : "Inactive"}
                      </TableBadge>
                    </td>
                    <td>
                      <ActionsDropdown
                        isOpen={drop && clickedIndex === index}
                        onCursorOut={() => {
                          setDrop(false);
                        }}
                        minWidth="210px"
                      >
                        <div className={"actions-dropdown-wrapper"}>
                          {!isMemberHOD(listOfMembers, member.user.id) && (
                            <div
                              style={{ ...dropStyle, color: "#5C696E" }}
                              onClick={() => triggerHODModal(member)}
                            >
                              Appoint as head
                            </div>
                          )}

                          <div
                            style={{ ...dropStyle, color: colors.error }}
                            onClick={() => triggerRemoveUserModal(member)}
                          >
                            Remove from Department
                          </div>
                        </div>
                      </ActionsDropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </TeamMemberTableContainer>
  );
};

export default DepartmentMemberTable;
