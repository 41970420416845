import { BusinessInterface, MerchantInterface } from "interfaces/users";
import { MasterWalletBalanceResponse } from "interfaces/wallet";
import React from "react";

export type UserProviderValues = {
  defaultBusinessInfo: BusinessInterface;
  ComplianceUpdateStep: number;
  ComplianceUpdateOption: string;
  bnplEnabled: boolean;
  invitationCode: string;
  authHeader: string;
  merchantInfo: MerchantInterface;
  masterWallet: MasterWalletBalanceResponse | undefined;
  storeDefaultBusinessInfo: (value: BusinessInterface) => void;
  storeMerchantInfo: (value: MerchantInterface) => void;
  storeMasterWallet: (value?: MasterWalletBalanceResponse) => void;
  storeAuthHeader: (value: string) => void;
  storeInvitationCode: (value: string) => void;
  storeComplianceUpdateStep: (value: number) => void;
  storeComplianceUpdateOption: (value: string) => void;
  storeBnplEnabled: (value: boolean) => void;
};

const initState = {
  defaultBusinessInfo: {} as BusinessInterface,
  ComplianceUpdateStep: 0,
  ComplianceUpdateOption: "",
  invitationCode: "",
  authHeader: "",
  bnplEnabled: false,
  merchantInfo: {} as MerchantInterface,
  masterWallet: undefined,
  storeDefaultBusinessInfo: (value: BusinessInterface) => void value,
  storeMerchantInfo: (value: MerchantInterface) => void value,
  storeMasterWallet: (value?: MasterWalletBalanceResponse) => void value,
  storeAuthHeader: (value: string) => void value,
  storeInvitationCode: (value: string) => void value,
  storeComplianceUpdateStep: (value: number) => void value,
  storeComplianceUpdateOption: (value: string) => void value,
  storeBnplEnabled: (value: boolean) => void value
};

const UserContext = React.createContext<UserProviderValues>(initState);
export const UserProvider = UserContext.Provider;

type Props = {
  children?: React.ReactNode;
};

export const UserProviderContainer: React.FC<Props> = ({ children }) => {
  const [defaultBusinessInfo, setDefaultBusinessInfo] =
    React.useState<BusinessInterface>(initState.defaultBusinessInfo);

  const [bnplEnabled, setBnplEnabled] = React.useState<boolean>(false);
  const [invitationCode, setInvitationCode] = React.useState<string>(
    initState.invitationCode
  );
  const [authHeader, setAuthHeader] = React.useState<string>(
    initState.authHeader
  );
  const [merchantInfo, setMerchantInfo] = React.useState<MerchantInterface>(
    initState.merchantInfo
  );
  const [masterWallet, setMasterWallet] = React.useState<
    MasterWalletBalanceResponse | undefined
  >(initState.masterWallet);
  const [ComplianceUpdateStep, setComplianceUpdateStep] =
    React.useState<number>(initState.ComplianceUpdateStep);

  const [ComplianceUpdateOption, setComplianceUpdateOption] =
    React.useState<string>(initState.ComplianceUpdateOption);

  const storeDefaultBusinessInfo = (value: BusinessInterface) => {
    setDefaultBusinessInfo(value);
  };

  const storeMerchantInfo = (value: MerchantInterface) => {
    setMerchantInfo(value);
  };

  const storeInvitationCode = (value: string) => {
    setInvitationCode(value);
  };

  const storeAuthHeader = (header: string) => {
    setAuthHeader(header);
  };

  const storeComplianceUpdateStep = (step: number) => {
    setComplianceUpdateStep(step);
  };

  const storeComplianceUpdateOption = (option: string) => {
    setComplianceUpdateOption(option);
  };
  const storeMasterWallet = (value?: MasterWalletBalanceResponse) => {
    setMasterWallet(value);
  };

  const storeBnplEnabled = (value: boolean) => {
    setBnplEnabled(value);
  };
  return (
    <UserProvider
      value={{
        defaultBusinessInfo,
        merchantInfo,
        masterWallet,
        authHeader,
        invitationCode,
        ComplianceUpdateStep,
        ComplianceUpdateOption,
        bnplEnabled,
        storeDefaultBusinessInfo,
        storeMerchantInfo,
        storeMasterWallet,
        storeAuthHeader,
        storeInvitationCode,
        storeComplianceUpdateStep,
        storeComplianceUpdateOption,
        storeBnplEnabled
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContext;
