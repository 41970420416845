import React from "react";
import { RouteChildrenProps } from "react-router";
import { userKey } from "utils/constants";
import { lazyWithRetry, componentLoader } from "utils/import-utils";
import storage from "utils/storage";
import UnauthenticatedWrapper from "./Auth/UnauthenticatedWrapper";
import InvoiceOpenLayoutWrapper from "./InvoiceOpenLayout/index";
import NotFound from "pages/404";

const AuthenticatedApp = lazyWithRetry(() =>
  componentLoader(() => import("layout/AppLayout"))
);

const RouteLayout = ({
  history
}: Required<Pick<RouteChildrenProps, "history">>) => {
  const token = storage.get(userKey);
  const pathName = history.location.pathname || "";

  return (
    <div className="routing-group-wrapper" style={{ height: "100%" }}>
      {pathName.includes("404") ? (
        <NotFound />
      ) : (
        <>
          {token && !pathName.includes("view-invoice") && <AuthenticatedApp />}
          {!token && !pathName.includes("view-invoice") && (
            <UnauthenticatedWrapper />
          )}
          {pathName.includes("view-invoice") && <InvoiceOpenLayoutWrapper />}
        </>
      )}
    </div>
  );
};

export default RouteLayout;
