import styled from "styled-components";
import { device } from "styles";

const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  min-height: 0;
  &:hover {
    .dropdownWrapper {
      display: block;
      border: 1px solid #eeeeee;
      border-top: none;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;

    .user-name {
      margin-left: 5px;
    }
    .logo {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      margin: 7px 0;
    }
    .padded-icon {
      margin-left: 10px;
    }
  }

  .dropdownChildBox {
    display: inline-block;
    text-align: center;
    .copped {
      display: inline-block;
      border: 1px solid #eeeeee;
      padding: 0 8px;
      border-radius: 5px;
      background-color: white;
      white-space: nowrap;
      margin: 0;
      .avatar {
        display: inline-block;
        border-radius: 50%;
        padding: 3px;
        vertical-align: middle;
      }

      .padded {
        display: inline-block;
        vertical-align: middle;
        margin-left: 40px;

        @media ${device.mobile_} {
          margin-left: 10px;
        }
      }
    }
  }

  .dropdownWrapper {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -3px;
    background-color: #ffffff;
    width: 98.2555555%;
    z-index: 30;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;

    .dropdownMenu {
      padding: 0;
      margin: 0;
      text-align: left;
      padding: 0px 6px 0 6px;
      list-style: none;
      min-height: 0;

      .logout {
        color: #ed5a5c;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        border-top: 1px solid #ededf2;
      }

      li {
        padding: 12px 10px;
        white-space: nowrap;
        word-break: normal;
        cursor: pointer;

        a {
          text-decoration: none;
          display: inline-block;
          width: 100%;
          color: #5c696e;
          font-size: 12px;
          font-weight: bold;
        }

        @media ${device.mobile_} {
          padding: 10px 2px;
        }
      }
    }
  }
`;

export { DropdownContainer };
