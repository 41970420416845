export const getFirstChar = (name: string) => {
  if (name) {
    return name.charAt(0);
  }

  return "";
};

export const truncateString = (str: string, num: number): string => {
  if (str && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const getOrdinal = (num: number, asWord = false): string => {
  let ord = "th";

  if (num % 10 == 1 && num % 100 != 11) {
    ord = "st";
  } else if (num % 10 == 2 && num % 100 != 12) {
    ord = asWord ? "ond" : "nd";
  } else if (num % 10 == 3 && num % 100 != 13) {
    ord = "rd";
  }

  return ord;
};

export const getNumbering = (num: number, asWord = false): string => {
  const tens = [
    "",
    "fir",
    "sec",
    "thi",
    "four",
    "fif",
    "six",
    "seven",
    "eig",
    "nin",
    "ten",
    "eleven",
    "twelv",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen"
  ];
  const hundreds = [
    "",
    "",
    "twentee",
    "thirtee",
    "fortee",
    "fifthee",
    "sixtee",
    "seventee",
    "eigh",
    "nine"
  ];

  if (num.toString().length > 9) return "overflow";
  const numString = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/) as Array<string>;

  if (!numString) {
    return "";
  }

  const [, first, second, third, fourth, fifth] = numString;

  let numberingString = "";
  numberingString +=
    Number(first) !== 0
      ? (tens[Number(first)] ||
          hundreds[Number(first[0])] + " " + tens[Number(first[1])]) + "crore "
      : "";
  numberingString +=
    Number(second) !== 0
      ? (tens[Number(second)] ||
          hundreds[Number(second[0])] + " " + tens[Number(second[1])]) + "lakh "
      : "";
  numberingString +=
    Number(third) !== 0
      ? (tens[Number(third)] ||
          hundreds[Number(third[0])] + " " + tens[Number(third[1])]) +
        "thousand "
      : "";
  numberingString +=
    Number(fourth) !== 0
      ? (tens[Number(fourth)] ||
          hundreds[Number(fourth[0])] + " " + tens[Number(fourth[1])]) +
        "hundred "
      : "";
  numberingString +=
    Number(fifth) !== 0
      ? (numberingString !== "" ? "and " : "") +
        (tens[Number(fifth)] ||
          hundreds[Number(fifth[0])] + " " + tens[Number(fifth[1])]) +
        getOrdinal(num, asWord)
      : "";

  return numberingString;
};

export const getInitials = (name?: string) => {
  if (name) {
    const nameSplit = name?.split(" ");
    const firstInitials = nameSplit[0]?.charAt(0);
    const secondInitials = nameSplit[1]?.charAt(0);

    return (
      (firstInitials ? firstInitials : "") +
      (secondInitials ? secondInitials : "")
    );
  }

  return "O";
};

export const titleCase = (str: string): string => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    /* @HINT: You do not need to check if i is larger than splitStr length, as your for does that for you */
    /* @HINT: ...assign it back to the array */
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  /* @HINT: Directly return the joined string */
  return splitStr.join(" ");
};

export const slugify = (text: string, separator = "_") => {
  return text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, separator);
};

export const getComplainceDocumentHeader = (title: string) => {
  switch (title) {
    case "tax_identification_number":
      return "TIN/TCC";
    case "bn_number":
      return "BN Number";
    case "cac_1_1_or_status_report":
      return "CAC 1.1 or Status report";
    case "cac_status_report":
      return "CAC Status report";
    case "audited_financials":
      return "Audited Financials";
    case "certificate_of_registration":
      return "Certificate of Registration";
    case "memorandum_and_articles_of_association":
      return "Memorandum and Articles of Association";
    case "certificate_of_incorporation":
      return "Certificate of Incorporation";
    case "business_proof_of_address":
      return "Business Proof of Address";
    case "board_resolution":
      return "Board Resolution";
    case "rc_number":
      return "RC Number";
    case "operating_license":
      return "Operating Licence";
    case "scuml_certificate":
      return "SCUML Certificate";
    case "aml_or_kyc_questionnaire_and_policies":
      return "AML/KYC Questionnaire";
    case "cac_2_7_or_status_report":
      return "Cac 2 & 7 or Status Report";
    default:
      return "";
  }
};

export const returnComplainceExtension = (file: string) => {
  if (file.includes("png")) {
    return ".png";
  }

  if (file.includes("pdf")) {
    return ".pdf";
  }

  if (file.includes("docx")) {
    return ".docx";
  }

  if (file.includes("jpeg")) {
    return ".jpeg";
  }

  return "";
};

export const getComplianceDocumentInputValue = (
  documents: { [key: string]: any }[],
  key: string
) => {
  return documents?.find((document) => document.name === key)?.value;
};

export const getComplianceDocumentFileValue = (
  documents: { [key: string]: any }[],
  key: string
) => {
  return documents?.find((document) => document.name === key)?.fileId;
};

export const isOnlyTextRegex = (key: string) => {
  const regex = /^[A-Za-z\s]*$/;

  if (!regex.test(key)) {
    return false;
  } else {
    return true;
  }
};


