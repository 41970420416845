import React from "react";
import { ReactComponent as OverviewIcon } from "assets/svg/Roles/Overview.svg";
import { ReactComponent as BusinessIcon } from "assets/svg/Roles/Business.svg";
import { ReactComponent as TransactionIcon } from "assets/svg/Roles/Transaction.svg";
import { ReactComponent as CustomerIcon } from "assets/svg/Roles/Customers.svg";
import { ReactComponent as InvoiceIcon } from "assets/svg/Roles/Invoice.svg";
import { ReactComponent as VAIcon } from "assets/svg/Roles/VA.svg";
import { ReactComponent as RecipientIcon } from "assets/svg/Roles/Recipients.svg";
import { ReactComponent as PayoutsIcon } from "assets/svg/Roles/Payout.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/Roles/Settings.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/Roles/Profile.svg";
import { ReactComponent as ApprovalIcon } from "assets/svg/Roles/Approvals.svg";
import { ReactComponent as BillsIcon } from "assets/svg/Roles/Bills.svg";
import { ReactComponent as ComplianceIcon } from "assets/svg/Roles/Compliance.svg";
import {
  BusinessInterface,
  IPermissionResponseObject,
  ITeamMemberResponseObject,
  UserActionStatus
} from "interfaces/users";
import storage from "./storage";
import { decrypt_user0bj } from "./auth-util";
import { defaultBusinessKey, memberKey } from "./constants";

function renderIcon(permission: string) {
  switch (permission) {
    case "overview_page":
      return <OverviewIcon />;
    case "business_page":
      return <BusinessIcon />;
    case "transactions_page":
      return <TransactionIcon />;
    case "customers_page":
      return <CustomerIcon />;
    case "invoices_page":
      return <InvoiceIcon />;
    case "va_page":
      return <VAIcon />;
    case "recipients_page":
      return <RecipientIcon />;
    case "payouts_page":
      return <PayoutsIcon />;
    case "settings_page":
      return <SettingsIcon />;
    case "profile_page":
      return <ProfileIcon />;
    case "approval_page":
      return <ApprovalIcon />;
    case "bills_page":
      return <BillsIcon />;
    case "compliance_page":
      return <ComplianceIcon />;
    default:
      break;
  }
}

const defaultBusinessInfo: BusinessInterface =
  decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

const memberInfo: ITeamMemberResponseObject =
  decrypt_user0bj<ITeamMemberResponseObject>(storage.get(memberKey));

const isMemberUser = !(
  memberInfo &&
  Object.keys(memberInfo).length === 0 &&
  Object.getPrototypeOf(memberInfo) === Object.prototype
);
const isMemberAdminOnly =
  isMemberUser && defaultBusinessInfo?.role?.name === UserActionStatus.admin;

const isMemberBusinessOwnerOnly =
  !isMemberUser &&
  (defaultBusinessInfo?.role?.name === UserActionStatus.owner ||
    !defaultBusinessInfo?.role?.name);

const isPermiitedToAccessPage = (
  permissions: IPermissionResponseObject[],
  page: string
) => {
  if (isMemberAdminOnly || isMemberBusinessOwnerOnly) {
    return true;
  } else
    return permissions?.find(
      (permission) => permission?.name === page && permission?.is_allowed
    );
};

const isPermiitedToAccessFeature = (
  permissions: IPermissionResponseObject[],
  page: string,
  feature: string
) => {
  if (isMemberAdminOnly || isMemberBusinessOwnerOnly) {
    return true;
  } else
    return permissions
      ?.find(
        (permission) => permission?.name === page && permission?.is_allowed
      )
      ?.allowed_roles.some((role) => role?.name === feature);
};

const accessGranted = (page: string, feature: string) => {
  return isPermiitedToAccessFeature(
    defaultBusinessInfo?.role?.permissions,
    page,
    feature
  );
};

export {
  renderIcon,
  isPermiitedToAccessPage,
  isPermiitedToAccessFeature,
   accessGranted
};
