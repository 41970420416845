import React, { useState } from "react";
import { useEffect } from "react";
import { SwitchButtonsBox } from "./styles";

const SwitchButtons = ({
  defaultSwitchIndex = 0,
  labels,
  onSwitch
}: {
  defaultSwitchIndex?: number;
  labels: Array<React.ReactElement | string>;
  onSwitch: (label: React.ReactElement | string, currentIndex?: number) => void;
}) => {
  const [currentSwitchIndex, setCurrentSwitchIndex] =
    useState(defaultSwitchIndex);

  useEffect(() => {
    onSwitch(labels[currentSwitchIndex], currentSwitchIndex);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentSwitchIndex]);

  return (
    <SwitchButtonsBox>
      {labels.map((label, index) => {
        const isDefault = defaultSwitchIndex === index;
        return (
          <span
            key={String(index)}
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              const targetElement = event.target as HTMLSpanElement;
              const parentElement = targetElement.parentNode;
              if (currentSwitchIndex === index) {
                return;
              }

              targetElement.classList.toggle("switchOn");

              if (parentElement !== null) {
                const childElement =
                  parentElement.children.item(currentSwitchIndex);

                if (childElement !== null) {
                  childElement.classList.remove("switchOn");
                }
              }
              setCurrentSwitchIndex(index);
            }}
            className={
              isDefault ? "switchButtonsItems switchOn" : "switchButtonsItems"
            }
          >
            {label}
          </span>
        );
      })}
    </SwitchButtonsBox>
  );
};

export default SwitchButtons;
