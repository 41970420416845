import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Routes } from "routes/routes-constants";
import AppRoutes from "routes/routes.config";

const InvoiceOpenLayout = () => {
  const UnauthenticatedOther = AppRoutes.filter(
    (route) => !route.isPrivate && route.isOpenInvoice
  );
  return (
    <div className="layout-innermost-wrapper" style={{ height: "100%" }}>
      <Switch>
        {UnauthenticatedOther.map((path, index: number) => (
          <Route
            key={path.path + index}
            exact={path.exact}
            path={path.path}
            component={path.component}
          />
        ))}
        <Redirect to={Routes.Login} />
      </Switch>
    </div>
  );
};

export default InvoiceOpenLayout;
