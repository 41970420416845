import React from "react";
import { useHistory } from "react-router";
import { lazyWithRetry, componentLoader } from "utils/import-utils";

const UnauthenticatedWrapper = () => {
  const UnauthenticatedApp = lazyWithRetry(() => componentLoader(() => import("layout/Auth")));
  const UnauthenticatedAppOthers = lazyWithRetry(() => componentLoader(
    () => import("layout/OtherLayout")
  ));
  const history = useHistory();
  const pathName = history.location.pathname;
  return (
    <div style={{ height: "100%" }}>
      {pathName.includes("registration") || pathName.includes("checkout") ? (
        <UnauthenticatedAppOthers />
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  );
};

export default UnauthenticatedWrapper;
