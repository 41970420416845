import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/lottie/success.json";
import successIcon from "assets/gif/successIcon.gif";

interface AnimationProps {
  width: number;
  height: number;
  if_gif?: boolean;
}

const SuccessAnimation: React.FC<AnimationProps> = ({
  width,
  height,
  if_gif
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div>
      {if_gif ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <img src={successIcon} alt="success" width={width} height={height} />
        </div>
      ) : (
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          isStopped={false}
          isPaused={false}
        />
      )}
    </div>
  );
};

export default SuccessAnimation;
