import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";

interface ActionsDropdownProps {
  children: React.ReactNode;
  isOpen: boolean;
  onCursorOut?: Function;
  draftStyle?: boolean;
  isActive?: boolean;
  minWidth?: string;
}

const ActionsDropdown = ({
  children,
  isOpen,
  onCursorOut,
  draftStyle,
  isActive,
  minWidth
}: ActionsDropdownProps) => {
  return (
    <section
      style={{
        display: "inline-block",
        textIndent: "15px"
      }}
      onMouseLeave={() => {
        if (typeof onCursorOut === "function") {
          onCursorOut();
        }
      }}
    >
      <div
        className="more-icon"
        style={{
          fontSize: "20px",
          fontWeight: 600,
          cursor: "pointer"
        }}
      >
        <FiMoreHorizontal
          style={{
            marginBottom: draftStyle ? "-4px" : "",
            opacity: isActive ? "0.2" : ""
          }}
        />
      </div>
      {isOpen && (
        <div style={{ position: "relative" }}>
          <div
            style={{
              minWidth: minWidth ? minWidth : "150px",
              position: "absolute",
              backgroundColor: "#ffffff",
              zIndex: 3000,
              right: 0,
              top: "120%",
              padding: "10px",
              boxShadow: "0 0 3px #a4a7b0",
              borderRadius: "6px"
            }}
          >
            {children}
          </div>
        </div>
      )}
    </section>
  );
};

export default ActionsDropdown;
