import colors from "styles/colors";
import { device } from "styles";
import styled, { css } from "styled-components";

type ApprovalPreviewContainerProps = {
  gridTemplateSizes?: string;
};
type ApprovalCommentWrapperProps = {
  isBillComment?: boolean;
};

const ApprovalsContainer = styled.div`
  @media ${device.mobileS} {
    padding: 20px;
  }

  @media ${device.tablet} {
    padding: 30px;
  }
`;

const ApprovalTableContainer = styled.div`
  margin-top: 30px;
  overflow-x: scroll;

  .avatar__td {
    & span:nth-child(1) {
      height: 35px;
      width: 35px;
    }
  }

  .letter {
    font-size: 12px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #162930;
    background: #b8eedf;
  }
`;

const ApprovalTableLink = styled.div`
  text-align: center;
  color: ${colors.pri};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

const EmptyApprovalIcon = styled.div`
  background: #f1f2f4;
  border: 1px dashed #cacaca;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  display: inline-block;
  margin-right: 12px;

  &:after {
    content: "+";
    position: absolute;
    color: #cacaca;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ApproversSection = styled.div`
  .header {
    background: #f8f9f9;
    padding: 16px;
    text-transform: uppercase;
    color: rgba(123, 123, 118, 0.8);
    font-size: 14px;
  }

  ul {
    margin: 16px;
    padding: 0;
    list-style-type: none;

    li {
      color: #162930;
      font-weight: 400;
      line-height: 20px;
      font-size: 15px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 10px;

      & span:first-of-type {
        height: 35px;
        flex-shrink: 0;
        position: relative;
        overflow: visible;
      }
      .letter {
        font-size: 14px;
        font-weight: 800;
        height: 35px;
        line-height: 35px;
        width: 35px;

        color: #fff;
      }
      small {
        color: #6d6f75;
        font-weight: 300;
        font-size: 13px;
      }
    }
  }
`;

const ApprovalPreviewContainer = styled.div<ApprovalPreviewContainerProps>`
  padding: 30px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateSizes ? props.gridTemplateSizes : "65% auto"};
  grid-column-gap: 30px;

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
    grid-column: 1;
    grid-row: 1;
    padding: 16px;
  }

  @media ${device.tablet} {
    grid-template-columns: ${(props) =>
      props.gridTemplateSizes ? props.gridTemplateSizes : "65% auto"};
    grid-column: 2;
    grid-row: 1;
    padding: 30px;
  }

  .content {
    border: 1px solid #ecf0f3;
    padding: 20px;
    overflow: auto;

    @media ${device.mobileS} {
      padding: 16px;
    }

    @media ${device.tablet} {
      padding: 20px;
    }

    h2 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      color: #162930;
      line-height: 25px;
    }
  }

  .right-nav {
    overflow-y: scroll;
    height: 100vh;

    & ${ApproversSection}:nth-of-type(3) {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(189, 189, 189, 0.34375);
      border-radius: 2px;

      .header {
        background: rgba(45, 180, 158, 0.12);
      }
      .body {
        padding: 15px 20px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #5c696e;
          margin: 0 0 20px;
        }
      }
    }
  }
`;
const RecipientPreviewContainer = styled.div<ApprovalPreviewContainerProps>`
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  column-gap: 30px;

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
    grid-column: 1;
    grid-row: 1;
    padding: 16px;
  }

  @media ${device.tablet} {
    grid-template-columns: ${(props) =>
      props.gridTemplateSizes ? props.gridTemplateSizes : "65% auto"};
    grid-column: 2;
    grid-row: 1;
    padding: 30px;
  }

  .content {
    border: 1px solid #ecf0f3;
    padding: 20px;
    overflow: auto;
    width: 65%;

    @media ${device.mobileS} {
      padding: 16px;
    }

    @media ${device.tablet} {
      padding: 20px;
    }

    h2 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      color: #162930;
      line-height: 25px;
    }
  }

  .right-nav {
    overflow-y: scroll;
    height: 100vh;

    & ${ApproversSection}:nth-of-type(3) {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(189, 189, 189, 0.34375);
      border-radius: 2px;

      .header {
        background: rgba(45, 180, 158, 0.12);
      }
      .body {
        padding: 15px 20px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #5c696e;
          margin: 0 0 20px;
        }
      }
    }
  }
`;

const AuditTrailSection = styled.ul`
  margin-top: 40px;
  li {
    margin-bottom: 30px !important;
    position: relative;

    & span:not(.letter):first-of-type {
      &:before {
        content: "";
        position: absolute;
        border: 0.8px solid #dcdde5;
        height: 29px;
        right: 50%;
        bottom: -30px;
      }
    }
  }
  li.no-trail {
    margin-bottom: 30px !important;

    & span:not(.letter):first-of-type {
      &:before {
        content: "";
        position: absolute;
        border: 0;
        height: 32px;
      }
    }
  }
  .comment:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      border: 0.8px solid #dcdde5;
      height: 29px;
      left: 16px;
      bottom: -30px;
    }
  }

  .inv_financing_title {
    color: #162930;
    font-weight: 400;
    font-size: 14px;
  }
  .inv_financing {
    padding: 6px 12px;
    border-radius: 6px;
    border: none;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    line-height: 18px;
  }
  .inv_financing.approve {
    background: #006355;
    color: #fff;
    font-weight: 400;
  }
  .inv_financing.deny {
    background: #fff;
    color: #5c696e;
    border: 1px solid #e2e4e5;
  }
`;

const ApprovalCTASection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & div:first-of-type {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media ${device.mobileS} {
    flex-direction: column;
    align-items: start;

    & div:nth-of-type(2) {
      margin: 20px 0;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;

    & div:nth-of-type(2) {
      margin: 0;
    }
  }
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
`;

const ApprovalContent = styled.div`
  border: 1px solid #ecf0f3;
  padding: 20px 16px;
  margin: 20px 0;

  .documents-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 16px;
  }

  .header {
    background: rgba(22, 41, 48, 0.03);
    padding: 16px;
    text-transform: uppercase;
    color: rgba(123, 123, 118, 0.8);
    font-size: 14px;
  }
  dt {
    color: rgba(92, 105, 110, 0.6);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }
  dl {
    display: grid;
    grid-template-columns: 30% 70%;
    margin: 20px 0;
    padding: 0 16px;

    @media ${device.mobileS} {
      padding: 0px;
    }

    @media ${device.tablet} {
      padding: 0 16px;
    }
  }
  dd {
    color: #162930;
    font-weight: 300;
  }
  hr {
    margin: 20px 0;
    border: 1px solid #eeeeee;
  }
  .footer {
    padding: 16px;

    @media ${device.mobileS} {
      padding: 0px;
    }

    @media ${device.tablet} {
      padding: 16px;
    }

    h4 {
      color: rgba(123, 123, 118, 0.8);
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
      font-weight: 500;
      white-space: nowrap;
    }
  }
`;

const DocumentWrapper = styled.ul`
  margin: 16px 0;
  list-style-type: none;
  padding: 0;
`;
const ApprovalDocumentItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fdfdfd;
  border: 1.4px solid #e1e1eb;
  border-radius: 8px;
  margin: 0;
  padding: 10px 16px;

  & div:first-of-type {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    gap: 5px;
  }
  a {
    color: ${colors.pri};
    cursor: pointer;
    text-decoration: none;
  }
`;

const FailedApprovalIcon = styled.div`
  background: #ed5b5c;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  flex-shrink: 0;
  &:before {
    content: "";
    position: absolute;
    border: 0.8px solid #dcdde5;
    height: 29px;
    right: 50%;
    bottom: -30px;
  }

  &:after {
    content: "x";
    position: absolute;
    color: #fff;
    font-size: 25px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
`;
const SuccessApprovalIcon = styled.div`
  background: #00cf40;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    border: 0.8px solid #dcdde5;
    height: 29px;
    right: 50%;
    bottom: -30px;
  }
`;
const ApprovalCommentWrapper = styled.div<ApprovalCommentWrapperProps>`
  box-shadow: 0px 1px 4px rgba(189, 189, 189, 0.34375);
  border-radius: 2px;
  width: 100%;

  .header {
    background: rgba(45, 180, 158, 0.12);
    padding: 6px 16px;
    text-transform: none;

    p {
      color: #01493d;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin: 8px 0;

      small {
        font-size: 12px;
        color: #6d6f75;
      }
    }
  }
  .body {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    color: #5c696e;

    p {
      margin: 6px 0;
    }

    ${(props) =>
      props.isBillComment &&
      css`
        &:before {
          content: "";
          position: absolute;
          border: 0.8px solid #dcdde5;
          height: 29px;
          left: 5%;
          bottom: -30px;
        }
      `}
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 25px 0;
`;

const VendorCardContainer = styled.div`
  margin: 40px 0 25px 0;
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 20px;
  }
`;

const Vendorcard = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 26px -7px #5c696e1a;

  padding: 16px 0 20px 15px;
  margin-bottom: 25px;
  .title {
    color: #5c696e;
    line-height: 17.75px;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 0;
  }
  .payout {
    color: #2db49e;
    line-height: 15px;
    font-weight: 600;
    font-size: 11px;
    padding: 8px 0;
  }
`;
export {
  ApprovalsContainer,
  ApprovalTableContainer,
  ApprovalTableLink,
  ApprovalPreviewContainer,
  ApprovalCTASection,
  ApprovalContent,
  DocumentWrapper,
  ApprovalDocumentItem,
  ApproversSection,
  AuditTrailSection,
  EmptyApprovalIcon,
  FailedApprovalIcon,
  SuccessApprovalIcon,
  ApprovalCommentWrapper,
  FlexContainer,
  RecipientPreviewContainer,
  VendorCardContainer,
  Vendorcard
};
