import CustomButton from "components/CustomButton";
import SuccessAnimation from "components/SuccessAnimation";
import React from "react";
import Modal from ".";
import { SuccessfulActivationModalContainer } from "./styles";

import { AiOutlineClose } from "react-icons/ai";

interface SuccessfulActivationModalProps {
  closeSuccessModal: () => void;
  text:
    | string
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLSpanElement>,
        HTMLSpanElement
      >;
  buttonText?: string;
  hasVendorLink?: boolean;
  children?: React.ReactNode;
}

const SuccessfulActivationModal: React.FC<SuccessfulActivationModalProps> = ({
  closeSuccessModal,
  text,
  buttonText = "Okay",
  hasVendorLink,
  children
}) => {
  return (
    <Modal isFooter={false} isHeader={false} isCloseIcon>
      {hasVendorLink && (
        <div className="close-modal-icon" onClick={closeSuccessModal}>
          <AiOutlineClose />
        </div>
      )}
      <div className="modal-body">
        <SuccessAnimation width={150} height={150} if_gif={true} />

        <SuccessfulActivationModalContainer hasVendorLink={hasVendorLink}>
          <h3>Successful</h3>

          <p>{text}</p>

          {children}

          {!hasVendorLink && (
            <CustomButton
              height={45}
              width={140}
              bgColor="#2DB49E"
              label={buttonText}
              type="submit"
              onClick={closeSuccessModal}
            />
          )}
        </SuccessfulActivationModalContainer>
      </div>
    </Modal>
  );
};

export default SuccessfulActivationModal;
