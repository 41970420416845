import styled from "styled-components";
import { device } from "styles";
import WalletBg from "assets/png/business-wallet-bg.png";
import colors from "styles/colors";

interface BussinessSummaryItemsProps {
  height?: string;
}

const BusinessActionLinksMobile = styled.div`
  padding: 10px 20px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  justify-content: space-around;
  background-color: rgba(22, 41, 48, 0.03);

  .actionLinksBoxRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: transparent;
    -webkit-flex-flow: row;
    flex-flow: row;
    + {
      .actionLinksBoxRow {
        margin-top: 10px;
      }
    }
  }

  .actionLinksBoxColumn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
    flex-flow: row;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e1e7eb;
    color: rgba(25, 40, 80, 1);
    flex: 0 50%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 10px;
    border-radius: 5px;
    span {
      vertical-align: middle;
      text-align: left;
    }
    + {
      .actionLinksBoxColumn {
        margin-left: 20px;
      }
    }
  }
`;

const BusinessContainer = styled.div`
  display: grid;
  height: 100%;

  @media ${device.laptop} {
    grid-template-columns: 35% 63%;
    grid-column-gap: 10px;
    border-right: 1px solid #ecf0f3;
  }
`;

const BusinessTableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;

  .pagination-container {
    display: flex;
    align-items: center;
    p {
      color: #5c696e !important;
      display: flex;
      align-items: center;
      text-align: right;
      margin-right: 25px;
      text-decoration: none;
      cursor: pointer;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  p {
    color: ${colors.pri};
    text-align: right;
    margin-right: 25px;
    text-decoration: underline;
    margin-top: 30px;
    cursor: pointer;
  }

  @media ${device.mobile_} {
    width: auto;
    padding: 0 20px;
  }
`;

const BusinessActionLinks = styled.div`
  border-bottom: 1px solid #ecf0f3;
  margin-top: 30px;

  p {
    color: #5c696e;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 10px;
  }
`;

const BusinessSummaryContainer = styled.div`
  padding: 20px 0;
  @media ${device.laptop} {
    border-right: 1px solid #ecf0f3;
  }
`;

const BusinessOptionMenu = styled.div`
  border: 1px solid #e1e7eb;
  border-radius: 5px;
  margin-top: 30px;
  height: 73px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: start;

  #loading {
    width: 10px;
    height: 10px;
    border: 2px solid #000 !important;
    border-top-color: #fff !important;
    margin-left: 8px;
  }

  &:hover {
    background: #f8fcfc;
    border: 1px solid ${colors.pri};
  }

  h3 {
    color: #192850;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin: 0 0 13px 0;
  }

  p {
    font-weight: 300;
    font-size: 14.5px;
    line-height: 18px;
    color: #94a2ab;
    max-width: 250px;
    margin: 0;
  }
`;

//todo remove unused exports
const BusinessTableHeaderContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin-top: 30px;

  p {
    color: #5c696e;
    font-weight: 600;
  }
`;

const BusinessAccountContainer = styled.div`
  display: grid;
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);

  @media ${device.laptop} {
    grid-template-columns: auto 30%;
    grid-column-gap: 15px;
  }
`;

const BusinessAccountItem = styled.div`
  padding: 20px;
  border-radius: 6px;

  :first-child {
    border-right: 1px solid rgba(22, 41, 48, 0.05);
  }
`;

const BussinessWalletContainer = styled.div`
  background: #f8f9f9;
  border: 1px solid rgba(22, 41, 48, 0.03);
  border-radius: 10px;

  img {
    max-width: 170px;
    display: flex;
    margin: 12px auto;
  }

  .image {
    background-image: url(${WalletBg});
    background-repeat: no-repeat;
    height: 100%;
  }

  .button-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 5px;
  }
`;

const BusinessIDContainer = styled.div`
  margin: 20px auto 0 auto;
  display: flex;
  align-items: center;
  background: rgba(244, 244, 244, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 15px 20px;
  max-width: 510px;

  p {
    color: #5c696e;
    margin: 0;
    padding: 0;
  }

  .copy-container {
    width: 30px;
    height: 30px;
    background: #ededf0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 5px;
  }
`;

const SweepButton = styled.button`
  background: #fff;
  color: ${colors.pri};
  border: 1px solid ${colors.pri};
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  cursor: pointer;
`;

const BussinessSummaryContainer = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
  @media ${device.laptop} {
    grid-template-columns: 50% 50%;
    grid-column-gap: 15px;
  }
`;

const BusinessTransferContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  button {
    margin: 0;
  }
`;

const BussinessSummaryItems = styled.div<BussinessSummaryItemsProps>`
  height: ${(props) => (props.height ? props.height : "150px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;

  .ledger-balance-container {
    display: flex;
    align-items: center;
    span {
      color: #162930;
      margin: 0 5px 0 10px;
    }
  }

  :first-child {
    border-right: 1px solid rgba(22, 41, 48, 0.05);
  }

  .balance {
    color: #94a2ab;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .amount {
    color: #162930 !important;
    font-weight: 500;
    font-size: 25px;
    margin: 15px 0;
    padding: 0;

    span {
      color: #162930 !important;
    }
  }

  .send-money {
    font-weight: 600;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: ${colors.pri};

    #loading {
      width: 10px;
      height: 10px;
      border: 2px solid #000 !important;
      border-top-color: #fff !important;
      margin-left: 8px;
    }
  }
`;
//todo remove unused exports

export {
  BusinessActionLinksMobile,
  BusinessAccountContainer,
  BusinessAccountItem,
  BussinessWalletContainer,
  SweepButton,
  BussinessSummaryContainer,
  BussinessSummaryItems,
  BusinessTableHeaderContainer,
  BusinessIDContainer,
  BusinessContainer,
  BusinessActionLinks,
  BusinessSummaryContainer,
  BusinessOptionMenu,
  BusinessTableContainer,
  BusinessTransferContainer
};
