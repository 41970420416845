import React, { useRef, FormEventHandler } from "react";
import {
  CancelButton,
  FilterModalFooter,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalInnerContainer,
  ModalRightFooter,
  SubTextContainer
} from "./styles";
import colors from "styles/colors";
import { AiOutlineClose } from "react-icons/ai";
import { FormikValues } from "formik";
import CustomButton from "components/CustomButton";

interface ModalProps {
  children?: React.ReactNode;
  width?: number;
  isWorking?: boolean;
  subText?: string;
  isHeader?: boolean;
  isModalFooter?: boolean;
  isMonoModalFooter?: boolean;
  submitButtonType?: "submit" | "button";
  isFooter?: boolean;
  title?: string;
  submitButtonText?: string;
  close?: () => void;
  isCloseIcon?: boolean;
  submitButtonOnClick?: () => void;
  footerInlineStyle?: { [key: string]: string };
  submitButtonForm?: string;
  submitButtonWidth?: number;
  submitButtonHeight?: number;
  submitButtonDisabled?: boolean;
  contentAreaScrollable?: boolean;
  contentAreaHeight?: number;
  proceed?: FormEventHandler<HTMLButtonElement>;
  isOnlyClose?: boolean;
  align?: string;
}

export default function Modal<
  P extends { children?: React.ReactNode | ((props: P) => React.ReactNode) }
>({
  children,
  subText,
  isHeader = true,
  isFooter = false,
  isMonoModalFooter = false,
  isModalFooter = false,
  isWorking = false,
  isCloseIcon = false,
  width = 450,
  title,
  close,
  submitButtonOnClick = () => undefined,
  submitButtonType = "submit",
  submitButtonDisabled = false,
  submitButtonText = "",
  submitButtonHeight = 50,
  submitButtonForm,
  contentAreaHeight = 600,
  contentAreaScrollable = false,
  submitButtonWidth,
  align
}: ModalProps) {
  const hasOneChild = (children: React.ReactNode) => {
    const childCount = React.Children.count(children);
    const isSingleChild = childCount === 1;
    return isSingleChild;
  };

  const hasNoChild = (children: React.ReactNode) => {
    const childCount = React.Children.count(children);
    const isZeroChild = childCount === 0;
    return isZeroChild;
  };

  const getFirstChild = (children: React.ReactNode) => {
    let firstChild = null;
    if (hasOneChild(children)) {
      firstChild = React.Children.toArray(children)[0];
    }
    return React.isValidElement(firstChild)
      ? firstChild
      : { type: "", props: {}, key: null, ref: null };
  };

  const firstChild = getFirstChild(children);
  const isFormik = String(firstChild?.type).includes("Formik");
  const formRef = useRef<FormikValues>();

  const renderChildren = (children: React.ReactNode) => {
    const noChild = hasNoChild(children);

    if (isFormik && typeof children === "object") {
      if (children !== null && "props" in children) {
        return React.cloneElement(children, { innerRef: formRef });
      }
    }
    return noChild ? null : children;
  };

  return (
    <ModalContainer>
      <ModalContent className="modal-content" widthSize={width}>
        {isHeader && (
          <ModalHeader>
            {align === "center" && <div></div>}
            <div>{title && title}</div>
            <div>
              {isCloseIcon && (
                <div className="close-icon" onClick={close}>
                  <AiOutlineClose />
                </div>
              )}
            </div>
          </ModalHeader>
        )}
        {subText && (
          <SubTextContainer isHeader={isHeader}>{subText}</SubTextContainer>
        )}

        <ModalInnerContainer
          id={"modal-inner-container"}
          scrollable={contentAreaScrollable}
          scrollableHeight={contentAreaHeight}
        >
          {renderChildren(children)}
        </ModalInnerContainer>
        {isFooter && (
          <>
            {align === "center" ? (
              <ModalRightFooter>
                <CancelButton onClick={close}>Cancel</CancelButton>
                <CustomButton
                  type={submitButtonType}
                  form={submitButtonForm}
                  disabled={submitButtonDisabled}
                  label={submitButtonText}
                  onClick={
                    submitButtonType === "button"
                      ? submitButtonOnClick
                      : undefined
                  }
                  height={submitButtonHeight}
                  width={submitButtonWidth}
                  isLoading={isWorking}
                />
              </ModalRightFooter>
            ) : (
              <FilterModalFooter>
                <CancelButton onClick={close}>Cancel</CancelButton>
                <CustomButton
                  type={submitButtonType}
                  form={submitButtonForm}
                  disabled={submitButtonDisabled}
                  label={submitButtonText}
                  onClick={
                    submitButtonType === "button"
                      ? submitButtonOnClick
                      : undefined
                  }
                  height={submitButtonHeight}
                  width={submitButtonWidth}
                  isLoading={isWorking}
                />
              </FilterModalFooter>
            )}
          </>
        )}
        {isModalFooter &&
          (isMonoModalFooter ? (
            <ModalFooter style={{ justifyContent: "center" }}>
              <CustomButton onClick={close} label="Close" height={40} />
            </ModalFooter>
          ) : (
            <ModalFooter>
              <CancelButton
                style={{
                  margin: "auto",
                  backgroundColor: colors.pri,
                  color: "#fff"
                }}
                onClick={close}
              >
                Close
              </CancelButton>
            </ModalFooter>
          ))}
      </ModalContent>
    </ModalContainer>
  );
}
