/**
 * fileSizeCheker:
 * 
 * @param file {File | Null | Undefined}
 * @returns {String}
 */
export const fileSizeChecker = (file?: File | null) => {
  if (file) {
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      return "File shouldn't be more than 5MB";
    }
  }
  return "";
};

/**
 * fileTypeChecker:
 * 
 * @param file {File | Null | Undefined}
 * @returns {String}
 */
export const fileTypeChecker = (file?: File | null): string => {
  const correcTypeSentinel = "";
  if (file instanceof File) {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/svg+xml" ||
      file.type === "application/pdf"
    ) {
      return correcTypeSentinel;
    } else {
      return "File should be either png, jpeg, jpg or pdf";
    }
  }
  return correcTypeSentinel;
};

/**
 * fileExtension:
 * 
 * @param urlOrFileType {String}
 * @returns {String}
 */
export const fileExtension = (urlOrFileType?: string | null): string => {
  let extension = "blob";
  if (
    urlOrFileType === "image/png" ||
    urlOrFileType === "image/jpeg" ||
    urlOrFileType === "image/jpg" ||
    urlOrFileType === "image/svg+xml" ||
    urlOrFileType === "application/pdf" ||
    urlOrFileType === "text/plain" ||
    urlOrFileType === "application/json" ||
    urlOrFileType === "text/javascript" ||
    urlOrFileType === "text/css" ||
    urlOrFileType === "text/csv" ||
    urlOrFileType === "text/x-csv" ||
    urlOrFileType === "application/vnd.ms-excel" ||
    urlOrFileType === "application/csv" ||
    urlOrFileType === "application/x-csv" ||
    urlOrFileType === "text/comma-separated-values" ||
    urlOrFileType === "text/x-comma-separated-values" ||
    urlOrFileType === "text/tab-separated-values" ||
    urlOrFileType === "application/octet-stream"
  ) {
    [ extension ] = (urlOrFileType || "/").split("/").reverse();
    if (extension === "octet-stream") {
      extension = "blob";
    }

    if ([
      "x-csv",
      "vnd.ms-excel",
      "tab-separated-values",
      "comma-separated-values",
      "x-comma-separated-values"].includes(extension)) {
      extension = "csv";
    }
  } else if (typeof urlOrFileType === "string") {
    const [ urlBaseName ] = urlOrFileType.split(/[#?]/);
    [ extension ] = urlBaseName.split(".").reverse();
  }
  return extension === "javascript" ? "js" : extension;
};
