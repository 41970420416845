import {
  BusinessIndustryInterface,
  BusinessTypeInterface,
  Director
} from "interfaces/directors";
import {
  BulkPayoutModel,
  BulkPayoutSummaryTableModel
} from "interfaces/payout";
import { Bank, BankAccountListInterface } from "interfaces/wallet";
import React from "react";
import {
  bankComplianceSentinel,
  directorsComplianceSentinel,
  documentsComplianceSentinel
} from "utils/constants";

const initState = {
  filterType: [] as string[],
  filterStatus: [] as string[],
  filterSyncStatus: [] as string[],
  bulkFilterStatus: [] as string[],
  approvalFilterStatus: [] as string[],
  filterCategory: [] as string[],
  filterAmount: "",
  activeComplianceOption: "",
  showBankSubSteps: false,
  isComplianceUpdate: false,
  showDirectorsSubSteps: false,
  showDocumentsSubSteps: false,
  listOfAddedBanks: [] as BankAccountListInterface[],
  listOfDirectors: [] as Director[],
  bulkPayoutPayload: {} as BulkPayoutModel | undefined,
  bulkPayoutSummaryTable: {} as BulkPayoutSummaryTableModel | undefined,
  listOfBanks: [] as Bank[],
  selectedComplianceOption: "",
  selectedBusinessType: "",
  isBusinessProfile: false,
  businessDetailsStep: [] as string[],
  currentBusinessDetailsStep: "",
  businessTypeId: "",
  industryId: "",
  proofOfAddressImg: "",
  identityDocumentImg: "",
  isPreview: false,
  activeBusinessIndustry: {} as BusinessIndustryInterface | undefined,
  activeBusinessType: {} as BusinessTypeInterface | undefined,
  // eslint-disable-next-line
  storeFilterType: (value: string[]) => {},
  // eslint-disable-next-line
  storeFilterStatus: (value: string[]) => {},
  // eslint-disable-next-line
  storeFilterSyncStatus: (value: string[]) => {},
  // eslint-disable-next-line
  storeBulkFilterStatus: (value: string[]) => {},
  // eslint-disable-next-line
  storeApprovalFilterStatus: (value: string[]) => {},
  // eslint-disable-next-line
  storeFilterCategory: (value: string[]) => {},
  // eslint-disable-next-line
  storeFilterAmount: (value: string) => {},
  // eslint-disable-next-line
  storeActiveComplianceOption: (value: string) => {},
  // eslint-disable-next-line
  storeListOfAddedBanks: (value: BankAccountListInterface[]) => {},
  // eslint-disable-next-line
  storeListOfDirectors: (value: Director[]) => {},
  // eslint-disable-next-line
  storeBulkPayoutPayload: (value: BulkPayoutModel) => {},
  // eslint-disable-next-line
  storeListOfBanks: (value: Bank[]) => {},
  // eslint-disable-next-line
  showCurrentSteps: (value: string) => {},
  // eslint-disable-next-line
  storeBulkPayoutSummaryTable: (value: BulkPayoutSummaryTableModel) => {},
  // eslint-disable-next-line
  storeComplianceUpdate: (value: boolean) => {},
  // eslint-disable-next-line
  storeSelectedComplianceOption: (value: string) => {},
  // eslint-disable-next-line
  storeIsBusinessProfile: (value: boolean) => {},
  // eslint-disable-next-line
  storeBusinessDetailsStep: (value: string) => {},
  // eslint-disable-next-line
  storeSelectedBusinessType: (value: string) => {},
  // eslint-disable-next-line
  storeCurrentBusinessDetailsStep: (value: string) => {},
  // eslint-disable-next-line
  storeBusinessTypeId: (value: string) => {},
  // eslint-disable-next-line
  storeIndustryId: (value: string) => {},
  // eslint-disable-next-line
  storeProofOfAddressImg: (value: string) => {},
  // eslint-disable-next-line
  storeIdentityDocumentImg: (value: string) => {},
  // eslint-disable-next-line
  storeIsPreview: (value: boolean) => {},
  // eslint-disable-next-line
  storeActiveBusinessIndustry: (value: BusinessIndustryInterface) => {},
  // eslint-disable-next-line
  storeActiveBusinessType: (value: BusinessTypeInterface) => {}
};

const TransactionContext = React.createContext(initState);
export const TransactionProvider = TransactionContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
  status: string[];
  bulkStatus: string[];
  syncStatus: string[];
  approvalStatus: string[];
  type: string[];
  amount: string;
  category: string[];
};

export const TransactionProviderContainer: React.FC<Props> = ({
  children,
  status,
  bulkStatus,
  syncStatus,
  approvalStatus,
  type,
  category,
  amount
}) => {
  const [filterType, setFilterType] = React.useState<string[]>(type);

  const [filterAmount, setFilterAmount] = React.useState<string>(amount);

  const [filterStatus, setFilterStatus] = React.useState<string[]>(status);
  const [filterSyncStatus, setFilterSyncStatus] =
    React.useState<string[]>(syncStatus);

  const [bulkFilterStatus, setBulkFilterStatus] =
    React.useState<string[]>(bulkStatus);

  const [approvalFilterStatus, setApprovalFilterStatus] =
    React.useState<string[]>(approvalStatus);

  const [bulkPayoutSummaryTable, setBulkPayoutSummaryTable] =
    React.useState<BulkPayoutSummaryTableModel>();

  const [filterCategory, setFilterCategory] =
    React.useState<string[]>(category);

  const [activeComplianceOption, setActiveComplianceOption] =
    React.useState<string>("bank");
  const [showBankSubSteps, setShowBankSubSteps] =
    React.useState<boolean>(false);
  const [showDirectorsSubSteps, setShowDirectorsSubSteps] =
    React.useState<boolean>(false);
  const [showDocumentsSubSteps, setShowDocumentsSubSteps] =
    React.useState<boolean>(false);
  const [listOfAddedBanks, setListOfAddedBanks] = React.useState<
    BankAccountListInterface[]
  >([]);

  const [listOfDirectors, setListOfDirectors] = React.useState<Director[]>([]);

  const [listOfBanks, setListOfBanks] = React.useState<Bank[]>([]);
  const [isComplianceUpdate, seIsComplianceUpdate] =
    React.useState<boolean>(false);

  const [bulkPayoutPayload, setBulkPayoutPayload] =
    React.useState<BulkPayoutModel>();

  const [selectedComplianceOption, setSelectedComplianceOption] =
    React.useState<string>("overview");

  const [isBusinessProfile, setIsBusinessProfile] =
    React.useState<boolean>(false);

  const [businessDetailsStep, setBusinessDetailsStep] = React.useState<
    string[]
  >([]);

  const [selectedBusinessType, setSelectedBusinessType] =
    React.useState<string>("");

  const [currentBusinessDetailsStep, setCurrentBusinessDetailsStep] =
    React.useState<string>(bankComplianceSentinel);

  const [businessTypeId, setBusinessTypeId] = React.useState<string>("");
  const [industryId, setIndustryId] = React.useState<string>("");
  const [proofOfAddressImg, setProofOfAddressImg] = React.useState<string>("");
  const [identityDocumentImg, setIdentityDocumentImg] =
    React.useState<string>("");

  const [isPreview, setIsPreview] = React.useState<boolean>(false);

  const [activeBusinessIndustry, setActiveBusinessIndustry] =
    React.useState<BusinessIndustryInterface>();

  const [activeBusinessType, setActiveBusinessType] =
    React.useState<BusinessTypeInterface>();

  const storeIsPreview = (value: boolean) => {
    setIsPreview(value);
  };

  const storeProofOfAddressImg = (value: string) => {
    if (value) {
      setProofOfAddressImg(value);
    }
  };

  const storeIdentityDocumentImg = (value: string) => {
    if (value) {
      setIdentityDocumentImg(value);
    }
  };

  const storeActiveBusinessIndustry = (value: BusinessIndustryInterface) => {
    if (value) {
      setActiveBusinessIndustry(value);
    }
  };

  const storeActiveBusinessType = (value: BusinessTypeInterface) => {
    if (value) {
      setActiveBusinessType(value);
    }
  };

  const storeIndustryId = (value: string) => {
    if (value) {
      setIndustryId(value);
    }
  };

  const storeBusinessTypeId = (value: string) => {
    if (value) {
      setBusinessTypeId(value);
    }
  };

  const storeCurrentBusinessDetailsStep = (value: string) => {
    if (value) {
      setCurrentBusinessDetailsStep(value);
    }
  };

  const storeSelectedBusinessType = (value: string) => {
    if (value) {
      setSelectedBusinessType(value);
    }
  };

  const storeBusinessDetailsStep = (value: string) => {
    if (value) {
      const steps = [...businessDetailsStep];
      if (!steps.includes(value)) {
        steps.push(value);
      }
      setBusinessDetailsStep(steps);
    }
  };

  const storeSelectedComplianceOption = (value: string) => {
    if (value) {
      setSelectedComplianceOption(value);
    }
  };

  const storeFilterType = (value: string[]) => {
    if (value) {
      setFilterType(value);
    }
  };

  const storeBulkPayoutSummaryTable = (value: BulkPayoutSummaryTableModel) => {
    if (value) {
      setBulkPayoutSummaryTable(value);
    }
  };

  const storeListOfBanks = (value: Bank[]) => {
    setListOfBanks(value);
  };

  const storeBulkPayoutPayload = (value: BulkPayoutModel) => {
    if (value) {
      setBulkPayoutPayload(value);
    }
  };

  const storeFilterStatus = (value: string[]) => {
    if (value) {
      setFilterStatus(value);
    }
  };
  const storeFilterSyncStatus = (value: string[]) => {
    if (value) {
      setFilterSyncStatus(value);
    }
  };

  const storeBulkFilterStatus = (value: string[]) => {
    if (value) {
      setBulkFilterStatus(value);
    }
  };

  const storeApprovalFilterStatus = (value: string[]) => {
    if (value) {
      setApprovalFilterStatus(value);
    }
  };

  const storeFilterCategory = (value: string[]) => {
    if (value) {
      setFilterCategory(value);
    }
  };

  const storeFilterAmount = (value: string) => {
    if (value) {
      setFilterAmount(value);
    } else {
      setFilterAmount("");
    }
  };
  const showCurrentSteps = (value: string) => {
    switch (value) {
      case bankComplianceSentinel:
        setShowBankSubSteps(!showBankSubSteps);
        break;
      case directorsComplianceSentinel:
        setShowDirectorsSubSteps(!showDirectorsSubSteps);
        break;
      case documentsComplianceSentinel:
        setShowDocumentsSubSteps(!showDocumentsSubSteps);
        break;
      default:
        return;
    }
  };

  const storeComplianceUpdate = (value: boolean) => {
    if (value) {
      seIsComplianceUpdate(value);
    }
  };

  const storeIsBusinessProfile = (value: boolean) => {
    setIsBusinessProfile(value);
  };

  const storeActiveComplianceOption = (value: string) => {
    if (value) {
      setActiveComplianceOption(value);
      showCurrentSteps(value);
    } else {
      setActiveComplianceOption("");
    }
  };

  const storeListOfAddedBanks = (value: BankAccountListInterface[]) => {
    if (value) {
      setListOfAddedBanks(value);
    } else {
      setListOfAddedBanks([]);
    }
  };

  const storeListOfDirectors = (value: Director[]) => {
    if (value) {
      setListOfDirectors(value);
    } else {
      setListOfDirectors([]);
    }
  };

  return (
    <TransactionProvider
      value={{
        filterType,
        filterStatus,
        filterSyncStatus,
        bulkFilterStatus,
        approvalFilterStatus,
        filterCategory,
        filterAmount,
        activeComplianceOption,
        listOfAddedBanks,
        isComplianceUpdate,
        listOfDirectors,
        listOfBanks,
        bulkPayoutPayload,
        bulkPayoutSummaryTable,
        selectedComplianceOption,
        isBusinessProfile,
        businessDetailsStep,
        selectedBusinessType,
        currentBusinessDetailsStep,
        businessTypeId,
        industryId,
        proofOfAddressImg,
        identityDocumentImg,
        isPreview,
        activeBusinessIndustry,
        activeBusinessType,
        storeFilterType,
        storeFilterStatus,
        storeFilterSyncStatus,
        storeBulkFilterStatus,
        storeApprovalFilterStatus,
        storeFilterCategory,
        storeFilterAmount,
        storeActiveComplianceOption,
        storeListOfAddedBanks,
        storeListOfDirectors,
        storeBulkPayoutPayload,
        storeListOfBanks,
        storeSelectedComplianceOption,
        showBankSubSteps,
        showDirectorsSubSteps,
        showDocumentsSubSteps,
        showCurrentSteps,
        storeBulkPayoutSummaryTable,
        storeComplianceUpdate,
        storeIsBusinessProfile,
        storeBusinessDetailsStep,
        storeSelectedBusinessType,
        storeCurrentBusinessDetailsStep,
        storeBusinessTypeId,
        storeIndustryId,
        storeProofOfAddressImg,
        storeIsPreview,
        storeActiveBusinessIndustry,
        storeActiveBusinessType,
        storeIdentityDocumentImg
      }}
    >
      {children}
    </TransactionProvider>
  );
};

export default TransactionContext;
