import { ReactComponent as Drawer } from "assets/svg/mobile-drawer.svg";
import {
  BusinessInterface,
  ITeamMemberResponseObject,
  UserActionStatus,
  UserPermissionPages
} from "interfaces/users";
import { PageStateCustomerWalletResponse } from "interfaces/wallet";
import { PageIdentifierContainer } from "layout/AppLayout/styles";
import { useFeatureToggle, useMediaQuery } from "lib/hooks";
import { SweepButton } from "pages/Business/styles";
import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { StaticContext } from "react-router";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PersonaDropdown from "routes/PersonaDropdown/PersonaDropdown";
import { device } from "styles";
import colors from "styles/colors";
import { decrypt_user0bj, handleLogout } from "utils/auth-util";
import { defaultBusinessKey, memberKey, profileMenu } from "utils/constants";
import { componentLoader, lazyWithRetry } from "utils/import-utils";
import storage from "utils/storage";
import { truncateString } from "utils/text-util";
import HeadwayWidget from "./HeadwayWidget/HeadwayWidget";
import { Routes } from "./routes-constants";
import QuickActions from "./QuickActions/QuickActions";
import { accessGranted, isPermiitedToAccessPage } from "utils/roles";
import Restricted from "layout/RestrictedLayout";
import DepartmentPreview from "pages/Settings/DepartmentPreview";
import ExpenseDetails from "pages/Expense/expenseDetails";

// Authentication
const Dashboard = lazyWithRetry(() =>
  componentLoader(() => import("pages/Dashboard"))
);
const ApproverDasboard = lazyWithRetry(() =>
  componentLoader(() => import("pages/Dashboard/ApproverDashboard"))
);
const Login = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/Login"))
);
const Signup = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/Signup"))
);
const ActivateMember = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/ActivateMember"))
);
const Verification = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/Verfication"))
);
const VerifyToken = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/VerifyToken"))
);
const ForgotPassword = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/ForgotPassword"))
);
const ResetPassword = lazyWithRetry(() =>
  componentLoader(() => import("pages/Auth/ResetPassword"))
);

// BNPL
const Checkout = lazyWithRetry(() =>
  componentLoader(() => import("pages/Checkout/index"))
);

//Transaction
const Transaction = lazyWithRetry(() =>
  componentLoader(() => import("pages/Transaction"))
);
const Bussiness = lazyWithRetry(() =>
  componentLoader(() => import("pages/Business"))
);

//Wallets (ALL PAGE COMPONENETS TO BE DELETED - 27/12/2023)
const Wallets = lazyWithRetry(() =>
  componentLoader(() => import("pages/Wallets"))
);
const WalletDetails = lazyWithRetry(() =>
  componentLoader(() => import("pages/Wallets/WalletDetails"))
);
const CreateWallet = lazyWithRetry(() =>
  componentLoader(() => import("pages/Wallets/NewWallets"))
);
const BulkUpload = lazyWithRetry(() =>
  componentLoader(() => import("pages/Wallets/BulkUpload"))
);

//Invoice
const Invoice = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice"))
);
const CreateInvoice = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice/NewInvoiceForm"))
);
const EditInvoice = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice/NewInvoiceForm"))
);
const PreviewInvoice = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice/PreviewInvoice"))
);
const InvoiceLink = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice/InvoiceLink"))
);
const RevisedInvoiceLink = lazyWithRetry(() =>
  componentLoader(() => import("pages/Invoice/RevisedInvoice"))
);

//Customers
const Customers = lazyWithRetry(() =>
  componentLoader(() => import("pages/Customers"))
);
const CustomerDetails = lazyWithRetry(() =>
  componentLoader(() => import("pages/Customers/CustomerDetails"))
);

const CustomerKYCDetails = lazyWithRetry(() =>
  componentLoader(() => import("pages/Customers/CustomerKYCDetails"))
);
const CreateCustomer = lazyWithRetry(() =>
  componentLoader(() => import("pages/Customers/CreateCustomer"))
);
const CustomerBulkUpload = lazyWithRetry(() =>
  componentLoader(() => import("pages/Customers/CustomerBulkUpload"))
);

//Compliance
const Compliance = lazyWithRetry(() =>
  componentLoader(() => import("pages/Compliance"))
);
const ComplianceUpdate = lazyWithRetry(() =>
  componentLoader(() => import("pages/Compliance/ComplianceUpdate"))
);

//Virtual
const Virtual = lazyWithRetry(() =>
  componentLoader(() => import("pages/Virtual"))
);

//Profile
const Profile = lazyWithRetry(() =>
  componentLoader(() => import("pages/Profile"))
);
const Security = lazyWithRetry(() =>
  componentLoader(() => import("pages/Security"))
);
const GoogleAuth = lazyWithRetry(() =>
  componentLoader(() => import("pages/Security/GoogleAuthSetup"))
);

//Settings
const Settings = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings"))
);
const Permissions = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings/Permissions"))
);
const NewPolicy = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings/NewPolicy"))
);
const AddTeamMember = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings/MemberInvites"))
);
const EditTeamMember = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings/MemberRoleEdits"))
);
const PayoutsSettings = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/PayoutsSettings"))
);
const CreateRole = lazyWithRetry(() =>
  componentLoader(() => import("pages/Settings/NewRole"))
);
//Recipients
const Recipients = lazyWithRetry(() =>
  componentLoader(() => import("pages/Recipients"))
);
const CreateRecipient = lazyWithRetry(() =>
  componentLoader(() => import("pages/Recipients/CreateRecipient"))
);
const RecipientsPreview = lazyWithRetry(() =>
  componentLoader(() => import("pages/Recipients/RecipientPreview"))
);
const RecipientBillPreview = lazyWithRetry(() =>
  componentLoader(() => import("pages/Recipients/BillsPreview"))
);

//Payout
const Payout = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout"))
);
const NewPayout = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/NewPayout"))
);
const NewBulkPayout = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/NewBulkPayout"))
);
const BulkPayoutDetails = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/BulkPayoutDetails"))
);
const BulkPayoutSummary = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/PreviewBulkPayoutSummary"))
);
const BulkPayoutReview = lazyWithRetry(() =>
  componentLoader(() => import("pages/Payout/PreviewBulkPayout"))
);

// International
const International = lazyWithRetry(() =>
  componentLoader(() => import("pages/International"))
);
const NewInternationalPayment = lazyWithRetry(() =>
  componentLoader(() => import("pages/International/NewInternationalPayment"))
);

const PreviewInternationalPayment = lazyWithRetry(() =>
  componentLoader(() => import("pages/International/PreviewPaymentOnCreation"))
);

// Approvals
const Approvals = lazyWithRetry(() =>
  componentLoader(() => import("pages/Approvals"))
);
const ApprovalsPreview = lazyWithRetry(() =>
  componentLoader(() => import("pages/Approvals/ApprovalPreview"))
);

// Requisitions
const Requisitions = lazyWithRetry(() =>
  componentLoader(() => import("pages/Expense/index"))
);

const NewRequisitions = lazyWithRetry(() =>
  componentLoader(() => import("pages/Expense/newExpense"))
);

const RequestDetails = lazyWithRetry(() =>
  componentLoader(() => import("pages/Expense/requestDetails"))
);

// Automation
const Apps = lazyWithRetry(() => componentLoader(() => import("pages/Apps")));
const AppsPreview = lazyWithRetry(() =>
  componentLoader(() => import("pages/Apps/AppsPreview"))
);

//Bills
const Bills = lazyWithRetry(() => componentLoader(() => import("pages/Bills")));

const NewBill = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/NewBill"))
);

const PreviewBill = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/PreviewBillOnCreation"))
);

const ViewBill = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/BillsApprovalPreview"))
);

const BillTransaction = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/BillsTransactions"))
);

const PayBill = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/PayBills"))
);

const AllBillApprovals = lazyWithRetry(() =>
  componentLoader(() => import("pages/Bills/AllBillApprovals"))
);

const Balances = lazyWithRetry(() =>
  componentLoader(() => import("pages/Balances"))
);

const EditBalanceAccounts = lazyWithRetry(() =>
  componentLoader(() => import("pages/Balances/EditBalances"))
);

const PreviewDocuments = lazyWithRetry(() =>
  componentLoader(() => import("pages/Recipients/DocumentsApprovalPreview"))
);
const NotFoundPage = lazyWithRetry(() =>
  componentLoader(() => import("pages/404"))
);

type ReactFunctionalComponent = () => JSX.Element | null;

export interface RoutesInterface {
  path: string;
  exact: boolean;
  isOtherModule?: boolean;
  isPrivate: boolean;
  isOpenInvoice?: boolean;
  component:
    | React.LazyExoticComponent<ReactFunctionalComponent>
    | ReactFunctionalComponent;
}

interface DecoratedComponentProps<T = object>
  extends Pick<RoutesInterface, "path"> {
  Title: React.FC<
    Pick<RouteComponentProps<{}, StaticContext, T>, "history"> & {
      mobileEnv?: boolean;
      roleName?: string;
      isApprover?: boolean;
    }
  > | null;
  TitleBar: React.FC<
    Pick<RouteComponentProps<{}, StaticContext, T>, "history"> & {
      mobileEnv?: boolean;
    }
  > | null;
  renderProp: (
    routePath: string,
    permission: string,
    user?: ITeamMemberResponseObject
  ) => JSX.Element | null;
}

const DecoratedComponent: React.FC<DecoratedComponentProps> = ({
  renderProp,
  path,
  Title,
  TitleBar
}) => {
  const isLaptop = useMediaQuery(device.laptop);

  /* @HINT: Get the merchant business details attached to the logged-in user account */
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

  /* @HINT: Get the merchant member details for the logged-in user account */
  const memberInfo: ITeamMemberResponseObject =
    decrypt_user0bj<ITeamMemberResponseObject>(storage.get(memberKey));

  const isMemberUser = !(
    memberInfo &&
    Object.keys(memberInfo).length === 0 &&
    Object.getPrototypeOf(memberInfo) === Object.prototype
  );
  const isMemberBusinessOwnerOnly =
    !isMemberUser &&
    (defaultBusinessInfo?.role?.name === UserActionStatus.owner ||
      defaultBusinessInfo?.role?.name === undefined);
  const isMemberApproverOnly =
    isMemberUser &&
    defaultBusinessInfo?.role?.name === UserActionStatus.approver;
  const isMemberAdminOnly =
    isMemberUser && defaultBusinessInfo?.role?.name === UserActionStatus.admin;

  const history = useHistory<object>();
  const features = useFeatureToggle();
  const isMobile = useMediaQuery(device.mobile_);
  const allBusinessLiveStatus = JSON.parse(
    window.sessionStorage.getItem("allBusinessLiveStatus") || "{}"
  ) as { [key: string]: boolean };

  return (
    <>
      <PageIdentifierContainer
        topIndent={Boolean(
          defaultBusinessInfo.display_state !== "test" ||
            (allBusinessLiveStatus[defaultBusinessInfo.id] && true)
        )}
        topInsetType={isMobile ? "extra" : "basic"}
        isApprover={isMemberApproverOnly}
      >
        {Title && (
          <Title
            history={history}
            mobileEnv={isMobile}
            roleName={defaultBusinessInfo?.role?.name || "Owner"}
            isApprover={isMemberApproverOnly}
          >
            {isMobile ? (
              <Drawer
                onClick={() => {
                  const pageRoot = document.documentElement as HTMLHtmlElement;
                  if (pageRoot.classList.contains("mobile-menu-show")) {
                    pageRoot.classList.remove("mobile-menu-show");
                  } else {
                    pageRoot.classList.add("mobile-menu-show");
                  }
                }}
              />
            ) : null}
          </Title>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {(isMemberAdminOnly || isMemberBusinessOwnerOnly) && (
            <QuickActions history={history} />
          )}
          <div id="headway-badge" style={{ marginRight: "5px" }}>
            <HeadwayWidget />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {TitleBar && <TitleBar history={history} mobileEnv={!isLaptop} />}
            {(features.isEnabledFor(profileMenu) && (
              <PersonaDropdown link={Routes.Profile} />
            )) ||
              null}
          </div>
        </div>
      </PageIdentifierContainer>
      {renderProp(path, defaultBusinessInfo?.role?.name || "Owner", memberInfo)}
    </>
  );
};

const choosePageToRender = (
  routePath: string,
  permission: string,
  user?: ITeamMemberResponseObject
) => {
  const invalidRoutingActionComponent = () => {
    /* @NOTE: This component renders `null` (nothing) and
      logs the currently logged-in user out and displays
      an access control message in a dismissable dialog
      on the `pages/Auth/Login.tsx` page. */
    handleLogout(false, true);
    return null;
  };
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

  /* @HINT: Get the merchant member details for the logged-in user account */
  const memberInfo: ITeamMemberResponseObject =
    decrypt_user0bj<ITeamMemberResponseObject>(storage.get(memberKey));

  const isMemberUser = !(
    memberInfo &&
    Object.keys(memberInfo).length === 0 &&
    Object.getPrototypeOf(memberInfo) === Object.prototype
  );
  const isMemberCustomRoleOnly =
    isMemberUser && Boolean(defaultBusinessInfo?.role?.business_id);

  const isPageAccessGranted = (page: string) => {
    return isPermiitedToAccessPage(
      defaultBusinessInfo?.role?.permissions,
      page
    );
  };

  if (routePath === Routes.Dashboard) {
    switch (permission) {
      case UserActionStatus.approver:
        return <ApproverDasboard />;
      default:
        return <Dashboard />;
    }
  }

  if (routePath === Routes.Bussiness) {
    if (
      isMemberCustomRoleOnly &&
      isPageAccessGranted(UserPermissionPages.business)
    ) {
      return <Bussiness />;
    } else {
      switch (permission) {
        case UserActionStatus.approver:
          /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Business page */
          return invalidRoutingActionComponent();
        default:
          return <Bussiness />;
      }
    }
  }

  if (routePath === Routes.Approvals) {
    switch (permission) {
      case UserActionStatus.owner:
      case UserActionStatus.admin:
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `admin` & `owner` only can view 
            Approvals page */
        return <Approvals />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.PreviewApproval) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.owner:
      case UserActionStatus.admin:
      case UserActionStatus.payer:
      case UserActionStatus.initiator:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` ..... only can view ApprovalsPreview page */
        return <ApprovalsPreview />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.PreviewBApproval) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.owner:
      case UserActionStatus.admin:
      case UserActionStatus.payer:
      case UserActionStatus.initiator:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` ..... only can view View Bill page */
        return <ViewBill />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  // Requisitions
  if (routePath === Routes.Requisitions) {
    switch (permission) {
      case UserActionStatus.submitter:
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `admin` & `owner` only can view 
            Approvals page */
        return <Requisitions />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.NewRequisitions) {
    switch (permission) {
      case UserActionStatus.submitter:
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `admin` & `owner` only can view 
            Approvals page */
        return <NewRequisitions />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.RequisitionsDetails) {
    switch (permission) {
      case UserActionStatus.submitter:
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `admin` & `owner` only can view 
            Approvals page */
        return <RequestDetails />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.ExpenseDetails) {
    switch (permission) {
      case UserActionStatus.submitter:
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `admin` & `owner` only can view 
            Approvals page */
        return <ExpenseDetails />;
      default:
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.Apps) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.payer:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Apps page */
        return invalidRoutingActionComponent();
      default:
        return <Apps />;
    }
  }
  if (routePath === Routes.Expenses) {
    switch (permission) {
      // case UserActionStatus.approver:
      // case UserActionStatus.payer:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Apps page */
        return invalidRoutingActionComponent();
      default:
        return <Balances />;
    }
  }
  if (routePath === Routes.EditBalances) {
    switch (permission) {
      case UserActionStatus.approver:
      // case UserActionStatus.payer:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Apps page */
        return invalidRoutingActionComponent();
      default:
        return <EditBalanceAccounts />;
    }
  }

  if (routePath === Routes.AppsPreview) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.payer:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view AppsPreview page */
        return invalidRoutingActionComponent();
      default:
        return <AppsPreview />;
    }
  }

  if (routePath === Routes.Payout) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Payout page */
        return invalidRoutingActionComponent();
      default:
        return <Payout />;
    }
  }

  if (routePath === Routes.NewPayout) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view New Payout page */
        return invalidRoutingActionComponent();
      default:
        return <NewPayout />;
    }
  }

  if (routePath === Routes.NewBulkPayout) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view New Bulk Payout page */
        return invalidRoutingActionComponent();
      default:
        return <NewBulkPayout />;
    }
  }

  if (routePath === Routes.BulkPayoutDetails) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Bulk Payout Details page */
        return invalidRoutingActionComponent();
      default:
        return <BulkPayoutDetails />;
    }
  }

  if (routePath === Routes.BulkPayoutSummary) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Bulk Payout Summary page */
        return invalidRoutingActionComponent();
      default:
        return <BulkPayoutSummary />;
    }
  }

  if (routePath === Routes.BulkPayoutReview) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Bulk Payout Review page */
        return invalidRoutingActionComponent();
      default:
        return <BulkPayoutReview />;
    }
  }

  if (routePath === Routes.Wallets) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Wallet page */
        return invalidRoutingActionComponent();
      default:
        return <Wallets />;
    }
  }

  if (routePath === Routes.WalletsUpload) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Wallets Upload page */
        return invalidRoutingActionComponent();
      default:
        return <BulkUpload />;
    }
  }

  if (routePath === Routes.CreateWallet) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Create Wallet page */
        return invalidRoutingActionComponent();
      default:
        return <CreateWallet />;
    }
  }

  if (routePath === Routes.Invoice) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Invoice page */
        return invalidRoutingActionComponent();
      default:
        return <Invoice />;
    }
  }

  if (routePath === Routes.PreviewInvoice) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Preview Invoice page */
        return invalidRoutingActionComponent();
      default:
        return <PreviewInvoice />;
    }
  }

  if (routePath === Routes.CreateInvoice) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Create Invoice page */
        return invalidRoutingActionComponent();
      default:
        return <CreateInvoice />;
    }
  }

  if (routePath === Routes.EditInvoice) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Edit Invoice page */
        return invalidRoutingActionComponent();
      default:
        return <EditInvoice />;
    }
  }

  if (routePath === Routes.RevisedInvoice) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Revised Invoice page */
        return invalidRoutingActionComponent();
      default:
        return <RevisedInvoiceLink />;
    }
  }

  if (routePath === Routes.International) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view International Remittances page */
        return invalidRoutingActionComponent();
      default:
        return <International />;
    }
  }
  if (routePath === Routes.NewInternationalPayment) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view International Remittances page */
        return invalidRoutingActionComponent();
      default:
        return <NewInternationalPayment />;
    }
  }
  if (routePath === Routes.PreviewInternationalPayment) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Preview Create Bill page */
        return invalidRoutingActionComponent();
      default:
        return <PreviewInternationalPayment />;
    }
  }

  if (routePath === Routes.Transaction) {
    if (
      isPageAccessGranted(UserPermissionPages.transactions) &&
      accessGranted(UserPermissionPages.transactions, "view_all_transactions")
    ) {
      return <Transaction />;
    } else if (
      !(
        isPageAccessGranted(UserPermissionPages.transactions) &&
        accessGranted(UserPermissionPages.transactions, "view_all_transactions")
      )
    ) {
      return invalidRoutingActionComponent();
    } else {
      return <Transaction />;
    }
  }

  if (routePath === Routes.Recipients) {
    if (
      isMemberCustomRoleOnly &&
      isPageAccessGranted(UserPermissionPages.recipients) &&
      accessGranted(UserPermissionPages.recipients, "view_all_recipients")
    ) {
      return <Recipients />;
    } else {
      switch (permission) {
        case UserActionStatus.approver:
          /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Recipients page */
          return invalidRoutingActionComponent();
        default:
          return <Recipients />;
      }
    }
  }

  if (routePath === Routes.CreateRecipient) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Create Recipients page */
        return invalidRoutingActionComponent();
      default:
        return <CreateRecipient />;
    }
  }

  if (routePath === Routes.RecipientPreview) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Recipients Preview page */
        return invalidRoutingActionComponent();
      default:
        return <RecipientsPreview />;
    }
  }

  if (routePath === Routes.RecipientBillPreview) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Recipient Bills Preview page */
        return invalidRoutingActionComponent();
      default:
        return <RecipientBillPreview />;
    }
  }

  if (routePath === Routes.Bills) {
    if (
      isMemberCustomRoleOnly &&
      isPageAccessGranted(UserPermissionPages.bills)
    ) {
      return <Bills />;
    } else {
      if (
        Boolean(user?.composite_payer) ||
        permission === UserActionStatus.payer
      ) {
        return Boolean(user?.composite_payer) ? (
          <Bills />
        ) : (
          <AllBillApprovals />
        );
      }

      switch (permission) {
        case UserActionStatus.approver:
        case UserActionStatus.view_only:
          /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Bills page */
          return invalidRoutingActionComponent();
        default:
          return [
            UserActionStatus.admin as string,
            UserActionStatus.owner as string,
            UserActionStatus.initiator as string
          ].includes(permission) ? (
            <Bills />
          ) : (
            invalidRoutingActionComponent()
          );
      }
    }
  }

  if (routePath === Routes.PayBill) {
    if (
      Boolean(user?.composite_payer) ||
      permission === UserActionStatus.payer
    ) {
      return <PayBill />;
    }

    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
      case UserActionStatus.initiator:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` & `initiator` cannot view 
            Pay Bill page */
        return invalidRoutingActionComponent();
      default:
        return [
          UserActionStatus.admin as string,
          UserActionStatus.owner as string
        ].includes(permission) ? (
          <PayBill />
        ) : (
          invalidRoutingActionComponent()
        );
    }
  }

  if (routePath === Routes.CreateBill) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Create Bill page */
        return invalidRoutingActionComponent();
      default:
        return [
          UserActionStatus.admin as string,
          UserActionStatus.owner as string,
          UserActionStatus.initiator as string
        ].includes(permission) ? (
          <NewBill />
        ) : (
          invalidRoutingActionComponent()
        );
    }
  }

  if (routePath === Routes.BillsDetail) {
    switch (permission) {
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `view_only` cannot view Bills Detail page */
        return invalidRoutingActionComponent();
      default:
        return [
          UserActionStatus.admin as string,
          UserActionStatus.owner as string,
          UserActionStatus.payer as string,
          UserActionStatus.approver as string,
          UserActionStatus.initiator as string
        ].includes(permission) ? (
          <ViewBill />
        ) : (
          invalidRoutingActionComponent()
        );
    }
  }

  if (routePath === Routes.BillTransactions) {
    if (
      Boolean(user?.composite_payer) ||
      permission === UserActionStatus.payer
    ) {
      return <BillTransaction />;
    }

    switch (permission) {
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Bill
            Transactions page */
        return invalidRoutingActionComponent();
      default:
        return [
          UserActionStatus.admin as string,
          UserActionStatus.owner as string,
          UserActionStatus.initiator as string,
          UserActionStatus.approver as string
        ].includes(permission) ? (
          <BillTransaction />
        ) : (
          invalidRoutingActionComponent()
        );
    }
  }

  if (routePath === Routes.PreviewBill) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Preview Create Bill page */
        return invalidRoutingActionComponent();
      default:
        return [
          UserActionStatus.admin as string,
          UserActionStatus.owner as string,
          UserActionStatus.initiator as string
        ].includes(permission) ? (
          <PreviewBill />
        ) : (
          invalidRoutingActionComponent()
        );
    }
  }

  if (routePath === Routes.RecipientDocumentsPreview) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Recipient Bills Preview page */
        return invalidRoutingActionComponent();
      default:
        return <PreviewDocuments />;
    }
  }
  if (routePath === Routes.ApprovalDocumentsPreview) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Recipient Bills Preview page */
        return invalidRoutingActionComponent();
      default:
        return <PreviewDocuments />;
    }
  }

  if (routePath === Routes.Virtual) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Virual Accounts page */
        return invalidRoutingActionComponent();
      default:
        return <Virtual />;
    }
  }

  if (routePath === Routes.Customers) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Customers page */
        return invalidRoutingActionComponent();
      default:
        return <Customers />;
    }
  }

  if (routePath === Routes.CreateCustomer) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Create Customers page */
        return invalidRoutingActionComponent();
      default:
        return <CreateCustomer />;
    }
  }

  if (routePath === Routes.CustomerDetails) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Customer Details page */
        return invalidRoutingActionComponent();
      default:
        return <CustomerDetails />;
    }
  }

  if (routePath === Routes.CustomerKYCDetails) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Customer Details page */
        return invalidRoutingActionComponent();
      default:
        return <CustomerKYCDetails />;
    }
  }
  if (routePath === Routes.CustomerBulkUpload) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Customer Bulk Uploads page */
        return invalidRoutingActionComponent();
      default:
        return <CustomerBulkUpload />;
    }
  }

  if (routePath === Routes.Profile) {
    return <Profile />;
  }

  if (routePath === Routes.Security) {
    switch (permission) {
      case UserActionStatus.owner:
      case UserActionStatus.admin:
      case UserActionStatus.approver:
      case UserActionStatus.payer:
      case UserActionStatus.initiator:
        return <Security />;
      default:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `view_only` cannot view Security page */
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.Permissions) {
    switch (permission) {
      case UserActionStatus.owner:
      case UserActionStatus.admin:
      case UserActionStatus.approver:
      case UserActionStatus.payer:
      case UserActionStatus.initiator:
        return <Permissions />;
      default:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `view_only` cannot view Permissions page */
        return invalidRoutingActionComponent();
    }
  }

  if (routePath === Routes.Settings) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
      case UserActionStatus.payer:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver`, `view_only` & `payer` cannot view Settings page */
        return invalidRoutingActionComponent();
      default:
        return <Settings />;
    }
  }
  if (routePath === Routes.CreateRole) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
      case UserActionStatus.payer:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver`, `view_only` & `payer` cannot view Settings page */
        return invalidRoutingActionComponent();
      default:
        return <CreateRole />;
    }
  }

  if (routePath === Routes.Compliance) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.initiator:
      case UserActionStatus.view_only:
      case UserActionStatus.admin:
      case UserActionStatus.payer:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `intiator` & `admin` & `view_only` 
            cannot view Complaince page */
        return invalidRoutingActionComponent();
      default:
        return <Compliance />;
    }
  }

  if (routePath === Routes.ComplianceUpdate) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.initiator:
      case UserActionStatus.view_only:
      case UserActionStatus.admin:
      case UserActionStatus.payer:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `intiator` & `admin` & `view_only` 
            cannot view Complaince Update page */
        return invalidRoutingActionComponent();
      default:
        return <ComplianceUpdate />;
    }
  }

  if (routePath === Routes.PayoutsSettings) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Payouts Setting page */
        return invalidRoutingActionComponent();
      default:
        return <PayoutsSettings />;
    }
  }

  if (routePath === Routes.NewTeamMember) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Add Team Member page */
        return invalidRoutingActionComponent();
      default:
        return <AddTeamMember />;
    }
  }

  if (routePath === Routes.EditTeamMember) {
    switch (permission) {
      case UserActionStatus.approver:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` cannot view Edit Team Member page */
        return invalidRoutingActionComponent();
      default:
        return <EditTeamMember />;
    }
  }

  if (routePath === Routes.GoogleAuth) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Google 2FA Auth page */
        return invalidRoutingActionComponent();
      default:
        return <GoogleAuth />;
    }
  }

  if (routePath === Routes.NewPolicy) {
    switch (permission) {
      case UserActionStatus.approver:
      case UserActionStatus.view_only:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `approver` & `view_only` cannot view Approval 
            Workflow Policy page */
        return invalidRoutingActionComponent();
      default:
        return <NewPolicy />;
    }
  }
  if (routePath === Routes.NotFound) {
    return <NotFoundPage />;
  }

  if (routePath === Routes.DepartmentPreview) {
    switch (permission) {
      case UserActionStatus.owner:
      case UserActionStatus.admin:
        return <DepartmentPreview />;
      default:
        /* @NOTE: Access Control: logged-in user(s) with role
            of `view_only` cannot view Permissions page */
        return invalidRoutingActionComponent();
    }
  }

  return null;
};

const AppRoutes: RoutesInterface[] = [
  // authentication
  {
    path: Routes.Dashboard,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Dashboard}
        Title={({ children, roleName }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              {roleName === UserActionStatus.approver ? "Home" : "Overview"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },

  {
    path: Routes.Login,
    exact: true,
    isPrivate: false,
    component: Login
  },
  {
    path: Routes.Signup,
    exact: true,
    isPrivate: false,
    component: Signup
  },
  {
    path: Routes.ActivateMember,
    exact: true,
    isPrivate: false,
    component: ActivateMember
  },
  {
    path: Routes.Invitation,
    exact: true,
    isPrivate: false,
    component: Signup
  },
  {
    path: Routes.ResetPassword,
    exact: true,
    isPrivate: false,
    component: ResetPassword
  },
  {
    path: Routes.ForgotPassword,
    exact: true,
    isPrivate: false,
    component: ForgotPassword
  },
  {
    path: Routes.Verification,
    exact: true,
    isOtherModule: true,
    isPrivate: false,
    component: Verification
  },
  {
    path: Routes.VerifyToken,
    exact: true,
    isOtherModule: true,
    isPrivate: false,
    component: VerifyToken
  },
  {
    path: Routes.Checkout,
    exact: true,
    isOtherModule: true,
    isPrivate: false,
    component: Checkout
  },
  // transactions
  {
    path: Routes.Transaction,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Transaction}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Transactions
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Invoice,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Invoice}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Invoice
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.RevisedInvoice,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.RevisedInvoice}
        Title={({ history, children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Invoice)}
            >
              Invoice
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.PreviewInvoice)}
            >
              View Invoice
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Revised Invoice
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CreateCustomer,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateCustomer}
        Title={({ history, children, mobileEnv }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Customers)}
            >
              Customers
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Add customer" : "Add new customer"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CustomerDetails,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CustomerDetails}
        Title={({ history, children, mobileEnv }) => {
          const customerWalletData = history.location
            .state as PageStateCustomerWalletResponse;
          return (
            <h4 className="title">
              {children}
              <span
                onClick={() => history.push(Routes.Customers)}
                style={{
                  color: colors.pri,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
              >
                Customers
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer"
                      }
                }
              >
                {mobileEnv
                  ? truncateString(customerWalletData?.full_name || "", 10)
                  : customerWalletData?.full_name || ""}
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CustomerKYCDetails,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CustomerKYCDetails}
        Title={({ history, children, mobileEnv }) => {
          const customerWalletData = history.location
            .state as PageStateCustomerWalletResponse;
          return (
            <h4 className="title">
              {children}
              <span
                onClick={() => history.push(Routes.Customers)}
                style={{
                  color: colors.pri,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
              >
                Customers
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer"
                      }
                }
              >
                {mobileEnv
                  ? truncateString(customerWalletData?.full_name || "", 10)
                  : customerWalletData?.full_name || ""}
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Customers,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Customers}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Customers
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CustomerBulkUpload,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CustomerBulkUpload}
        Title={({ history, children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Customers)}
            >
              Customers
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold"
              }}
              onClick={() => history.push(Routes.CreateCustomer)}
            >
              Add new customer
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Bulk customers upload
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CreateInvoice,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateInvoice}
        Title={({ history, children, mobileEnv }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Invoice)}
            >
              Invoice
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Create Invoice" : "Create New Invoice"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.EditInvoice,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.EditInvoice}
        Title={({ history, children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Invoice)}
            >
              Invoice
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.CreateInvoice)}
            >
              View invoice
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Recipients,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Recipients}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold"
              }}
            >
              Recipients
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CreateRecipient,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateRecipient}
        Title={({ history, children, mobileEnv }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Recipients)}
              >
                Recipients
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer"
                      }
                }
              >
                Create New Recipient
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.RecipientPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.RecipientPreview}
        Title={({ history, children, mobileEnv }) => {
          const recipientDetails = history.location.state as {
            name: string;
          };

          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Recipients)}
              >
                Recipients
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textTransform: "capitalize"
                      }
                    : {
                        color: "#AFAFAF",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "capitalize"
                      }
                }
              >
                {mobileEnv
                  ? truncateString(
                      recipientDetails?.name || "",
                      10
                    )?.toLowerCase()
                  : recipientDetails?.name?.toLowerCase() || ""}
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.RecipientBillPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.RecipientBillPreview}
        Title={({ history, children }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Recipients)}
              >
                Recipients
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span
                style={{
                  color: "#AFAFAF",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
              >
                View Bill
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.PreviewInvoice,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PreviewInvoice}
        Title={({ history, children, mobileEnv }) => {
          const customerInvoiceData = history.location.state;

          return typeof customerInvoiceData !== "undefined" ? (
            <>
              <h4 className="title">
                {children}
                <span
                  style={{
                    color: `${colors.pri}`,
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(Routes.Invoice)}
                >
                  Invoice
                </span>
                <span>
                  <FaAngleRight color="#AFAFAF" />
                </span>
                <span
                  style={{
                    color: "#AFAFAF",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(Routes.CreateInvoice)}
                >
                  View invoice
                </span>
              </h4>
            </>
          ) : (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Invoice)}
              >
                Invoice
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={{
                  color: `${colors.pri}`,
                  cursor: "pointer",
                  whiteSpace: "nowrap"
                }}
                onClick={() => history.push(Routes.CreateInvoice)}
              >
                Create invoice
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={{
                  color: "#AFAFAF",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
              >
                {mobileEnv ? "P." : "Preview invoice"}
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.InvoiceLink,
    exact: true,
    isPrivate: false,
    isOpenInvoice: true,
    component: InvoiceLink
  },
  {
    path: Routes.Bussiness,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Bussiness}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Business
            </span>
          </h4>
        )}
        TitleBar={({ history, mobileEnv }) =>
          mobileEnv ? null : (
            <Restricted
              page={UserPermissionPages.business}
              permission="view_customer_button"
            >
              <SweepButton onClick={() => history.push(Routes.Customers)}>
                Go to Customers
              </SweepButton>
            </Restricted>
          )
        }
      />
    )
  },
  {
    path: Routes.Wallets,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Wallets}
        Title={({ history }) => (
          <h4 className="title">
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Bussiness)}
            >
              Accounts
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span style={{ color: "#AFAFAF" }}>Wallets</span>
          </h4>
        )}
        TitleBar={({ history }) => (
          <SweepButton onClick={() => history.push(Routes.CreateWallet)}>
            Add new wallet
          </SweepButton>
        )}
      />
    )
  },
  {
    path: Routes.Virtual,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Virtual}
        Title={({ children }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Virtual Accounts
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.WalletsUpload,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.WalletsUpload}
        Title={({ history }) => (
          <h4 className="title">
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Bussiness)}
            >
              Accounts
            </span>
            <FaAngleRight color="#AFAFAF" />
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Wallets)}
            >
              Wallets
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.CreateWallet)}
            >
              Add new wallet
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span style={{ color: "#AFAFAF" }}>Bulk upload</span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.WalletDetails,
    exact: true,
    isPrivate: true,
    component: WalletDetails
  },
  {
    path: Routes.CreateWallet,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateWallet}
        Title={({ history }) => (
          <h4 className="title">
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Bussiness)}
            >
              Accounts
            </span>
            <FaAngleRight color="#AFAFAF" />
            <span
              style={{ color: `${colors.pri}`, cursor: "pointer" }}
              onClick={() => history.push(Routes.Wallets)}
            >
              Wallets
            </span>
            <FaAngleRight color="#AFAFAF" />
            <span style={{ color: "#AFAFAF" }}>Add new wallet</span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Profile,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Profile}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Profile
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  // Security
  {
    path: Routes.Security,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Security}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Security
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.GoogleAuth,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.GoogleAuth}
        Title={({ history }) => (
          <h4 className="title">
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(`${Routes.Profile}?tab=1`)}
            >
              Security
            </span>

            <FaAngleRight color="#AFAFAF" />
            <span style={{ color: "#AFAFAF" }}>2FA</span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  // Settings
  {
    path: Routes.Settings,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Settings}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Settings
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //  Permissions
  {
    path: Routes.Permissions,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Permissions}
        Title={({ children, history, mobileEnv }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=5"
                })
              }
            >
              {mobileEnv ? "Team" : "Team Members"}
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Perm..." : "Permissions"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //  Approval Workflow Policy
  {
    path: Routes.NewPolicy,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewPolicy}
        Title={({ children, history, mobileEnv }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=4"
                })
              }
            >
              {mobileEnv ? "Approvals" : "Approval Workflow"}
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "New Pol..." : "New Policy"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  // Invite New Team Members
  {
    path: Routes.NewTeamMember,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewTeamMember}
        Title={({ children, mobileEnv, history }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=5"
                })
              }
            >
              {mobileEnv ? "Teams" : "Team members"}
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Add Memb..." : "Add team member"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  // Edit Team Members
  {
    path: Routes.EditTeamMember,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.EditTeamMember}
        Title={({ children, mobileEnv, history }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      fontWeight: "bold",
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=5"
                })
              }
            >
              {mobileEnv ? "Teams" : "Team members"}
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Edit Memb..." : "Edit team member"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //Compliance
  {
    path: Routes.Compliance,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Compliance}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Compliance
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  // Compliance Update
  {
    path: Routes.ComplianceUpdate,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.ComplianceUpdate}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Compliance
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //Payout
  {
    path: Routes.Payout,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Payout}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Payouts
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //Payout Settings
  {
    path: Routes.PayoutsSettings,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PayoutsSettings}
        Title={({ children, history }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Payout)}
            >
              Payouts
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Payout customisation
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //NewPayout
  {
    path: Routes.NewPayout,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewPayout}
        Title={({ history, children, mobileEnv }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Payout)}
              >
                Payouts
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                Single Payout
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.NewBulkPayout,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewBulkPayout}
        Title={({ history, children, mobileEnv }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Payout)}
              >
                Payouts
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                Bulk Payment
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  //BulkPayout Details
  {
    path: Routes.BulkPayoutDetails,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.BulkPayoutDetails}
        Title={({ history, children, mobileEnv }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Payout)}
              >
                Payouts
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: `${colors.pri}`,
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: `${colors.pri}`,
                        fontWeight: "bold",
                        cursor: "pointer"
                      }
                }
                onClick={() =>
                  history.push({
                    pathname: Routes.Payout,
                    search: "?tab=1"
                  })
                }
              >
                Bulk Payout
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span style={{ color: "#AFAFAF", fontWeight: "bold" }}>
                Bulk Payout Details
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  //BulkPayout Summary
  {
    path: Routes.BulkPayoutSummary,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.BulkPayoutSummary}
        Title={({ history, children }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Payout)}
              >
                Payouts
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span style={{ color: "#AFAFAF", fontWeight: "bold" }}>
                Bulk Payment
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  //BulkPayout Review
  {
    path: Routes.BulkPayoutReview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.BulkPayoutReview}
        Title={({ history, children, mobileEnv }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Payout)}
              >
                Payouts
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: `${colors.pri}`,
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: `${colors.pri}`,
                        cursor: "pointer"
                      }
                }
                onClick={() =>
                  history.push({
                    pathname: Routes.Payout,
                    search: "?tab=1"
                  })
                }
              >
                Bulk Payout
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span style={{ color: "#AFAFAF", fontWeight: "bold" }}>
                Review Saved Payout
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  // International
  {
    path: Routes.International,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.International}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              International Payment
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.NewInternationalPayment,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewInternationalPayment}
        Title={({ children, mobileEnv, history }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.International)}
            >
              International Payment
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: "#AFAFAF",
                      fontWeight: "bold",
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textTransform: "capitalize"
                    }
                  : {
                      color: "#AFAFAF",
                      fontWeight: "bold",
                      cursor: "pointer",
                      textTransform: "capitalize"
                    }
              }
            >
              Payment
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },

  {
    path: Routes.PreviewInternationalPayment,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PreviewInternationalPayment}
        Title={({ children, mobileEnv, history }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.International)}
            >
              International Payment
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: "#AFAFAF",
                      fontWeight: "bold",
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textTransform: "capitalize"
                    }
                  : {
                      color: "#AFAFAF",
                      fontWeight: "bold",
                      cursor: "pointer",
                      textTransform: "capitalize"
                    }
              }
            >
              Payment
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },

  // Approvals
  {
    path: Routes.Approvals,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Approvals}
        Title={({ children, isApprover }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${Boolean(isApprover) ? "#162930" : "#AFAFAF"}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Approvals
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.PreviewApproval,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PreviewApproval}
        Title={({ history, children }) => {
          return (
            <h4 className="title">
              {children}
              <span
                style={{
                  color: `${colors.pri}`,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Approvals)}
              >
                Approvals
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span style={{ color: "#AFAFAF", fontWeight: "bold" }}>
                View Payout
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  // Requisitions
  {
    path: Routes.Requisitions,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Requisitions}
        Title={({ children, isApprover }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${Boolean(isApprover) ? "#162930" : "#AFAFAF"}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Requisitions
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.NewRequisitions,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.NewRequisitions}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Requisitions)}
            >
              Home
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold"
              }}
              onClick={() => history.push(Routes.NewRequisitions)}
            >
              New Expense
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: `${Routes.RequisitionsDetails}/:id`,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.RequisitionsDetails}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Requisitions)}
            >
              Home
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold"
              }}
              onClick={() => history.push(Routes.RequisitionsDetails)}
            >
              View Request
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: `${Routes.ExpenseDetails}/:id`,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.ExpenseDetails}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Requisitions)}
            >
              Home
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold"
              }}
              onClick={() => history.push(Routes.ExpenseDetails)}
            >
              View Request
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Apps,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Apps}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Apps
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.AppsPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.AppsPreview}
        Title={({ children, history, mobileEnv }) => {
          const appsDetails = history.location.state as {
            name: string;
          };
          return (
            <h4 className={"title"}>
              {children}
              <span
                style={{
                  color: "#AFAFAF",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Apps)}
              >
                Apps
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                {appsDetails?.name}
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Bills,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Bills}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Bills
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.CreateBill,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateBill}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Bills)}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              New Bill
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.BillsDetail,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.BillsDetail}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.goBack()}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              View Bill
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.PreviewBApproval,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PreviewBApproval}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.goBack()}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              View Bill
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.PreviewBill,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PreviewBill}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.goBack()}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              New Bill
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.BillTransactions,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.BillTransactions}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.go(-2)}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.goBack()}
            >
              View Bill
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              Bill Transactions
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.PayBill,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.PayBill}
        Title={({ children, history }) => (
          <h4 className="title">
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.go(-2)}
            >
              Bills
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.goBack()}
            >
              View Bill
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              Pay Bill
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.Expenses,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.Expenses}
        Title={({ children }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: "#AFAFAF",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Expenses
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.EditBalances,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.EditBalances}
        Title={({ children, history, mobileEnv }) => {
          return (
            <h4 className={"title"}>
              {children}
              <span
                style={{
                  color: colors.pri,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Expenses)}
              >
                Expenses
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                Manage Account(s)
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },

  // Approval Document Preview
  {
    path: Routes.ApprovalDocumentsPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.ApprovalDocumentsPreview}
        Title={({ children, history, mobileEnv }) => {
          return (
            <h4 className={"title"}>
              {children}
              <span
                style={{
                  color: colors.pri,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Approvals)}
              >
                Approvals
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>

              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                View Document
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },
  {
    path: Routes.RecipientDocumentsPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.RecipientDocumentsPreview}
        Title={({ children, history, mobileEnv }) => {
          const recipientDetails = history.location.state as {
            name: string;
          };
          return (
            <h4 className={"title"}>
              {children}
              <span
                style={{
                  color: colors.pri,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => history.push(Routes.Recipients)}
              >
                Recipent
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: `${colors.pri}`,
                        fontWeight: "bold",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textTransform: "capitalize"
                      }
                    : {
                        color: `${colors.pri}`,
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "capitalize"
                      }
                }
                onClick={() => history.goBack()}
              >
                {mobileEnv
                  ? truncateString(
                      recipientDetails?.name || "",
                      10
                    )?.toLowerCase()
                  : recipientDetails?.name?.toLowerCase() || ""}
              </span>
              <span>
                <FaAngleRight color="#AFAFAF" />
              </span>
              <span
                style={
                  mobileEnv
                    ? {
                        color: "#AFAFAF",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }
                    : {
                        color: "#AFAFAF",
                        cursor: "pointer"
                      }
                }
              >
                View Document
              </span>
            </h4>
          );
        }}
        TitleBar={null}
      />
    )
  },

  // Create role
  {
    path: Routes.CreateRole,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.CreateRole}
        Title={({ children, history, mobileEnv }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=5"
                })
              }
            >
              {mobileEnv ? "Team" : "Team Members"}
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              {mobileEnv ? "Add new..." : "Add new role"}
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  },
  //404 Page
  {
    path: Routes.NotFound,
    exact: true,
    isPrivate: false,
    component: NotFoundPage,
    isOtherModule: true
  },
  // Department Preview Page
  {
    path: Routes.DepartmentPreview,
    exact: true,
    isPrivate: true,
    component: () => (
      <DecoratedComponent
        renderProp={choosePageToRender}
        path={Routes.DepartmentPreview}
        Title={({ children, history, mobileEnv }) => (
          <h4 className={"title"}>
            {children}
            <span
              style={{
                color: `${colors.pri}`,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => history.push(Routes.Settings)}
            >
              Settings
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={
                mobileEnv
                  ? {
                      color: `${colors.pri}`,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }
                  : {
                      color: `${colors.pri}`,
                      cursor: "pointer"
                    }
              }
              onClick={() =>
                history.push({
                  pathname: Routes.Settings,
                  search: "?tab=5"
                })
              }
            >
              Team Members
            </span>
            <span>
              <FaAngleRight color="#AFAFAF" />
            </span>
            <span
              style={{
                color: "#AFAFAF",
                cursor: "pointer"
              }}
            >
              Department
            </span>
          </h4>
        )}
        TitleBar={null}
      />
    )
  }
];

export default AppRoutes;
