import { QueryClient, QueryCache } from "react-query";

const queryClientSettings = {
	queryCache: new QueryCache({
		onError: (error) => {
			/* eslint-disable-next-line no-console */
			console.error("[Query Cache Error]: ", error);
		},
	}),
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			cacheTime: 250000, /* @HINT: 3 minutes */
			refetchInterval: 10000,
			refetchIntervalInBackground: true,
			staleTime: 150000 /* @HINT: 2 minutes */,
		},
		mutations: {
			retry: 1,
		},
	},
};

export const queryClient = new QueryClient(queryClientSettings);