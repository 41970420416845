import PaginationDropdownComponent from "components/PaginationDropdown";
import { PaginationOnChange } from "interfaces/formElements";
import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { pageSize } from "utils/constants";
import {
  PaginationContainer,
  PaginationDiv,
  PaginationDropdownContainer,
  PaginationElement
} from "./styles";

interface PaginationProps {
  selectedOption: string;
  currentPage: number;
  setSelectedOption: Function;
  pageCount: number;
  handlePaginationChange: Function;
  gripped?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  selectedOption,
  setSelectedOption,
  pageCount,
  handlePaginationChange,
  currentPage = 1,
  gripped = false
}) => {
  return (
    <PaginationContainer gripped={gripped}>
      <PaginationElement>
        <PaginationDropdownContainer>
          <PaginationDropdownComponent
            selectedOption={selectedOption}
            handleChange={setSelectedOption}
            dropdownOption={pageSize}
          />

          <p>per page</p>
        </PaginationDropdownContainer>

        <PaginationDiv>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<FaAngleRight />}
            onPageChange={(e: PaginationOnChange) => handlePaginationChange(e)}
            marginPagesDisplayed={2}
            forcePage={currentPage >= 1 ? currentPage - 1 : 0}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={<FaAngleLeft />}
          />
        </PaginationDiv>
      </PaginationElement>
    </PaginationContainer>
  );
};

export default Pagination;
