import React, { Component, ErrorInfo, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "routes";
import GlobalStyles, { device } from "styles";
import "react-loading-skeleton/dist/skeleton.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import config from "config";
import { initializeHotjar, trackEventForHotjar } from "utils/hotjar";
import { ErrorState, AppErrorBoundaryProps } from "interfaces/errors";
import { FeaturesToggleProvider, useMediaQuery } from "lib/hooks";
import {
  envProduction,
  envDevelopment,
  envTest,
  GA_TRACKING_ID
} from "utils/constants";
import toast from "react-hot-toast";
import * as rudderanalytics from "rudder-sdk-js";
import { DismissibleAlert } from "layout/AppLayout/styles";
import ReactGA from "react-ga";

if (config.PROD) {
  if (typeof rudderanalytics.getAnonymousId() === "undefined") {
    /* @HINT: the cookie being accessed here was set in the `public/index.html` file in the <head></head> section */
    rudderanalytics.setAnonymousId(window.Cookies.get("duplo_id"));
    rudderanalytics.ready(() => undefined);
  }

  const ignorePatterns = [
    "ResizeObserver loop limit exceeded",
    "Can't find variable: ResizeObserver"
  ];

  Bugsnag.start({
    collectUserIp: true,
    apiKey: config.BUGSNAG.apiKey || "",
    plugins: [new BugsnagPluginReact()],
    releaseStage: envProduction,
    maxBreadcrumbs: 40,
    maxEvents: 30,
    onError: (event) => {
      const user = event.getUser();
      const id = (user || { id: "" }).id;
      if (!id) {
        const userId = window.Cookies.get("duplo_id");
        if (userId) {
          event.setUser(userId);
        }
      }

      if (
        ignorePatterns.some((pattern) =>
          pattern.includes(event.errors[0].errorMessage)
        )
      ) {
        return false;
      }
    }
  });
  Bugsnag.leaveBreadcrumb("[App.tsx]: App starting…");
}

class AppErrorBoundary extends Component<AppErrorBoundaryProps, ErrorState> {
  public state: ErrorState = { hasError: false, error: null };

  static getDerivedStateFromError(error: Error) {
    /* @NOTE: Update state so the next render will show the fallback UI */
    return { hasError: error instanceof Error, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /* @NOTE: You can also log the error to the console */
    /* eslint-disable-next-line no-console */
    console.error(
      "[Duplo Merchant Dashboard Frontend - Uncaught Exception]: \n\r",
      error.message,
      error.stack,
      errorInfo.componentStack
    );
  }

  render() {
    if (this.state.hasError) {
      /* @FIXME: Implement generic error page to render a custom error UI already designed by @#Josh */
      return <React.Fragment></React.Fragment>;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = config.PROD
  ? Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? AppErrorBoundary
  : AppErrorBoundary;

function App() {
  /* @HINT: Read Hotjar secrets from environment varaibles */
  const hotjarId = Number(config.HOTJAR_ID);
  const hotjarSnipVersion = Number(config.HOTJAR_SNIPPET_VERSION);

  /* @HINT: */
  const isMobile = useMediaQuery(device.mobile_);

  const bootHotJar = useCallback((hotjarId, hotjarSnipVersion) => {
    initializeHotjar(hotjarId, hotjarSnipVersion);
  }, []);

  const bootGoogleAnalytics = useCallback(() => {
    ReactGA.initialize(GA_TRACKING_ID);
  }, []);

  React.useEffect(() => {
    const onAnyPageElementClick = (e: MouseEvent) => {
      const sourceElement = (e.target || {
        parentNode: { id: "", tagName: "" },
        id: "",
        tagName: ""
      }) as HTMLElement;

      if (isMobile) {
        const parentElement = (sourceElement.parentNode || {
          tagName: "",
          id: ""
        }) as SVGElement;
        const pageRootElement = document.documentElement as HTMLHtmlElement;

        /* @HINT: If the element that was clicked is not the hambuger icon on the mobile screen header */
        if (parentElement.tagName !== "SVG" && parentElement.id !== "drawer") {
          /* @HINT: And... If the DOM element that was clicked wasn't the dropdown menu for business
            at the top of the side menu */
          if (
            sourceElement.tagName !== "INPUT" &&
            sourceElement.id !== "menu_options_lever"
          ) {
            /* @HINT: And... if the <html> element contains the "mobile-menu-show" class name */
            if (pageRootElement.classList.contains("mobile-menu-show")) {
              /* @HINT: Then... remove the "mobile-menu-show" class name from the <html> element so 
                  the floating side menu can collapse off the screen and become invisible */
              pageRootElement.classList.remove("mobile-menu-show");
            }
          }
        }
        /* @HINT: For desktop and tablet devices only */
      } else {
        const parentElement = (sourceElement.parentNode || {
          tagName: "",
          id: "",
          contains: (node: Node) => !node
        }) as HTMLElement;

        /* @HINT: If the current page being viewed by the user is the payouts page */
        if (window.location.href.endsWith("/app/payout")) {
          const customFilterCalendarFrame = document.getElementById(
            "date-picker-wrapper-box"
          );
          const customFiltersOptionsBox =
            document.getElementById("date-picker");
          /* @HINT: And... if the calendar frame element for the custom filter for payouts is visible */
          if (
            customFilterCalendarFrame &&
            customFilterCalendarFrame.style.getPropertyValue("display") ===
              "flex"
          ) {
            /* @HINT: And... if the DOM element that was clicked is not the calendar frame element and the filter options box is visible */
            if (
              sourceElement.id !== customFilterCalendarFrame.id &&
              customFiltersOptionsBox
            ) {
              /* @HINT: And... if the inner text of the DOM element that was clicked doesn't read: "Custom date range" */
              if (
                (sourceElement.textContent || "").trim() !== "Custom date range"
              ) {
                /* @HINT: And finally... if the parent DOM element of the DOM element that was clicked isn't contained or isn't a child 
                          of the calendar frame element */
                if (!customFilterCalendarFrame.contains(parentElement)) {
                  /* @HINT: The payouts page of this React app listens for this custom event ("hidecalenderframe") and acts accordingly */
                  /* @NOTE: Event "hidecalenderframe" is fired here so that the calender frame for custom filter option "Custom date range" 
                            is hidden on any click that happens outside the calendar frame */
                  window.dispatchEvent(new Event("hidecalenderframe"));
                }
              }
            }
          }
        }
      }
    };

    const onTabVisibilityToggle = () => {
      if (config.PROD) {
        if (document.visibilityState === "visible" && document.hasFocus()) {
          trackEventForHotjar("window_app_tab_active");
        } else {
          if (document.activeElement === null) {
            trackEventForHotjar("window_app_tab_inactive");
          }
        }
      }
    };

    const onWebConnected = () => {
      if (config.PROD) {
        toast.custom(
          <DismissibleAlert>
            <span className="closebtn" onClick={() => toast.dismiss()}>
              &times;
            </span>
            Your internet connectivity is back on. Yes!
          </DismissibleAlert>,
          { duration: 4200 }
        );
        trackEventForHotjar("window_app_online");
      }
    };

    const onWebDisconnected = () => {
      if (config.PROD) {
        toast.custom(
          <DismissibleAlert>
            <span className="closebtn" onClick={() => toast.dismiss()}>
              &times;
            </span>
            Your internet connectivity is off. Please reconnect to an internet
            source
          </DismissibleAlert>,
          { duration: 4200 }
        );
        trackEventForHotjar("window_app_offline");
      }
    };

    const onPrint = () => {
      if (config.PROD) {
        trackEventForHotjar("window_app_tab_print_preview");
      }
    };

    const onError = (event: ErrorEvent) => {
      const errContent = `-${event.type || "Error"}_${(
        (event.filename || "anonymous").split("/").reverse()[0] || ""
      ).replace(".", "-")}_${event.lineno}-${event.colno}`;
      if (config.PROD) {
        trackEventForHotjar(
          `window_app_detect_error_${errContent.toLowerCase()}`
        );
      }
    };

    const onResize = (e: Event) => {
      if (config.PROD) {
        trackEventForHotjar("window_resized");
      }

      e.preventDefault();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      setTimeout(() => {
        if (
          viewportHeight !== window.innerHeight &&
          viewportWidth !== window.innerWidth
        ) {
          if (isMobile) {
            window.location.reload();
          }
        }
      }, 0);
    };

    window.addEventListener("beforeprint", onPrint, false);
    window.addEventListener("error", onError, false);
    window.addEventListener("offline", onWebDisconnected, false);
    window.addEventListener("online", onWebConnected, false);
    document.addEventListener("visibilitychange", onTabVisibilityToggle, false);
    window.addEventListener("resize", onResize, false);
    document.addEventListener("click", onAnyPageElementClick, false);

    return () => {
      window.removeEventListener("beforeprint", onPrint, false);
      window.removeEventListener("error", onError, false);
      window.removeEventListener("offline", onWebDisconnected, false);
      window.removeEventListener("online", onWebConnected, false);
      document.removeEventListener(
        "visibilitychange",
        onTabVisibilityToggle,
        false
      );
      window.removeEventListener("resize", onResize, false);
      document.removeEventListener("click", onAnyPageElementClick, false);
    };
  }, [isMobile]);

  React.useEffect(() => {
    /* @NOTE: `config.PROD` means both "staging" and "production" environments */
    if (config.PROD) {
      const isStagingEnvironment =
        config.API_ENDPOINT?.search(/\.(dev|staging)\./) !== -1;
      if (isStagingEnvironment) {
        /* @HINT: Don't boot up the `HotJar` analytics tracking library for the staging environrmnt */
        return;
      }
      bootHotJar(hotjarId, hotjarSnipVersion);
    }
  }, [bootHotJar, hotjarId, hotjarSnipVersion]);

  React.useEffect(() => {
    bootGoogleAnalytics();
  }, [bootGoogleAnalytics]);

  return (
    <div className="page-wrapper">
      <FeaturesToggleProvider
        enabledFeaturesTable={{
          [envDevelopment]: config.DEV_FEATURES_LIST,
          [envTest]: config.DEV_FEATURES_LIST,
          [envProduction]: config.PROD_FEATURES_LIST
        }}
        environment={config.ENV}
      >
        <GlobalStyles />
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </FeaturesToggleProvider>
    </div>
  );
}

export default App;
