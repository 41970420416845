import styled from "styled-components";
import { device } from "styles";
import colors from "styles/colors";

interface ExpenseDrawerProps {
  isOpen: boolean;
}

const ExpenseSummaryContainer = styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
    margin: 40px 15px 0 15px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto auto auto;
  }
`;

const ExpenseSummaryCard = styled.div`
  height: 132px;
  border: 1px solid #00000008;
  box-shadow: 0px 12px 26px -7px #5c696e1a;
  width: 356px;

  div {
    margin: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h4 {
    padding: 0 0 20px 0;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.82px;
    letter-spacing: 0.1em;
    color: ${colors.pri};
    font-family: Euclid Circular B;
  }

  p {
    padding: 20px 0 0 0;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
  }

  @media ${device.mobileS} {
    width: 100%;
  }
`;

const ExpenseTableContainer = styled.div`
  margin-top: 40px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 25px 0;
`;

const ExpenseFilterContainer = styled.section`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NewExpenseContainer = styled.div`
  margin: 30px 0 0 0;
  box-shadow: 0px 12px 26px 0px #5c696e1a;
  padding: 30px 30px;

  .nav-container {
    display: flex;
    justify-content: space-between;

    h4 {
      color: #162930;

      font-size: 18px;
      font-weight: 500;
      line-height: 25.36px;
      margin: 0;
    }
  }
`;

const ExpenseCardElement = styled.div`
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 30px;

  .header {
    border-bottom: 1px solid #dddddd;
    padding: 20px;
    color: #666666;

    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
`;

const ExpenseCardGrid = styled.div`
  display: grid;
  margin: 30px 0;
  @media ${device.laptop} {
    grid-template-columns: 54% 46%;
    grid-column-gap: 15px;
  }
`;

const ExpenseUploadMoreBtn = styled.button`
  background: #2db49e14;
  width: 165px;
  height: 40px;
  border-radius: 6px;
  outline: none;
  border: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: #2db49e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
`;

const SupportingPDFContainerEl = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  margin-bottom: 30px;
`;

const SupportingPDFContainer = styled.a`
  background: #d8f3ef66;
  color: #006355;
  cursor: pointer;
  height: 42px;
  padding: 0 10px;
  width: 125px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: Euclid Circular B;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  border-radius: 8px;
  text-decoration: none;
`;

const ExpenseDragContainer = styled.div`
  background: #f8f9f9;
  height: 110px;
  border-radius: 10px;
  padding: 30px;
  margin-top: 25px;

  .progress-bar {
    height: 8px;
    border-radius: 8px;
    background: #006355;
    width: 264px;
  }

  .progress-bar-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin: 0 8px;
  }

  .upload-el {
    .file-info {
      color: #4a5361;

      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    .el-size {
      color: #4a5361cc;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    .el-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .empty-container {
    svg {
      display: flex;
      margin: auto;
    }

    p {
      color: #4a5361cc;

      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      margin: 20px 0 0 0;
      padding: 0;
    }
  }
`;

const ExpenseDrawerContainer = styled.div<ExpenseDrawerProps>`
  height: 100%;
  width: ${(props) => (props.isOpen ? "400px" : "0")};
  position: fixed;
  top: ${(props) => props.isOpen && "0"};
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  padding: 0 20px;
  overflow-x: hidden;
  transition: 0.3s;
  border: 1px solid #ebeff5;
  box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
  overflow-y: auto;

  .close-container {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background: #d8f3ef66;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
  }

  .header {
    height: 68px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #07000f;
    display: flex;
    align-items: center;
  }

  .submitted {
    font-family: Euclid Circular B;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    color: #5c696e;
  }

  .el-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    h3 {
      font-family: Euclid Circular B;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: right;
    }

    p {
      font-family: Euclid Circular B;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  .menu-item {
    background: #f8f9f9;
    height: 48px;
    margin: 25px 0 0 0;
    padding: 0 20px;
    font-family: Euclid Circular B;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #babfc1;
  }

  .amount {
    margin-top: 40px;
    p {
      padding: 0 0 10px 0;
      margin: 0;
      font-family: Euclid Circular B;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #8b8b8b;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-family: Euclid Circular B;
      font-size: 22px;
      font-weight: 500;
      line-height: 18px;

      span {
        color: #003f34;
      }
    }
  }
`;

const RequestDetailsContainer = styled.div`
  display: grid;
  margin-top: 25px;
  @media ${device.laptop} {
    grid-template-columns: 60% 36%;
    grid-column-gap: 25px;
  }
`;

const RequestDetailsContainerEl = styled.div`
  border: 1px solid #ecf0f3;
  background: #fdfdfd;
  padding: 20px;

  .details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      padding: 0;
      margin: 0 10px 0 0;
      color: #162930;
      font-family: Euclid Circular B;
      font-size: 18px;
      font-weight: 500;
      line-height: 25.36px;
      text-align: left;
    }
  }

  .request-info-container {
    display: grid;
    margin-top: 25px;
    @media ${device.laptop} {
      grid-template-columns: 30% 46%;
      grid-column-gap: 25px;
    }

    p {
      margin: 0;
      padding: 0;
      color: #5c696e99;
      font-family: Euclid Circular B;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }

    h3 {
      margin: 0;
      padding: 0;
      font-family: Euclid Circular B;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #162930;

      span {
        margin: 0;
        padding: 0;
        font-family: Euclid Circular B;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #162930;
      }
    }
  }

  .el-header {
    background: #f8f9f9;
    height: 48px;
    margin: 25px 0 0 0;
    padding: 0 20px;
    font-family: Euclid Circular B;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #7b7b76cc;
  }
`;

const UploadItemContainer = styled.div`
  background: #fdfdfd;
  border: 1.4px solid #ecebf5;
  height: 60px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const UserIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px;

  p {
    margin-left: 10px;
  }
`;

const ExpenseUploadNotification = styled.div`
  display: flex;
  border-radius: 6px;
  align-items: center;
  gap: 8px;
  margin: 30px auto;
  color: ${colors.pri};
  border: 1px solid ${colors.pri};
  font-size: 14px;
  font-weight: 300;
  background: #2db49e14;
  padding: 16px 25px;
  width: 60%;

  svg {
    flex-shrink: none;
  }
`;

const DownloadReceiptContainer = styled.div`
  background: #d8f3ef66;
  cursor: pointer;
  height: 42px;
  padding: 0 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: Euclid Circular B;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  border-radius: 8px;
  text-decoration: none;

  a {
    color: #006355;
    text-decoration: none;
  }
`;

export {
  ExpenseSummaryContainer,
  ExpenseSummaryCard,
  ExpenseTableContainer,
  FlexContainer,
  ExpenseFilterContainer,
  NewExpenseContainer,
  ExpenseCardElement,
  ExpenseCardGrid,
  ExpenseDragContainer,
  ExpenseUploadMoreBtn,
  ExpenseUploadNotification,
  ExpenseDrawerContainer,
  SupportingPDFContainer,
  RequestDetailsContainer,
  RequestDetailsContainerEl,
  SupportingPDFContainerEl,
  UploadItemContainer,
  UserIconContainer,
  DownloadReceiptContainer
};
