import { ModalProviderContainer } from "context/modalContext";
import { UserProviderContainer } from "context/userContext";
import { WalletProviderContainer } from "context/walletContext";
import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";

import DisplayError from "pages/DisplayError";
import App, { ErrorBoundary } from "./App";

import reportWebVitals from "./reportWebVitals";
import { TransactionProviderContainer } from "context/transactionContext";
import { install } from "resize-observer";

/* @NOTE: `navigator.clipboard` is undefined in Safari 12.1.x as well as the earlier versions 
  of other browsers like Chrome (Webkit), Firefox, Edge (EdgeHTML) */
/* @CHECK: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard#clipboard_availability */
import "clipboard-polyfill/overwrite-globals"; /* @SHIM: # */
import { TourProvider } from "context/tourContext";


if (!window.ResizeObserver) {
  /* @NOTE: This polyfill installation is for the Safari browser only */
  /* @CHECK: https://stackoverflow.com/a/65832955 */
  install(); /* @SHIM: # */
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" reverseOrder={false} gutter={8} />
        <TourProvider>
          <TransactionProviderContainer
            type={[]}
            syncStatus={[]}
            status={[]}
            bulkStatus={[]}
            approvalStatus={[]}
            category={[]}
            amount={""}
          >
            <ModalProviderContainer>
              <WalletProviderContainer
                value={{
                  billingInfo: {},
                  sendMoneySummary: {},
                  payoutSummaryInfo: {},
                  customPayoutMetaInfo: {}
                }}
              >
                <UserProviderContainer>
                  <ErrorBoundary FallbackComponent={DisplayError}>
                    <App />
                  </ErrorBoundary>
                </UserProviderContainer>
              </WalletProviderContainer>
            </ModalProviderContainer>
          </TransactionProviderContainer>
        </TourProvider>
      </QueryClientProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
