import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  ChangeEvent
} from "react";
import debounce from "lodash/debounce";
import BrowserStorageContext, {
  BrowserStoreContext
} from "context/browserStorageContext";
import TextFilterAlgorithmsContext, {
  TextFilterAlgorithms,
  FilterFunction
} from "context/textFilterAlgorithmn";
import FeaturesToggleContext, {
  FeatureFlagEnvs
} from "context/featuresToggleContext";

import {
  toUniqueItemList,
  extractPropertyValue,
  ReducableObject,
  throttleFilterCallbackRoutine
} from "utils/object-util";
import {
  FilterInterface,
  TransactionsFilterInterface
} from "interfaces/filter";
import { PaginationOnChange } from "interfaces/formElements";
import { PaginationApiResponse } from "interfaces/pagination";
import { decrypt_userobj, encrypt_userobj } from "utils/auth-util";
import { useHistory } from "react-router";
import { Location } from "history";
import {
  BusinessInterface,
  ITeamMemberResponseObject,
  MerchantInterface
} from "interfaces/users";
import PermissionContext from "context/permissionContext";

type ReactQueryFetchStatus = "idle" | "loading" | "error" | "success";

type ReactQueryFetchHook<F extends unknown[], P, Q> = (
  ...hookArguments: [...F, P]
) => { data: Q; status: ReactQueryFetchStatus };

type PaginatedResponseFilterActionFunction<K> =
  | K
  | ((previousUpdateDetails: K) => K);

export function useFetchResponseAndPageFilters<
  PaginatedResponseData extends { meta: PaginationApiResponse },
  FetchHookFunctionArguments extends Array<unknown | string | undefined>,
  PaginatedResponseFilterOptionsInterface extends FilterInterface &
    TransactionsFilterInterface
>(
  /* @HINT: The `fecthHook` parameter is a custom react-query (or @tansatck/query) hook for fetching data */
  fetchHook: ReactQueryFetchHook<
    FetchHookFunctionArguments,
    PaginatedResponseFilterOptionsInterface,
    PaginatedResponseData
  >,
  /* @HINT: The `fetchHookArgs` parameter is a list (array) of values that are to be passed to the first function parameter (`fetchHook`) when called */
  fetchHookArgs: FetchHookFunctionArguments,
  /* @HINT: The `extraFilterOptions` parameter is for including any additional filter options for either pagination or text search purposes */
  extraFilterOptions: {},
  /* @HINT: The `pageItemsLimitCount` parameter is for limit for how many items are to be displayed per page (for pagination) */
  pageItemsLimitCount = 20
): [
  PaginatedResponseFilterOptionsInterface,
  PaginatedResponseData,
  ReactQueryFetchStatus,
  (pageSelection: PaginationOnChange) => void,
  (currentPageSelected: string) => void,
  (filterUpdateDetails: PaginatedResponseFilterOptionsInterface) => void
] {
  /* @HINT: Setup the filters for pagination and text search purposes */
  const [filters, setFilters] =
    useState<PaginatedResponseFilterOptionsInterface>({
      page: 1,
      perPage: pageItemsLimitCount,
      pageCount: 1,
      limit: pageItemsLimitCount,
      ...extraFilterOptions
    } as PaginatedResponseFilterOptionsInterface);

  /* @HINT: Setup functions to handle pagination of items returned from the server-side */
  const handlePaginationChange = (e: PaginationOnChange) => {
    setFilters({ ...filters, page: e.selected + 1 });
  };

  const handlePageDropdown = (e: string) => {
    setFilters({ ...filters, perPage: Number(e), limit: Number(e), page: 1 });
  };

  const setter = (
    updateDetailsOrFunction: PaginatedResponseFilterActionFunction<PaginatedResponseFilterOptionsInterface>
  ) => {
    if (typeof updateDetailsOrFunction === "function") {
      return setFilters(updateDetailsOrFunction);
    }
    setFilters({ ...updateDetailsOrFunction });
  };

  /* @HINT: Execute the custom `react-query` fetch hook to retrieve data from the server-side via a HTTP request */
  const { data: responseData, status: requestStatus } = fetchHook(
    ...[...fetchHookArgs, filters]
  );

  /* @HINT: Setup the side effects for updating the filters immediately after each HTTP request */
  useEffect(() => {
    if (requestStatus === "success") {
      if (
        responseData.meta?.pageCount !== 0 &&
        filters.pageCount !== responseData.meta?.pageCount
      ) {
        setFilters({
          ...filters,
          page: responseData.meta.page,
          limit: responseData.meta.limit,
          pageCount: responseData.meta.pageCount,
          perPage: responseData.meta.limit
        });
      }
    }
  }, [responseData, requestStatus, filters]);

  /* @HINT: Finally, ... */
  return [
    filters,
    responseData,
    requestStatus,
    handlePaginationChange,
    handlePageDropdown,
    setter
  ];
}

interface SearchQueryController<T> {
  text: string;
  isLoading: boolean;
  page: number;
  list: T[];
}

interface SearchQueryPageOptions<T> {
  text: string;
  page?: number;
  list: T[];
}

type SearchQueryOptions<T> = {
  filterTaskName?: "specific" | "fuzzy" | "complete";
  fetchRemoteFilteredList?: (
    text?: string,
    searchKey?: string[]
  ) => Promise<T[]>;
  filterUpdateCallback?: (
    controller?: SearchQueryController<T>,
    setter?: React.Dispatch<React.SetStateAction<SearchQueryController<T>>>
  ) => () => undefined;
};

export function useTextFilteredList<T>(
  { text = "", page = 1, list }: SearchQueryPageOptions<T>,
  {
    filterTaskName = "specific",
    fetchRemoteFilteredList = () => Promise.resolve([]),
    filterUpdateCallback = (controller) => () => void controller
  }: SearchQueryOptions<T>
): [
  SearchQueryController<T>,
  (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemKey?: string[]
  ) => void
] {
  /* @HINT: Fetch all the default text search algorithm functions from React context */
  const algorithms = useContext(TextFilterAlgorithmsContext);
  /* @HINT: Select the text search algorithm function chosen by the client code (via `filterTaskName` argument) for text query purposes */
  const filterTextAlgorithmRunner =
    algorithms !== null ? algorithms[filterTaskName] : () => [];

  /* @HINT: Setup the search query controller values - values that control the processing of the text search */
  const [controller, setController] = useState<SearchQueryController<T>>({
    text,
    isLoading: false,
    list,
    page
  });

  /* @HINT: Use a debounce function to batch keystrokes together and make calls to the server only after typing has ceased */
  const delayedFetchRemoteFilteredList = useRef(
    debounce((searchTerm?: string, listItemKeys?: string[]) => {
      if (typeof fetchRemoteFilteredList === "function") {
        return fetchRemoteFilteredList(searchTerm, listItemKeys);
      }
      return Promise.resolve<T[]>([]);
    }, 500)
  ).current;

  /* @HINT: Setup function to handle `onChange` event of any <input> or <textarea> element used to enter text search query */
  const handleFilterTrigger = useCallback(
    (
      filterListAlgoRunner: FilterFunction,
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      listItemKeys: string[] = []
    ) => {
      /* @HINT: Only react to `chnage` events from text inputs */
      if (
        event &&
        event.type === "change" &&
        event.target instanceof Element &&
        !event.defaultPrevented
      ) {
        /* @HINT: get the search query from the <input> or <textarea> element */
        const searchTerm = event.target.value;

        /* @HINT: Update the state depending on whether a 
          search term was entered into the text input element */
        if (searchTerm !== "") {
          setController((prevController) => ({
            ...prevController,
            text: searchTerm,
            isLoading: true
          }));
        } else {
          setController((prevController) => ({
            ...prevController,
            text: searchTerm,
            isLoading: false,
            list,
            page: 1
          }));
          return;
        }

        /* @HINT: Perform the text search using the search query on the list of items to search from */
        const filteredList = filterListAlgoRunner(
          searchTerm,
          // @ts-ignore
          list,
          listItemKeys
        ) as T[];

        /* @HINT: If the text search algorithm function didn't return any results (on the client-side)... */
        if (filteredList.length === 0) {
          /* @HINT: ...then, use the debounced function to fetch a list of items from 
            the server-side that may match the search query */
          (
            delayedFetchRemoteFilteredList(searchTerm, listItemKeys) ||
            new Promise((resolve) => {
              resolve([]);
            })
          ).then((fetchedList) =>
            setController((prevController) => ({
              ...prevController,
              isLoading: false,
              page: 1,
              /* @ts-ignore */
              list: fetchedList.__fromCache
                ? (filterListAlgoRunner(
                    searchTerm,
                    // @ts-ignore
                    fetchedList,
                    listItemKeys
                  ) as T[])
                : fetchedList
            }))
          );
          return;
        }

        /* @HINT: filtering on the client-side returned results so update state accordingly */
        setController({
          text: searchTerm,
          isLoading: false,
          list: filteredList,
          page: 1
        });
      }
    },
    [delayedFetchRemoteFilteredList, list]
  );

  useEffect(() => {
    if (list?.length === 0) {
      if (controller?.list?.length !== list?.length) {
        if (controller?.text === "") {
          setController((prevController) => ({
            ...prevController,
            list
          }));
        }
      }
      return;
    }

    if (controller.text === "") {
      if (
        controller.list?.length === 0 ||
        controller.list?.length !== list?.length
      ) {
        setController((prevController) => ({
          ...prevController,
          list
        }));
      } else {
        if (controller.page !== page) {
          setController((prevController) => ({
            ...prevController,
            page,
            list
          }));
        }
      }
    }
  }, [list, controller, page]);

  useEffect(() => {
    const throttledFilterUpdateCallback = throttleFilterCallbackRoutine<
      SearchQueryController<T>,
      React.Dispatch<React.SetStateAction<SearchQueryController<T>>>,
      () => undefined
    >(filterUpdateCallback, [controller, setController]);
    let shutdownCallback = () => undefined;

    if (controller.text !== text) {
      shutdownCallback = throttledFilterUpdateCallback();
    }

    return () => {
      shutdownCallback();
    };
  }, [text, controller, filterUpdateCallback]);

  /* @HINT: Finally, ... */
  return [
    controller,
    handleFilterTrigger.bind(null, filterTextAlgorithmRunner)
  ];
}

export const TextFilterAlgorithmsProvider = ({
  children,
  extendAlgos = {}
}: {
  children: React.ReactNode;
  extendAlgos: TextFilterAlgorithms;
}) => {
  const shared = useRef(
    Object.assign(extendAlgos, {
      specific(filterText = "", filterList = [], filterListItemKeys = [""]) {
        return filterList.filter((filterListItem) => {
          return filterListItemKeys.reduce<boolean>(
            (finalStatusResult, filterListItemKey) => {
              const listItem =
                typeof filterListItem !== "object"
                  ? filterListItem
                  : extractPropertyValue(filterListItemKey, filterListItem);
              const haystack =
                typeof listItem === "string"
                  ? listItem.toLowerCase()
                  : String(listItem).toLowerCase();
              const needle = filterText.toLowerCase();

              return (
                filterText === "" ||
                haystack.indexOf(needle) > -1 ||
                finalStatusResult
              );
            },
            false
          );
        });
      },
      fuzzy(filterText = "", filterList = [], filterListItemKeys = [""]) {
        if (filterText === "") {
          return filterList;
        }

        const characters = filterText.split("");

        /* @NOTE: flatten the multi-dimesional list (array) */
        const chunks = Array.prototype.concat.apply(
          [] as ReducableObject[],
          characters.map<ReducableObject[]>((character) => {
            return filterList.filter((filterListItem) => {
              return filterListItemKeys.reduce<boolean>(
                (finalStatusResult, filterListItemKey) => {
                  const needle = character.toLowerCase();
                  const listItem =
                    typeof filterListItem !== "object"
                      ? filterListItem
                      : extractPropertyValue(filterListItemKey, filterListItem);
                  const haystack =
                    typeof listItem === "string"
                      ? listItem.toLowerCase()
                      : String(listItem).toLowerCase();
                  const radix = haystack.indexOf(needle);
                  let result = true;

                  if (radix === -1) {
                    result = false;
                  }
                  return result || finalStatusResult;
                },
                false
              );
            });
          })
        );

        return toUniqueItemList(
          filterListItemKeys.flatMap((filterListItemKey) =>
            toUniqueItemList(chunks, filterListItemKey)
          )
        );
      },
      complete(filterText = "", filterList = [], filterListItemKeys = [""]) {
        return filterList.filter((filterListItem) => {
          return filterListItemKeys.reduce<boolean>(
            (finalStatusResult, filterListItemKey) => {
              const listItem =
                typeof filterListItem !== "object"
                  ? filterListItem
                  : extractPropertyValue(filterListItemKey, filterListItem);
              const haystack =
                typeof listItem === "string"
                  ? listItem.toLowerCase()
                  : String(listItem).toLowerCase();
              const needle = filterText.toLowerCase();

              let result = true,
                radix = -1,
                charPosition = 0,
                charValue = needle[charPosition] || null;

              while (null !== charValue) {
                radix = haystack.indexOf(charValue, radix + 1);
                if (radix === -1) {
                  result = false;
                  break;
                }
                charPosition += 1;
                charValue = needle[charPosition] || null;
              }
              return result || finalStatusResult;
            },
            false
          );
        });
      }
    })
  );

  return (
    <TextFilterAlgorithmsContext.Provider value={shared.current}>
      {children}
    </TextFilterAlgorithmsContext.Provider>
  );
};

export const BrowserStorageProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const shared = useRef({
    local: window.localStorage,
    session: window.sessionStorage
  });

  return (
    <BrowserStorageContext.Provider value={shared.current}>
      {children}
    </BrowserStorageContext.Provider>
  );
};

export const FeaturesToggleProvider = ({
  children,
  environment,
  enabledFeaturesTable
}: {
  children: React.ReactNode;
  environment: FeatureFlagEnvs;
  enabledFeaturesTable: {
    development: string[];
    test: string[];
    production: string[];
  };
}) => {
  return (
    <FeaturesToggleContext.Provider
      value={{ enabledFeatures: enabledFeaturesTable[environment] }}
    >
      {children}
    </FeaturesToggleContext.Provider>
  );
};

export const useBrowserStorage = ({
  enableEncryption = false,
  storageType = "local"
}: {
  enableEncryption: boolean;
  storageType: keyof BrowserStoreContext;
}) => {
  const browserstorage = useContext(BrowserStorageContext);

  if (browserstorage === null) {
    throw new Error(
      "useBrowserStorage[Error]: Load provider before using hook"
    );
  }

  const storageDriver = browserstorage[storageType];

  return {
    setToStorage: (
      key: string,
      value = null as object | string | null
    ): boolean => {
      /* @HINT: This is the side-effect for each state change cycle - we want to write to `localStorage` | `sessionStorage` */
      if (typeof storageDriver.setItem === "function") {
        try {
          if (!enableEncryption) {
            if (value !== null) {
              if (typeof key === "string") {
                storageDriver.setItem(
                  key,
                  typeof value === "string" ? value : JSON.stringify(value)
                );
                return true;
              }
            }
          } else {
            if (value !== null) {
              if (typeof key === "string") {
                storageDriver.setItem(key, encrypt_userobj(value));
                return true;
              }
            }
          }
        } catch (error) {
          const storageError = error as Error;
          if (storageError.name === "QuotaExceededError") {
            return false;
          }
        }
      }
      return false;
    },
    clearFromStorage: (key = "") => {
      /* @HINT: As the component unmounts, we want to delete from `localStorage` | `sessionStorage` */
      if (typeof storageDriver.removeItem === "function") {
        try {
          storageDriver.removeItem(key);
        } catch (_) {
          return false;
        }
        return true;
      }
      return false;
    },
    getFromStorage<T>(key: string, defaultPayload = {}) {
      /* @HINT: We want to fetch from `sessionStorage` */
      let stringifiedPayload = null;

      try {
        if (typeof storageDriver.getItem === "function") {
          stringifiedPayload = storageDriver.getItem(key);
        }
      } catch (error) {
        const storageError = error as Error;
        if (storageError.name === "SecurityError") {
          stringifiedPayload = null;
        }
      }

      if (!enableEncryption) {
        let payload = null;
        try {
          payload = !stringifiedPayload
            ? (defaultPayload as T)
            : (JSON.parse(stringifiedPayload) as T);
        } catch (e) {
          const error = e as Error;
          payload = defaultPayload as T;
          if (error.name === "SyntaxError") {
            if (stringifiedPayload !== null) {
              payload = stringifiedPayload;
            }
          }
        }
        return payload;
      } else {
        const payload = decrypt_userobj<T>(stringifiedPayload);
        return !payload ? (defaultPayload as T) : payload;
      }
    }
  };
};

type FeatureToggleHandlers = {
  isDisabledFor: (feature: string) => boolean;
  isEnabledFor: (feature: string) => boolean;
};

export const useFeatureToggle = () => {
  const features = useContext(FeaturesToggleContext);

  return {
    isDisabledFor: (feature: string) => {
      return !features.enabledFeatures.includes(feature);
    },
    isEnabledFor: (feature: string) => {
      return features.enabledFeatures.includes(feature);
    }
  } as FeatureToggleHandlers;
};

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

type UserConfirmationCheck = ((status: boolean) => boolean | void) | null;

export const useRoutingMonitor = ({
  unsavedChangesRouteKeysMap = {
    home: "__root_unsaved_items"
  }, // { "/app/payout/settings": "merchantUnsavedItems" }
  documentTitlePrefix = "Page - ", // "Duplo - "
  appLiveHostName = "locahost", // ".tryduplo.com"
  appPathnamePrefix = "/", // "/app/",
  useBrowserPrompt = false, // true
  promptMessage = "Are you sure ?", // "You have unsaved items on this page. Would you like to discard them ?"
  mustBlockRoutingTo = () => false,
  routeGuard = () => undefined
}: {
  unsavedChangesRouteKeysMap: Record<string, string>;
  documentTitlePrefix?: string;
  appLiveHostName: string;
  appPathnamePrefix: string;
  promptMessage?: string;
  mustBlockRoutingTo?: (pathname: string) => boolean;
  useBrowserPrompt?: boolean;
  routeGuard: Function;
}) => {
  const history = useHistory();
  const { setToStorage, getFromStorage } = useBrowserStorage({
    enableEncryption: false,
    storageType: "session"
  });

  const [backStack, setBackStack] = useState<Location[] | undefined>([]);
  const [verifyConfimation, setVerifyConfirmation] = useState<boolean>(false);
  const [verifyConfirmCallback, setVerifyConfirmCallback] =
    useState<UserConfirmationCheck>(null);

  const routeChangeProcessCallbackFactory = (
    unsavedChangesKey: string,
    location: Location
  ) => {
    return (canDiscardUnsavedItems: boolean) => {
      if (canDiscardUnsavedItems) {
        setToStorage(unsavedChangesKey, "saved");
        //unblock();
        /* @HINT: There are parts of this React app that listen for this custom event ("discardunsaveditems") 
            and acts accordingly */
        /* @NOTE: Event "discardunsaveditems" is fired here so that items yet to saved are discarded and not saved */
        window.dispatchEvent(new Event("discardunsaveditems"));

        return mustBlockRoutingTo(location.pathname) ? false : undefined;
      } else {
        /* @HINT: Store signal for unsaved items on the Dashboard as pending */
        setToStorage(unsavedChangesKey, "pending");
        return false;
      }
    };
  };

  const getUserConfirmation = (
    message: string,
    callback: (status: boolean) => false | void
  ) => {
    if (useBrowserPrompt) {
      const allowTransition = window.confirm(message);
      return callback(allowTransition);
    } else {
      setVerifyConfirmCallback((status: boolean) => callback(status));
      setVerifyConfirmation(true);
    }
  };

  const onBeforeRouteChange = (location: Location) => {
    const formerPathname = getFromStorage<string>("former_url", "/");
    const unsavedChangesKey =
      unsavedChangesRouteKeysMap[
        formerPathname.replace(appPathnamePrefix, "")
      ] || "";

    /* @HINT: Fetch signal for unsave items on the Dashboard created by the user (e.g. Payout Setting, Approver Policies) */
    const unsavedItemsStatus = getFromStorage<string>(
      unsavedChangesKey,
      "saved"
    );
    /* @HINT: If the there are items to be "saved", then prompt the user with a dialog box message */
    if (unsavedItemsStatus !== "saved") {
      return getUserConfirmation(
        promptMessage,
        routeChangeProcessCallbackFactory(unsavedChangesKey, location)
      );
    }
  };

  useEffect(() => {
    /* @HINT: */
    const unblock = history.block(onBeforeRouteChange);

    /* @HINT: */
    const unlisten = history.listen(function onRouteChange(
      location: Location,
      action: string
    ) {
      /* @HINT: The last loaded page URL is stored in session storage and retrieved upon the next page route change */
      const formerPathname = getFromStorage<string>("former_url", "/");
      /* @HINT: The document <title> of the page is programatically created from the page URL pathname */
      const title = location.pathname
        .replace(/^\//, "")
        .split("/")
        .slice(1)
        .reduce((buffer, suffix) => {
          const capitalizedSuffix =
            suffix.charAt(0).toUpperCase() + suffix.substring(1);
          return (
            buffer +
            (buffer !== "" ? " " + capitalizedSuffix : capitalizedSuffix)
          );
        }, "");

      const [normalizedTitle] = title.includes("-")
        ? title.split("-")
        : [title];

      /* @HINT: The document <title> assigned with an additional prefix */
      document.title = documentTitlePrefix + normalizedTitle;

      /* @HINT: Update the last loaded URL so it is consistent with the next page route change */
      setToStorage("former_url", location.pathname || "/");

      /* @HINT: Programtically take the user to the previous page if the 
            user did navigate from the root path ( "/" ) and user is being 
            referred or navigating */
      if (
        location.pathname.includes(appPathnamePrefix) &&
        formerPathname === "/"
      ) {
        const dummyURL = "https://x.yx/";
        const referrerURL = new URL(document.referrer || dummyURL);
        if (
          referrerURL.host.includes(appLiveHostName) &&
          referrerURL.pathname.includes(appPathnamePrefix)
        ) {
          history.goBack();
        }
      }

      setBackStack((backStack) => {
        switch (action) {
          case "POP":
            if (backStack) return backStack.slice(0, backStack.length - 1);
            break;
          case "PUSH":
            if (backStack) return [...backStack, location];
            break;
          case "REPLACE":
            if (backStack)
              return [...backStack.slice(0, backStack.length - 1), location];
            break;
        }
      });

      /* @HINT: Don't allow a page which belongs to the root path ( "/" ) to be tracked by Rudderstack */
      if (title.trim() === "Duplo") {
        return;
      }

      return routeGuard(document.title, {
        nextPathname: location.pathname,
        previousPathname: formerPathname
      });
    });

    return () => {
      /* @HINT: If there is a listener set for the "beforeunload" event */
      if (typeof unblock === "function") {
        /* @HINT: Then, at this point, assume all unsaved items are saved  
            and then remove the listener for "beforeunload" event */
        for (const unsavedChangesKey in unsavedChangesRouteKeysMap) {
          if (unsavedChangesRouteKeysMap.hasOwnProperty(unsavedChangesKey)) {
            setToStorage(unsavedChangesKey, "saved");
          }
        }
        unblock();
      }
      unlisten();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [history]);

  return {
    backStack,
    verifyConfimation,
    getUserConfirmation,
    allowTransition: () => {
      setVerifyConfirmation(false);
      if (verifyConfirmCallback !== null) {
        verifyConfirmCallback(true);
      }
    },
    blockTransition: () => {
      setVerifyConfirmation(true);
      if (verifyConfirmCallback !== null) {
        verifyConfirmCallback(false);
      }
    }
  };
};

interface MerchantBusinessData extends Record<string, object> {
  defaultBusinessInfo: BusinessInterface;
  merchantInfo: MerchantInterface;
  memberInfo: ITeamMemberResponseObject;
}

export const useMerchantBusinessData = () => {
  /* @FIXME: */
  const [merchantBusinessData, setAllMerchantBusinessData] =
    useState<MerchantBusinessData | null>(null);

  const { setToStorage } = useBrowserStorage({
    enableEncryption: true,
    storageType: "local"
  });

  useEffect(() => {
    if (merchantBusinessData !== null) {
      for (const merchantBusinessDataKey in merchantBusinessData) {
        if (merchantBusinessData.hasOwnProperty(merchantBusinessDataKey)) {
          const merchantBusinessDataKeyValue =
            merchantBusinessData[merchantBusinessDataKey];
          setToStorage(merchantBusinessDataKey, merchantBusinessDataKeyValue);
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [merchantBusinessData]);

  return [merchantBusinessData, setAllMerchantBusinessData];
};

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export const usePermission = (page:string, permission: string) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();
  const { isAllowedTo } = useContext(PermissionContext);
 
    isAllowedTo(page,permission).then((allowed) => {
      setLoading(false);
      setAllowed(allowed);
    });
    return [loading, allowed];
};
