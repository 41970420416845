import React from "react";
import { ReactComponent as EmptyState } from "assets/svg/empty-txn.svg";
import { TableEmptyContainer } from "./styles";

interface Props {
  message: string;
  renderAddendum?: () => JSX.Element | null;
}

const TableEmptyMessage: React.FC<Props> = ({
  message,
  renderAddendum = () => null
}) => {
  return (
    <TableEmptyContainer>
      <EmptyState />
      <p
        style={{
          textDecoration: "none",
          fontWeight: 600,
          fontSize: "16px",
          color: "#07000F"
        }}
      >
        {message}
      </p>
      {renderAddendum()}
    </TableEmptyContainer>
  );
};

export default TableEmptyMessage;
