import { usePermission } from "lib/hooks";
import React from "react";

type Props = {
  page: string;
  permission: string;
  loadingComponent?: JSX.Element | string;
  fallback?: JSX.Element | string;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({
  page,
  permission,
  loadingComponent,
  children
}) => {
  const [loading, allowed] = usePermission(page, permission);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  // If the user has that permission, render the children
  if (allowed) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  return <>{children}</>;
};

export default Restricted;
