import { useField } from "formik";
import { FormInputProps } from "interfaces/formElements";
import React from "react";
import { Container, ErrorSpan, Label, Span } from "../styles";
import colors from "styles/colors";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";

interface TextInputProps extends FormInputProps {
  type?: string;
  value?: string;
  disabled?: boolean;
  showInfoIcon?: boolean;
  toolTipId?: string;
  autocomplete?: string;
  isRequired?: boolean;
  maxLength?: number;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  labelStyles?: Record<string, string>;
}

const InputComponent: React.FC<TextInputProps> = ({
  title,
  type = "text",
  disabled,
  isRequired,
  toolTipId,
  showInfoIcon,
  maxLength,
  onKeyDown,
  onInput,
  onChange,
  className,
  labelStyles,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Container className={`${meta.touched && meta.error ? "error" : ""}`}>
      <Label
        style={{
          ...labelStyles,
          ...(title === "\\t" ? { color: "transparent" } : undefined)
        }}
      >
        {title} {isRequired && <span style={{ color: colors.error }}>*</span>}{" "}
        {showInfoIcon ? <InfoIcon data-tip data-for={toolTipId} /> : null}
      </Label>
      <Span>
        <input
          disabled={disabled}
          onKeyDown={onKeyDown}
          onInput={onInput}
          type={type}
          className={className}
          min={type === "number" ? Number("0") : undefined}
          maxLength={maxLength}
          {...field}
          onChange={(e) => {
            if (typeof onChange === "function") {
              onChange(e);
            }
            if (type === "number") {
              props?.value?.replace(/^0+/, "");
            }
            /* @HINT: `onChange` for <Formik /> from `useField()` */
            if (!e.defaultPrevented) {
              field.onChange(e);
            }
          }}
          {...props}
        />
      </Span>

      {meta.touched && meta.error ? <ErrorSpan>{meta.error}</ErrorSpan> : null}
    </Container>
  );
};

export default InputComponent;
