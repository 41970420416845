import CustomButton from "components/CustomButton";
import SuccessAnimation from "components/SuccessAnimation";
import React from "react";
import Modal from ".";
import { SuccessfulActivationModalContainer } from "./styles";

interface SuccessfulSweepModalProps {
  closeSuccessModal: () => void;
}

const SuccessfulSweepModal: React.FC<SuccessfulSweepModalProps> = ({
  closeSuccessModal
}) => {
  return (
    <Modal isFooter={false} isHeader={false}>
      <div className="modal-body">
        <SuccessAnimation width={150} height={150} />

        <SuccessfulActivationModalContainer>
          <h3>Successful</h3>

          <p>
            Your sweep action request is successful. We will process your
            request within 30 minutes or less.
          </p>

          <CustomButton
            height={45}
            width={140}
            bgColor="#2DB49E"
            label="Okay"
            type="submit"
            onClick={closeSuccessModal}
          />
        </SuccessfulActivationModalContainer>
      </div>
    </Modal>
  );
};

export default SuccessfulSweepModal;
