import styled from "styled-components";

const SwitchButtonsBox = styled.div`
    background-color: #F8F8FA;
	border-radius: 6px;
	display: inline-flex;
	overflow: hidden;
	.switchButtonsItems {
		vertical-align: middle;
        cursor: pointer;
		text-align: center;
		box-sizing: border-box;
		line-height: 40px;
		padding: 0 16px;
		height: 40px;
		background-color: #F8F9F9;
		color: #5C696E;
		font-weight: 600;
		font-size: 13px;
		white-space: nowrap;
	}
	.switchOn {
		background-color: #CDE2EA;
		color: #162930;
	}
`;

export {
    SwitchButtonsBox
};