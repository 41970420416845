import React from "react";
import { DuploFullPageLoaderContainer } from "./styles";
import { ReactComponent as Logo } from "assets/svg/logo-dark.svg";

const DuploFullPageLoader = () => {
  return (
    <DuploFullPageLoaderContainer>
      <div className="loading"></div>
      <div className="logo-container">
        <Logo className="logo" />
      </div>
    </DuploFullPageLoaderContainer>
  );
};

export default DuploFullPageLoader;
