import colors from "./colors";
import { createGlobalStyle } from "styled-components";
import { size } from "utils/constants";

export const device = {
  mobile_: `(max-width: ${size.mobileL})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileL_: `(max-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tablet_: `(max-width: ${size.tablet})`,
  minitablet_: `(max-width: ${size.minitablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptop_: `(max-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopM_: `(max-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopL_: `(max-width: ${size.laptopL})`,
  laptopXM_: `(max-width: ${size.laptopXM})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

const GlobalStyles = createGlobalStyle`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
  }

  *:not(.dropdown-list):not(#modal-inner-container):not(#modal-inner-container *):not([data-role=menu]):not(#table-wrapper)::-webkit-scrollbar {
    display: none;
  }

  *:not(.dropdown-list):not(#modal-inner-container):not(#modal-inner-container *):not(#table-wrapper) {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 6px;
}

.react-tagsinput--focused {
  border-color: #006355 !important;
}

.react-pdf__Page__canvas {
  height: 600px !important;
}

.react-tagsinput-tag {
  background-color: transparent !important;
  border-radius: 2px;
  border: 1px solid #006355 !important;
  color: #000 !important;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100px !important;
}

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'EuclidCircularB', sans-serif !important;
    font-size: 15px;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    outline: none;
    background: #ffffff;
  }

  html .input-has-error-operation {
    border: 1px solid red !important;
  }

  .page-wrapper {
    height: 100%;
  }

  html .pointer-hand {
    cursor: pointer;
  }

  #root {
    height: 100%;
  }

  .text_stub {
    color: #5c696e;;
  }

  [draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
    cursor: grab;
  }

  html li.drag:active {
    opacity: 0.4;
  }

  html li.over {
    border-top: 2px solid blue;
  }

  .preview-date-holder {
    position: relative;
    font-size: 12px;
    top: auto;
    bottom: 40px;
    color: rgba(57,63,73,0.6);
  }

  .copy-button {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    border-radius: 50%;
    border: 0px solid transparent;
    padding: 4px 6px;
    cursor: pointer;
  }

  .text-v-aligned {
    vertical-align: middle;
    display: inline-block;

    & + & {
      margin-left: 10px;
    }
  }

  .actions-dropdown-wrapper > div:hover {
    background-color: #f2f2f2;
  }

  /* layout styles */

  .container {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: none;
  }

  .container.gutter-horiz-30 {
    padding: 0 30px;
  }

  .container.gutter-horiz-50 {
    padding: 0 50px;
  }

  .container.gutter-horiz-70 {
    padding: 0 70px;
  }

  .container.gutter-horiz-90 {
    padding: 0 90px;
  }

  .container.gutter-horiz-20-vert-10 {
    padding: 10px 20px;
  }

  .container.gutter-horiz-50-vert-20 {
    padding: 20px 50px;
  }

  .container.gutter-horiz-90-vert-20 {
    padding: 20px 90px;
  }

  .container.gutter-horiz-90-vert-30 {
    padding: 30px 90px;
  }

  .container.gutter-horiz-200-vert-30 {
    padding: 30px 200px;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .row + .row {
    margin-top: 15px;
  }

  .column, .double-column {
    display: flex;
    flex-direction: row;
  }

  .column .wrapper,
  .double-column .wrapper {
    width: 100%;
  }

  .column {
    flex: 1;
  }

  .double-column {
    flex: 2;
  }

  .column.fixed-50 {
    flex: 0 0 50px;
  }

  .column.fixed-100 {
    flex: 0 0 100px;
  }

  .column.fixed-200 {
    flex: 0 0 200px;
  }

  .column.fixed-300 {
    flex: 0 0 300px;
  }

  .column.fixed-400 {
    flex: 0 0 400px;
  }

  .column.fixed-500 {
    flex: 0 0 500px;
  }

  .column.fixed-600 {
    flex: 0 0 600px;
  }

  .column.fixed-700 {
    flex: 0 0 700px;
  }

  .full-vert {
    height: 100%;
  }

  @media screen and (max-width: 425px) {
    *:not(.dropdown-list) {
      -ms-overflow-style: unset;
      scrollbar-width: none;
    }

    html, body {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .preview-date-holder {
      display: none;
    }

    .authpage-inner-wrapper {
      height: auto;
    }

    column,
    .column[class*="fixed-"],
    .double-column {
      flex: 1 100%;
      width: 100%;
    }

    .double-column {
      order: 2;
    }

    .container.gutter-horiz-200-vert-30 {
      padding: 10px 20px;
    }
  }

  @media screen and (max-width: 1211px) {
    .row {
      flex-wrap: wrap;
    }

    .row + .row {
      margin-top: 10px;
    }

    .column, .column[class*="fixed-"], .double-column {
      flex-direction: column;
      flex: 0 0 100%;
    }
  }

  /* core styles for table */

  .table {
    width: 100%;
    font-size: 0.78rem;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: var(--tableBgColor, #fff);
  }

  .mobile_table {
    display: table;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    list-style: none;
    margin-top: 5px;
    padding: 0px;
  }

  .va-table {
    h4 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin: 68px 0 20px 8px;
      color: #5c696e;
    }
  }

  .txn-table {
    .table__th {
      width: 6% !important;
    }
  }

  .table__thead {
    background-color: var(--tableTitleBgColor);
    color: var(--tableTitleTextColor);
    width: auto;
  }

  .table__mobile-caption {
    display: none;
  }

  .table__td, .table__th {
    padding: var(--tableCellPadding, 10px);
    box-sizing: border-box;
    width: fit-content !important;
    white-space: nowrap;
  }

  .table__td {
    font-family: 'EuclidCircularB', sans-serif !important;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 14px;
    color: #162930;


    span {
      font-weight: 400;
      color: rgba(22, 41, 48, 1);
    }
    

    &.__base {
      span {
        font-weight: 400;
        color: rgba(22, 41, 48, 1);
      }
    }
  }

  input {
    outline: none !important;
  }

  .table__tr {
    &.__base {
      border-bottom: 1px solid #e2e2e2;
    }
  }

  .mobile_table__tr {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    padding: 20px 10px;
  }

  .mobile_table__tr + .mobile_table__tr {
    margin-top: 10px;
  }

  .mobile_table_td {
    display: flex;
    flex: 0 0 50%;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
  .mobile_table_td + .mobile_table_td span {
    text-align: right;
  }

  .mobile_table_td .super_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #162930;
    vertical-align: middle;

    span {
      color: #000
    }
  }
  
  .mobile_table_td .sub_text {
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #94A2AB;
  }

  .mobile_table_td_badge {
    color: #192850;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    padding: 1px 8px;
    vertical-align: middle;
    background: rgba(25, 40, 80, 0.18);
    border-radius: 100px;
    margin-left: 5px;
  }

  .table__tr:last-child {
    border-bottom: none;
  }

  @media screen and (min-width: 769px){

    .table__tr:hover{
      background-color: var(--tableRowBgColorHover);
    }

    .table__td:first-child, .table__th:first-child{
      text-align: left;
    }

    .table__td, .table__th{
      text-align: left;
    }
  }

  @media screen and (max-width: 768px) {
    .table, .table__thead, .table__tbody, .table__tr{
      display: block;
    }

    .table__td {
      display: flex;
      align-items:center;
    }

    .table__head {
      display: none;
    }

    .table__mobile-title {
      background-color: var(--tableTitleBgColor);
      color: var(--tableTitleTextColor);
    }

    .table__td {
      border-bottom: 1px solid var(--tableSeparatorColor);
    }

    .table__td:last-of-type {
      border-bottom: none;
    }

    .table__mobile-caption, .table__value {
      display: block;
      width: 50%;
      box-sizing: border-box;
    }

    .table__mobile-caption {
      padding-right: 2%;
    }
  }

  .table {
    --tableCellPadding: 1.5rem;
    --tableTitleBgColor: #f8f8f8;
    --tableTitleTextColor: #a4a7b0;
    --tableRowBgColorHover: #f0f0f0;
    --tableSeparatorColor: #eee;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0
  }

  .dropdown-content-items {
    cursor: pointer
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .company-icon-container {
    background: #2DB49E;
    border-radius: 3px;
    height: 24px;
    width: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-pagination-dropdown {
    .custom-select-wrapper {
        position: relative;
        user-select: none;
        width: 100%;
    }
    .custom-select__div {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }
    .custom-select__trigger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40px;
        padding: 0px 12px;
        font-weight: 300;
        color: #3b3b3b;
        max-height: 48px;
        font-size: 13px;
        line-height: 60px;
        background: #ffffff;
        cursor: pointer;
        border: 1px solid #EEEEEE;
        border-radius: 1px;
    }
 
    
    .custom-dropdown__option {
        position: absolute;
        border-radius: 6px;
        display: block;
        bottom: 54px;
        left: 0;
        right: 0;
        border: 0.5px solid #eaeaea;
        background: #fff;
        transition: all 0.5s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 1200;
        max-height: 250px;
        overflow-y: auto;
        box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
        -webkit-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
        -moz-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
    }
    .custom-select__div .custom-dropdown__option {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    .company-custom-option {
      display: flex;
      align-items: center;
      position: relative;
      padding: 2px 22px 2px 22px;
      font-weight: 300;
      color: #192851;
      font-weight: 500;
      font-size: 13.5px;
      line-height: 40px;
      cursor: pointer;
      transition: all 0.5s;
    }

    .add-company-container {
      padding: 10px 22px 8px 22px;
      margin-bottom: 22px;
      color: #5C696E;
      font-size: 13.5px;
      cursor: pointer;
    }
    
    .custom-option {
        position: relative;
        display: block;
        padding: 0 22px 0 22px;
        font-weight: 300;
        color: #192851;
        font-weight: 500;
        font-size: 13.5px;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.5s;
    }
    .custom-option:hover {
        cursor: pointer;
        background-color: #b2b2b2;
    }
    .custom-option.selected {
      color: #fff;
      background-color: #1c1c1e;
      position: relative;
      
    }

  .arrow {
    position: relative;
    height: 8px;
    width: 8px;
  }


  .arrow::before,
  .arrow::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.5s;
  }

  .arrow::before {
    left: -5px;
    transform: rotate(45deg);
    background-color: ${colors.pri};
  }

  .arrow::after {
    left: 0;
    transform: rotate(-45deg);
    background-color: ${colors.pri};
  }

  .open .arrow::before {
      left: -5px;
      transform: rotate(-45deg);
  }
  .open .arrow::after {
      left: 0;
      transform: rotate(45deg);
  }
}

.custom-dropdown {
  font-family: 'EuclidCircularB', sans-serif !important;
  margin-bottom: 1.1rem;

  .custom-select-wrapper {
    position: relative;
	  white-space: nowrap; 
	  text-overflow: ellipsis;
    user-select: none;
    width: 100%;
  }

  .custom-select__div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }

  .custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-weight: 300;
    color: #3b3b3b;
    height: 45px;
    font-size: 13px;
    line-height: 60px;
    background: #ffffff;
    cursor: pointer;
    border: 1px solid #DCDDE6;
    border-radius: 5px;
	  overflow: hidden;
	  white-space: nowrap;
	  text-overflow: ellipsis;
  }

  .company-dropdown__option {
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(22, 41, 48, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 58px;
    left: 0;
    width: 215px;
    right: 0;
    transition: all 0.5s;
    min-height: 30px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px 0;
  }
  
  .custom-dropdown__option {
    position: absolute;
	  white-space: nowrap;    
	  overflow: hidden;         
	  text-overflow: ellipsis;
    border-radius: 6px;
    display: block;
    top: 58px;
    left: 0;
    right: 0;
    border: 0.5px solid #eaeaea;
    background: #fff;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 999;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
    -webkit-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
  }

  .custom-select__div .custom-dropdown__option {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .company-custom-option {
    display: flex;
    align-items: center;
    position: relative;
    padding: 2px 22px 2px 22px;
    font-weight: 300;
    color: #192851;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 40px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .add-company-container {
    padding: 10px 22px 8px 22px;
    margin-bottom: 22px;
    color: #5C696E;
    font-size: 13.5px;
    cursor: pointer;
  }
  
  .custom-option {
    position: relative;
    display: block;
    padding: 0 22px 0 22px;
    font-weight: 300;
    color: #192851;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 40px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .custom-option:hover {
      cursor: pointer;
      background-color: #b2b2b2;
  }

  .custom-option.selected {
    color: #fff;
    background-color: #1c1c1e;

     .check-icon{
    position: absolute;
    right: 20px;
    top: 4px;
 }
  }


  .multi-custom-option {
    position: relative;
    display: block;
    padding: 10px 0;
    font-weight: 400;
    color: #162930;
    font-size: 15px;
    font-style: normal;
    cursor: pointer;
    transition: all 0.5s;
  }

  .multi-custom-option:hover {
    background-color: #e1e1e1;
  }

  .multi-custom-option.selected {
    color: #1a1a1a;
    background-color: #F0F0F0;
  }

  .arrow {
      position: relative;
      height: 8px;
      width: 8px;
  }

  .arrow::before,
  .arrow::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 0.15rem;
      height: 100%;
      transition: all 0.5s;
  }

  .arrow::before {
      left: -5px;
      transform: rotate(45deg);
      background-color: ${colors.pri};
  }

  .arrow::after {
      left: 0;
      transform: rotate(-45deg);
      background-color: ${colors.pri};
  }

  .open .arrow::before {
      left: -5px;
      transform: rotate(-45deg);
  }

  .open .arrow::after {
      left: 0;
      transform: rotate(45deg);
  }
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
  z-index: 1;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input[class="surf"] {
  border: 0px solid transparent !important;
  width: 100%;
  display: block;
  height: 100%;
}

input {
  font-weight: normal !important;
  font-size: 14px !important;
  border: 1px solid #DCDDE6 !important;
}

input:checked + .slider {
  background-color: #2DB49E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2DB49E;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}



/* input, .custom-select__trigger {
  border: 1px solid #d1d1d1 !important;
} */

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.lds-dual-ring {
  display: inline-block;
  width: 25px;
  height: 25px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.fp-loader-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #f8f8f8ad;
	margin-top: 100px;
}

.fp-loader-container .fp-loader {
	top: 30%;
	left:48%;
	z-index: 1000;
	position: absolute;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bouncing-animation {
  animation: key1 .5s ease infinite alternate;
}

@keyframes key1{
  0% {transform: translateY(0px);}
  100% {transform: translateY(-12px);}
}


.div-loader {
  display: inline-block;
  width: 25px;
  height: 25px;
}

input[type="date"] {
  font-family: 'EuclidCircularB', sans-serif !important;
  font-size: 13.5px !important;
  color: #000;
}

.div-loader:after {
  content: " ";
  margin-left: 30px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #2DB49E;
  border-color: #2DB49E transparent #2DB49E transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.payoutmodal-container {
  .modal-content {
    max-height: calc(100vh - 30px);
    overflow-y: auto
  }
}

.payoutsummary-container {
  .modal-content {
    max-height: calc(100vh - 30px);
    overflow-y: auto
  }
}

html h4.title {
  letter-spacing: normal;

  span {
    vertical-align: middle !important;

    svg {
      vertical-align: middle;
    }
  }

  @media ${device.mobile_} {
    font-size: 14px;

    svg#drawer {
      margin-right: 10px;
      padding: 10px;
    }
  }
}

.highlighted-password {
  color: ${colors.pri}
}

.HW_badge_cont {
 	position: absolute !important;
} 

.HW_badge {
  background: #fc3d03 !important;
  top: -30px !important;
  left: 12px !important;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  font-size: 7px !important;
  display: flex;
  justify-content: center;
}

div#proofOfAddress {
  width: 200px;

  @media ${device.laptop} {
    width: 300px; 
  }
}

.CalendarDay__blocked_calendar {
   background: #fff !important;
    border: 1px solid #e4e7e7 !important;
    color: #cacccd !important;
}
.CalendarDay__blocked_out_of_range, .CalendarDay__default{
  border: none !important;
}
.CalendarDay__selected_span,.CalendarDay__selected_span:hover {
  background: rgba(45, 180, 158,0.3) !important;
}
..CalendarDay__selected{
  background: rgb(45, 180, 158) !important;
}
.DayPicker__withBorder{
box-shadow: none !important;
}
.react-tel-input .form-control:focus{
      box-shadow: none !important;
}
`;

export default GlobalStyles;
