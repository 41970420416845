import {
  BillingInfoResponse,
  PayoutSummaryInterface,
  SendMoneySummary,
  WalletDataSummaries
} from "interfaces/wallet";
import React from "react";

const WalletContext = React.createContext<{
  storeSendMoneySummary?: (value: SendMoneySummary) => void;
  storeBillingInfo?: (value: BillingInfoResponse) => void;
  storePayoutSummaryInfo?: (value: PayoutSummaryInterface) => void;
  storeCustomPayoutMetaInfo?: (value: {}) => void;
  getPayout?: Function;
  getBilling?: Function;
  getSendMoney?: Function;
  getCustomPayoutMetaInfo?: Function;
}>({});
export const WalletProvider = WalletContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
  value: WalletDataSummaries;
};

export const WalletProviderContainer: React.FC<Props> = ({
  children,
  value = {
    billingInfo: {},
    sendMoneySummary: {},
    payoutSummaryInfo: {},
    customPayoutMetaInfo: {}
  }
}) => {

  const testSummary = React.useRef(value);

  const storeBillingInfo = (value: BillingInfoResponse) => {
    testSummary.current.billingInfo = value;
  };

  const getPayout = () => {
    return testSummary.current.payoutSummaryInfo;
  };

  const getSendMoney = () => {
    return testSummary.current.sendMoneySummary;
  };

  const getBilling = () => {
    return testSummary.current.billingInfo;
  };

  const getCustomPayoutMetaInfo = () => {
    return testSummary.current.customPayoutMetaInfo;
  };

  const storeSendMoneySummary = (value: SendMoneySummary) => {
    testSummary.current.sendMoneySummary = value;
  };

  const storePayoutSummaryInfo = (value: PayoutSummaryInterface) => {
    testSummary.current.payoutSummaryInfo = value;
  };

  const storeCustomPayoutMetaInfo = (value: {}) => {
    testSummary.current.customPayoutMetaInfo = value;
  };

  return (
    <WalletProvider
      value={{
        storeBillingInfo,
        storeSendMoneySummary,
        storePayoutSummaryInfo,
        storeCustomPayoutMetaInfo,
        getPayout,
        getBilling,
        getSendMoney,
        getCustomPayoutMetaInfo
      }}
    >
      {children}
    </WalletProvider>
  );
};

export const useWalletData = () => {
  return React.useContext(WalletContext);
};

