import InputComponent from "components/FormElements/inputs";
import styled from "styled-components";
import { device } from "styles";
import colors from "styles/colors";
import DropdDownIcon from "assets/png/custom-down-arrow.png";
import ProductTourBg from "assets/png/product-tour[intro].png";

interface ModalContentProps {
  widthSize?: number;
  width?: string;
}

interface ModalInnerContainerProps {
  scrollable: boolean;
  scrollableHeight: number;
}

interface SubTextProps {
  isHeader: boolean;
}
interface SuccessApprovalIconProps {
  isTrailing?: boolean;
}

interface FilterElementProps {
  isActive: boolean;
}

interface ModalFooterProps {
  isOnlyClose?: boolean;
}

interface AuthenticationOptionProps {
  isActiveColor: string;
}
interface CustomInputProps {
  isErrored?: boolean;
}
interface StyledTableEmptyContainerProps {
  isOnModal?: boolean;
}
interface TransactionDetailsTypeProps {
  templateSize?: string;
}
interface PermissionModalContainerProps {
  select?: boolean;
}
interface ClosePreviewIconProps {
  isQuickActions?: boolean;
}
interface SuccessfulActivationModalContainerProps {
  hasVendorLink?: boolean;
}
const ModalContainer = styled.div`
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, 0.7);
  transition: opacity 0.9s ease-in;
  .close-modal-icon {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcdde6;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const ModalContent = styled.div<ModalContentProps>`
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  min-height: 1px;
  transition: background-color 0.9s ease-out;
  .modal-body {
    padding: 30px;

    .switchButtonsItems {
      width: 100%;
    }
  }
  width: ${(props) => (props.width ? props.width : "490px")};
  @media ${device.laptop} {
    min-width: ${(props) => props.widthSize}px;
  }
  @media ${device.mobileL} {
    width: ${(props) => (props.width ? props.width : "490px")};
  }
`;

const ModalHeader = styled.div`
  background: #f9f9f9;
  border-radius: 10px 10px 0px 0px;
  height: 64px;
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  font-size: 14.5px;
  font-weight: 600;
  color: #162930;
  .close-icon {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcdde6;
    cursor: pointer;
  }
`;

const ModalFooter = styled.div<ModalFooterProps>`
  background: #f9f9f9;
  height: 78px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  justify-content: ${(props) =>
    props.isOnlyClose ? "center" : "space-between"};
  padding: 0 25px 0 15px;
  border-radius: 0px 0px 10px 10px;
`;

const ModalInnerContainer = styled.div<ModalInnerContainerProps>`
  overflow-y: ${(props) => (props.scrollable ? "scroll" : "visible")};
  scrollbar-width: auto !important;
  height: ${(props) =>
    props.scrollable ? `${props.scrollableHeight}px` : "auto"};
  position: relative;

  &:hover {
    &::-webkit-scrollbar {
      width: 6px;
      display: block !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const CancelButton = styled.div<ModalFooterProps>`
  border: 1px solid rgba(92, 105, 110, 0.17);
  width: 130px;
  height: 40px;
  display: flex;
  color: ${(props) => (props.isOnlyClose ? "#fff" : "")};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props) => (props.isOnlyClose ? "#2DB49E" : "")};
`;

const FilterModalFooter = styled.div`
  background: #f9f9f9;
  height: 78px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 40px;
  border-radius: 10px 10px 10px 10px;
`;

const ModalRightFooter = styled.div`
  background: #f9f9f9;
  height: 78px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  padding: 0 20px;
  border-radius: 10px 10px 10px 10px;
`;

const FilterAmountContainer = styled.div`
  max-width: 150px;
  margin-bottom: 20px;
  input {
    border: 1px solid #dcdde6 !important;
    height: 0;
    padding: 18px 12px;
  }
  label {
    text-align: left;
  }
`;

const DateFilterContainer = styled.div`
  max-width: 350px;
  margin-bottom: 20px;
  input {
    border: 1px solid #dcdde6 !important;
  }
`;

const AddRecipientContainer = styled.div`
  padding: 0 10px;
`;

const FilterTypeContainer = styled.div`
  margin: 8px 0 20px 0;
  display: grid;
  grid-template-columns: 45% 45%;
  grid-column-gap: 15px;
  grid-row-gap: 10px;

  @media ${device.laptop} {
    grid-template-columns: 32% 32% 32%;

    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }
`;

const SliderAmountContainer = styled.div`
  border: 1px solid #dcdde6;
  border-radius: 5px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 13px 0 25px 0;
  color: #babfc1;
  width: 100px;
  span {
    margin-right: 4px;
  }
`;

const FilterTypeElement = styled.div<FilterElementProps>`
  background: #ffffff;
  border: 1px solid ${(prop) => (prop.isActive ? "black" : "#dcdde6")};
  border-radius: 5px;
  width: 100%;
  height: 35px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${(prop) => (prop.isActive ? colors.pri : "#babfc1")};

  @media ${device.laptop} {
    width: 108px;
  }
`;

const VirtualAccountModalContainer = styled.div`
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 50% 50%;
    grid-column-gap: 15px;
  }
`;

const NewBusinessTwoGridContainer = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 22px;
  @media ${device.laptop} {
    grid-template-columns: 47% 47%;
    grid-column-gap: 25px;
  }
  @media ${device.mobile_} {
    display: block;
  }
`;

const TwoGridContainer = styled.div`
  display: grid;
  width: 100%;
  @media ${device.mobile_} {
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    grid-template-columns: 48% 48%;
    grid-column-gap: 20px;
  }
  @media ${device.mobile_} {
    display: block;
  }
`;

const ChangeLogoContainer = styled.div`
  margin: 40px;
  border: 1px dotted #000;
  .drop {
    text-align: center;
    margin: 30px 0;
  }
`;

const LogoUploadCancelContainer = styled.div`
  cursor: pointer;
`;

const ChangeLogoButtonContainer = styled.div`
  text-align: center;
  background: #f9f9f9;
  height: 78px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 40px;
  border-radius: 10px 10px 0px 0px;
`;

const BusinessCurrencyContainer = styled.h3`
  color: #103129;
  fontsize: 20px;
  fontweight: 700;
  margintop: 3px;

  span {
    color: #103129;
  }
`;

const VirtualAccountModalItem = styled.div`
  .header {
    font-size: 14px;
    line-height: 18px;
    color: #5c696e;
  }
  .value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #003f34;
  }
`;

const ResendOtpContainer = styled.div`
  text-align: center;
  .disable-selection {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: not-allowed;
  }
  p {
    margin: 0 0 10px 0;
    padding: 0;
    color: #2db49e;
    cursor: pointer;
    text-decoration: underline;
  }
  .alternate {
    text-decoration: none;
    cursor: auto;
    color: #192850;
    font-weight: 300;
    margin-top: 16px;

    span {
      text-decoration: underline;
      color: #2db49e;
      font-weight: 400;
      cursor: pointer;
    }
  }
`;

const TransactionDetailsDateContainer = styled.div`
  text-align: right;
  margin-bottom: 30px;

  .date-container {
    color: #5c696e;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 300 !important;
  }

  p {
    color: #5c696e;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  .copy-container {
    width: 24px;
    height: 24px;
    background: #ededf0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
  }
  .session_id {
    margin-top: 7px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;

const TransactionDetailsAmountContainer = styled.div`
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  padding-bottom: 20px;

  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 15px;
  }
  p {
    color: #5c696e;
    font-size: 15px;
    margin: 0 0 5px 0;
    padding: 0;
  }
  h3 {
    span {
      color: #003f34;
    }
    font-size: 25px;
    margin: 0;
    padding: 0;
  }
`;
const WithdrawDetailsContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;

  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
  }
  p {
    color: #5c696e;
    font-size: 15px;
    margin: 0 0 5px 0;
    padding: 0;
  }
  h3 {
    span {
      color: #003f34;
    }
    font-size: 25px;
    margin: 0;
    padding: 0;
  }
`;

const TransactionDetailsTypeContainer = styled.div<TransactionDetailsTypeProps>`
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: ${(props) =>
      props.templateSize ? props.templateSize : "32% 32% 32%"};
    grid-column-gap: 15px;
  }
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  padding: 10px 0;
`;

const TransactionBankTypeContainer = styled.div`
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 15px;
  }
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  padding: 15px 0;
`;

const TransactionDescriptionTypeContainer = styled.div`
  display: grid;
  @media ${device.laptop} {
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 15px;
  }
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  padding: 15px 0;
`;
const TransactionSupportingDocContainer = styled.div`
  padding: 15px 0;
`;

const TransactionButtonContainer = styled.div`
  margin: 10px 0 30px 0;
  button {
    width: 100%;
    max-width: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const OtpInputContainer = styled.div`
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: center;
  width: auto;
  input {
    border: 1px solid #dcdde6;
    border-radius: 5px;
    width: 40px !important;
    height: 40px;
    margin: 0 7px !important;
  }
`;

const TransactionDetailsSourceContainer = styled.div`
  display: grid;
  border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  padding: 10px 0;
  @media ${device.laptop} {
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
  }
`;

const SuccessfulActivationModalContainer = styled.div<SuccessfulActivationModalContainerProps>`
  text-align: center;
  margin-bottom: 40px;
  min-height: ${(props) => (props.hasVendorLink ? "200px" : "0px")};
  h3 {
    color: #192850;
  }
  p {
    color: #192850;

    line-height: 20px;
    max-width: 310px;
    font-size: 15px;
    margin: auto;
  }
  button {
    margin-top: 35px;
  }
  a {
    color: #2db49e;
  }
`;

const NameResolutionContainer = styled.div`
  div:first-child {
    margin-bottom: 8px;
  }
  .container {
    width: 100%;
    text-align: right;
    margin-bottom: 1.2rem;
  }
  #loading {
    width: 10px;
    height: 10px;
    border: 2px solid #000 !important;
    border-top-color: #fff !important;
  }
  @media ${device.mobile_} {
    #loading {
      height: auto;
    }
  }
`;
const NameResolutionLabel = styled.span`
  color: ${colors.pri};
  font-size: 16px;
  background: #d4fcea;
  padding: 4px 12px;
`;

const TransactionDetailsSourceElement = styled.div`
  overflow-x: auto;
  .header {
    color: #5c696e;
    font-size: 14px;
    margin-bottom: 0;
  }
  .value {
    display: grid;
    grid-template-columns: 120px auto;
    font-size: 14px;
    margin: 0 0 8px 0;
    padding: 0;
    margin-top: 7px;
    color: #162930;
    span {
      color: #003f34;
    }
  }
`;

const TransactionDetailsChannelContainer = styled.div`
  padding: 10px 0;
  display: grid;
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 32% 68%;
    grid-column-gap: 15px;
  }
  .header {
    color: #5c696e;
    font-size: 14px;
    margin-bottom: 0;
  }
  .value {
    font-size: 14px;
    margin-top: 7px;
    text-transform: capitalize;
    color: #162930;
    span {
      color: #003f34;
    }
  }
`;

const TransactionDetailsTypeElement = styled.div`
  .header {
    color: #5c696e;
    font-size: 14px !important;
    font-weight: 300 !important;
    margin-bottom: 0px;
  }

  .status-pill {
    width: fit-content;
    padding: 0px 10px;
    border-radius: 4px;
    font-size: 12px;
  }

  .success {
    background: #bbe5b3;
    color: #414f3e !important;
  }

  .error {
    background: #ed5b5c;
    color: #000000 !important;
  }
  .warning {
    background: #fdefcb;
    color: #d97724 !important;
  }

  .value {
    font-size: 14px !important;
    line-height: 24px;
    font-weight: 400;
    overflow-wrap: break-word;
    text-transform: capitalize;
    margin-top: 8px;
    color: #162930;
    span {
      color: #003f34;
    }
  }
`;

const NameIconContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const NameText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2db49e;
  margin-left: 5px;
`;

const DeleteContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
`;
const InfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
`;
const DeleteText = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #162930;
  margin: 15px;
`;

const TextContent = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: #192850;
  margin-bottom: 30px;
  .__boldText {
    font-weight: 600;
  }
  .info {
    width: 85%;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 300;
    color: #000;
  }
`;
const PolicyTextContent = styled.p`
  font-weight: 300;
  font-size: 15px;
  color: #192850;
  line-height: 19px;
`;

const CancelButtonContainer = styled.div`
  margin-right: 30px;
`;

const IconTextContainer = styled.div`
  margin-bottom: 100px;
`;

const InputContainer = styled.div`
  margin: 20px 0;
`;

const SuccessMessage = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #192850;
`;

const MessageDetails = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #192850;
  margin: 20px 0 50px 0;
`;

const AutomatedModalContainer = styled.div`
  margin: 80px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  margin: auto;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: 250px 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubTextContainer = styled.div<SubTextProps>`
  background: #f9f9f9;
  font-size: 14px;
  text-align: center;
  padding-bottom: 25px;
  padding-left: 20px;
  border-top: 1px solid #eee;
  padding-right: 20px;
  padding-top: ${(props) => (!props.isHeader ? "30px" : "16px")};
`;

const NoPhoneNumberIcon = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const NoPhoneNumberBody = styled.div`
  text-align: center;
  font-size: 15px;
  .link {
    color: #46bca8;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 30px;
  }
`;

const PhoneUpdateContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h5 {
    font-size: 18px;
  }
`;

const PhoneUpdateText = styled.div`
  h5 {
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  div {
    text-align: center;
    a {
      color: #00c7b8;
      font-size: 16px;
      margin: auto;
      cursor: pointer;
    }
    a :hover {
      opacity: 0.7;
    }
  }
`;

const MarkAsPaidText = styled.div`
  align-items: center;
  width: 60%;
  margin: auto h4 {
    font-size: 18px;
    font-weight: 200;
    color: yellow;
  }
`;

const MakePayoutScheduleContainer = styled.div`
  max-width: 28rem;
`;

const MakePayoutContainer = styled.div`
  .payout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wallet-container {
    margin: 0;
    padding: 10px 0 0 0;
  }
  .wallet-balance {
    span {
      color: #000;
    }
  }
  .row {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .wallet-balance {
      span {
        color: #162930;
        font-size: 23px;
      }
    }
    .bulk-payout {
      margin-top: 20px;
    }
  }
  .error {
    border-color: #f84f31 !important;
  }
  .error-message {
    display: block;
    margin-bottom: 1.1rem;
    color: #f84f31;
    font-size: 0.875rem;
    margin-top: -12px;
  }

  input {
    height: 48px;
    padding: 0 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    border-width: 1px;
    outline: none;
    margin-bottom: 1.1rem;
  }
`;

const AddNewRecipientContainer = styled.div`
  color: ${colors.pri};
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 30px;
`;

const AddNewRecipientScheduleContainer = styled.div`
  color: ${colors.pri};
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  z-index: 9;
  right: 0;
`;

const RecipientDropDownContainer = styled.div`
  .custom-dropdown {
    margin-bottom: 5px;
  }
  .error {
    margin-bottom: 10px;
  }
`;

const PayoutSummaryHeader = styled.div`
  text-align: center;
  margin: 25px auto;
  color: #504f60;
  font-weight: 300;
  max-width: 400px;
  line-height: 21px;
  span {
    color: #504f60;
  }
`;

const PayoutSummaryBody = styled.div`
  background: #f9f9f9;
  width: 80%;
  margin: 0 auto 60px auto;
  padding: 20px;
  .border {
    border-bottom: 1px solid rgba(22, 41, 48, 0.05);
  }
  .border-top {
    padding-top: 25px;
    border-top: 1px solid rgba(22, 41, 48, 0.05);
  }
  .date {
    font-weight: 600;
    color: #5c696e;
    font-size: 14px;
    margin-bottom: 25px;
    font-weight: 300;
  }
`;

const PayoutSummaryGrid = styled.div`
  display: grid;
  margin-top: 10px;
  padding-bottom: 15px;
  grid-template-columns: 45% 45%;
  grid-column-gap: 15px;
  @media ${device.laptop} {
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 15px;
  }
  .title {
    margin: 0 0 8px 0;
    font-size: 14px;
    color: #5c696e;
    font-weight: 300;
  }
  .value {
    margin: 0 0 5px 0;
    font-size: 14px;
    color: #003f34;
    word-break: break-word;
    span {
      color: #003f34;
    }
  }
`;

const AuthenticationOptionHeader = styled.div`
  text-align: center;
  .header {
    color: #504f60;
    font-size: 16px;
    margin-top: 35px;
  }
  .sub-text {
    color: #192850cc;
    font-size: 14px;
    span {
      color: ${colors.pri};
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const AuthenticationOptionBody = styled.div`
  text-align: center;
  margin: 80px 20px 100px 20px;
  display: flex;
  justify-content: space-between;
  @media ${device.laptop} {
    margin: 80px 80px 100px 80px;
  }
`;

const AuthenticationOption = styled.div<AuthenticationOptionProps>`
  width: 150px;
  height: 165px;
  border: ${(props) =>
    props.isActiveColor === "#2DB49E"
      ? `1px solid ${props.isActiveColor}`
      : "0.5px solid #192850"};
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isActiveColor ? props.isActiveColor : "")};
  svg {
    path {
      fill: ${(props) => (props.isActiveColor ? props.isActiveColor : "")};
    }
  }
  &.disable {
    border: 1px solid #d2d4dc !important;
    color: #d2d4dc !important;
    cursor: not-allowed !important;
  }
  &:hover {
    border: 1px solid ${colors.pri};
  }
  .disable {
    border: 1px solid #192850 !important;
  }
`;
const BulkPayoutButton = styled.button`
  background: #fff;
  color: #162930;
  border: 1px solid ${colors.pri};
  height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
`;

const BulkPayoutUploadContainer = styled.div`
  margin: 5px auto;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info {
    background: rgba(240, 244, 249, 0.6);
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    p {
      font-size: 14px;
      margin: 0 0 10px 0;
      padding: 0;
      color: rgba(92, 105, 110, 0.6);
    }
  }
`;

const BulkPayoutDropzoneContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  .button-container {
    margin: 50px 30px 30px 30px;
    button {
      width: 100%;
      margin: 0 !important;
    }
  }
  .drop {
    border: 2px solid #eee;
    margin: 5px 30px;
    height: 250px;
    border-radius: 3px;
    .dotted {
      margin: 20px;
      height: 170px;
      border: 2px dotted #eee;
      padding: 20px;
      .file-info {
        font-size: 13px;
        color: #5c696e;
      }
      .header {
        color: #6e757c;
        font-size: 12px;
        line-height: 15px;
      }
      img {
        max-width: 80px;
        display: flex;
        justify-content: center;
        margin: 20px auto 25px auto;
      }
      p {
        text-align: center;
      }
    }
  }
`;
const AddTeamMemberContainer = styled.div`
  padding: 0 15px;
  .custom-dropdown .custom-dropdown__option {
    max-height: 120px;
  }
`;

const BulkPayoutPreviewContainer = styled.div`
  max-width: 350px;
  margin: 5rem auto;
  text-align: center;
  p {
    margin-top: 25px;
    line-height: 20px;
    span {
      color: #000;
    }
  }
`;

const SchedulePayoutContainer = styled.div`
  h6 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #192850;
    margin-bottom: 5px;
  }
  .option-buttons {
    display: flex;
    justify-content: space-between;
  }
  .calendar-icon {
    top: -8px;
  }
`;

const OptionButton = styled.div<AuthenticationOptionProps>`
  width: 90px;
  height: 30px;
  background: #fff;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: ${(props) => (props.isActiveColor ? props.isActiveColor : "#babfc1")};
  border: 1px solid
    ${(props) => (props.isActiveColor ? "#0b2e28" : "rgba(92, 105, 110, 0.17)")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
`;

const DateInputContainer = styled.div`
  max-width: 300px;
`;

const PaymentCounterOuterContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 14.5px;
    color: #babfc1;
    margin: 0 0 0 10px;
    padding: 10px 0 0 0;
  }
`;

const PaymentCounterContainer = styled.div`
  border: 1px solid #dcdde5;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  margin-top: 10px;
  &.disable-option {
    pointer-events: none;
    color: #aaa;
    background: #f5f5f5;
    input {
      background: #f5f5f5;
    }
  }
  .counter {
    padding: 0 5px;
    input {
      height: 40px;
      width: 50px;
      border: none !important;
      outline: none !important;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .cta {
    color: ${colors.pri};
    font-size: 26px;
    padding: 6px 20px;
    cursor: pointer;
    :first-child {
      border-right: 1px solid #dcdde5;
    }
    :last-child {
      border-left: 1px solid #dcdde5;
    }
  }
`;

const StyledCustomInput = styled(InputComponent)<CustomInputProps>`
  border: ${(props) =>
    props.isErrored
      ? `1px solid ${colors.error}`
      : "1px solid #d1d1d1"} !important;
`;

const StyledCustomDropDown = styled.div<CustomInputProps>`
  .custom-select__trigger {
    border: ${(props) =>
      props.isErrored
        ? `1px solid ${colors.error}`
        : "1px solid #d1d1d1"} !important;
  }
`;
const StyledCalendarInputWrapper = styled.div<CustomInputProps>`
  max-width: 100%;
  input,
  .custom-select__trigger {
    border: ${(props) =>
      props.isErrored
        ? `1px solid ${colors.error}`
        : "1px solid #d1d1d1"} !important;
  }
`;
const AccountNameLoader = styled.div`
  #loading {
    width: 12px;
    height: 12px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: ${colors.pri};
  }
`;
const PermissionModalContainer = styled.div<PermissionModalContainerProps>`
  padding: 20px 40px;
  .icon {
    height: 40px;
    width: 40px;
    background: #f6f6f6;
    border-radius: 50%;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    h3 {
      color: #050301;
      font-size: 19px;
      span {
        font-weight: 200;
      }
    }
  }
  .content {
    background: rgba(22, 41, 48, 0.03);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    height: 100%;
    margin: 20px 0;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid rgba(122, 121, 120, 0.16);
        padding: 20px;
        color: #000;
        font-weight: 300;
        display: ${(props) => (props.select ? "flex" : "block")};
        align-items: center;

        label {
          margin-left: ${(props) => (props.select ? "10px" : "0")};
        }
        span {
          flex-shrink: 0;
        }
      }
    }
  }
`;

const InvoiceDeliveryModalContainer = styled.div`
  padding: 20px 40px;

  .header {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #5c696e;
  }

  .cancelingHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #162930;
  }
`;
const ReminderConfirmationModalContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  position: relative;

  .icon {
    margin-top: 40px;
  }

  h3 {
    margin-bottom: 0;
  }

  .close-icon {
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcdde6;
    cursor: pointer;
  }
`;

const InvoiceDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px 0;

  p {
    margin: 0 15px 0 0;
    padding: 0;
    color: #5c696e;
    font-size: 14px;
  }

  .datespan {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .calendar-icon {
    position: absolute;
    right: 15px;
  }

  input {
    height: 45px;
    padding: 0 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background: #eeeeee;
    border: 1px solid #eeeeee !important;
    border-radius: 7px;
    outline: none;
  }

  &:focus-within svg {
    color: #2054d2;
  }

  &:focus-within span:before {
    border-color: #d2ddf7;
  }

  &.error input {
    border-color: #f84f31;
  }

  &.error:focus-within span:before {
    border-color: #ffddd8;
  }
`;

const DocumentWrapper = styled.ul`
  margin: 16px 0;
  list-style-type: none;
  padding: 0;
  display: grid;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
`;
const SupportingDocumentItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fdfdfd;
  border: 1.4px solid #e1e1eb;
  border-radius: 8px;
  margin: 0 0 15px 0;
  padding: 6px 14px;
  & div:first-of-type {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  a {
    color: ${colors.pri};
    cursor: pointer;
  }
`;

const PayoutApproversContainer = styled.div`
  margin-top: 20px;
  overflow: scroll;

  .table {
    --tableCellPadding: 0.85rem 1.5rem;
  }

  & span .letter {
    font-size: 14px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    color: #fff;
  }
  .payout-reminder {
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    background: #dafff9;
    color: #2db49e;
    cursor: pointer;
    border: none;
  }
  .payout-reminder:disabled,
  button[disabled] {
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    background: #eee;
    color: #babfc1;
    cursor: pointer;
    border: none;
  }
`;

const StyledTableEmptyContainer = styled.div<StyledTableEmptyContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: ${(props) => (props.isOnModal ? "40px 0" : "80px 0")};
  p {
    color: #5c696e;
    margin: 10px 0 0 0;
    padding: 0 0 0 0;
  }
`;
const PayoutAuditTrailsContainer = styled.div`
  margin-top: 20px;

  .header {
    padding: 0.85rem;
    text-transform: uppercase;
    background: #f8f9f9;
    color: #a4a7b0;
    font-size: 12.5px;
    font-weight: bold;
  }
  ul {
    margin: 16px;
    padding: 0;
    list-style-type: none;
    li {
      color: #162930;
      font-weight: 400;
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 16px;
      gap: 5px;
      display: flex;
      justify-content: start;
      align-items: center;

      & span:first-of-type {
        height: 35px;
        flex-shrink: 0;
        position: relative;
        overflow: visible;
      }
      .letter {
        font-size: 14px;
        font-weight: 800;
        height: 35px;
        line-height: 35px;
        width: 35px;
      }
      small {
        color: #6d6f75;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
`;

const AuditTrailSection = styled.ul`
  margin-top: 40px;
  .letter {
    color: #fff;
  }
  .comment {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
  }
  li {
    & span:not(.letter):first-of-type {
      &:before {
        content: "";
        position: absolute;
        border: 0.8px solid #dcdde5;
        height: 32px;
        right: 50%;
        bottom: -33px;
      }
    }
  }
  li.no-trail {
    & span:not(.letter):first-of-type {
      &:before {
        content: "";
        position: absolute;
        border: 0;
      }
    }
  }

  .comment:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      border: 0.8px solid #dcdde5;
      height: 29px;
      left: 16px;
      bottom: -30px;
    }
  }
`;

const FailedApprovalIcon = styled.div<SuccessApprovalIconProps>`
  background: #ed5b5c;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  flex-shrink: 0;
  &:before {
    content: "";
    position: absolute;
    border: ${(props) => (props.isTrailing ? "0" : "0.8px solid #dcdde5")};
    height: 32px;
    right: 50%;
    bottom: -33px;
  }
  &:after {
    content: "x";
    position: absolute;
    color: #fff;
    font-size: 25px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
`;
const SuccessApprovalIcon = styled.div<SuccessApprovalIconProps>`
  background: #00cf40;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    border: ${(props) => (props.isTrailing ? "0" : "0.8px solid #dcdde5")};
    height: 32px;
    right: 50%;
    bottom: -33px;
  }
`;
const ApprovalCommentWrapper = styled.div`
  box-shadow: 0px 1px 4px rgba(189, 189, 189, 0.34375);
  border-radius: 2px;
  margin-top: 16px;
  width: 100%;
  .header {
    background: rgba(45, 180, 158, 0.12);
    padding: 6px 16px;
    text-transform: none;
    p {
      color: #01493d;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      small {
        font-size: 12px;
        color: #6d6f75;
      }
    }
  }
  .body {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #5c696e;
  }
`;

const ApprovePayoutContainer = styled.div`
  p,
  label {
    font-weight: 400;
    font-size: 14px;
    color: #5c696e;
    margin: 0;
  }
  & > div {
    margin: 30px 0;
  }
  label {
    padding-bottom: 10px;
    display: block;
  }
  & textarea:focus {
    outline: none !important;
    border: 1px solid ${colors.pri};
  }
  .custom-select-wrapper {
    margin: 30px 0;
  }
  .custom-select__trigger {
    height: 40px !important;
  }
  textarea {
    width: 100%;
    border: 1px solid #dcdde6;
    border-radius: 5px;
    width: 96%;
    font-weight: 500;
    padding: 12px;
    font-family: "EuclidCircularB", sans-serif !important;
  }
`;

const StyledPreviewModal = styled.div`
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-content {
    position: relative;
    margin: 5% auto;
    height: 0px;
    width: fit-content;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    animation-name: modalopen;
    animation-duration: var(--modal-duration);
  }

  .modal-body {
    padding: 0;
    background: transparent;
    height: 500px;
    width: 450px;
  }
  iframe {
    width: 100%;
    height: 100%;
    z-index: 0.3;
    position: relative;
  }

  @keyframes modalopen {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ClosePreviewIcon = styled.div<ClosePreviewIconProps>`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.isQuickActions ? "#162930" : "#dcdde6")};
  cursor: pointer;
  right: ${(props) => (props.isQuickActions ? "-25px" : "-35px")};
  top: ${(props) => (props.isQuickActions ? "-30px" : "-35px")};
`;

const AppsComplianceBody = styled.div`
  width: 835px;
  margin-top: 10px;
  margin: 0 auto;

  p {
    background: #d9d9d93d;
    padding: 20px 30px;
    border-radius: 10px;
  }
`;

const AppsConnectionBody = styled.div`
  text-align: center;
`;
const TransactionTypeLabel = styled.div`
  font-weight: 600;
  border-radius: 7px;
  border: 1px solid #ecebf5;
  font-size: 14px;
  color: #0e0842;
  padding: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const TransactionTypeContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;

  .selectBox {
    position: relative;
    cursor: pointer;
  }

  .selectBox select {
    width: 100%;
    padding: 14px 22px 14px 10px;
    font-weight: 300;
    color: #3b3b3b;

    font-size: 13px;
    background: #ffffff;
    cursor: pointer;
    border: 1px solid #dcdde6;
    border-radius: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${DropdDownIcon});
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto, 100%;
  }

  .selectBox select::-ms-expand {
    display: none;
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #checkboxes {
    border: 1px #dadada solid;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 6px;
    top: -48px;
    left: 0;
    border: 0.5px solid #eaeaea;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    z-index: 999;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 10px 17px 48px -16px rgba(0, 0, 0, 0.2);
    padding: 20px 0;

    p {
      padding: 0 22px 0 22px;
      opacity: 60%;
      font-weight: 400;
      margin: 0 0 12px;
      font-weight: 14px;
    }
    input[type="checkbox"] {
      margin-right: 8px;
      accent-color: ${colors.pri};
      padding: 5px;
      height: 16px;
      width: 16px;
    }
  }

  #checkboxes label {
    padding: 0 22px 0 22px;
    line-height: 40px;
    z-index: 1000;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
  }

  #checkboxes label:hover {
    background-color: #f3f5f7;
  }
`;

const TransactionFilterWrapper = styled.div`
  z-index: 2;
  box-shadow: 0px 6px 32px 0px rgba(21, 41, 82, 0.12);
  position: absolute;
  left: -3px;
  top: -3px;
`;
const WithdrawTransactionWrapper = styled.div`
  position: relative;
  .title {
    text-align: center;
    margin: 0px 0 25px 0;
    color: #162930;
    font-size: 16px;
  }
  .sub-text {
    text-align: center;
    margin: 10px auto 35px;
    font-weight: 300;
    width: 60%;
  }
  .close-icon {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcdde6;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }
  .details {
    border-radius: 5px;
    background: #f9f9f9;
    padding: 20px;
  }
  hr {
    border-top: 1px solid rgba(22, 41, 48, 0.05);
  }
`;

const QuickActionsContent = styled.div`
  .title {
    font-size: 18px;
    color: #162930;
    font-weight: 600;
    margin-bottom: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 16px 0;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    h3 {
      margin: 5px 0;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.064px;
    }
    #loading {
      width: 10px;
      height: 10px;
      border: 2px solid #000 !important;
      border-top-color: #fff !important;
      margin-left: 8px;
    }

    p {
      margin: 0;
      color: rgba(148, 162, 171, 0.8);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const MoveFundsModalForm = styled.form`
  input {
    height: 42px;
    padding: 0 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    border-width: 1px;
    outline: none;
    margin-bottom: 1.1rem;
  }
`;
const InitiatorInviteForm = styled.form`
  label {
    font-weight: 300;
  }
  .error {
    margin-bottom: 1.1rem;
    margin-top: -12px;
  }
  .custom-dropdown .custom-select__trigger {
    margin: 0;
  }
  .custom-select__trigger.error {
    border: 1px solid #f84f31 !important;
  }
`;
const ProductTourContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;

  .intro-left {
    background-image: url(${ProductTourBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px 0 0 10px;
  }

  .content {
    padding: 60px 40px 30px;

    h2 {
      color: #162930;
      font-size: 25px;
      line-height: 44px;
      margin: 0;
    }
    p {
      color: #5c696e;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
    }

    ul {
      list-style-type: none;
      margin: 24px 0;
      padding: 0;
      li {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 6px 28px 0px rgba(172, 172, 190, 0.08);
        font-size: 15px;
        font-weight: 600;
        padding: 8px;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        margin: 10px 0;
      }
    }
    .tour-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 50px;

      a {
        color: #5c696e;
        font-size: 14px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
`;

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

const DownloadPDFModalButton = styled.div`
  background: ${colors.pri};
  color: #ffffff;
  height: 42px;
  width: 145px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;

  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

const NewDashboardMigrationContainer = styled.div`
  h3 {
    color: #162930;
    font-size: 20px;
    line-height: 28px;
  }

  p {
    color: #5c696e;
    font-size: 14px;
    line-height: 22px;
    max-width: 437px;
  }

  ul {
    font-size: 14px;
    line-height: 22px;
    color: #5c696e;
    font-weight: 400;
    padding-left: 14px;

    span {
      color: #162930;
    }

    li {
      margin-bottom: 8px;
    }
  }

  .cta-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .instruction {
    background: #eaf1fa;
    padding: 16px 20px;
    margin-top: 30px;
    color: #003c88;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    gap: 10px;

    ol {
      padding-left: 14px;
    }

    p {
      color: #003c88;
      font-size: 12px;
      margin-top: 0px;
    }
  }
`;

export {
  ModalContainer,
  RecipientDropDownContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  CancelButton,
  ModalInnerContainer,
  VirtualAccountModalContainer,
  VirtualAccountModalItem,
  TransactionDetailsDateContainer,
  TransactionDetailsAmountContainer,
  TransactionDetailsTypeContainer,
  TransactionDetailsTypeElement,
  TransactionDetailsChannelContainer,
  TransactionDetailsSourceContainer,
  TransactionDetailsSourceElement,
  NameResolutionContainer,
  NameIconContainer,
  NameText,
  DeleteContent,
  CancelButtonContainer,
  DeleteText,
  TextContent,
  IconTextContainer,
  InputContainer,
  SuccessMessage,
  ContentContainer,
  MessageDetails,
  SubTextContainer,
  TransactionButtonContainer,
  OtpInputContainer,
  NoPhoneNumberIcon,
  NoPhoneNumberBody,
  NewBusinessTwoGridContainer,
  PhoneUpdateContent,
  PhoneUpdateText,
  FilterModalFooter,
  FilterTypeContainer,
  FilterTypeElement,
  SliderAmountContainer,
  MarkAsPaidText,
  SuccessfulActivationModalContainer,
  FilterAmountContainer,
  ChangeLogoContainer,
  ChangeLogoButtonContainer,
  LogoUploadCancelContainer,
  MakePayoutContainer,
  AddRecipientContainer,
  AddNewRecipientContainer,
  PayoutSummaryHeader,
  PayoutSummaryBody,
  PayoutSummaryGrid,
  AuthenticationOptionHeader,
  AuthenticationOptionBody,
  AuthenticationOption,
  BulkPayoutButton,
  BulkPayoutUploadContainer,
  BulkPayoutDropzoneContainer,
  DateFilterContainer,
  AddTeamMemberContainer,
  TransactionDescriptionTypeContainer,
  TransactionBankTypeContainer,
  BulkPayoutPreviewContainer,
  SchedulePayoutContainer,
  OptionButton,
  DateInputContainer,
  PaymentCounterContainer,
  PaymentCounterOuterContainer,
  AddNewRecipientScheduleContainer,
  TwoGridContainer,
  MakePayoutScheduleContainer,
  ResendOtpContainer,
  StyledCustomInput,
  StyledCustomDropDown,
  StyledCalendarInputWrapper,
  AccountNameLoader,
  PermissionModalContainer,
  InvoiceDeliveryModalContainer,
  InvoiceDateContainer,
  TransactionSupportingDocContainer,
  DocumentWrapper,
  SupportingDocumentItem,
  PayoutApproversContainer,
  PayoutAuditTrailsContainer,
  SuccessApprovalIcon,
  ApprovalCommentWrapper,
  FailedApprovalIcon,
  AuditTrailSection,
  StyledTableEmptyContainer,
  ApprovePayoutContainer,
  StyledPreviewModal,
  ClosePreviewIcon,
  ReminderConfirmationModalContainer,
  AppsComplianceBody,
  PolicyTextContent,
  AppsConnectionBody,
  TransactionTypeLabel,
  TransactionTypeContainer,
  TransactionFilterWrapper,
  InfoContent,
  WithdrawDetailsContainer,
  WithdrawTransactionWrapper,
  QuickActionsContent,
  MoveFundsModalForm,
  ProductTourContainer,
  InitiatorInviteForm,
  AutomatedModalContainer,
  ModalRightFooter,
  NameResolutionLabel,
  PDFDocumentWrapper,
  BusinessCurrencyContainer,
  DownloadPDFModalButton,
  NewDashboardMigrationContainer
};
