import * as departmentClient from "lib/api/departments";
import { Errors } from "interfaces/errors";
import { useMutation, useQuery } from "react-query";
import {
  AddDepartmentPayload,
  EditDepartmentPayload,
  DepartmentResponseObject,
  RemoveUserPayload,
  AppointUserPayload
} from "interfaces/departments";
import { TeamMembersFilterInterface } from "interfaces/filter";
import { ITeamMemberResponseObject } from "interfaces/users";

const useAddNewDepartment = (options = {}) => {
  return useMutation<{}, Errors, AddDepartmentPayload>(
    departmentClient.createDepartment,
    options
  );
};

function useDeactivateDepartment(options = {}) {
  return useMutation<object, Errors, EditDepartmentPayload>(
    departmentClient.deactivateDepartment,
    options
  );
}

function useGetDepartments(business_id: string) {
  const { data, status } = useQuery(["list-of-departments" + business_id], () =>
    departmentClient.getDepartments(business_id)
  );
  return {
    data: data || ([] as DepartmentResponseObject[]),
    status
  };
}

function useGetDepartmentMembers(
  merchant_id: string,
  business_id: string,
  department_id: string,
  params?: TeamMembersFilterInterface
) {
  const { data, status } = useQuery(
    ["all-department-members" + business_id, params],
    () =>
      departmentClient.getDepartmentMembers(
        {},
        merchant_id,
        business_id,
        department_id,
        params
      )
  );

  return {
    data: data || ([] as ITeamMemberResponseObject[]),
    status
  };
}

const useRemoveUserFromDepartment = (options = {}) => {
  return useMutation<{}, Errors, RemoveUserPayload>(
    departmentClient.removeUserFromDepartment,
    options
  );
};
const useAssignAsHOD = (options = {}) => {
  return useMutation<{}, Errors, AppointUserPayload>(
    departmentClient.assignAsHOD,
    options
  );
};

export {
  useAddNewDepartment,
  useGetDepartments,
  useDeactivateDepartment,
  useGetDepartmentMembers,
  useRemoveUserFromDepartment,
  useAssignAsHOD
};
