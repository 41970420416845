import React from "react";
import defaultImagePath from "assets/svg/photo-holder.svg";
import { AvatarContainer } from "./styles";

export enum AvatarSizes {
    Tiny="tn",
    Small= "sm",
    Medium= "md",
    Large= "lg"
}
  
export interface AvatarProps {
    size?: AvatarSizes;
    src?: string;
    letter?: string;

}
  
const Avatar: React.FC<AvatarProps> = ({
    size = AvatarSizes.Small,
    src = defaultImagePath,
    letter
}: AvatarProps) => {
    return (
      <AvatarContainer sizeTag={size} useBgColor={Boolean(letter)}>
        {letter ? <span className="letter">{letter}</span> : <img alt="avatar" className="image" src={src} />}
      </AvatarContainer>
    );
};
  
export default Avatar;