import { CancelButton } from "components/Modal/styles";
import styled from "styled-components";
import { device } from "styles";

const NotFoundContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  main {
    flex: 1;
  }

  h1 {
    color: #d4fcea;
    font-size: 12rem;
    margin-bottom: 0;

    text-shadow: 8px 4px 4px rgba(22, 41, 48, 0.6);

    @media ${device.mobileS} {
      font-size: 6rem;
    }

    @media ${device.tablet} {
      font-size: 12rem;
    }
  }
  h3 {
    font-size: 24px;
    margin: 0;
  }
  p {
    font-size: 16px;
    color: rgba(72, 72, 71, 0.8);
    font-weight: 300;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media ${device.mobileS} {
      flex-direction: column;
      gap: 1rem;
    }

    @media ${device.tablet} {
      flex-direction: row;
      gap: 0.5rem;
    }
  }
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 40px;

    @media ${device.mobileS} {
      flex-direction: column;
      gap: 5px;
    }

    @media ${device.tablet} {
      flex-direction: row;
      gap: 25px;
    }

    .link {
      cursor: pointer;
      position: relative;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
        color: #006355;
      }
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          border: 0.5px solid rgba(16, 49, 41, 0.2);
          height: 16px;
          margin-left: 10px;

          @media ${device.mobileS} {
            border: none;
          }

          @media ${device.tablet} {
            border: 0.5px solid rgba(16, 49, 41, 0.2);
          }
        }
      }
    }
  }
`;

const ModifiedCancelButton = styled(CancelButton)`
  font-size: 14px;
  height: 50px;
`;

export { NotFoundContainer, ModifiedCancelButton };
