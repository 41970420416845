import styled from "styled-components";

type CountProps = {
  willInheritColor?: boolean;
}

type MobilePageHeadingProps = {
  latch?: boolean;
}

const MobilePageHeading = styled.div<MobilePageHeadingProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  background-color: #f9f9f9;
  color: rgba(22, 41, 48, 1);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: ${(props) => props.latch ? "-20px" : "0px"};
  margin-right: ${(props) => props.latch ? "-20px" : "0px"};

  span:first-child {
    font-weight: 600;
  }
`;

const Count = styled.span<CountProps>`
  color: ${(props) => props.willInheritColor ? "inherit" : "#fff"};

  sup {
    top: -0.4em;
    font-size: 60%;
    margin-right: 5px;
  }

  sub {
    opacity: 0.4;
    font-size: 60%;
    bottom: -0.05em;
  }
`;

export { Count, MobilePageHeading };
