import React, { ReactElement } from "react";
import Modal from ".";
import {
  CancelButton,
  DeleteContent,
  DeleteText,
  ModalFooter,
  TextContent
} from "./styles";
import CustomButton from "components/CustomButton";

interface MemberActionModalProps {
  closeModal: () => void;
  title: string;
  subtitle: React.ReactNode;
  icon: ReactElement;
  handleSubmitButton: Function;
  isButtonLoading?: boolean;
  submitButtonText: string;
  submitButtonColor?: string;
}

const MemberActionModal: React.FC<MemberActionModalProps> = ({
  closeModal,
  title,
  subtitle,
  icon,
  isButtonLoading,
  handleSubmitButton,
  submitButtonText = "proceed",
  submitButtonColor
}) => {
  return (
    <Modal
      isFooter={false}
      isHeader={false}
      close={closeModal}
      isWorking={isButtonLoading}
      proceed={() => handleSubmitButton()}
      submitButtonHeight={40}
      submitButtonText={submitButtonText}
    >
      <DeleteContent>
        <div className="modal-body" style={{ textAlign: "center" }}>
          {icon}
          <DeleteText>
            <div>{title}</div>
          </DeleteText>
          <TextContent>
            <div>{subtitle}</div>
          </TextContent>
        </div>
        <ModalFooter>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <CustomButton
            type="button"
            label={submitButtonText}
            isLoading={isButtonLoading}
            disabled={isButtonLoading}
            onClick={() => handleSubmitButton()}
            height={40}
            width={140}
			      bgColor={submitButtonColor ? submitButtonColor : undefined}
          />
        </ModalFooter>
      </DeleteContent>
    </Modal>
  );
};

export default MemberActionModal;
