import React, { ChangeEvent } from "react";
import CustomButton from "components/CustomButton";
import { decrypt_user0bj } from "utils/auth-util";
import { defaultBusinessKey, memberKey } from "utils/constants";
import storage from "utils/storage";
import { useHistory } from "react-router";
import {
  MemberContainer,
  TeamMemberTableContainer,
  FlexContainer,
  FilterButton,
  DepartmentPreviewHeader
} from "./styles";
import {
  BusinessInterface,
  IRolesResponseObject,
  ITeamMemberResponseObject,
  UserActionStatus
} from "interfaces/users";
import TableEmptyMessage from "components/Table/TableEmptyMessage";
import { SearchInputContainer } from "pages/Transaction/styles";
import { useMediaQuery, useTextFilteredList } from "lib/hooks";
import { device } from "styles";
import { TeamMembersFilterInterface } from "interfaces/filter";
import { FaAngleDown } from "react-icons/fa";

import {
  FilterDropdownContent,
  PayoutStatusContainer
} from "pages/Payout/styles";
import DropdownContent from "components/DropdownComponent/DropdownContent";
import { getPayoutStatusColor } from "utils/payout";
import { MdOutlineAdd } from "react-icons/md";
import DepartmentMemberTable from "./DepartmentMemberTable";
import { DepartmentResponseObject } from "interfaces/departments";
import { useGetDepartmentMembers } from "lib/query/departments";
import { useGetAllTeamMembers, useGetRoles } from "lib/query/user";

import { useModals } from "context/modalContext";
import AddDepartmentMemberModal from "components/Modal/AddDepartmentMemberModal";
import Pagination from "components/Pagination";
import { PaginationOnChange } from "interfaces/formElements";
import DuploFullPageLoader from "components/DuploFullPageLoader";
import { useQueryClient } from "react-query";

const DepartmentPreview = () => {
  const history = useHistory<{ department: DepartmentResponseObject }>();
  const queryClient = useQueryClient();

  const DepartmentHistoryState = history.location.state;
  /* @HINT: Get the merchant business details attached to the logged-in user account */
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

  const [filters, setFilters] = React.useState<TeamMembersFilterInterface>({
    page: 1,
    limit: 20,
    pageCount: 1,
    perPage: 20
  });

  const [open, setOpen] = React.useState<boolean>(false);

  /* @HINT: Establish whether this page is being displayed on a mobile device */
  const isMobile = useMediaQuery(device.mobile_);
  const memberInfo: ITeamMemberResponseObject =
    decrypt_user0bj<ITeamMemberResponseObject>(storage.get(memberKey));

  const isMemberUser = !(
    memberInfo &&
    Object.keys(memberInfo).length === 0 &&
    Object.getPrototypeOf(memberInfo) === Object.prototype
  );

  const { data: members, status: memberStatus } = useGetDepartmentMembers(
    defaultBusinessInfo.merchant_id,
    defaultBusinessInfo.id,
    DepartmentHistoryState.department.id,
    filters
  );
  const { data: listOfAllMembers, status } = useGetAllTeamMembers(
    defaultBusinessInfo.merchant_id,
    defaultBusinessInfo.id,
    { ...filters, department_id: "null" }
  );
  const { data: listOfRoles } = useGetRoles(
    !isMemberUser
      ? UserActionStatus.owner
      : defaultBusinessInfo?.role?.name || memberInfo?.role?.name,
    defaultBusinessInfo.id
  );

  const { close: closeUserModal, push } = useModals();

  const handlePaginationChange = (e: PaginationOnChange) => {
    setFilters({ ...filters, page: e.selected + 1 });
  };

  const handlePageDropdown = (e: string) => {
    setFilters({ ...filters, limit: Number(e), page: 1, perPage: Number(e) });
  };

  React.useEffect(() => {
    queryClient.invalidateQueries("all-department-members");
  }, [queryClient]);

  const triggerAddUserModal = (
    department_id: string,
    members: ITeamMemberResponseObject[],
    roles: IRolesResponseObject[]
  ) => {
    push(
      <AddDepartmentMemberModal
        departmentId={department_id}
        closeAddMemberModal={closeUserModal}
        listOfAllMembers={members}
        listOfRoles={roles}
      />
    );
  };

  const [uiDepartmentMembers, handleFilterTask] =
    useTextFilteredList<ITeamMemberResponseObject>(
      {
        text: "",
        list: members
      },
      {
        /* @HINT: This is for the type of filtering used on the list: options : [fuzzy, specific, complete]; default: specific */
        filterTaskName: "complete",
        /* @FIXME: fetch filtered team members from the server-side (Duplo backend) based on */
        /* ... the search term if and only if the client-side came up empty */
        fetchRemoteFilteredList: () => {
          return Promise.resolve([]);
        }
      }
    );

  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterTask(e, ["user.first_name", "user.last_name"]);
  };

  const handleStatusSelection = (status: string) => {
    setFilters({ ...filters, active_status: status });
    setOpen(false);
  };

  return (
    <div>
      {status === "loading" || memberStatus === "loading" ? (
        <DuploFullPageLoader />
      ) : (
        <MemberContainer>
          <FlexContainer>
            <DepartmentPreviewHeader>
              <h3>
                {DepartmentHistoryState.department.name}{" "}
                {DepartmentHistoryState?.department?.total_members !== 0 && (
                  <span>
                    {DepartmentHistoryState?.department?.total_members}
                  </span>
                )}
              </h3>
              <p>You can manage your team from here</p>
            </DepartmentPreviewHeader>

            <CustomButton
              label={
                <span
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <MdOutlineAdd style={{ flexShrink: 0 }} />
                  Add Team Member
                </span>
              }
              height={40}
              width={180}
              onClick={() =>
                triggerAddUserModal(
                  DepartmentHistoryState.department.id,
                  listOfAllMembers,
                  listOfRoles
                )
              }
            />
          </FlexContainer>

          <TeamMemberTableContainer>
            <SearchInputContainer>
              <div
                style={
                  isMobile
                    ? { display: "flex", alignItems: "center", gap: "10px" }
                    : { display: "flex", alignItems: "center", gap: "30px" }
                }
              >
                <input
                  placeholder="Search team member "
                  value={uiDepartmentMembers.text}
                  onChange={onSearchTextChange}
                  style={isMobile ? undefined : { marginBottom: "20px" }}
                />
                <div
                  style={
                    isMobile
                      ? { position: "relative", marginBottom: "0px" }
                      : { position: "relative", marginBottom: "20px" }
                  }
                >
                  <FilterButton
                    onClick={() => setOpen(!open)}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Filter by
                    <FaAngleDown size={17} />
                  </FilterButton>
                  {open && (
                    <FilterDropdownContent>
                      <DropdownContent
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <p className="header">Filter by Status</p>
                        <ul>
                          <li
                            className="dropdown-options"
                            style={
                              filters.active_status === "active"
                                ? {
                                    cursor: "pointer",
                                    backgroundColor: "#e1e1e1",
                                    borderLeft: "3px solid gray",
                                    paddingLeft: "5px"
                                  }
                                : {
                                    borderLeft: "3px solid transparent",
                                    paddingLeft: "5px"
                                  }
                            }
                          >
                            <PayoutStatusContainer
                              className={getPayoutStatusColor("successful")}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleStatusSelection("active")}
                            >
                              Active
                            </PayoutStatusContainer>
                          </li>
                          <li
                            className="dropdown-options"
                            style={
                              filters.active_status === "pending"
                                ? {
                                    cursor: "pointer",
                                    backgroundColor: "#e1e1e1",
                                    borderLeft: "3px solid gray",
                                    paddingLeft: "5px"
                                  }
                                : {
                                    borderLeft: "3px solid transparent",
                                    paddingLeft: "5px"
                                  }
                            }
                          >
                            <PayoutStatusContainer
                              className={getPayoutStatusColor("pending")}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleStatusSelection("pending")}
                            >
                              Pending
                            </PayoutStatusContainer>
                          </li>

                          <li
                            className="dropdown-options"
                            style={
                              filters.active_status === "inactive"
                                ? {
                                    cursor: "pointer",
                                    backgroundColor: "#e1e1e1",
                                    borderLeft: "3px solid gray",
                                    paddingLeft: "5px"
                                  }
                                : {
                                    borderLeft: "3px solid transparent",
                                    paddingLeft: "5px"
                                  }
                            }
                          >
                            <PayoutStatusContainer
                              className={getPayoutStatusColor("draft")}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleStatusSelection("inactive")}
                            >
                              Inactive
                            </PayoutStatusContainer>
                          </li>
                        </ul>
                      </DropdownContent>
                    </FilterDropdownContent>
                  )}
                </div>
              </div>
            </SearchInputContainer>

            <DepartmentMemberTable
              listOfMembers={uiDepartmentMembers.list}
              departmentId={DepartmentHistoryState?.department?.id}
            />
            {uiDepartmentMembers.list.length > 0 && (
              <>
                <Pagination
                  selectedOption={String(filters.limit)}
                  currentPage={filters?.page || 1}
                  setSelectedOption={handlePageDropdown}
                  handlePaginationChange={handlePaginationChange}
                  pageCount={filters.pageCount || 20}
                />
              </>
            )}
            {uiDepartmentMembers.list.length === 0 && (
              <TableEmptyMessage message={"No member(s) added yet"} />
            )}
          </TeamMemberTableContainer>
        </MemberContainer>
      )}
    </div>
  );
};

export default DepartmentPreview;
