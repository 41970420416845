import isEmpty from "lodash.isempty";
import { useMediaQuery } from "react-responsive";
import { kFormatter } from "./amountValidation";
import { Count } from "./styles";
import React from "react";

function isNotANumber(value?: string | number | boolean | null) {
  let localNumberValue = typeof value === "number" ? value : -Infinity;

  if (typeof value === "string") {
    localNumberValue = Number(value);
    return value === "NaN" || Number.isNaN(localNumberValue);
  }

  if (localNumberValue !== -Infinity) {
    if (typeof value === "number" && Number.isInteger(value)) {
      return Number.isNaN(value);
    }
  }

  return Number.isFinite(value);
}

function naira(number: string) {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  }).format(Number(number));
}

function nairaFormat(number: string): { naira: string; kobo: string } {
  const value = Number(number).toFixed(2);
  const [naira, kobo] = value.split(".");

  return { naira: thousandFormat(naira), kobo: `.${kobo}` };
}

function thousandFormat(amountText: string) {
  let formatted;

  if (
    !isEmpty(amountText) &&
    amountText[0] === "0" &&
    amountText.length === 1
  ) {
    return (formatted = amountText);
  } else if (!isEmpty(amountText) && amountText[0] === "0") {
    formatted = amountText
      .toString()
      .replace(/^0/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    formatted = amountText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return formatted;
}

function NairaFormatter({
  amount,
  override = false
}: {
  amount: string | undefined;
  override?: boolean;
}) {
  const isMobileAndTablet = useMediaQuery({
    query: "(max-width: 1023px)"
  });

  if (isMobileAndTablet) {
    return (
      <Count willInheritColor={override}>₦ {kFormatter(Number(amount))}</Count>
    );
  }

  return (
    <Count willInheritColor={override}>
      ₦{nairaFormat(amount || "0").naira}
      {nairaFormat(amount || "0").kobo}
    </Count>
  );
}

function NairaFormatterWithoutSign({ amount }: { amount: string | undefined }) {
  const isMobileAndTablet = useMediaQuery({
    query: "(max-width: 1023px)"
  });

  if (isMobileAndTablet) {
    return <Count>{kFormatter(Number(amount))}</Count>;
  }

  return (
    <Count>
      {nairaFormat(amount || "0").naira}
      {nairaFormat(amount || "0").kobo}
    </Count>
  );
}

function formatAmount(amount?: number | string, nilSentinel = "0") {
  const numeral = Number(amount);

  if (Number.isNaN(numeral)) {
    return nilSentinel;
  }

  return (numeral + 0.001).toLocaleString().slice(0, -1);
}

export {
  NairaFormatter,
  thousandFormat,
  isNotANumber,
  naira,
  nairaFormat,
  NairaFormatterWithoutSign,
  formatAmount
};
