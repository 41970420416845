import React, { ErrorInfo } from "react";
import { Routes } from "routes/routes-constants";

const DisplayError = ({ error, clearError }: { error: Error | null, info: ErrorInfo, clearError: () => void, context?: any }) => {
    return (
      <React.Fragment>
        <h1>Something went wrong: {(error?.message) || ""}.</h1>
        <button onClick={() => {
            clearError();
            window.location.href = Routes.Dashboard;
        }}>Reset</button>
      </React.Fragment>
    );
};

export default DisplayError;