import React from "react";

export type FilterFunction<T = {}> = (fiterText: string, filterList: {}[], filterListItemKey?: string[]) => T[]

export interface TextFilterAlgorithms {
    [key: string]: FilterFunction
}

const TextFilterAlgorithmsContext = React.createContext<TextFilterAlgorithms | null>(null);

export default TextFilterAlgorithmsContext;