import * as yup from "yup";
import { SchemaObjectDescription } from "interfaces/forms";
import { PayoutConfigCustomFieldObject } from "interfaces/payout";

export type Literal = unknown
export interface ReducableObject {
    [key:string]: Literal | ReducableObject;
}

export const isEmpty = <T>(objectValue: T): boolean => {
  if(!objectValue || typeof objectValue !== "object") {
    return true;
  }
 
  for(const prop in objectValue) {
    if(Object.prototype.hasOwnProperty.call(objectValue, prop)) {
      return false;
    }
  }

  return JSON.stringify(objectValue) === JSON.stringify({});
};

function diff (source: ReducableObject, extra: ReducableObject, exclude = ([] as unknown[])) {
  const result = {} as ReducableObject;
  
  if (!exclude)	exclude = [];
  
  for (const prop in source) {
    if (source.hasOwnProperty(prop) && prop !== "__proto__") {
      if (exclude.indexOf(source[prop]) == -1) {

        // check if obj2 has prop
        if (!extra.hasOwnProperty(prop)) {
          result[prop] = source[prop];
        }

        // check if prop is object and 
        // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
        else if (source[prop] === Object(source[prop])) {
          const difference = diff(source[prop] as ReducableObject, extra[prop] as ReducableObject);

          if (Object.keys(difference).length > 0) {
            result[prop] = difference;
          }
        }

        // check if obj1 and obj2 are equal
        else if (source[prop] !== extra[prop]) {
          if (source[prop] === undefined)
            result[prop] = "undefined";
          if (source[prop] === null)
            result[prop] = null;
          else if (typeof source[prop] === "function")
            result[prop] = "function";
          else if (typeof source[prop] === "object")  
            result[prop] = "object";
          else
            result[prop] = source[prop];
        }
      }
    }
  }

  return result;
}

export const hasDiff = (source: ReducableObject, extra: ReducableObject, exclude = []) => {
  return !isEmpty(diff(source, extra, exclude));
};

function createYupSchemaDefinitionFromConfig(
  schema: { 
    [key: string]: yup.NumberSchema | yup.BooleanSchema | yup.DateSchema | yup.StringSchema
  },
  config: {
    title: string,
    type: "string" | "number" | "boolean" | "object",
    tests: Array<{
      name: "required";
      params: Record<string, unknown> | undefined;
    }>
  }
) {
  const { title, type, tests = [] } = config;
  if (!yup[type]) {
    return schema;
  }
  let validator = yup[type]() as (yup.NumberSchema | yup.BooleanSchema | yup.DateSchema | yup.StringSchema);
  tests.forEach(test => {
    const { params, name } = test;
    if (!validator[name]) {
      return;
    }

    if (params) {
      validator = Boolean(params.regex) ? validator[name]() : validator[name](params);
    } else {
      validator = validator[name]();
    }
  });

  schema[title] = validator.required();
  return schema;
}

export function createYupSchemaValidationFromPlainValidation (
    serialiedForm: {
      validation: SchemaObjectDescription,
      fields: Array<PayoutConfigCustomFieldObject>
    }
  ) {
  const { fields } = serialiedForm.validation;
  const configurations = [];

  for (const title in fields) {
    if (fields.hasOwnProperty(title)) {
      const field = fields[title];
      configurations.push({
        title,
        type: field.type,
        tests: field.tests
      });
    }
  }

  const yupSchema = configurations.reduce<{ [key: string]: yup.NumberSchema | yup.BooleanSchema | yup.DateSchema | yup.StringSchema }>(
    createYupSchemaDefinitionFromConfig,
    {}
  );

  return yupSchema;
}

export const createYupSchemaFromSerializedFormValidation = (serialiedForm: {
  validation: SchemaObjectDescription,
  fields: Array<PayoutConfigCustomFieldObject>
}) => {
  const { type } = serialiedForm.validation;
  return yup[type]().shape<{}>(createYupSchemaValidationFromPlainValidation(serialiedForm));
};

export function throttleFilterCallbackRoutine<C, S, N extends () => void> (routine: (...args: [C,S]) => N, routineArgs: [C, S], interval = 500) {
    let shouldFire = true;
    return function callback() {
        if (shouldFire) {
            const result = routine.call(null, ...routineArgs);
            shouldFire = false;
            setTimeout(() => {
              shouldFire = true;
            }, interval);
            return result;
        }
        return (() => undefined) as N;
    };
}

export function extractPropertyValue<O extends ReducableObject> (
    objectProperty = "",
    object = {} as O,
    delimeter = "."
  ) {
    const value = objectProperty.includes(delimeter)
        // @ts-ignore
      ? objectProperty.split(delimeter).reduce<O>((subObject, prop) => {
          const result = typeof subObject === "object" ? subObject[prop] : subObject;
          return result;
        }, object)
      : object[objectProperty];
    return value;
}
  
export function toUniqueItemList<T extends {}> (initialList: T[] = [], propertyKey = "") {
  let initialListCounter,
    innerLoopCounter,
    finalListItem,
    initialListItem;
  
  const finalList: T[] = [];

  resetLabel: for (
    initialListCounter = 0;
    initialListCounter < initialList.length;
    ++initialListCounter
  ) {
    for (
      innerLoopCounter = 0;
      innerLoopCounter < finalList.length;
      ++innerLoopCounter
    ) {
      finalListItem = finalList[innerLoopCounter];
      finalListItem =
        propertyKey !== "" && typeof finalListItem !== "string"
          ? extractPropertyValue<T>(propertyKey, finalListItem)
          : finalListItem;
      initialListItem = initialList[initialListCounter];
      initialListItem =
        propertyKey !== "" && typeof initialListItem !== "string"
          ? extractPropertyValue<T>(propertyKey, initialListItem)
          : initialListItem;

      if (finalListItem === initialListItem) continue resetLabel;
    }
    finalList.push(initialList[initialListCounter]);
  }

  return finalList;
}