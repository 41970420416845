import { AxiosRequestHeaders } from "axios";
import config from "config";
import { ExpenseListFilterInterface } from "interfaces/filter";
import { client } from "lib/client";
import {
  ExpenseApprovalPayload,
  ExpenseListResponse,
  ExpenseStatsResponse,
  SingleExpenseResponse
} from "pages/Expense/@types";

const HEADERS: AxiosRequestHeaders = {
  "Content-Type": "application/json"
};

async function getExpenseStats(
  {},
  department_id: string,
  business_id: string,
  initiator_id: string
) {
  const response = await client<ExpenseStatsResponse, object>(
    `${config.API_ENDPOINT}merchants/expense/${department_id}/stat?business_id=${business_id}&initiator_id=${initiator_id}`,
    "GET",
    {
      headers: HEADERS,
      withCredentials: true
    }
  );
  return response;
}

async function getSingleExpense({}, id: string, business_id: string) {
  const response = await client<SingleExpenseResponse, object>(
    `${config.API_ENDPOINT}merchants/expense/${id}?business_id=${business_id}`,
    "GET",
    {
      headers: HEADERS,
      withCredentials: true
    }
  );
  return response;
}

async function getAllExpenses(
  {},
  department_id: string,
  business_id: string,
  creator_id: string,
  params?: ExpenseListFilterInterface
) {
  let URL = "";
  if (creator_id) {
    URL = `${config.API_ENDPOINT}merchants/expense?business_id=${business_id}&department_id=${department_id}&creator_id=${creator_id}`;
  } else {
    URL = `${config.API_ENDPOINT}merchants/expense?business_id=${business_id}&department_id=${department_id}`;
  }
  const response = await client<ExpenseListResponse, object>(URL, "GET", {
    headers: HEADERS,
    withCredentials: true,
    params
  });
  return response;
}

async function createExpense(payload: FormData) {
  const response = await client<{}, FormData>(
    `${config.MERCHANT_API}expense`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function approveExpense(payload: ExpenseApprovalPayload) {
  const response = await client<{}, ExpenseApprovalPayload>(
    `${config.MERCHANT_API}expense/approve`,
    "PATCH",
    {
      body: payload
    }
  );
  return response;
}

export {
  getExpenseStats,
  createExpense,
  getAllExpenses,
  getSingleExpense,
  approveExpense
};
