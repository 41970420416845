import aes from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import { AxiosRequestConfig } from "axios";
import { ENCRYPTION_KEY } from "./constants";
import storage from "./storage";
import { Routes } from "routes/routes-constants";

export const isPasswordEightCharactersOrMore = (
  passwordText?: string | null
): boolean => {
  if (!passwordText) {
    return false;
  }
  return passwordText.length >= 8;
};

export const encrypt_key = (token: string): string => {
  const encryptedToken = aes.encrypt(token, ENCRYPTION_KEY).toString();

  return encryptedToken;
};

export const decrypt_key = (token: string): string => {
  const decryptedToken = aes.decrypt(token, ENCRYPTION_KEY);
  return decryptedToken.toString(enc);
};

export const encrypt_userobj = (payload: object | string): string => {
  const encryptedPayload = aes
    .encrypt(
      typeof payload === "string" ? payload : JSON.stringify(payload),
      ENCRYPTION_KEY
    )
    .toString();
  return encryptedPayload;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export function decrypt_userobj<T extends unknown>(
  payload: string | null
): T | null {
  let parsedDecryptedPayload: T | null = null;

  if (payload) {
    const decryptedPayload = aes.decrypt(payload, ENCRYPTION_KEY);
    const decryptedPayloadString = decryptedPayload.toString(enc);

    if (decryptedPayloadString !== "") {
      try {
        parsedDecryptedPayload = JSON.parse(decryptedPayloadString) as T;
      } catch (e) {
        const error = e as Error;
        if (error.name === "SyntaxError") {
          parsedDecryptedPayload = decryptedPayloadString as T;
        }
      }
    }
  }

  return parsedDecryptedPayload;
}

export function decrypt_user0bj<T extends { id: string }>(
  user: string | null
): T {
  if (user) {
    const decryptedUser = aes.decrypt(user, ENCRYPTION_KEY);
    const decryptedUserString = decryptedUser.toString(enc);

    let parsedDecryptedUser = null;

    if (decryptedUserString !== "") {
      parsedDecryptedUser = JSON.parse(decryptedUserString) as
        | T
        | null
        | boolean;

      if (parsedDecryptedUser instanceof Object) {
        window.sessionStorage.setItem("decrypted:user", decryptedUserString);
      }
    } else {
      const decryptedUserFromStorage =
        window.sessionStorage.getItem("decrypted:user");
      if (decryptedUserFromStorage !== null) {
        parsedDecryptedUser = JSON.parse(decryptedUserFromStorage) as T | null;
      }
    }

    if (parsedDecryptedUser) {
      return parsedDecryptedUser === true ? ({} as T) : parsedDecryptedUser;
    }
  }

  return {} as T;
}

export function decrypt_codat_object<T>(codatInfo: string | null): T {
  if (codatInfo) {
    const decryptedCodatInfo = aes.decrypt(codatInfo, ENCRYPTION_KEY);
    const decryptedUserString = decryptedCodatInfo.toString(enc);

    let parsedDecryptedUser = null;

    if (decryptedUserString !== "") {
      parsedDecryptedUser = JSON.parse(decryptedUserString) as
        | T
        | null
        | boolean;

      if (parsedDecryptedUser instanceof Object) {
        window.sessionStorage.setItem("decrypted:codat", decryptedUserString);
      }
    } else {
      const decryptedUserFromStorage =
        window.sessionStorage.getItem("decrypted:codat");
      if (decryptedUserFromStorage !== null) {
        parsedDecryptedUser = JSON.parse(decryptedUserFromStorage) as T | null;
      }
    }

    if (parsedDecryptedUser) {
      return parsedDecryptedUser === true ? ({} as T) : parsedDecryptedUser;
    }
  }

  return {} as T;
}

/**
 * handleLogout: executes the logout functionality
 *
 * @param showDismissableAlert
 * @return {Undefined}
 */
export const handleLogout = (
  showDismissableAlert = false,
  redirectToHomeOrOverviewPage = false
) => {
  storage.clear();
  storage.set("activeMins", "");
  localStorage.setItem("isIdle", String(showDismissableAlert));
  sessionStorage.clear();

  if (redirectToHomeOrOverviewPage) {
    window.location.href = Routes.Dashboard;
  } else {
    window.location.reload();
  }
};

/**
 * handleNavigation:
 */
export const handleNavigation = () => {
  window.location.replace("https://www.tryduplo.com");
};

/**
 * isCORSVIolation:
 *
 * @param request
 * @param config
 * @returns {Boolean}
 */
export const isCORSViolation = (
  request: XMLHttpRequest,
  config: AxiosRequestConfig
) => {
  const frontendURIHost = window.location.host;
  const backendBaseURL = new URL(
    config?.baseURL || config?.url || "https://x.yz"
  );
  const backendURIHost = backendBaseURL.host;
  const isCrossDomainRequest =
    backendURIHost !== "x.yz" && frontendURIHost !== backendURIHost;

  let hasAccessControlOnOrigin = false;
  const requestHasValidStatus = Boolean(
    request.status >= 200 && request.status <= 508
  );
  const contentType = request.getResponseHeader("Content-Type");

  if (isCrossDomainRequest) {
    const allowedOrigin =
      request.getResponseHeader("Access-Control-Allow-Origin") || "";

    if (allowedOrigin.trim() === "*") {
      if (request.withCredentials) {
        return true;
      }
    } else {
      hasAccessControlOnOrigin = Boolean(
        allowedOrigin.trim() === backendBaseURL.origin.trim()
      );
    }
  }

  return (
    isCrossDomainRequest &&
    requestHasValidStatus &&
    !hasAccessControlOnOrigin &&
    contentType !== null
  );
};
