import config from "config";
import {
  ForgotPasswordInterface,
  LoginInterface,
  ResetPasswordSubmitInterface,
  SignUpSubmitInterface,
  V2PasswordMigrateInterface
} from "interfaces/auth";
import { TeamMembersFilterInterface } from "interfaces/filter";
import {
  ActivateTeamMemberPayload,
  AddonTeamMemberRolePayload,
  CustomRolePayload,
  IAllTeamMembersResponse,
  IRolesResponseObject,
  ITeamMemberResponseObject,
  ProfileUpdatePayload,
  RemoveOnTeamMemberRolePayload,
  TeamMemberPayload,
  UpdateTeamMemberInterface
} from "interfaces/users";
import { client } from "lib/client";

async function login(payload: LoginInterface) {
  const response = await client<any, LoginInterface>(
    `${config.API_ENDPOINT}merchants/accounts/sign-in`,
    "POST",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function v2PasswordMigrate(payload: V2PasswordMigrateInterface) {
  const response = await client<any, V2PasswordMigrateInterface>(
    `${config.API_ENDPOINT}merchants/accounts/process-v3-migration`,
    "POST",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function signup(payload: SignUpSubmitInterface) {
  const response = await client<{}, SignUpSubmitInterface>(
    `${config.API_ENDPOINT}merchants/accounts/sign-up`,
    "POST",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function verifyToken(payload: { token: string }) {
  const response = await client<any, { token: string }>(
    `${config.API_ENDPOINT}merchants/accounts/verify-token`,
    "POST",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function profileUpdate(payload: ProfileUpdatePayload) {
  const response = await client<any, ProfileUpdatePayload>(
    `${config.API_ENDPOINT}merchants/profile/update-profile`,
    "PATCH",
    { body: payload }
  );
  return response;
}

async function forgotPassword(payload: ForgotPasswordInterface) {
  const response = await client<any, ForgotPasswordInterface>(
    `${config.API_ENDPOINT}merchants/accounts/forgot-password`,
    "PATCH",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function resetPassword(payload: ResetPasswordSubmitInterface) {
  const response = await client<any, ResetPasswordSubmitInterface>(
    `${config.API_ENDPOINT}merchants/accounts/reset-password`,
    "PUT",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function refreshCookies() {
  const response = await client<string, object>(
    `${config.API_ENDPOINT}merchants/profile/refresh-cookies`,
    "GET"
  );
  return response;
}

async function getRoles(role: string, businessId: string) {
  const response = await client<IRolesResponseObject[], object>(
    `${config.API_ENDPOINT}merchants/roles?requesting_role=${role}&business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function assignAdditionalRoles(payload: AddonTeamMemberRolePayload) {
  const response = await client<{}, AddonTeamMemberRolePayload>(
    `${config.API_ENDPOINT}merchants/accounts/assign-user-role`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function unassignAdditionalRoles(payload: RemoveOnTeamMemberRolePayload) {
  const response = await client<{}, RemoveOnTeamMemberRolePayload>(
    `${config.API_ENDPOINT}merchants/accounts/unassign-user-role`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function getUserDetail(
  _: unknown,
  userId: string,
  businessId: string,
  params?: TeamMembersFilterInterface
) {
  const response = await client<ITeamMemberResponseObject, object>(
    `${config.API_ENDPOINT}merchants/accounts/user/${userId}?business_id=${businessId}`,
    "GET",
    { params }
  );

  return response;
}

async function getAllTeamMembers(
  _: unknown,
  merchantId: string,
  businessId: string,
  roleType?:
    | "Approver"
    | "Payer"
    | "Owner"
    | "Admin"
    | "Approver,Owner,Admin"
    | "Approver,Admin"
    | "Initiator"
    | "Initiator, Payer"
    | "Initiator,Payer",
  params?: TeamMembersFilterInterface
) {
  const response = await client<ITeamMemberResponseObject[], object>(
    `${
      config.API_ENDPOINT
    }merchants/accounts/users?merchant_id=${merchantId}&business_id=${businessId}${
      roleType ? "&role_type=" + roleType : ""
    }`,
    "GET",
    { params }
  );

  return response;
}

async function fetchTeamMembers(
  merchantId: string,
  params?: TeamMembersFilterInterface
): Promise<IAllTeamMembersResponse> {
  return client<IAllTeamMembersResponse, TeamMembersFilterInterface>(
    `${config.API_ENDPOINT}merchants/accounts/users?merchant_id=${merchantId}`,
    "GET",
    {
      params: params
    }
  );
}

async function createTeamMember(payload: TeamMemberPayload) {
  const response = await client<ITeamMemberResponseObject, TeamMemberPayload>(
    `${config.API_ENDPOINT}merchants/accounts/user`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function activateTeamMember(payload: ActivateTeamMemberPayload) {
  const response = await client<object, ActivateTeamMemberPayload>(
    `${config.API_ENDPOINT}merchants/accounts/user/activate`,
    "PATCH",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function reactivateTeamMember({
  email,
  account_reactivation,
  business_id
}: {
  email: string;
  account_reactivation: boolean;
  business_id?: string;
}) {
  const response = await client<{}, object>(
    `${
      config.API_ENDPOINT
    }merchants/accounts/resend-email-verification/${encodeURIComponent(email)}${
      account_reactivation ? "?account_reactivation=true" : ""
    }${
      account_reactivation && Boolean(business_id)
        ? "&business_id=" + business_id
        : ""
    }`,
    "GET"
  );
  return response;
}

async function resendMemberInvite(email: string, business_id?: string) {
  const response = await client<object, object>(
    `${
      config.API_ENDPOINT
    }merchants/accounts/resend-email-verification/${encodeURIComponent(
      email
    )}?business_id=${business_id}`,
    "GET"
  );
  return response;
}

async function updateTeamMemberDetails(payload: UpdateTeamMemberInterface) {
  const response = await client<
    ITeamMemberResponseObject,
    UpdateTeamMemberInterface
  >(`${config.API_ENDPOINT}merchants/accounts/user`, "PATCH", {
    body: payload,
    isHeader: true
  });
  return response;
}

async function createCustomRole(payload: CustomRolePayload) {
  const response = await client<{}, CustomRolePayload>(
    `${config.API_ENDPOINT}merchants/roles`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

export {
  login,
  signup,
  forgotPassword,
  resetPassword,
  profileUpdate,
  verifyToken,
  refreshCookies,
  getRoles,
  createTeamMember,
  activateTeamMember,
  reactivateTeamMember,
  getAllTeamMembers,
  fetchTeamMembers,
  assignAdditionalRoles,
  resendMemberInvite,
  unassignAdditionalRoles,
  updateTeamMemberDetails,
  createCustomRole,
  getUserDetail,
  v2PasswordMigrate
};
