import { client } from "lib/client";
import config from "config";
import {
  AddDepartmentPayload,
  EditDepartmentPayload,
  DepartmentResponseObject,
  RemoveUserPayload,
  AppointUserPayload
} from "interfaces/departments";
import { TeamMembersFilterInterface } from "interfaces/filter";
import { ITeamMemberResponseObject } from "interfaces/users";

async function createDepartment(payload: AddDepartmentPayload) {
  const response = await client<{}, AddDepartmentPayload>(
    `${config.API_ENDPOINT}merchants/departments`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function getDepartments(businessId: string) {
  const response = await client<DepartmentResponseObject[], object>(
    `${config.API_ENDPOINT}merchants/departments?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function deactivateDepartment(payload: EditDepartmentPayload) {
  const response = await client<object, EditDepartmentPayload>(
    `${config.API_ENDPOINT}merchants/departments/${payload.department_id}`,
    "PUT",
    {
      body: payload,
      isHeader: true
    }
  );
  return response;
}

async function getDepartmentMembers(
  _: unknown,
  merchantId: string,
  businessId: string,
  departmentId: string,
  params?: TeamMembersFilterInterface
) {
  const response = await client<ITeamMemberResponseObject[], object>(
    `${config.API_ENDPOINT}merchants/accounts/users?merchant_id=${merchantId}&business_id=${businessId}&department_id=${departmentId}`,
    "GET",
    { params }
  );

  return response;
}

async function removeUserFromDepartment(payload: RemoveUserPayload) {
  const response = await client<{}, RemoveUserPayload>(
    `${config.API_ENDPOINT}merchants/departments/remove-user`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}
async function assignAsHOD(payload: AppointUserPayload) {
  const response = await client<{}, AppointUserPayload>(
    `${config.API_ENDPOINT}merchants/departments/assign-hod`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

export {
  createDepartment,
  getDepartments,
  deactivateDepartment,
  getDepartmentMembers,
  removeUserFromDepartment,
  assignAsHOD
};
