export const Routes = {
  Apps: "/app/applications",
  AppsPreview: "/app/applications/preview",
  Bills: "/app/bills",
  BillTransactions: "/app/bills/transactions",
  BillsDetail: "/app/bills/detail",
  Expenses: "/app/expenses",
  EditBalances: "/app/expenses/edit",
  PayBill: "/app/bills/pay",
  PreviewBill: "/app/bills/create/preview",
  CreateBill: "/app/bills/create",
  Recipients: "/app/recipients",
  CreateRecipient: "/app/recipients/create",
  Payout: "/app/payout",
  PayoutsSettings: "/app/payout/settings",
  NewPayout: "/app/payout/new",
  NewPolicy: "/app/policy/new",
  CreateRole: "/app/team/role/new",
  NewTeamMember: "/app/team/member/new",
  EditTeamMember: "/app/team/member/edit",
  NewBulkPayout: "/app/payout/new-bulk",
  BulkPayoutSummary: "/app/payout/bulk-summary",
  BulkPayoutDetails: "/app/payout/details",
  BulkPayoutReview: "/app/payout/review",
  International: "/app/international",
  NewInternationalPayment: "/app/international/new",
  PreviewInternationalPayment: "/app/international/preview",
  RecipientPreview: "/app/recipients/preview",
  Customers: "/app/customers",
  CustomerDetails: "/app/customers/details",
  CustomerKYCDetails: "/app/customers/kyc-details",
  CreateCustomer: "/app/customers/create",
  CustomerBulkUpload: "/app/customers/upload",
  Intro: "app/get-started",
  Dashboard: "/app/dashboard",
  Transaction: "/app/transactions",
  Invoice: "/app/invoice",
  Compliance: "/app/compliance",
  ComplianceUpdate: "/app/compliance/update",
  CreateInvoice: "/app/invoice/create",
  EditInvoice: "/app/invoice/edit",
  DraftInvoice: "/app/invoice/draft",
  PreviewInvoice: "/app/invoice/preview",
  InvoiceLink: "/view-invoice/:id",
  RevisedInvoice: "/app/invoice/revise-invoice",
  Bussiness: "/app/business",
  Wallets: "/app/wallets",
  Virtual: "/app/virtual-accounts",
  WalletsUpload: "/app/wallets/upload",
  CreateWallet: "/app/wallets/create",
  WalletDetails: "/app/wallets/details",
  Profile: "/app/profile",
  Settings: "/app/settings",
  Permissions: "/app/settings/permissions",
  Security: "/app/security",
  GoogleAuth: "/app/security/2fa",
  Login: "/auth/login",
  ForgotPassword: "/auth/forgot-password",
  ResetPassword: "/auth/reset-password",
  VerifyToken: "/registration/verify",
  Checkout: "/checkout",
  Signup: "/auth/signup",
  Verification: "/registration/success",
  Invitation: "/auth/welcome",
  ActivateMember: "/auth/member/activation",
  Approvals: "/app/approvals",
  Requisitions: "/app/requisitions",
  NewRequisitions: "/app/requisitions/new",
  RequisitionsDetails: "/app/requisitions/view",
  ExpenseDetails: "/app/requisitions/expense-details",
  PreviewBApproval: "/app/approvals/bill/preview",
  PreviewApproval: "/app/approvals/payout/preview",
  ApprovalDocumentsPreview: "/app/approvals/document",
  RecipientBillPreview: "/app/bills/preview",
  RecipientDocumentsPreview: "/app/recipients/preview/document",
  NotFound: "/404",
  DepartmentPreview: "/app/settings/department/preview"
};
